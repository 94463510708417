import React from 'react';
import "./roundchart.scss";

import { withTranslation } from 'react-i18next';

class RoundChart extends React.Component {

    state = {
        donutval: 0
    };

    render() {

        const {value, isStart, width, stroke, viewReportChart} = this.props;
        const { t } = this.props;

        let cSize = 173, cStroke = 16;

        if(width) {
          cSize = width;
        }
        
        if(stroke) {
          cStroke = stroke;
        }

        return(
            <div className="hp-report-status__graph">
                <div className="hp-report-graph-wrap">
                    <DonutChart t={t} value={value} size={cSize} viewReportChart={viewReportChart} valuelabel="Completed" strokewidth={cStroke} isStart={isStart}/>
                </div>
            </div>
        )
    }
}


class DonutChart extends React.Component {

    render() {
      const { t } = this.props;
      const halfsize = (this.props.size * 0.5);
      const radius = halfsize - (this.props.strokewidth * 0.5);
      const circumference = 2 * Math.PI * radius;
      const strokeval = ((this.props.value * circumference) / 100);
      const dashval = (strokeval + ' ' + circumference);
  
      const trackstyle = {strokeWidth: this.props.strokewidth};
      const indicatorstyle = {strokeWidth: this.props.strokewidth, strokeDasharray: dashval}
      const rotateval = 'rotate(-90 '+halfsize+','+halfsize+')';

      let mobCompleteTextY = 32;
      if(this.props.viewReportChart) {
        mobCompleteTextY = 18;
      }
  
      return (
        <svg width={this.props.size} height={this.props.size} className="donutchart">
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track"/>
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className="donutchart-indicator"/>

          {!this.props.isStart && 
            <text className="donutchart-text d-none d-md-block" x={halfsize} y={halfsize * 1.15} style={{textAnchor:'middle'}} >
                <tspan className="donutchart-text-val">{this.props.value}</tspan>
                <tspan className="donutchart-text-percent" x={halfsize*1.6} y={halfsize - 8}>%</tspan>
                <tspan className="donutchart-text-label" x={halfsize} y={halfsize+33}>{this.props.valuelabel}</tspan>
            </text>
          }

          {!this.props.isStart && 
            <text className="donutchart-text d-sm-block d-md-none" x={halfsize} y={halfsize * 1.15} style={{textAnchor:'middle'}} >
                <tspan className="donutchart-text-val">{this.props.value}</tspan>
                <tspan className="donutchart-text-percent" x={halfsize*1.63} y={halfsize - 6}>%</tspan>
                <tspan className="donutchart-text-label" x={halfsize} y={halfsize+mobCompleteTextY}>{this.props.valuelabel}</tspan>
            </text>
          }

          {this.props.isStart && 
            <text className="donutchart-text" x={halfsize} y={halfsize * 1.25} style={{textAnchor:'middle'}} >
                <tspan className="donutchart-start-text">Start</tspan>
            </text>
          }
        </svg>
      );
    }
  }

export default withTranslation()(RoundChart);