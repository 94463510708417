import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { MDBIcon } from 'mdbreact';

/** Custom Imports **/
import './featurefloatingbutton.scss';
import icon1 from '../../assets/icons/UX/Component 72 – 1.svg';
import icon2 from '../../assets/icons/UX/Component 73 – 1.svg';
import icon3 from '../../assets/icons/UX/Component 74 – 1.svg';

import icon1_d from '../../assets/icons/UX/Disabled/Component 72 – 1.svg';
import icon2_d from '../../assets/icons/UX/Disabled/Component 73 – 1.svg';
import icon3_d from '../../assets/icons/UX/Disabled/Component 74 – 1.svg';

import icon1_m from '../../assets/icons/UX/Floating/004-analytics.svg';
import icon2_m from '../../assets/icons/UX/Floating/001-new-product.svg';
import icon3_m from '../../assets/icons/UX/Floating/003-advice.svg';

/**
 * Hook that alerts clicks outside of the passed ref
 */
 function useOutsideAlerter(ref, setFloatingModal) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setFloatingModal(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setFloatingModal]);
}

export default function FeatureFloatingButton() {
    const [isFloatingModalOpen, setFloatingModal] = useState(false);
    const { t, i18n } = useTranslation();
    const wrapperRef = useRef(null);
    const history = useHistory();
    let hasAssessmentCompleted;
    let showEDC;

    try {
        hasAssessmentCompleted = localStorage?.getItem('hasSubmited');
        showEDC = JSON.parse(localStorage?.getItem('userInfo'))?.showEDC;
    }catch(e) {}

    useOutsideAlerter(wrapperRef, setFloatingModal);
    let handleIconClick = () => {
        setFloatingModal(true);
    }

    /** 
     * Handle item click
     * Redirect to the corresponsding page
     * **/
    let handleItemClick = (page) => {
        if(page === 'report') {
            let ppid;
            try {
                ppid = localStorage.getItem('ppid');
            }catch(e){}
            history.push(`/report/${ppid}`);
        } else if(page === 'toolkit') {
            history.push(`/toolkit`);
        } else if(page === 'edc') {
            history.push(`/edc`);
        }
    }

    return (
        <div className="feature-floating-button">
            { !isFloatingModalOpen && 
                <div className="feature-floating-buttons-wrap">
                        { hasAssessmentCompleted === 'true' &&
                            <div className="feature-floating-buttons-wrap-buttons">
                                <div className="feature-floating-button-icon">
                                    <img src={icon1} onClick={handleIconClick} alt="Insight & Report"/>
                                    <div className="floating-text">{t('UX.FLOATING_LABEL_REPORT')}</div>
                                </div>
                                <div className="feature-floating-button-icon">
                                    <img src={icon2} onClick={handleIconClick} alt="Toolkit"/>
                                    <div className="floating-text">{t('UX.FLOATING_LABEL_TOOLKIT')}</div>
                                </div>
                                {showEDC &&
                                    <div className="feature-floating-button-icon">
                                        <img src={icon3} onClick={handleIconClick} alt="Hire Experts(EDC)"/>
                                        <div className="floating-text">{t('UX.FLOATING_LABEL_EXPERT')}</div>
                                    </div>
                                }
                                
                            </div>
                        }
                        { hasAssessmentCompleted === 'true' &&
                            <div className="unlocked-label">{t('UX.FLOATING_LABEL_UNLOCKED')}</div>
                        }

                        { (hasAssessmentCompleted === 'false'|| hasAssessmentCompleted === null || hasAssessmentCompleted === undefined )&&
                            <div className="feature-floating-buttons-wrap-buttons">
                                <div className="feature-floating-button-icon">
                                    <img src={icon1_d} alt="Insight & Report"/>
                                </div>
                                <div className="feature-floating-button-icon">
                                    <img src={icon2_d} alt="Toolkit"/>
                                </div>
                                {showEDC &&
                                    <div className="feature-floating-button-icon">
                                        <img src={icon3_d} alt="Hire Experts(EDC)"/>
                                    </div>
                                }
                            </div>
                        }
                        
                </div>
            }
            { isFloatingModalOpen && 
                <div className="feature-floating-modal" ref={wrapperRef}>
                    <div className="feature-floating-modal-content">
                        <div className="feature-floating-modal-content-row feature-modal-item-border-bottom" onClick={handleItemClick.bind(this, 'report')}>
                            <div className="feature-floating-modal-content-item">
                                <span className="feature-floating-modal-content-img-wrap">
                                    <img src={icon1_m} alt="Insight & Report"/>
                                </span>
                                <span className="feature-floating-modal-content-text">
                                    {t('UX.FLOATING_BUTTON_REPORT')}
                                </span>
                            </div>
                            <div className="feature-floating-modal-content-desc">
                                <span>{t('UX.FLOATING_BUTTON_REPORT_DESC')}</span>
                            </div>
                            <div className="feature-floating-modal-content-img">
                                <span>
                                    <MDBIcon icon="arrow-circle-right" size="1x"/>
                                </span>
                            </div>
                        </div>
                        <div className="feature-floating-modal-content-row feature-modal-item-border-bottom" onClick={handleItemClick.bind(this, 'toolkit')}>
                            <div className="feature-floating-modal-content-item">
                                <span className="feature-floating-modal-content-img-wrap">
                                    <img src={icon2_m} alt="Toolkit"/>
                                </span>
                                <span className="feature-floating-modal-content-text">
                                    {t('UX.FLOATING_BUTTON_TOOLKIT')}
                                </span>
                            </div>
                            <div className="feature-floating-modal-content-desc">
                                <span>{t('UX.FLOATING_BUTTON_TOOLKIT_DESC')}</span>
                            </div>
                            <div className="feature-floating-modal-content-img">
                                <span>
                                    <MDBIcon icon="arrow-circle-right" size="1x"/>
                                </span>
                            </div>
                        </div>
                        <div className="feature-floating-modal-content-row" onClick={handleItemClick.bind(this, 'edc')}>
                            <div className="feature-floating-modal-content-item">
                                <span className="feature-floating-modal-content-img-wrap">
                                    <img src={icon3_m} alt="Hire Experts(EDC)"/>
                                </span>
                                <span className="feature-floating-modal-content-text">
                                {t('UX.FLOATING_BUTTON_EDC')}
                                </span>
                            </div>
                            <div className="feature-floating-modal-content-desc">
                                <span>{t('UX.FLOATING_BUTTON_EDC_DESC')}</span>
                            </div>
                            <div className="feature-floating-modal-content-img">
                                <span>
                                    <MDBIcon icon="arrow-circle-right" size="1x"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            
        </div>
    )
}