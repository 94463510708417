import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

function Copyright() {
    useEffect(() => {
        window.scroll({ top: 0, behavior: "smooth" });
    }, [])
  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
      <div className="d-flex hp-layout hp-home">
        <Header />
        <div className="hp-content-wrap faq-container">
          <p className="bread-crumb hp-lite">
          <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;{" "}
            <span>Copyright</span>
          </p>
          <div>
            <span className="hp-regular">Copyright</span> <span className="hp-lite">- Copyrights 2021, All Rights Reserved. Ethinos Digital Marketing. </span>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Copyright;
