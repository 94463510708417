import React from "react";
import { Line } from "react-chartjs-2";

function AreaChart({ data1, data2, label }) {

  console.log('label:', label, 'data1:', data1, 'data2:', data2)
  const data = {
    labels: label,
    datasets: [

      {
        label: "2021 Scores",
        fill: true,
        lineTension: 0,
        backgroundColor: "rgba(209, 209, 209, 2)",
        borderColor: "rgba(209, 209, 209, 1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(209, 209, 209, 1)",
        pointBackgroundColor: "rgba(209, 209, 209, 1)",
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgb(0, 0, 0)",
        pointHoverBorderColor: "rgba(220, 220, 220, 1)",
        pointHoverBorderWidth: 2,
        pointRadius: 0.5,
        pointHitRadius: 0.5,
        data: data1,
      },
      {
        label: "2022 Scores",
        fill: true,
        lineTension: 0,
        backgroundColor: "rgba(162, 226, 253, .3)",
        borderColor: "rgba(162, 226, 253, 1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(162, 226, 253, 1)",
        pointBackgroundColor: "rgba(162, 226, 253, 1)",
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgb(0, 0, 0)",
        pointHoverBorderColor: "rgba(220, 220, 220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data2,
      },
    ],
    responsive: true,
    legend: { display: false },
  };
  return (
    <div className="my-4">
      <Line
        data={data}
        options={{
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              grid: {
                display: false
              }
            }
          }

        }}
      />
    </div>
  );
}

export default AreaChart;
