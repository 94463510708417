import React from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header/Header";

import Bg from "../../assets/images/profile-settings.png";

import "./profileSetting.scss";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from 'react-i18next';
import { sendPageViewGTM } from "../../utils/util";

function ProfileSetting({ history }) {
  const goBack = () => history.goBack();
  let raw = localStorage.getItem('userInfo');
  let userInfo = JSON.parse(raw)
  const { t, i18n } = useTranslation();
  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
      <div className="d-flex hp-layout hp-home">
        <Header />
        <div className="hp-content-wrap edc-container">
          <p className="bread-crumb hp-lite">
          <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;
            <span>Profile and settings</span>
          </p>
          <img src={Bg} alt="edc-image" className="edc-bg-wrap" />

      

          <div className="edc-filter-wrapper">
            <div className="edc-filter-contain">
              <p className="hp-lite heading">{t('PROFILE.PROFILE_TITLE')}</p>
              <div className="input-wrapper">
                <p className="input-label hp-lite">{t('PROFILE.PROFILE_NAME')}</p>
                <p className="input-text hp-regular">{userInfo?.FirstName}&nbsp;{userInfo?.LastName}</p>
              </div>
              <div className="input-wrapper">
                <p className="input-label hp-lite">{t('PROFILE.PROFILE_EMAIL')}</p>
                <p className="input-text hp-regular">{userInfo?.Email}</p>
              </div>
              <div className="input-wrapper">
                <p className="input-label hp-lite">{t('PROFILE.PROFILE_PARTNER_NAME')}</p>
                <p className="input-text hp-regular">{userInfo?.PartnerName}</p>
              </div>
              <div className="input-wrapper">
                <p className="input-label hp-lite">{t('PROFILE.PROFILE_MEMBERSHIP')}</p>
                <p className="input-text hp-regular">{userInfo?.AmplifyMembership}</p>
              </div>
              <p className="text-right hp-regular back-btn" onClick={goBack}>
              {t('PROFILE.PROFILE_BACK')}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ProfileSetting;
