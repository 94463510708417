
/**
 * Asset Imports
 */
import RightArrowIcon from "../../../assets/icons/right-arrow.svg";
import Header from "../../../components/BusinessPlanner/WizardHeader/Header";

/**
 * Style Imports
 */
import './completeplannerstart.scss';

const CompletePlannerConfirm = (props) => {
    function HandleProceed () {
        props.history.push('/bp/complete-planner');
    }
    return (
        <div className="bp-complete-planner-start-container">
            <div className="hp-nav w-100">
                <Header />
            </div>
            <div className="bp-cps-data-container">
                <div className="bp-cps-data">
                    <p className="cps-data-ttl">Show the complete planner for <span className="rglr-txt">Reach, Engage, Transact and Retain</span></p>
                    <p className="cps-data-desc">We have recommended and set parameters considering the best outcome. You can chose to change the values.</p>
                    <div className="cps-data-btns" onClick={HandleProceed}>
                        <p>Next</p>
                        <img src={RightArrowIcon} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompletePlannerConfirm;