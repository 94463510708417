import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import { sendPageViewGTM } from "../../utils/util";

function Terms() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    //GTM PageLoad
    try {
      let pagePath = window.location ? window.location.pathname: '';
      sendPageViewGTM('Terms and Conditions Page', pagePath);
  } catch(e){}
  }, []);
  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
      <div className="d-flex hp-layout hp-home">
        <Header />
        <div className="hp-content-wrap faq-container">
          <p className="bread-crumb hp-lite">
            <Link to={`/dashboard/${localStorage.getItem("ppid")}`}>Home</Link>{" "}
            &gt; <span>T&C</span>
          </p>
          <h4 className="hp-lite">Terms and Condition</h4>
          <h5 className="text-primary hp-regular">
            Survey Purpose and Confidentiality
          </h5>
          <ul>
            <li>
              <p>
                The purpose of this online survey is to work with our key
                partners to evaluate those business capabilities HP deems
                critical to business success today and tomorrow, and help
                partner succeed on the market.
              </p>
            </li>
            <li>
              <p>
                Please note that the completion of the Capabilities Assessment
                is either a prerequisite for a program track participation or is
                part of several eligibility criteria (associated with a point
                scoring) to join HP Amplify
              </p>
            </li>
            <li>
              <p>
                Amplify Power track Eligibility: HP uses an eligibility point
                scoring system to determine if partners are eligible for the
                Power track. Each eligibility criterion is assigned a specific
                number of points. Partners need to complete this Capabilities
                Assessment to earn two points and update it on a yearly basis.
                In the absence of completion, partner may lose participation in
                the Power Track if they do not meet the total required
                eligibility score.
              </p>
            </li>
            <li>
              <p>
                Power Services track Eligibility: All Power Services partners
                must complete the Capabilities Assessment update it on a yearly
                basis. Please note that the Capability Assessment is a
                requirement to be in the Power Services track. Some of the
                answers and information provided under this questioner will be
                used for the Power Services track eligibility criteria points.{" "}
              </p>
            </li>
            <li>
              <p>
                The data provided by each partner will be treated as
                confidential by HP in accordance with the terms of your HP
                Partner Agreement Once completed, participating partners will
                receive a customized report including an overall anonymous
                comparison with other survey participants and recommendations on
                improvement areas based on industry best practices.
              </p>
            </li>
            <li>
              <p>
                Within HP, survey results will be shared with HP’s channel team
                for the purposes set forth in the Amplify Program Guide.
              </p>
            </li>
            <li>
              <p>
                For the processing of the survey and its results, HP works with
                third party Ethinos, which shall abide by similar confidential
                obligations of confidentiality than those agreed with HP in the
                HP Partner Agreement.
              </p>
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Terms;
