import React, { useEffect, useState } from "react";
import "./reportpdf.scss";
import hp_logo from "../../assets/icons/hp-logo.svg";
import report from "../../assets/images/report/002-analytics.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_BASE_URL } from "../../config/api-config";
import AreaChart from "../../components/AreaChart";
import { Divider } from "@material-ui/core";
import RadarComponent2 from "../../components/RadarComponent2";
import PieChart from "../../components/PieChart";
import { MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import gobal_active from "../../assets/images/report/global.svg";
import employee from "../../assets/images/report/employee.svg";
import solidarity from "../../assets/images/report/solidarity.svg";
import transaction from "../../assets/images/report/transaction.svg";
import portfolio_active from "../../assets/images/report/portfolio_active.svg";
import lorry_active from "../../assets/images/report/lorry_active.svg";
import revenue_active from "../../assets/images/report/revenue_active.svg";
import GaugeChart from "react-gauge-chart/dist/GaugeChart";
import { useHistory } from "react-router";

export default function ReportPrint() {
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [chartData, setChartData] = useState(null);
  const getUserDetail = () => {
    const ppid = history?.location?.pathname?.replace("/pdf-ReportPrint/", "");
    const config = {
      method: "get",
      url: `${API_BASE_URL}/partners/getdetails?ppid=${ppid}`,
      headers: {
        //Accept: "application/json, text/plain, */*",
        //Authorization: localStorage.getItem("authorizationtoken"),
        // Language: "en",
      },
    };

    axios(config)
      .then(function (response) {
        setUserData(response?.data?.data?.[0]);

        getChartData();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserDetail();
  }, []);
  useEffect(() => {
    console.log(loading)
    if (!loading) window.print();
  }, [loading]);
  let areaLabel2021D = ["Reach", "Engage", "Transact", "Retain"];
  let areaLabel2021S = ["Reach", "Engage", "Transact", "Retain"];

  let areaData2021D = pdfData?.[0]?.scores?.[0]?.sections?.map(
    (item) => item?.scorePercentage * 100
  );
  console.log("areaData2021D", areaData2021D);
  let areaData2021S = pdfData?.[0]?.scores?.[1]?.sections?.map(
    (item) => item?.scorePercentage * 100
  );
  let areaData2022D = pdfData?.[1]?.scores?.[0]?.sections?.map(
    (item) => item?.scorePercentage * 100
  );
  console.log("areaData2022D", areaData2022D);
  let areaData2022S = pdfData?.[1]?.scores?.[1]?.sections?.map(
    (item) => item?.scorePercentage * 100
  );


  const chartDesktopStyle = {
    width: "250px",
  };

  const getAssessmentPositionD = () => {
    let areaData2021 = Math.round(pdfData?.[0]?.scores?.[0]?.scorePercentage);
    let areaData2022 = Math.round(pdfData?.[1]?.scores?.[0]?.scorePercentage);
    let assessmentSectionScore =
      (Number(areaData2021) + Number(areaData2022)) / 2;
    let partnerPosition = "";
    if (assessmentSectionScore) {
      if (assessmentSectionScore <= 25) {
        partnerPosition = "Lagging";
      } else if (assessmentSectionScore > 25 && assessmentSectionScore <= 50) {
        partnerPosition = "Intermediate";
      } else if (assessmentSectionScore > 50 && assessmentSectionScore <= 75) {
        partnerPosition = "Advanced";
      } else if (assessmentSectionScore > 75 && assessmentSectionScore <= 100) {
        partnerPosition = "Leader";
      }
    }
    return partnerPosition?.toUpperCase();
  };

  const getAssessmentPosition2022D = () => {
    let areaData2022 = Math.round(pdfData?.[1]?.scores?.[0]?.scorePercentage);
    let assessmentSectionScore = Number(areaData2022) / 2;
    let partnerPosition = "";
    if (assessmentSectionScore) {
      if (assessmentSectionScore <= 25) {
        partnerPosition = "Lagging";
      } else if (assessmentSectionScore > 25 && assessmentSectionScore <= 50) {
        partnerPosition = "Intermediate";
      } else if (assessmentSectionScore > 50 && assessmentSectionScore <= 75) {
        partnerPosition = "Advanced";
      } else if (assessmentSectionScore > 75 && assessmentSectionScore <= 100) {
        partnerPosition = "Leader";
      }
    }
    return partnerPosition?.toUpperCase();
  };



  const getAssessmentPositionS = () => {
    let areaData2021 = Math.round(pdfData?.[0]?.scores?.[1]?.scorePercentage);
    let areaData2022 = Math.round(pdfData?.[1]?.scores?.[1]?.scorePercentage);
    let assessmentSectionScore =
      (Number(areaData2021) + Number(areaData2022)) / 2;
    let partnerPosition = "";
    if (assessmentSectionScore) {
      if (assessmentSectionScore <= 25) {
        partnerPosition = "Lagging";
      } else if (assessmentSectionScore > 25 && assessmentSectionScore <= 50) {
        partnerPosition = "Intermediate";
      } else if (assessmentSectionScore > 50 && assessmentSectionScore <= 75) {
        partnerPosition = "Advanced";
      } else if (assessmentSectionScore > 75 && assessmentSectionScore <= 100) {
        partnerPosition = "Leader";
      }
    }
    return partnerPosition?.toUpperCase();
  };

  const getAssessmentPosition2022S = () => {
    let areaData2022 = Math.round(pdfData?.[1]?.scores?.[1]?.scorePercentage);
    let assessmentSectionScore = Number(areaData2022) / 2;
    let partnerPosition = "";
    if (assessmentSectionScore) {
      if (assessmentSectionScore <= 25) {
        partnerPosition = "Lagging";
      } else if (assessmentSectionScore > 25 && assessmentSectionScore <= 50) {
        partnerPosition = "Intermediate";
      } else if (assessmentSectionScore > 50 && assessmentSectionScore <= 75) {
        partnerPosition = "Advanced";
      } else if (assessmentSectionScore > 75 && assessmentSectionScore <= 100) {
        partnerPosition = "Leader";
      }
    }
    return partnerPosition?.toUpperCase();
  };



  const colorScore = [
    { key: 'LAGGING', value: 'red' },
    { key: 'INTERMEDIATE', value: 'yellow' },
    { key: 'ADVANCED', value: 'green' },
    { key: 'LEADER', value: '#053b0d' },
  ]



  const getChartData = () => {
    const ppid = history?.location?.pathname?.replace("/pdf-ReportPrint/", "");

    const config = {
      method: "get",
      url: `${API_BASE_URL}/assessment-statuses/getscore-chart?ppid=${localStorage.getItem("ppid")}`,
      headers: {
        //Authorization: localStorage.getItem("authorizationtoken"),
        Language: localStorage.getItem("lan") ? localStorage.getItem("lan") : "en",
      },
    };

    axios(config)
      .then((response) => {
        setPdfData(response?.data);
        if (response) setChartData(response?.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const data2021 = pdfData?.[0]?.scores;
  const data2022 = pdfData?.[1]?.scores;

  console.log(`data2021`, data2021)
  console.log(`data2022`, data2022)

  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
      <div className="d-flex hp-layout hp-home">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center loading-wrap">
            <div
              className="spinner-border text-info text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="hp-pdf-report-page">
              <div className="hp-blue-header">
                <img src={hp_logo} alt="logo" />
                <p className="hp-regular">Survey Full Report</p>
              </div>
              <div className="d-flex hp-report-top px-4">
                <div className="d-flex hp-report-top-left-panel">
                  <div className="d-flex pdf-top-panel-icon">
                    <img src={report} className="img-fluid report-image" />
                  </div>
                  <div className="d-flex flex-column ml-2">
                    <div className="hp-report-page-link1">
                      REPORT COMPARISON
                    </div>
                    <div className="hp-report-page-link2">2022 -2021</div>
                  </div>
                </div>
                <div className="d-flex flex-column hp-report-top-right-panel">
                  <div className="d-flex hp-report-heading">
                    {t("REPORT.REPORT_USER_POSITION", {
                      name: userData?.PartnerName,
                      position: userData?.AmplifyMembership,
                    })}
                  </div>
                  <div className="d-flex hp-report-content">
                    {t("REPORT.REPORT_DESC")}
                  </div>
                </div>
              </div>
              {localStorage.getItem('reAssessmentOption') === "DIGITAL_ONLY" && <>
                <div className="wrap-grid">
                  <p className="hp-bold hp-22">DIGITAL RE-ASSESSMENT</p>
                  <p className="hp-lite hp-22">Results</p>
                </div>
                <p className="heading hp-regular  hp-22">
                  Digital assessment survey comparison 2022-2021
                </p>
                <Divider className="m-5" />
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <div className="w-75">
                    <AreaChart
                      data1={areaData2021D}
                      data2={areaData2022D}
                      label={areaLabel2021D}
                    />
                  </div>
                </div>
                <Divider className="m-5" />
                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <p>
                      Your overall position in Digital <br />{" "}
                      <span className="font-weight-bold">
                        In 2022:{" "}
                        <span style={{ color: colorScore?.find(item => item?.key === getAssessmentPosition2022D())?.value }}>
                          {getAssessmentPosition2022D()}
                        </span>
                      </span>
                    </p>
                  </div>

                  <div style={{ width: 30, height: 20, margin: "0 10px" }} />
                  <div>
                    <p>
                      Your overall position in Digital <br />{" "}
                      <span className="font-weight-bold">
                        In 2021-2022:{" "}
                        <span style={{ color: colorScore?.find(item => item?.key === getAssessmentPositionD())?.value }}>
                          {getAssessmentPositionD()}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <RadarComponent2 data1={chartData?.[1]?.scores?.[0]} />
                  <div
                    style={{
                      width: 0.75,
                      background: "#f1f1f1",
                      height: 250,
                      margin: "0 10px",
                    }}
                  />
                  <RadarComponent2
                    data1={chartData?.[1]?.scores?.[0]}
                    type={true}
                    data2={chartData?.[0]?.scores?.[0]}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">

                </div>
                <div className="hp-report-survey-chart d-flex flex-column">
                  <div className="hp-report-survey-chart-title d-flex w-100 justify-content-center">
                    {t("REPORT.REPORT_YOUR_POSITION")}:{" "}
                    <span className="hp-report-partner-position">
                      {/* {partnerPosition} */}
                    </span>
                  </div>

                  <div className="tabs-container mb-3">
                    <div className="tabs">
                      <span className="tab1 hp-lite">
                        {t("REPORT.REPORT_LAGGING")}
                      </span>
                      <span className="hp-lite">0%-25%</span>
                    </div>
                    <div className="tabs">
                      <span className="tab2 hp-lite">
                        {t("REPORT.REPORT_INTERMEDIATE")}
                      </span>
                      <span className="hp-lite">26%-50%</span>
                    </div>
                    <div className="tabs">
                      <span className="tab3 hp-lite">
                        {t("REPORT.REPORT_ADVANCED")}
                      </span>
                      <span className="hp-lite">51%-75%</span>
                    </div>
                    <div className="tabs">
                      <span className="tab4 hp-lite">
                        {t("REPORT.REPORT_LEADER")}
                      </span>
                      <span className="hp-lite">76%-100%</span>
                    </div>
                  </div>
                </div>
                {/* <Divider className="mx-5 mt-5" /> */}

                <div className="m-5">
                  <Divider />
                  <div className="gridCorner">
                    <h5 className="h-lite text-center justify-content-center align-items-center">
                      Compare and see how you performed in each category.
                    </h5>
                  </div>
                  <div className="subWrap">
                    <div className="subSec">
                      <img src={gobal_active} alt="reach" />
                      <h1 className="hp-bold">REACH</h1>
                      <div className="d-flex align-items-center">
                        <div>
                          {" "}
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#D3F2FF", "#007DB2"]}
                            arcWidth={0.2}
                            percent={data2021?.[0]?.sections?.[0]?.scorePercentage * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center mb-3">
                            {t("REPORT.REPORT_YOUR_SCORE")}
                          </div>
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#F5F5F5", "#222222"]}
                            arcWidth={0.2}
                            percent={data2021?.[0]?.sections?.[0]?.ScorePercentagePeerScore * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center">
                            {t("REPORT.REPORT_PEER_SCORE")}
                          </div>
                        </div>
                        <div className="partitioner" />
                        <div>
                          {" "}
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#D3F2FF", "#007DB2"]}
                            arcWidth={0.2}
                            percent={data2022?.[0]?.sections?.[0]?.scorePercentage * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center mb-2">
                            {t("REPORT.REPORT_YOUR_SCORE")}
                          </div>
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#F5F5F5", "#222222"]}
                            arcWidth={0.2}
                            percent={data2022?.[0]?.sections?.[0]?.ScorePercentagePeerScore * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center">
                            {t("REPORT.REPORT_PEER_SCORE")}
                          </div>
                        </div>
                      </div>

                      <div className="score-bar w-100 mt-4">
                        <div className="score-wrapper">
                          <div className="report-score-dflex">
                            <p className="hp-22 hp-lite hp-primary-color mb-0">
                              Social Media
                              <br />
                              <span
                                className="hp-lite hp-14 mb-0"
                                style={{ color: "rgb(70,70,70)" }}
                              >

                              </span>
                            </p>
                            <p className="title-head-text-right">
                              {" "}
                              {t("REPORT.REPORT_SCORE_TITLE", { score: data2022?.[0]?.sections?.[0]?.sub_sections?.[0]?.TotalScore })}
                            </p>
                          </div>
                          <div className="score-bar">
                            <div
                              className="score-bar-fill"
                              style={{ width: `${data2022?.[0]?.sections?.[0]?.sub_sections?.[0]?.TotalScore}%` }}
                            ></div>
                          </div>
                          <p className="mt-3 hp-lite hp-18">
                            Excellent, you are a leader at X you have scored Y out
                            of 100% against a peer rating of Z. Overall you are
                            doing amazing work, see how your scores breakdown and
                            what else you can do to improve:
                          </p>
                        </div>
                        <div className="recommdationsWrap">
                          <div className="rec-header">
                            <p className="hp-regular hp-18">
                              Reach recommendations
                            </p>
                          </div>
                          <div className="m-3">
                            <p className="hp-regular">
                              Consider integrating a chatbot into your website.
                            </p>
                            <p className="hp-lite">
                              A chatbot does the job of a virtual salesperson,
                              welcoming the visitor and addressing their queries.
                              It marries your business offerings to the visitor’s
                              needs, thus contributing to the conversion funnel.
                              <br /> It also delivers on several others fronts.
                              The chatbot captures and sorts the lead, engages the
                              customer around the clock even when your call
                              centres are unavailable, and create a humanized
                              brand experience on an online platform for potential
                              customers.
                              <br />
                              <br />
                              <span className="hp-regular">Setting </span> up a
                              chatbot is relatively simple and easy. Here is a
                              quick guide to how you can get one up and running
                              for your website:
                              <br />
                              <br />
                              <span className="hp-regular">
                                Make sure contact information is readily available
                                on your website.
                              </span>
                              <br />
                              <br />
                              Despite all the product information and FAQs you may
                              share on your website, customers may want to connect
                              with a salesperson before going through with a
                              purchase. Hence, it is important to share contact
                              information for their reference. <br />
                              Displaying your contact information also assures
                              customers about your business legibility and
                              accountability. This becomes especially important
                              when we consider big ticket purchases. <br />
                              It can also serve a customer care channel in the
                              absence of a dedicated customer care support
                              helpline. <br /> Here are some best practices to
                              follow when putting up your contact information on
                              the website:
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="score-bar w-100 mt-4">
                        <div className="score-wrapper">
                          <div className="report-score-dflex">
                            <p className="hp-22 hp-lite hp-primary-color mb-0">
                              Paid Advertising
                              <br />
                              <span
                                className="hp-lite hp-14 mb-0"
                                style={{ color: "rgb(70,70,70)" }}
                              >

                              </span>
                            </p>
                            <p className="title-head-text-right">
                              {" "}
                              {t("REPORT.REPORT_SCORE_TITLE", { score: data2022?.[0]?.sections?.[0]?.sub_sections?.[1]?.TotalScore })}
                            </p>
                          </div>
                          <div className="score-bar">
                            <div
                              className="score-bar-fill"
                              style={{ width: `${data2022?.[0]?.sections?.[0]?.sub_sections?.[1]?.TotalScore}%` }}
                            ></div>
                          </div>
                          <p className="mt-3 hp-lite hp-18">
                            Excellent, you are a leader at X you have scored Y out
                            of 100% against a peer rating of Z. Overall you are
                            doing amazing work, see how your scores breakdown and
                            what else you can do to improve:
                          </p>
                        </div>
                        <div className="recommdationsWrap">
                          <div className="rec-header">
                            <p className="hp-regular hp-18">
                              Reach recommendations
                            </p>
                          </div>
                          <div className="m-3">
                            <p className="hp-regular">
                              Consider integrating a chatbot into your website.
                            </p>
                            <p className="hp-lite">
                              A chatbot does the job of a virtual salesperson,
                              welcoming the visitor and addressing their queries.
                              It marries your business offerings to the visitor’s
                              needs, thus contributing to the conversion funnel.
                              <br /> It also delivers on several others fronts.
                              The chatbot captures and sorts the lead, engages the
                              customer around the clock even when your call
                              centres are unavailable, and create a humanized
                              brand experience on an online platform for potential
                              customers.
                              <br />
                              <br />
                              <span className="hp-regular">Setting </span> up a
                              chatbot is relatively simple and easy. Here is a
                              quick guide to how you can get one up and running
                              for your website:
                              <br />
                              <br />
                              <span className="hp-regular">
                                Make sure contact information is readily available
                                on your website.
                              </span>
                              <br />
                              <br />
                              Despite all the product information and FAQs you may
                              share on your website, customers may want to connect
                              with a salesperson before going through with a
                              purchase. Hence, it is important to share contact
                              information for their reference. <br />
                              Displaying your contact information also assures
                              customers about your business legibility and
                              accountability. This becomes especially important
                              when we consider big ticket purchases. <br />
                              It can also serve a customer care channel in the
                              absence of a dedicated customer care support
                              helpline. <br /> Here are some best practices to
                              follow when putting up your contact information on
                              the website:
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="score-bar w-100 mt-4">
                        <div className="score-wrapper">
                          <div className="report-score-dflex">
                            <p className="hp-22 hp-lite hp-primary-color mb-0">
                              Search Engine
                              <br />
                              <span
                                className="hp-lite hp-14 mb-0"
                                style={{ color: "rgb(70,70,70)" }}
                              >

                              </span>
                            </p>
                            <p className="title-head-text-right">
                              {" "}
                              {t("REPORT.REPORT_SCORE_TITLE", { score: data2022?.[0]?.sections?.[0]?.sub_sections?.[2]?.TotalScore })}
                            </p>
                          </div>
                          <div className="score-bar">
                            <div
                              className="score-bar-fill"
                              style={{ width: `${data2022?.[0]?.sections?.[0]?.sub_sections?.[2]?.TotalScore}%` }}
                            ></div>
                          </div>
                          <p className="mt-3 hp-lite hp-18">
                            Excellent, you are a leader at X you have scored Y out
                            of 100% against a peer rating of Z. Overall you are
                            doing amazing work, see how your scores breakdown and
                            what else you can do to improve:
                          </p>
                        </div>
                        <div className="recommdationsWrap">
                          <div className="rec-header">
                            <p className="hp-regular hp-18">
                              Reach recommendations
                            </p>
                          </div>
                          <div className="m-3">
                            <p className="hp-regular">
                              Consider integrating a chatbot into your website.
                            </p>
                            <p className="hp-lite">
                              A chatbot does the job of a virtual salesperson,
                              welcoming the visitor and addressing their queries.
                              It marries your business offerings to the visitor’s
                              needs, thus contributing to the conversion funnel.
                              <br /> It also delivers on several others fronts.
                              The chatbot captures and sorts the lead, engages the
                              customer around the clock even when your call
                              centres are unavailable, and create a humanized
                              brand experience on an online platform for potential
                              customers.
                              <br />
                              <br />
                              <span className="hp-regular">Setting </span> up a
                              chatbot is relatively simple and easy. Here is a
                              quick guide to how you can get one up and running
                              for your website:
                              <br />
                              <br />
                              <span className="hp-regular">
                                Make sure contact information is readily available
                                on your website.
                              </span>
                              <br />
                              <br />
                              Despite all the product information and FAQs you may
                              share on your website, customers may want to connect
                              with a salesperson before going through with a
                              purchase. Hence, it is important to share contact
                              information for their reference. <br />
                              Displaying your contact information also assures
                              customers about your business legibility and
                              accountability. This becomes especially important
                              when we consider big ticket purchases. <br />
                              It can also serve a customer care channel in the
                              absence of a dedicated customer care support
                              helpline. <br /> Here are some best practices to
                              follow when putting up your contact information on
                              the website:
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="score-bar w-100 mt-4">
                        <div className="score-wrapper">
                          <div className="report-score-dflex">
                            <p className="hp-22 hp-lite hp-primary-color mb-0">
                              Tech Stack
                              <br />
                              <span
                                className="hp-lite hp-14 mb-0"
                                style={{ color: "rgb(70,70,70)" }}
                              >

                              </span>
                            </p>
                            <p className="title-head-text-right">
                              {" "}
                              {t("REPORT.REPORT_SCORE_TITLE", { score: data2022?.[0]?.sections?.[0]?.sub_sections?.[3]?.TotalScore })}
                            </p>
                          </div>
                          <div className="score-bar">
                            <div
                              className="score-bar-fill"
                              style={{ width: `${data2022?.[0]?.sections?.[0]?.sub_sections?.[3]?.TotalScore}%` }}
                            ></div>
                          </div>
                          <p className="mt-3 hp-lite hp-18">
                            Excellent, you are a leader at X you have scored Y out
                            of 100% against a peer rating of Z. Overall you are
                            doing amazing work, see how your scores breakdown and
                            what else you can do to improve:
                          </p>
                        </div>
                        <div className="recommdationsWrap">
                          <div className="rec-header">
                            <p className="hp-regular hp-18">
                              Reach recommendations
                            </p>
                          </div>
                          <div className="m-3">
                            <p className="hp-regular">
                              Consider integrating a chatbot into your website.
                            </p>
                            <p className="hp-lite">
                              A chatbot does the job of a virtual salesperson,
                              welcoming the visitor and addressing their queries.
                              It marries your business offerings to the visitor’s
                              needs, thus contributing to the conversion funnel.
                              <br /> It also delivers on several others fronts.
                              The chatbot captures and sorts the lead, engages the
                              customer around the clock even when your call
                              centres are unavailable, and create a humanized
                              brand experience on an online platform for potential
                              customers.
                              <br />
                              <br />
                              <span className="hp-regular">Setting </span> up a
                              chatbot is relatively simple and easy. Here is a
                              quick guide to how you can get one up and running
                              for your website:
                              <br />
                              <br />
                              <span className="hp-regular">
                                Make sure contact information is readily available
                                on your website.
                              </span>
                              <br />
                              <br />
                              Despite all the product information and FAQs you may
                              share on your website, customers may want to connect
                              with a salesperson before going through with a
                              purchase. Hence, it is important to share contact
                              information for their reference. <br />
                              Displaying your contact information also assures
                              customers about your business legibility and
                              accountability. This becomes especially important
                              when we consider big ticket purchases. <br />
                              It can also serve a customer care channel in the
                              absence of a dedicated customer care support
                              helpline. <br /> Here are some best practices to
                              follow when putting up your contact information on
                              the website:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="subSecWrap w-100">
                    <img src={employee} alt="reach" />
                    <h1 className="hp-bold">ENGAGE</h1>
                    <div className="d-flex align-items-center w-100 justify-content-around">
                      <div className="d-flex align-items-center">
                        <div>
                          {" "}
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#D3F2FF", "#007DB2"]}
                            arcWidth={0.2}
                            percent={data2021?.[0]?.sections?.[1]?.scorePercentage * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center mb-3">
                            {t("REPORT.REPORT_YOUR_SCORE")}
                          </div>
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#F5F5F5", "#222222"]}
                            arcWidth={0.2}
                            percent={data2021?.[0]?.sections?.[1]?.ScorePercentagePeerScore * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center">
                            {t("REPORT.REPORT_PEER_SCORE")}
                          </div>
                        </div>
                        <div className="partitioner" />
                        <div>
                          {" "}
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#D3F2FF", "#007DB2"]}
                            arcWidth={0.2}
                            percent={data2022?.[0]?.sections?.[1]?.scorePercentage * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center mb-2">
                            {t("REPORT.REPORT_YOUR_SCORE")}
                          </div>
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#F5F5F5", "#222222"]}
                            arcWidth={0.2}
                            percent={data2022?.[0]?.sections?.[1]?.ScorePercentagePeerScore * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center">
                            {t("REPORT.REPORT_PEER_SCORE")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="subSecWrap w-100">
                    <img src={solidarity} alt="reach" />
                    <h1 className="hp-bold">RETAIN</h1>
                    <div className="d-flex align-items-center w-100 justify-content-around">
                      <div className="d-flex align-items-center">
                        <div>
                          {" "}
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#D3F2FF", "#007DB2"]}
                            arcWidth={0.2}
                            percent={data2021?.[0]?.sections?.[2]?.scorePercentage * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center mb-3">
                            {t("REPORT.REPORT_YOUR_SCORE")}
                          </div>
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#F5F5F5", "#222222"]}
                            arcWidth={0.2}
                            percent={data2021?.[0]?.sections?.[2]?.ScorePercentagePeerScore * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center">
                            {t("REPORT.REPORT_PEER_SCORE")}
                          </div>
                        </div>
                        <div className="partitioner" />
                        <div>
                          {" "}
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#D3F2FF", "#007DB2"]}
                            arcWidth={0.2}
                            percent={data2022?.[0]?.sections?.[2]?.scorePercentage * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center mb-2">
                            {t("REPORT.REPORT_YOUR_SCORE")}
                          </div>
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#F5F5F5", "#222222"]}
                            arcWidth={0.2}
                            percent={data2022?.[0]?.sections?.[2]?.ScorePercentagePeerScore * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center">
                            {t("REPORT.REPORT_PEER_SCORE")}
                          </div>
                        </div>
                      </div> </div>
                  </div>
                  <div className="subSecWrap w-100">
                    <img src={transaction} alt="reach" />
                    <h1 className="hp-bold">TRANSACT</h1>
                    <div className="d-flex align-items-center w-100 justify-content-around">
                      <div className="d-flex align-items-center">
                        <div>
                          {" "}
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#D3F2FF", "#007DB2"]}
                            arcWidth={0.2}
                            percent={data2021?.[0]?.sections?.[3]?.scorePercentage * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center mb-3">
                            {t("REPORT.REPORT_YOUR_SCORE")}
                          </div>
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#F5F5F5", "#222222"]}
                            arcWidth={0.2}
                            percent={data2021?.[0]?.sections?.[3]?.ScorePercentagePeerScore * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center">
                            {t("REPORT.REPORT_PEER_SCORE")}
                          </div>
                        </div>
                        <div className="partitioner" />
                        <div>
                          {" "}
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#D3F2FF", "#007DB2"]}
                            arcWidth={0.2}
                            percent={data2022?.[0]?.sections?.[3]?.scorePercentage * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center mb-2">
                            {t("REPORT.REPORT_YOUR_SCORE")}
                          </div>
                          <GaugeChart
                            id="gauge-chart1"
                            nrOfLevels={8}
                            cornerRadius={0}
                            hideText={true}
                            colors={["#F5F5F5", "#222222"]}
                            arcWidth={0.2}
                            percent={data2022?.[0]?.sections?.[3]?.ScorePercentagePeerScore * 100}
                            style={chartDesktopStyle}
                          />
                          <div className="hp-report-guage-title text-center">
                            {t("REPORT.REPORT_PEER_SCORE")}
                          </div>
                        </div>
                      </div></div>
                  </div>
                </div>

              </>}  </div>

            {localStorage.getItem('reAssessmentOption') !== "DIGITAL_ONLY" && <>
              <div className="wrap-grid">
                <p className="hp-bold hp-22">SERVICE RE-ASSESSMENT</p>
                <p className="hp-lite hp-22">Results</p>
              </div>
              <p className="heading hp-regular  hp-22">
                Service assessment survey comparison 2022-2021
              </p>
              <Divider className="m-5" />
              <div className="px-5">
                <div>
                  <AreaChart data1={areaData2021S} data2={areaData2022S} />
                </div>
              </div>
              <Divider className="m-5" />
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <p>
                    Your overall position in Service <br />{" "}
                    <span className="font-weight-bold">
                      In 2022:{" "}
                      <span style={{ color: colorScore?.find(item => item?.key === getAssessmentPosition2022S())?.value }}>
                        {getAssessmentPosition2022S()}
                      </span>
                    </span>
                  </p>
                </div>

                <div style={{ width: 30, height: 20, margin: "0 10px" }} />
                <div>
                  <p>
                    Your overall position in Service <br />{" "}
                    <span className="font-weight-bold">
                      In 2021:{" "}
                      <span style={{ color: colorScore?.find(item => item?.key === getAssessmentPositionS())?.value }}>
                        {getAssessmentPositionS()}
                      </span>
                    </span>
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <RadarComponent2 data1={chartData?.[1]?.scores?.[1]} />
                <div
                  style={{
                    width: 0.75,
                    background: "#f1f1f1",
                    height: 250,
                    margin: "0 10px",
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <RadarComponent2
                  data1={chartData?.[1]?.scores?.[1]}
                  type={true}
                  data2={chartData?.[0]?.scores?.[1]}
                />
              </div>
              <div className="hp-report-survey-chart d-flex flex-column">
                <div className="hp-report-survey-chart-title d-flex w-100 justify-content-center">
                  {t("REPORT.REPORT_YOUR_POSITION")}:{" "}
                  <span className="hp-report-partner-position">
                    {/* {partnerPosition} */}
                  </span>
                </div>

                <div className="tabs-container mb-3">
                  <div className="tabs">
                    <span className="tab1 hp-lite">
                      {t("REPORT.REPORT_LAGGING")}
                    </span>
                    <span className="hp-lite">0%-25%</span>
                  </div>
                  <div className="tabs">
                    <span className="tab2 hp-lite">
                      {t("REPORT.REPORT_INTERMEDIATE")}
                    </span>
                    <span className="hp-lite">26%-50%</span>
                  </div>
                  <div className="tabs">
                    <span className="tab3 hp-lite">
                      {t("REPORT.REPORT_ADVANCED")}
                    </span>
                    <span className="hp-lite">51%-75%</span>
                  </div>
                  <div className="tabs">
                    <span className="tab4 hp-lite">
                      {t("REPORT.REPORT_LEADER")}
                    </span>
                    <span className="hp-lite">76%-100%</span>
                  </div>
                </div>
              </div>
              <Divider className="mx-5 mt-5" />
              {/* <p className="heading hp-regular hp-22 text-center mt-4">
              Service Re-assessment KPIs comparison
            </p> */}
              <div className="m-5">
                {/* <Divider />
              <MDBTable>
                <MDBTableHead light>
                  <tr>
                    <th scope="col">Re-assessment questions</th>
                    <th scope="col">2022</th>
                    <th scope="col">2021</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <th scope="row">
                      <span>
                        How many vendors sales cloud certifications does your
                        organisation hold?
                      </span>
                    </th>
                    <td>From 1 vendor</td>
                    <td>None</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      How invested is your organisation in developing sales
                      opportunities across different verticals?
                    </th>
                    <td>We are strong in some verticals</td>
                    <td>We approach each vertical on an ad-hoc basis</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      How many technicians (for break and fix) do you have in
                      your company? (Capabilities points - Technical Delivery)
                    </th>
                    <td>We have 2-3 technicians</td>
                    <td>We have 2-3 technicians</td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
               */}
                {/* <Divider /> */}
                <div className="gridCorner mb-4">
                  <h5 className="h-lite text-center justify-content-center align-items-center">
                    Compare and see how you performed in each category.
                  </h5>
                </div>
                <div className="subSecWrap w-100">
                  <img src={portfolio_active} alt="reach" />
                  <h1 className="hp-bold">PORTFOLIO</h1>
                  <h1 className="hp-bold">OFFERING</h1>
                  <div className="d-flex align-items-center w-100 justify-content-around">


                    <div className="d-flex align-items-center">
                      <div>
                        {" "}
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#D3F2FF", "#007DB2"]}
                          arcWidth={0.2}
                          percent={data2021?.[1]?.sections?.[0]?.scorePercentage * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center mb-3">
                          {t("REPORT.REPORT_YOUR_SCORE")}
                        </div>
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#F5F5F5", "#222222"]}
                          arcWidth={0.2}
                          percent={data2021?.[1]?.sections?.[0]?.ScorePercentagePeerScore * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center">
                          {t("REPORT.REPORT_PEER_SCORE")}
                        </div>
                      </div>
                      <div className="partitioner" />
                      <div>
                        {" "}
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#D3F2FF", "#007DB2"]}
                          arcWidth={0.2}
                          percent={data2022?.[1]?.sections?.[0]?.scorePercentage * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center mb-2">
                          {t("REPORT.REPORT_YOUR_SCORE")}
                        </div>
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#F5F5F5", "#222222"]}
                          arcWidth={0.2}
                          percent={data2022?.[1]?.sections?.[0]?.ScorePercentagePeerScore * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center">
                          {t("REPORT.REPORT_PEER_SCORE")}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="subSecWrap w-100">
                  <img src={revenue_active} alt="reach" />
                  <h1 className="hp-bold">REVENUE</h1>
                  <h1 className="hp-bold">GROWTH</h1>
                  <div className="d-flex align-items-center w-100 justify-content-around">
                    <div className="d-flex align-items-center">
                      <div>
                        {" "}
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#D3F2FF", "#007DB2"]}
                          arcWidth={0.2}
                          percent={data2021?.[1]?.sections?.[1]?.scorePercentage * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center mb-3">
                          {t("REPORT.REPORT_YOUR_SCORE")}
                        </div>
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#F5F5F5", "#222222"]}
                          arcWidth={0.2}
                          percent={data2021?.[1]?.sections?.[1]?.ScorePercentagePeerScore * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center">
                          {t("REPORT.REPORT_PEER_SCORE")}
                        </div>
                      </div>
                      <div className="partitioner" />
                      <div>
                        {" "}
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#D3F2FF", "#007DB2"]}
                          arcWidth={0.2}
                          percent={data2022?.[1]?.sections?.[1]?.scorePercentage * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center mb-2">
                          {t("REPORT.REPORT_YOUR_SCORE")}
                        </div>
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#F5F5F5", "#222222"]}
                          arcWidth={0.2}
                          percent={data2022?.[1]?.sections?.[1]?.ScorePercentagePeerScore * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center">
                          {t("REPORT.REPORT_PEER_SCORE")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="subSecWrap w-100">
                  <img src={lorry_active} alt="reach" />
                  <h1 className="hp-bold">SERVICE</h1>
                  <h1 className="hp-bold">DELIVERY</h1>
                  <div className="d-flex align-items-center w-100 justify-content-around">
                    <div className="d-flex align-items-center">
                      <div>
                        {" "}
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#D3F2FF", "#007DB2"]}
                          arcWidth={0.2}
                          percent={data2021?.[1]?.sections?.[2]?.scorePercentage * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center mb-3">
                          {t("REPORT.REPORT_YOUR_SCORE")}
                        </div>
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#F5F5F5", "#222222"]}
                          arcWidth={0.2}
                          percent={data2021?.[1]?.sections?.[2]?.ScorePercentagePeerScore * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center">
                          {t("REPORT.REPORT_PEER_SCORE")}
                        </div>
                      </div>
                      <div className="partitioner" />
                      <div>
                        {" "}
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#D3F2FF", "#007DB2"]}
                          arcWidth={0.2}
                          percent={data2022?.[1]?.sections?.[2]?.scorePercentage * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center mb-2">
                          {t("REPORT.REPORT_YOUR_SCORE")}
                        </div>
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={8}
                          cornerRadius={0}
                          hideText={true}
                          colors={["#F5F5F5", "#222222"]}
                          arcWidth={0.2}
                          percent={data2022?.[1]?.sections?.[2]?.ScorePercentagePeerScore * 100}
                          style={chartDesktopStyle}
                        />
                        <div className="hp-report-guage-title text-center">
                          {t("REPORT.REPORT_PEER_SCORE")}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </>}

          </>
        )}
      </div>
    </div>
  );
}
