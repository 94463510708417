import React from 'react';
import { MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';

import axios from "axios";
import { Link } from 'react-router-dom';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { sendPageViewGTM } from "../../utils/util";

import './assessmentretake.scss';
import {API_BASE_URL} from '../../config/api-config';

import { LoadAssessments, SaveCompletedSections, submitAssessment } from '../HomeReAssessment/actions';
import { getAssessments, getCompletedSections } from '../Home/homeReducer';


import gobal_active from "../../assets/images/report/global.svg";
import employee from "../../assets/images/report/employee.svg";
import solidarity from "../../assets/images/report/solidarity.svg";
import transaction from "../../assets/images/report/transaction.svg";

import portfolio_active from "../../assets/images/report/portfolio_active.svg";
import lorry_active from "../../assets/images/report/lorry_active.svg";
import revenue_active from "../../assets/images/report/revenue_active.svg";


const mapStateToProps = (state) => {
    return {
     assessments: getAssessments(state),
     completedSections: getCompletedSections(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        LoadAssessments: (ppid) => dispatch(LoadAssessments(ppid, ownProps)),
        SaveCompletedSections: (data) => dispatch(SaveCompletedSections(data)),
        submitAssessment: () => dispatch(submitAssessment())
    }
}

class AssessmentRetake extends React.Component {

    state = {
        modal: false,
        selectedSection: '',
        selectedSectionName: '',
        selectedAssessment: null,
        typeFormID: '',
        imgObj: [ 
            {
                'Reach':gobal_active,
                'Engage':employee,
                'Transact':transaction,
                'Retain':solidarity
            }, {
                'Offering Portfolio': portfolio_active,
                'Revenue Growth': revenue_active,
                'Services & Solutions Delivery': lorry_active
            }
            
        ]
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount = () => {
        let ppid = localStorage.getItem('ppid');
        if(ppid) {
            this.props.LoadAssessments(ppid);
        }

        //GTM PageLoad
        try {
            let pagePath = window.location ? window.location.pathname: '';
            sendPageViewGTM('Assessment Retake Page', pagePath);
        } catch(e){}
    }

    retakeConfirmation = (secId, secName, assessmentData, typeFormID) => {
        this.setState({
            selectedSectionName: secName,
            modal: !this.state.modal,
            selectedSection: secId,
            selectedAssessment: assessmentData,
            typeFormID: typeFormID
        });
    }

    clearSectionData = () => {
        let ppid = localStorage.getItem('ppid');
        let currentAssessment = '';

        let paramString = '';
        if(this.props.assessments && this.props.assessments.length > 0) {
            paramString = (this.props.assessments[0].sections && this.props.assessments[0].sections.length > 0) ? this.props.assessments[0].sections[0].params : null;
            localStorage.setItem('qParam', paramString);
        }
        
        if(this.state.selectedAssessment) {
            if(this.state.selectedAssessment.Name.indexOf('Digital') > -1) {
                currentAssessment = 'D';
            } else if(this.state.selectedAssessment.Name.indexOf('Service') > -1) {
                currentAssessment = 'S';
            }
        }
        axios.get(`${API_BASE_URL}/partners/clear?year=2022&ppid=${ppid}&sectionid=${this.state.selectedSection}`)
        .then(res => {
            if(res) {
                this.toggle();
                this.props.history.push(`/assessment2022/${currentAssessment}/${this.state.typeFormID}/${this.state.selectedSectionName}/false/${this.state.selectedAssessment.id}`);
            }
        });
    }

    render() {
        const {imgObj} = this.state;
        const {assessments} = this.props;
        let selectedAssessment = this.props.match.params.assessmentID;

        let assessmentData;
        if(assessments) {
            assessmentData = assessments.filter(assessment => assessment.id === selectedAssessment);
        }

        let ppid = localStorage.getItem('ppid');

        return(
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                <div className="d-flex hp-layout hp-home">
                    <Header />
                    <p className=" ml-3 bread-crumb hp-lite">
                    <Link to={`/dashboard2022/${localStorage.getItem('ppid')}`}>Home</Link> &gt;  <Link to={`/dashboard/${ppid}`}>Dashboard</Link> &gt; <span>Retake</span>
                    </p>
                    <div className="hp-content-wrap assessment-retake-container">
                        <div className="retake-wrap">
                            <div className="retake-title">
                                Which section do you want to re-assess of Service?
                            </div>
                            <div className="retake-body">
                                {assessmentData && assessmentData.length > 0 && assessmentData[0].sections && assessmentData[0].sections.length > 0 &&
                                    assessmentData[0].sections.map((asmnt, i) => (
                                        <div className="retake-item" key={i} onClick={this.retakeConfirmation.bind(this, asmnt.id, asmnt.Name, assessmentData[0], asmnt.Typeform_ID)}>
                                            <div className="retake-item-title">
                                                <div className="retake-item-title-icon">
                                                    {(()=>{
                                                       if(assessmentData[0].Name.indexOf('Digital') > -1) {
                                                            return (
                                                                <img src={imgObj[0][asmnt.Name]} className="img-fluid" />
                                                            )
                                                        } else if(assessmentData[0].Name.indexOf('Service') > -1) {
                                                            return (
                                                                <img src={imgObj[1][asmnt.Name]} className="img-fluid" />
                                                            )
                                                        }
                                                    })()
                                                    }
                                                    
                                                </div>
                                                <div className="retake-item-title-text">{asmnt.Name}</div>
                                            </div>
                                            <div className="retake-item-body">
                                                <div className="retake-item-body-text">
                                                    {asmnt.Description}
                                                </div>
                                                <div className="retake-item-body-icon">
                                                    <MDBIcon icon="arrow-right"/>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}></MDBModalHeader>
                    <MDBModalBody>
                       <div className="hp-reatake-alert-wrap">
                            <div className="hp-retake-warn-text-1">
                               <div className="retake-assessment-title">Are you sure?</div>
                            </div>
                            <div className="hp-retake-warn-text-2">
                               <div>
                               By opting for a Retaking you shall wipe out the existing {this.state.selectedSectionName} section data.
                               </div>
                               <div className="retake-confirm-text">
                               Are you sure, you want to continue?
                               </div>
                            </div>
                       </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div className="hp-data-wipe-confirm" onClick={this.clearSectionData}>Yes, continue</div>
                        <div>
                            <div className="hp-cta-v1 hp-data-wipe-cancel-cta" onClick={this.toggle}>No, go back</div>
                        </div>
                    </MDBModalFooter>
                </MDBModal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentRetake);