import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBInput, MDBIcon } from 'mdbreact';
import { Fragment } from 'react';
import Slider from '../../Slider/Slider';
import { useSelector, useDispatch } from 'react-redux';
import {getAppState} from '../../../reducers/bpReducer';

import './reachplanner.scss';
import ProfileSetting from '../../../pages/ProfileSetting';

export default function ReachPlanner(props){
    const [budget, setBudget] = useState(props.budget ? props.budget : 5000);
    const [cr, setCR] = useState(props.cr);
    const [asv, setASV] = useState(props.asv);
    const [cpc, setCPC] = useState(props.cpc);
    const [planner, showPlanner] = useState(true);

    const reachData = useSelector((state) => state.bpReach);
    const appState = useSelector(getAppState);
    const dispatch = useDispatch();

    const toggleComponent =()=> {

    }

    const handleBudget = (data) => {
        setBudget(data);
        dispatch({type:'BP_REACH_SET_BUDGET', payload: parseInt(data)});
    }
    const handleCR = (data) => {
        setCR(data);
        dispatch({type:'BP_REACH_SET_CR', payload: data});
    }
    const handleASV = (data) => {
        setASV(data);
        dispatch({type:'BP_REACH_SET_ASV', payload: parseInt(data)});
    }
    const handleCPC = (data) => {
        setCPC(parseFloat(data));
        dispatch({type:'BP_REACH_SET_CPC', payload: parseFloat(data)});
    }


    const handleClick = () => {
        var data = {
            budget: budget,
            cr: cr,
            asv:asv,
            cpc:cpc
        };
        showPlanner(false);
        props.handleClick(data);
    }

    try {
        var cpcInterval = (props.cpc_min + props.cpc_max)/100;
    }catch(e) {
        cpcInterval = props.cpc_min;
    }
    

    return(
        <div className={props.showShadow ? 'bp-reach-budget-recommendation-collapsed-content': 'bp-reach-budget-recommendation-collapsed-content planner-shadow'}>
            <p className="bp-reach-budget-recommendation-collapsed-content-heading">
                PLANNING AS PER TOTAL MONTHLY BUDGET
            </p>
            <div className="bp-reach-budget-recommendation-collapsed-content-info">
                <div className="bp-reach-budget-recommendation-collapsed-content-info-left">
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-left-item">
                        <p>
                            My monthly budget
                            <MDBIcon icon="info-circle" className="info-icon tooltip-icon" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                                <span class="tooltip-text">
                                    <p>Monthly Budget​</p>
                                    <br/>
                                    <p>
                                    The monthly budget you set will be used to determine the recommended digital marketing channels for your plan. The higher the budget you set the broader your marketing coverage will be,  along with a greater opportunity for revenue generation. Depending on the conversion rate percentage, average sale value and the cost per click you indicate in the tool, your Return On Investment (“ROI”) calculation may vary. Your investment is calculated based on the media/technologies you have selected, and the media spends spread over a one-year period. If you have selected to invest in many media/technologies, it is recommended that you associate a sufficient budget in terms of demand generation, also in view of achieving your estimated ROI.
                                    </p>
                                </span>
                            </MDBIcon>
                        </p>
                        <Slider displayValue={true} valuePrefix={props.CurrencySymbol} valueSuffix={props.CurrencySuffix} min={props.budget_min} max={props.budget_max} initialValue={budget} handleValueChange={handleBudget} roundValue={true}/>
                    </div>
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-left-item">
                        <p>
                            Website Conversion Rate (CR%)
                            <MDBIcon icon="info-circle" className="info-icon tooltip-icon" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                                <span class="tooltip-text tooltip-text-cr">
                                    <p>Conversion Rate %​​</p>
                                    <br/>
                                    <p>
                                    By modifying the conversion rate percentage, you will be directly affecting your projected revenue. Moving from 1% to 2% is an effective doubling of revenue. Please ensure you think carefully about the conversion percentage, the more accurately you define your conversion rate the more accurate your revenue forecast will be. The conversion rate is calculated based on your total monthly sales number divided by your total monthly website traffic.
                                    </p>
                                </span>
                            </MDBIcon>
                        </p>
                        <Slider displayValue={false} valuePrefix="%" min={props.cr_min} valueSuffix="" max={props.cr_max} step={.1} initialValue={cr} handleValueChange={handleCR} roundValue={true}/>
                    </div>
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-left-item">
                        <p>
                            Average sales value
                            <MDBIcon icon="info-circle" className="info-icon tooltip-icon" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                                <span class="tooltip-text tooltip-text-asv">
                                    <p>Average Sales Value​​</p>
                                    <br/>
                                    <p>
                                    The average sales value (ASV) you set will directly affect the revenue forecast that gets calculated. To calculate an accurate ASV look at the total sales revenue and divide that by the total number of sales transactions. The ASV is one of the parameter used by HP to estimate your predicted ROI. It is therefore important that you define your ASV accurately.
                                    </p>
                                </span>
                            </MDBIcon>
                        </p>
                        <Slider displayValue={false} valuePrefix={props.CurrencySymbol} valueSuffix={props.CurrencySuffix} min={props.asv_min} max={props.asv_max} initialValue={asv} handleValueChange={handleASV} roundValue={true}/>
                    </div>
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-left-item">
                        <p>
                            CPC
                            <MDBIcon icon="info-circle" className="info-icon tooltip-icon" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                                <span class="tooltip-text tooltip-text-cpc">
                                    <p>Cost Per Click​</p>
                                    <br/>
                                    <p>
                                    If you know your typical cost per click price, please set it in the tool accordingly as this will make your revenue forecast more accurate, otherwise we would recommend that you do not change the CPC set by default in the tool, which is set as  an average CPC for your local Market. Any change to the CPC will directly affect the Return-on-Investment (ROI) calculation, as it will define the underlying volume of website traffic you will receive based on the budgets you set.
                                    </p>
                                </span>
                            </MDBIcon>
                        </p>
                        <Slider displayValue={false} valuePrefix={props.CurrencySymbol} valueSuffix={props.CurrencySuffix} min={props.cpc_min} step={cpcInterval.toFixed(4)} max={props.cpc_max} initialValue={cpc} handleValueChange={handleCPC} roundValue={false}/>
                    </div>
                </div>
                <div className="bp-reach-budget-recommendation-collapsed-content-info-right">
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-right-item">
                        <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-title">
                            Monthly budget
                        </p>
                        <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-value">
                        {props.CurrencySymbol}{budget.toLocaleString("en-US")}{props.CurrencySuffix}
                        </p>
                    </div >
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-right-item">
                        <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-title">
                            Website CR%
                        </p>
                        <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-value">
                            {cr}%
                        </p>
                    </div>
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-right-item">
                        <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-title">
                            Average sales value (ASV)
                        </p>
                        <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-value">
                        {props.CurrencySymbol}{asv.toLocaleString("en-US")}{props.CurrencySuffix}
                        </p>
                    </div>
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-right-item">
                        <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-title">
                            Cost per click (CPC)
                        </p>
                        <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-value">
                        {props.CurrencySymbol}{parseFloat(cpc)}{props.CurrencySuffix}
                        </p>
                    </div>
                    <div className="bp-reach-budget-recommendation-collapsed-content-info-right-item">
                        {
                            appState && !appState.showPlanner &&
                            <Fragment>
                                <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-description">
                                    Your parameters are set. In the next step you will get marketing plan that will get you maximum results.
                                </p>
                                <MDBBtn className="show-planner-btn" onClick={handleClick}>Show planner</MDBBtn>
                            </Fragment>
                        }
                        {
                            appState && appState.showPlanner &&
                            <Fragment>
                                <p className="bp-reach-budget-recommendation-collapsed-content-info-right-item-description-alt">
                                    Do you want to recalculate?
                                </p>
                                <MDBBtn className="show-planner-btn" onClick={handleClick}>Yes</MDBBtn>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}