import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

function Privacy() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
      <div className="d-flex hp-layout hp-home">
        <Header />
        <div className="hp-content-wrap faq-container">
          <p className="bread-crumb hp-lite">
          <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;{" "}
            <span>Privacy</span>
          </p>
          <h4 className="hp-lite">Privacy</h4>
          <h6 className="hp-regular">Ethinos Privacy Policy</h6>
          <p>
            Ethinos recognizes your right to privacy and is committed to
            handling the information you provide responsibly. Ethinos will take
            prudent and practical steps to safeguard your personal information
            and its use.
          </p>
          <p>
            Our strategies, policies and guidelines support this commitment to
            protect your information. Managers and employees are responsible for
            fulfilling the following general principles for collecting, using,
            disclosing, storing, accessing, transferring or otherwise processing
            personal information.
          </p>
          <h6 className="hp-regular">Your information</h6>
          <p>
            Any inquiries or requests submitted to this site through forms here
            are considered confidential. Email addresses and other contact
            details collected through this site may be used to update our
            internal databases for the purposes of maintaining and improving the
            delivery of our services and marketing activities but are not sold
            on to any third party. Recipients of emails from Ethinos may opt-out
            by reply and every effort will be made to process the request before
            any further emails are sent.
          </p>
          <p>
            We may ask you for, and collect, personal information, including
            when you subscribe to receive Ethinos press releases and company
            information; when you join our subscription services or online
            communities; when you request our free reports and access to
            published information; or when you interact with Ethinos at our
            forums, at trade shows or similar events. We use the information you
            provide to help us design and improve our website and our products,
            to customize your online experience, to provide you advice and our
            research, and for other purposes consistent with this policy
          </p>
          <p>
            Ethinos may use information derived from the site usage records,
            including cookie-based information, to help plan future improvements
            to its services and to promote usage, but again, does not sell such
            information to third parties.
          </p>
          <p>
            This site sometimes contains links to other web sites. Ethinos
            cannot be responsible for the privacy practices or content of these
            sites and therefore advises you to exercise caution when submitting
            personal information online.
          </p>{" "}
          <h6 className="hp-regular">The data we collect about you</h6>
          <p>
            We may collect, and store different kinds of personal data about you
            which we have grouped together as follows:
          </p>
          <p>
            Identity data includes first name, maiden name, last name, username
            or similar identifier and title.
          </p>
          <p>
            Contact data includes billing address, email address and telephone
            numbers.
          </p>
          <p>
            Usage data includes information about how you use our site, products
            and services.
          </p>
          <p>
            Content data includes any content which you upload onto the site
            such as comments and other text or images that contain personal
            data.
          </p>
          <p>
            Social media linked profile photograph, for example LinkedIn profile
            photo.
          </p>
          <h6 className="hp-regular">Usage of the personal data</h6>
          <p>
            We may use the personal data for the following reasons: Identity data
            and social media may be used of validate identity and login Refine and
            improve User experience Send regular updates, marketing information
            and newsletters
          </p>
          {/* <Please add any more usage requirements> */}
          <h6 className="hp-regular">Information we collect automatically</h6>
          <p>
            Our web server logs collect the domain names and certain related data
            of visitors to our web sites automatically (such as their IP address
            or device identifier). This information is used to measure number of
            visits, average time spent on this website, pages viewed and website
            usage information. We use this information to meet legal or regulatory
            requirements; to measure the use of our sites; to improve the content
            of our sites; and to provide tailored experiences. We may collect this
            information using cookies, as explained below under the ‘Cookies’
            section.
          </p>
          <h6 className="hp-regular">Cookies</h6>
          <p>
            A cookie is a small file that enables web servers to "identify" visitors.
            When you visit a site that uses this technology, the site adds a cookie to your browser that includes identification information.
            Cookies contain only as much information as you provide to the site and you have the ability to delete them from your system at any time.
            However, be aware that  certain cookies may be necessary to provide you with access to the more advanced or personalized features available on certain sites such as restoring unsaved sessions.
          </p>
          <h6 className="hp-regular">Transfer of personal information internationally</h6>
          <p>
            Ethinos is an India based digital marketing organization with its head quarters in Mumbai Where permitted by law, we may share your personal information within Ethinos and business partners.
            Regardless of where your information is transferred to and resides, our privacy and security practices are designed to provide protection for your personal information globally.
            Please note that some countries have privacy laws that are not equivalent to privacy laws in your own country.
            Ethinos will still handle information in the manner described here and we will try to take the necessary measures to comply with applicable data protection laws when transferring and processing your information. Where required, Ethinos will also maintain agreements between or with Ethinos partners or suppliers to govern the transferring, processing and protection of personal information.
          </p>
          <h6 className="hp-regular">Data Security</h6>
          <p>
            We have put in place appropriate industry standard security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
            We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline
          </p>
          <h6 className="hp-regular">Opt out to Communication</h6>
          <p>You may choose to opt out from any communication from us in relation to HP product and services by  sending an email to info@ethinos.com.</p>
          <h6 className="hp-regular">Changes in the policy</h6>
          <p>
            This policy may be updated keeping your privacy as our priority.
            Any updates on the same will be updated here for your reference.
          </p>
          <h6 className="hp-regular">Grievance Redressal</h6>
          <p>
            Any grievance or complaint, in relation to processing of information, should be sent to Ethinos at info@ethinos.com ,  Grievance shall be redressed as expeditiously as possible.
          </p>
          <h6 className="hp-regular">Grievance Redressal</h6>
          <p>
            In relation to any queries and concerns in relation to your personal data, please feel free to reach out to pavan.kumar@ethinos.com 
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Privacy;
