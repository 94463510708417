import React from "react";

import { BrowserRouter, Switch, Redirect, Route, Link } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { withTranslation } from "react-i18next";

import axios from "axios";
import { API_BASE_URL } from "../../config/api-config";
import ProgressLoader from "../../components/ProgressLoader";
import DownloadLink from "react-download-link";

import { connect } from "react-redux";
import {
  LoadAssessments,
  SaveCompletedSections,
  emptyCompletedSections,
  wipeAssessmentData,
  submitAssessment,
  SaveCompletedAssessmentByType,
  SaveTotalAssessmentByType,
  loadAssessmentReport,
} from "../HomeReAssessment/actions";
import {
  getAssessments,
  getAssessmentReport,
  getLoadingScoreStatus,
} from "../HomeReAssessment/homeReducer";

import { MDBIcon } from "mdbreact";
import RoundChart from "../../components/RoundChart/RoundChart";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import Tookit from "../../components/Toolkit/Toolkit";
import DigitalToolKitComponent from "../../components/DigitalToolkit";
import ServiceToolKitComponent from "../../components/ServiceToolkit";
import { sendPageViewGTM } from "../../utils/util";

import "./dashboard.scss";
import Iframe from "react-iframe";

import { getUserName } from "../../utils/util";

import alarm from "../../assets/images/alarm.svg";
import GetConnectedEDC from "../../components/GetConnectedEDC";
import IEModal from "../../components/IEDisclaimerModal";
import download_img from "../../assets/icons/download.svg";

const mapStateToProps = (state) => {
  return {
    assessments: getAssessments(state),
    report: getAssessmentReport(state),
    loadingProp: getLoadingScoreStatus(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    LoadAssessments: (ppid) => dispatch(LoadAssessments(ppid, ownProps)),
    saveComepletedSecetions: (data) => dispatch(SaveCompletedSections(data)),
    emptyCompletedSections: () => dispatch(emptyCompletedSections()),
    wipeAssessmentData: (ppid) => dispatch(wipeAssessmentData(ppid, ownProps)),
    submitAssessment: () => dispatch(submitAssessment()),
    SaveCompletedAssessmentByType: (data, type) =>
      dispatch(SaveCompletedAssessmentByType(data, type)),
    SaveTotalAssessmentByType: (data, type) =>
      dispatch(SaveTotalAssessmentByType(data, type)),
    loadAssessmentReport: (ppid) =>
      dispatch(loadAssessmentReport(ppid, ownProps)),
  };
};

class Dashboard2022 extends React.Component {
  constructor(props) {
    super(props);
    var savedPPID = localStorage.getItem("ppid");
    if (
      savedPPID &&
      savedPPID !== undefined &&
      savedPPID !== this.props.match.params.ppid
    ) {
      localStorage.removeItem("qParam");
      localStorage.setItem("ppid", this.props.match.params.ppid);
      this.props.emptyCompletedSections();
    }

    if (this.props.match.params.ppid) {
      this.props.LoadAssessments(this.props.match.params.ppid);
      this.props.loadAssessmentReport(this.props.match.params.ppid);
    }
  }

  getSectionStatus = (ppid, assessmentId) => {
    let typeFormId = this.props.match.params.url;
    axios
      .get(
        `${API_BASE_URL}/assessment-categories/status?year=2022&ppid=` + ppid
      )
      .then((res) => {
        if (res && res.data) {
          let asData = res.data.filter(
            (assessment) => assessment.id === assessmentId
          );
          asData = asData[0];

          if (asData && asData.sections && asData.sections.length > 0) {
            for (let i = 0; i < asData.sections.length; i++) {
              if (asData.sections[i].Typeform_ID === typeFormId) {
                if (asData.sections[i].hasCompleted) {
                  this.props.LoadAssessments(this.props.match.params.ppid);
                  this.setState({
                    loading: !this.state.loading,
                  });
                  break;
                } else {
                  this.getSectionStatus(ppid, assessmentId);
                }
              }
            }
          }
        }
      });
  };

  state = {
    modal: false,
    loading: false,
    showSubmit: false,
    loadingState: false,
    showLoader: false,
    isTab: true,
  };
  getSectionStatus1 = (ppid, assessmentId) => {
    let typeFormId = this.props.match.params.url;
    axios
      .get(
        `${API_BASE_URL}/assessment-categories/status?year=2022&ppid=` +
        localStorage.getItem("ppid")
      )
      .then((res) => {
        console.log(res.data);
        this.setState({ assessment: res.data });
      });
  };
  componentDidMount() {
    this.getSectionStatus1();
    let fromRetake;
    let completedDigitalAssessments = [],
      completedServiceAssessments = [],
      totalDigitalAssessments = 0,
      totalSericeAssessments = 0;
    const { assessments } = this.props;
    this.setState({
      loadingState: true,
    });

    var showSubmitStatus = localStorage.getItem("showSubmit");
    if (showSubmitStatus) {
      this.setState({
        showSubmit: showSubmitStatus,
      });
    }

    if (this.props.match.params.fromRetake) {
      fromRetake = this.props.match.params.fromRetake;
      if (fromRetake) {
        this.setState({
          loading: !this.state.loading,
        });
        this.getSectionStatus(this.props.match.params.ppid, fromRetake);
      }
    } else {
    }

    //GTM PageLoad
    try {
      let pagePath = window.location ? window.location.pathname : "";
      sendPageViewGTM("Dashboard Page", pagePath);
    } catch (e) { }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  wipeAssessmentData = () => {
    this.props.wipeAssessmentData(localStorage.getItem("ppid"));
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitAssessment = () => {
    //this.props.submitAssessment();
    this.setState({
      showLoader: true,
    });
    let ppid = localStorage.getItem("ppid");
    axios
      .get(`${API_BASE_URL}/assessment-statuses/score?year=2022&ppid=` + ppid)
      .then((res) => {
        if (res) {
          localStorage.setItem("hasSubmited202", true);
          this.props.history.push(`/reassessment-report/` + ppid);
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  resumeAssessment = (sectionName) => {
    let assessment;
    let selectedSection;

    /* This will save a list of completed section ID's in redux state */
    if (this.props.assessments && this.props.assessments.length > 0) {
      let sectionsList = [];
      this.props.assessments.map((assessment, index) => {
        if (assessment && assessment.sections) {
          assessment.sections.map((section, index) => {
            sectionsList = [...sectionsList, section];
          });
        }
      });

      this.setState({
        sectionsList: sectionsList,
      });

      let completedSections = [];
      if (sectionsList.length > 0) {
        sectionsList.map((section, index) => {
          if (section && section.hasCompleted) {
            completedSections.push(section.Typeform_ID);
          }
        });
      }

      this.props.saveComepletedSecetions(completedSections);
    }

    if (sectionName === "digital") {
      assessment = "D";
      if (this.props.assessments && this.props.assessments.length > 0) {
        this.props.assessments.map((assessment, index) => {
          if (
            assessment.Key.indexOf("Digital") > -1 &&
            assessment.sections &&
            assessment.sections.length > 0
          ) {
            for (let i = 0; i < assessment.sections.length; i++) {
              if (assessment.sections[i].hasCompleted === false) {
                selectedSection = assessment.sections[i];
                break;
              }
            }
          }
        });
      }
    } else if (sectionName === "service") {
      assessment = "S";

      if (this.props.assessments && this.props.assessments.length > 0) {
        this.props.assessments.map((assessment, index) => {
          if (
            assessment.Key.indexOf("Service") > -1 &&
            assessment.sections &&
            assessment.sections.length > 0
          ) {
            for (let i = 0; i < assessment.sections.length; i++) {
              if (assessment.sections[i].hasCompleted === false) {
                selectedSection = assessment.sections[i];
                break;
              }
            }
          }
        });
      }
    } else if (sectionName === "qualifier") {
      assessment = "Q";

      if (this.props.assessments && this.props.assessments.length > 0) {
        this.props.assessments.map((assessment, index) => {
          if (
            assessment.Key.indexOf("Qualifier") > -1 &&
            assessment.sections &&
            assessment.sections.length > 0
          ) {
            for (let i = 0; i < assessment.sections.length; i++) {
              if (assessment.sections[i].hasCompleted === false) {
                selectedSection = assessment.sections[i];
                break;
              }
            }
          }
        });
      }
    }

    if (selectedSection) {
      this.props.history.push(
        `/assessment2022/${assessment}/${selectedSection.Typeform_ID}/${selectedSection.Key}/false`
      );
    }
  };

  timer = () => {
    this.setState({
      showSubmit: true,
      loading: !this.state.loading,
    });
  };

  showLoader = () => {
    if (!this.state.showSubmit) {
      this.setState({
        loading: !this.state.loading,
      });

      localStorage.setItem("showSubmit", true);
      var timerVar = setTimeout(this.timer, 400);
    }
  };

  loadDetailedReport = () => {
    try {
      let ppid = localStorage.getItem("ppid");
      axios
        .get(`${API_BASE_URL}/partners/is-report-visited?ppid=` + ppid)
        .then((res) => {
          this.props.history.push(
            `/report2022/${localStorage.getItem("ppid")}`
          );
        })
        .catch((err) => {
          this.props.history.push(
            `/report2022/${localStorage.getItem("ppid")}`
          );
        });
    } catch (e) {
      this.props.history.push(`/report2022/${localStorage.getItem("ppid")}`);
    }
  };

  hasMatchingLan = (lan) => {
    let lanList = [
      "en",
      "fr",
      "it",
      "de",
      "es",
      "ru",
      "pt",
      "id",
      "ja",
      "ko",
      "th",
      "tr",
      "vi",
      "hi",
    ];
    let hasLan = lanList.includes(lan) || false;
    return hasLan;
  };

  hasNotMatchinglan = (lan) => {
    let lanList = [
      "en",
      "fr",
      "it",
      "de",
      "es",
      "ru",
      "pt",
      "id",
      "ja",
      "ko",
      "th",
      "tr",
      "vi",
      "hi",
    ];
    let hasLan = lanList.includes(lan) || false;
    return !hasLan;
  };

  retakeFullAssessment = () => {
    var txt;
    if (
      window.confirm(
        "Are you sure you want to wipe all the data and start a fresh?"
      )
    ) {
      let ppid = localStorage.getItem("ppid");
      axios
        .post(`${API_BASE_URL}/partners/retake-request/?ppid=` + ppid)
        .then((res) => {
          if (res) {
            alert("Your assessment data has been cleared");
          }
        });
    }
  };

  render() {
    const { assessments, report, loadingProp } = this.props;
    const { loading, loadingState, showLoader } = this.state;
    const { t } = this.props;

    let ppid = localStorage.getItem("ppid");
    let hasQualifierCompleted = false;
    let hasAssessmentSubmitted = false;
    let digitalData = {
      started: false,
      totalSections: 0,
      completedSections: 0,
    };
    let serviceData = {
      started: false,
      totalSections: 0,
      completedSections: 0,
    };

    let hasAssessmentFinallySubmitted = localStorage.getItem("hasSubmited2022")
      ? localStorage.getItem("hasSubmited2022")
      : false;

    if (
      assessments &&
      assessments.length > 0 &&
      assessments[0].sections &&
      assessments[0].sections.length > 0
    ) {
      console.log("assessment", assessments[0].sections[0].hasCompleted);
      console.log("assessment", assessments[0].sections[0].hasFinallySubmitted);
      hasQualifierCompleted = assessments[0].sections[0].hasCompleted;
      hasAssessmentSubmitted = assessments[0].sections[0].hasFinallySubmitted;
      let digitalTotalSections = 0;
      let digitalCompletedSections = 0;
      let serviceTotalSections = 0;
      let serviceCompletedSections = 0;

      assessments.map((assessment, index) => {
        if (
          assessment &&
          assessment.sections &&
          assessment.sections.length > 0
        ) {
          assessment.sections.map((sec, i) => {
            if (assessment.Key.indexOf("Digital") > -1) {
              digitalData.id = assessment.id;
              digitalTotalSections++;
              if (sec.hasCompleted) {
                digitalCompletedSections++;
              }
            }

            if (assessment.Key.indexOf("Service") > -1) {
              serviceData.id = assessment.id;
              serviceTotalSections++;
              if (sec.hasCompleted) {
                serviceCompletedSections++;
              }
            }
          });

          digitalData.started = digitalCompletedSections > 0 ? true : false;
          digitalData.totalSections = digitalTotalSections;
          digitalData.completedSections = digitalCompletedSections;

          serviceData.started = serviceCompletedSections > 0 ? true : false;
          serviceData.totalSections = serviceTotalSections;
          serviceData.completedSections = serviceCompletedSections;

          try {
            if (digitalData.digitalCompletedSections === 0) {
              digitalData.completedPer = 0;
            } else {
              digitalData.completedPer = Math.round(
                (100 / digitalData.totalSections) *
                digitalData.completedSections
              );

              if (digitalData.completedPer > 100) {
                digitalData.completedPer = 100;
              }
            }

            if (serviceData.serviceCompletedSections === 0) {
              serviceData.completedPer = 0;
            } else {
              serviceData.completedPer = Math.ceil(
                (100 / serviceData.totalSections) *
                serviceData.completedSections
              );
              if (serviceData.completedPer > 100) {
                serviceData.completedPer = 100;
              }
            }
          } catch (e) {
            console.info(e);
          }
        }
      });
    }
    let completedDigitalAssessments = [],
      completedServiceAssessments = [],
      totalDigitalAssessments = 0,
      totalSericeAssessments = 0;
    if (assessments && assessments.length > 0) {
      this.props.assessments.map((assessment, index) => {
        if (assessment && assessment.sections) {
          if (assessment.Key.indexOf("Digital") > -1) {
            totalDigitalAssessments = assessment.sections.length;
          } else if (assessment.Key.indexOf("Service") > -1) {
            totalSericeAssessments = assessment.sections.length;
          }

          assessment.sections.map((section, index) => {
            if (assessment.Key.indexOf("Digital") > -1) {
              if (section.hasCompleted) {
                completedDigitalAssessments.push(section.Typeform_ID);
              }
            } else if (assessment.Key.indexOf("Service") > -1) {
              if (section.hasCompleted) {
                completedServiceAssessments.push(section.Typeform_ID);
              }
            }
          });
        }
      });
    }

    this.props.SaveCompletedAssessmentByType(
      completedDigitalAssessments,
      "digital"
    );
    this.props.SaveTotalAssessmentByType(totalDigitalAssessments, "digital");
    this.props.SaveCompletedAssessmentByType(
      completedServiceAssessments,
      "service"
    );
    this.props.SaveTotalAssessmentByType(totalSericeAssessments, "service");

    let selectedLanguage = localStorage.getItem("lan") || "en";

    return (
      <div className="d-flex justify-content-center hp-layout-wrap w-100">
        <div className="d-flex hp-layout hp-home">
          <Header />
          <p className=" ml-3 bread-crumb hp-lite">
            <Link to={`/${localStorage.getItem("ppid")}/true`}>Home</Link> &gt;
            <span>Dashboard</span>
          </p>
          <div className="hp-tabs-wrapper">
            <div
              onClick={() =>
                this.props.history.push(
                  `/dashboard/${localStorage.getItem("ppid")}`
                )
              }
              className="tabsInactive"
              style={{ cursor: "pointer" }}
            >
              2021
            </div>
            <div
              onClick={() =>
                this.props.history.push(
                  `/dashboard2022/${localStorage.getItem("ppid")}`
                )
              }
              className="tabs"
              style={{ cursor: "pointer" }}
            >
              2022
            </div>
          </div>
          <div className="hp-content-wrap d-flex hp-assessment-submit hp-dashboard hp-report">
            {loading && (
              <div className="d-flex justify-content-center align-items-center loading-wrap">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {loadingProp && (
              <div className="d-flex justify-content-center align-items-center loading-wrap-full">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {hasAssessmentSubmitted &&
              selectedLanguage &&
              this.hasMatchingLan(selectedLanguage) && (
                <div className="hp-report-status hp-100-report-desktop">
                  <div className="d-flex flex-column hp-result-landing-chart-wrap">
                    <RoundChart value={100} isStart={false} />
                    <div className="d-flex hp-regular hp-12 hp-chart-text mx-1">
                      {t("REPORT.REPORT_THANK_MSG")}
                    </div>
                    <div className="d-flex col-12 daashboard-download-responses-wrap">
                      <a
                        target="_blank"
                        className="daashboard-download-responses-btn"
                        href={`${API_BASE_URL}/assessment-statuses/downloadResponse?ppid=${ppid}`}
                        rel="noreferrer"
                      >
                        {t("DASHBOARD.DASHBOARD_DOWNLOAD_RESPONSE")}
                        <span className="ml-2">
                          <img src={download_img} />
                        </span>
                      </a>
                    </div>
                  </div>

                  <div className="hp-report-status-score">
                    <div className="hp-primary-color hp-regular hp-22">
                      {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_COMPLETED_USER", {
                        name: getUserName(),
                      })}
                    </div>
                    <div className="hp-assessment-completed">
                      {t("REPORT.REPORT_YOUR_ASSESSMNET_COMPLETE")}
                    </div>
                    <div className="hp-regular hp-14 hp-assessment-last-updated">
                      {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_LAST_UPDATED")}
                      {new Date().toDateString()}
                    </div>
                    <div className="hp-assessment-scores">
                      <div className="hp-assessment-scores-section">
                        <div className="hp-assessment-scores-section-text hp-bold hp-16">
                          {t("REPORT.REPORT_YOUR_DIGITAL_SCORE")}
                        </div>
                        <div className="hp-assessment-scores-section-bar-wrap">
                          <div className="hp-report-score">
                            <span className="hp-report-score-left">&nbsp;</span>
                            <span className="hp-report-score-right">
                              {/* Scored
               {report.length > 0 && report[0].scorePercentage
                ? Math.round(report[0].scorePercentage)
                : 0}
               out of 100 */}
                              {t("REPORT.REPORT_SCORE_TITLE", {
                                score:
                                  report.length > 0 && report[0].scorePercentage
                                    ? Math.round(report[0].scorePercentage)
                                    : 0,
                              })}
                            </span>
                          </div>
                          <div className="hp-assessment-scores-section-bar">
                            <div
                              className="hp-assessment-scores-section-bar-fill"
                              style={{
                                width:
                                  (report.length > 0 &&
                                    report[0].scorePercentage
                                    ? Math.round(report[0].scorePercentage)
                                    : 0) + "%",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="hp-assessment-scores-section mt-4">
                        <div className="hp-assessment-scores-section-text hp-bold hp-16">
                          {t("REPORT.REPORT_YOUR_SERVICES_SCORE")}
                        </div>
                        <div className="hp-assessment-scores-section-bar-wrap">
                          <div className="hp-report-score">
                            <span className="hp-report-score-left">&nbsp;</span>
                            <span className="hp-report-score-right">
                              {/* Scored
               {report.length > 0 && report[1].scorePercentage
                ? Math.round(report[1].scorePercentage)
                : 0}
               out of 100 */}
                              {t("REPORT.REPORT_SCORE_TITLE", {
                                score:
                                  report.length > 0 && report[1].scorePercentage
                                    ? Math.round(report[1].scorePercentage)
                                    : 0,
                              })}
                            </span>
                          </div>
                          <div className="hp-assessment-scores-section-bar">
                            <div
                              className="hp-assessment-scores-section-bar-fill"
                              style={{
                                width:
                                  (report.length > 0 &&
                                    report[1].scorePercentage
                                    ? Math.round(report[1].scorePercentage)
                                    : 0) + "%",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hp-assessment-description d-flex mt-4">
                      {t("REPORT.REPORT_VIEW_YOUR_ENTIRE_REPORT")}
                    </div>
                    <div className="hp-assessment-show-details d-flex mt-3 mb-2 flex-column">
                      <div
                        className="hp-cta-v1 w-100"
                        onClick={this.loadDetailedReport}
                      >
                        {t("REPORT.REPORT_VIEW_FULL_REPORT")}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {hasAssessmentSubmitted &&
              selectedLanguage &&
              this.hasMatchingLan(selectedLanguage) && (
                <div className="hp-100-report-mobile">
                  <div className="hp-primary-color hp-regular hp-22">
                    {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_COMPLETED_USER", {
                      name: getUserName(),
                    })}
                  </div>
                  <div className="hp-report-status">
                    <div className="d-flex justify-content-center align-items-center hp-report-landing hp-report-landing-mob">
                      <RoundChart
                        value={100}
                        isStart={false}
                        width={85}
                        stroke={8}
                        viewReportChart={true}
                      />
                      <div className="hp-report-chart-right-mob">
                        <div className="hp-assessment-completed hp-22 hp-lite">
                          {t(
                            "DASHBOARD.DASHBOARD_CARD_ASSESSMENT_COMPLETED_TEXT1"
                          )}
                        </div>
                        <div className="hp-regular hp-14 hp-assessment-last-updated hp-14 hp-lite">
                          {t(
                            "DASHBOARD.DASHBOARD_CARD_ASSESSMENT_LAST_UPDATED"
                          )}
                          {new Date().toDateString()}
                        </div>
                      </div>
                    </div>

                    <div className="hp-report-status-score">
                      <div className="hp-assessment-scores">
                        <div className="hp-assessment-scores-section">
                          <div className="hp-assessment-scores-section-text hp-bold hp-16">
                            {t("REPORT.REPORT_YOUR_DIGITAL_SCORE")}
                          </div>
                          <div className="hp-assessment-scores-section-bar-wrap">
                            <div className="hp-report-score">
                              <span className="hp-report-score-left"></span>
                              <span className="hp-report-score-right">
                                {/* Scored
                {report.length > 0 && report[0].scorePercentage
                 ? Math.round(report[0].scorePercentage)
                 : 0}
                out of 100 */}
                                {t("REPORT.REPORT_SCORE_TITLE", {
                                  score:
                                    report.length > 0 &&
                                      report[0].scorePercentage
                                      ? Math.round(report[0].scorePercentage)
                                      : 0,
                                })}
                              </span>
                            </div>
                            <div className="hp-assessment-scores-section-bar">
                              <div
                                className="hp-assessment-scores-section-bar-fill"
                                style={{
                                  width:
                                    (report.length > 0 &&
                                      report[0].scorePercentage
                                      ? Math.round(report[0].scorePercentage)
                                      : 0) + "%",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="hp-assessment-scores-section mt-4">
                          <div className="hp-assessment-scores-section-text hp-bold hp-16">
                            {t("REPORT.REPORT_YOUR_SERVICES_SCORE")}
                          </div>
                          <div className="hp-assessment-scores-section-bar-wrap">
                            <div className="hp-report-score">
                              <span className="hp-report-score-left"></span>
                              <span className="hp-report-score-right">
                                {/* Scored
                {report.length > 0 && report[1].scorePercentage
                 ? Math.round(report[1].scorePercentage)
                 : 0}
                out of 100 */}
                                {t("REPORT.REPORT_SCORE_TITLE", {
                                  score:
                                    report.length > 0 &&
                                      report[1].scorePercentage
                                      ? Math.round(report[1].scorePercentage)
                                      : 0,
                                })}
                              </span>
                            </div>
                            <div className="hp-assessment-scores-section-bar">
                              <div
                                className="hp-assessment-scores-section-bar-fill"
                                style={{
                                  width:
                                    (report.length > 0 &&
                                      report[1].scorePercentage
                                      ? Math.round(report[1].scorePercentage)
                                      : 0) + "%",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hp-assessment-description d-flex mt-4">
                        {t("REPORT.REPORT_VIEW_YOUR_ENTIRE_REPORT")}
                      </div>
                      <div className="hp-assessment-show-details d-flex mt-3 mb-2 flex-column justify-content-center">
                        <div
                          className="hp-cta-v1"
                          onClick={this.loadDetailedReport}
                        >
                          {t("REPORT.REPORT_VIEW_FULL_REPORT")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {hasAssessmentSubmitted &&
              selectedLanguage &&
              this.hasNotMatchinglan(selectedLanguage) && (
                <div className="hp-content-wrap d-flex hp-assessment-submit hp-dashboard hp-report hp-other-lan-msg-wrap">
                  <div className="other-lan-msg">
                    {t("COMMON.COMMON_ASSESSMENT_COMPLETED_OTHERLAN")}
                  </div>
                </div>
              )}

            {!hasAssessmentSubmitted && (
              <div className="hp-guide-assessment-block">
                <div className="hp-assessment">
                  <div className="hp-regular hp-32 hp-primary-color">
                    {t("HOME.HOME_WELCOME", { name: getUserName() })}!
                  </div>
                  <div className="hp-lite hp-18 hp-assessment-completed-text">
                    {t("DASHBOARD.DASHBOARD_DESC")}
                  </div>
                  {!hasAssessmentSubmitted && (
                    <div className="mt-2 hp-assessment-link hp-regular hp-18">
                      <Link to={`/${ppid}/true`}>
                        {t("DASHBOARD.DASHBOARD_GUIDE_TO_ASSESSMENT")}
                      </Link>

                      <span className="ml-2">
                        <MDBIcon icon="arrow-right" />
                      </span>
                    </div>
                  )}

                  {serviceData &&
                    serviceData.totalSections ===
                    serviceData.completedSections &&
                    digitalData &&
                    digitalData.totalSections ===
                    digitalData.completedSections &&
                    hasAssessmentSubmitted && (
                      <div className="mt-2 hp-assessment-link hp-regular hp-18">
                        <Link to={`/report/${ppid}`}>
                          {t("DASHBOARD.DASHBOARD_VIEW_REPORT")}
                        </Link>

                        <span className="ml-2">
                          <MDBIcon icon="arrow-right" />
                        </span>
                      </div>
                    )}
                </div>
              </div>
            )}

            {!hasQualifierCompleted && (
              <div className="hp-guide-assessment-block">
                <div className="hp-assessment">
                  <div className="hp-regular hp-32 hp-primary-color">
                    {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_TITLE")}
                  </div>
                  <div className="hp-lite hp-18 hp-assessment-completed-text">
                    {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_DESC")}
                  </div>
                  <div className="hp-section-title-v">
                    {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_CTA_TITLE")}
                  </div>
                  <div className="hp-dashboard-cta-wrap">
                    <div
                      className="hp-cta-v1"
                      onClick={this.resumeAssessment.bind(this, "qualifier")}
                    >
                      {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_CTA_TEXT")}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!hasAssessmentSubmitted &&
              (digitalData.totalSections !== digitalData.completedSections ||
                serviceData.totalSections !==
                serviceData.completedSections) && (
                <div className="hp-final-submit-wrap mt-2">
                  <div className="hp-section-title-v3">
                    {t("DASHBOARD.DASHBOARD_CHOOSE_ASSESSMENT_TITLE")}
                  </div>
                  <div className="hp-lite hp-18 hp-assessment-completed-text">
                    {t("DASHBOARD.DASHBOARD_CHOOSE_ASSESSMENT_DESC")}
                  </div>
                </div>
              )}

            {!hasAssessmentSubmitted &&
              digitalData.totalSections === digitalData.completedSections &&
              serviceData.totalSections === serviceData.completedSections && (
                <div className="hp-final-submit-wrap mt-2">
                  {/*<div className="hp-section-title-v3">Guide to assessment</div>*/}
                  <div className="hp-lite hp-18 hp-assessment-completed-text">
                    {t("DASHBOARD.DASHBOARD_VIEW_RESULTS_MSG")}
                  </div>
                </div>
              )}

            {/* - assessment completed but not submitted yet to calculate the score */}

            {!hasAssessmentSubmitted &&
              digitalData.totalSections === digitalData.completedSections &&
              serviceData.totalSections === serviceData.completedSections && (
                <div className="hp-final-submit-wrap mt-2">
                  <div className="hp-lite hp-32">
                    {t("DASHBOARD.DASHBOARD_FINAL_SUBMIT_TITLE")}
                  </div>
                  <div className="hp-lite hp-18 hp-assessment-completed-text">
                    {t("DASHBOARD.DASHBOARD_FINAL_SUBMIT_MSG")}
                  </div>
                </div>
              )}

            {!hasAssessmentSubmitted &&
              localStorage.getItem("reAssessmentOption") === "BOTH" && (
                <div className="d-flex col-12 hp-completed-card-wrap">
                  <div className="hp-assessment-completed-graph-block d-flex col-sm-12 hp-completed-card flex-column pb-3">
                    <div className="d-flex hp-assessment-completed-top w-100 mt-3">
                      <div className="d-flex flex-column hp-50 hp-completed-card-title">
                        <div className="hp-bold hp-18">
                          {t("DASHBOARD.DASHBOARD_CARD_DIGITAL_TITLE")}
                        </div>
                        <div className="hp-regular hp-18">
                          {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_TEXT")}
                        </div>
                        {hasAssessmentSubmitted && (
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_COMPLETED_TEXT")}
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column hp-50">
                        <div className="hp-14 hp-bold hp-assessment-view-report">
                          {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT")}
                          <span className="ml-2">
                            <MDBIcon icon="arrow-right" />
                          </span>
                        </div>
                        <div className="hp-10 hp-regular flex-row d-flex justify-content-end mt-1">
                          <div className="d-flex hp-assessment-final-submit align-items-center mr-1">
                            <MDBIcon icon="lock" size="2x" />
                          </div>
                          <div className="d-flex flex-column hp-assessment-final-submit">
                            <div>
                              {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                            </div>
                            <div>
                              {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT_SMALL")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex hp-assessment-completed-chart justify-content-center my-4">
                      <RoundChart
                        value={digitalData.completedPer}
                        isStart={!digitalData.started}
                      />
                    </div>

                    {digitalData &&
                      digitalData.totalSections !==
                      digitalData.completedSections &&
                      hasQualifierCompleted && (
                        <div className="d-flex mb-3 hp-take-assessment-text">
                          {t("DASHBOARD.DASHBOARD_CARD_START_TEXT")}
                        </div>
                      )}

                    {digitalData &&
                      digitalData.completedSections === 0 &&
                      hasQualifierCompleted && (
                        <div
                          className="d-flex hp-dashboard-resume-btn hp-cta-v1 mx-4"
                          onClick={this.resumeAssessment.bind(this, "digital")}
                        >
                          {t("DASHBOARD.DASHBOARD_CARD_CTA_START_TEXT")}
                        </div>
                      )}

                    {digitalData &&
                      digitalData.completedSections > 0 &&
                      digitalData.totalSections !==
                      digitalData.completedSections &&
                      hasQualifierCompleted && (
                        <div
                          className="d-flex hp-dashboard-resume-btn hp-cta-v1 mx-4"
                          onClick={this.resumeAssessment.bind(this, "digital")}
                        >
                          {t("DASHBOARD.DASHBOARD_CARD_CTA_RESUME_TEXT")}
                        </div>
                      )}

                    {!hasQualifierCompleted && (
                      <div className="d-flex mb-3 hp-take-assessment-text">
                        {t("DASHBOARD.DASHBOARD_START_ASSESSMENT")}
                      </div>
                    )}
                    {!hasQualifierCompleted && (
                      <div className="d-flex hp-dashboard-resume-btn hp-cta-v1-disabled mb-3 mx-4">
                        {t("DASHBOARD.DASHBOARD_CARD_CTA_FINISH_TEXT")}
                      </div>
                    )}

                    <div className="d-flex hp-assessment-completed-retake flex-column">
                      {digitalData &&
                        digitalData.totalSections ===
                        digitalData.completedSections &&
                        !hasAssessmentSubmitted && (
                          <div className="d-flex justify-content-center hp-primary-color hp-regular hp-18 hp-retake-link">
                            <Link
                              to={`/assessment-retake2022/${digitalData.id}`}
                            >
                              {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                            </Link>
                          </div>
                        )}
                      {digitalData &&
                        digitalData.totalSections ===
                        digitalData.completedSections && (
                          <div className="d-flex justify-content-center align-items-center hp-lite hp-12 my-3 mx-4">
                            {t("DASHBOARD.DASHBOARD_CARD_DIGITAL_DESC")}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="hp-assessment-completed-graph-block d-flex col-sm-12 hp-completed-card flex-column pb-3">
                    <div className="d-flex hp-assessment-completed-top w-100 mt-3">
                      <div className="d-flex flex-column hp-50 hp-completed-card-title">
                        <div className="hp-bold hp-18">
                          {t("DASHBOARD.DASHBOARD_CARD_SERVICES_TITLE")}
                        </div>
                        <div className="hp-regular hp-18">
                          {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_TEXT")}
                        </div>
                        {hasAssessmentSubmitted && (
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_COMPLETED_TEXT")}
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column hp-50">
                        <div className="hp-14 hp-bold hp-assessment-view-report">
                          {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT")}
                          <span className="ml-2">
                            <MDBIcon icon="arrow-right" />
                          </span>
                        </div>
                        <div className="hp-10 hp-regular flex-row d-flex justify-content-end mt-1">
                          <div className="d-flex hp-assessment-final-submit align-items-center mr-1">
                            <MDBIcon icon="lock" size="2x" />
                          </div>
                          <div className="d-flex flex-column hp-assessment-final-submit">
                            <div>
                              {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                            </div>
                            <div>
                              {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT_SMALL")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex hp-assessment-completed-chart justify-content-center my-4">
                      <RoundChart
                        value={serviceData.completedPer}
                        isStart={!serviceData.started}
                      />
                    </div>

                    {serviceData &&
                      serviceData.totalSections !==
                      serviceData.completedSections &&
                      hasQualifierCompleted && (
                        <div className="d-flex mb-3 hp-take-assessment-text">
                          {t("DASHBOARD.DASHBOARD_CARD_START_TEXT")}
                        </div>
                      )}

                    {serviceData &&
                      serviceData.completedSections === 0 &&
                      hasQualifierCompleted && (
                        <div
                          className="d-flex hp-dashboard-resume-btn hp-cta-v1 mx-4"
                          onClick={this.resumeAssessment.bind(this, "service")}
                        >
                          {t("DASHBOARD.DASHBOARD_CARD_CTA_START_TEXT")}
                        </div>
                      )}

                    {serviceData &&
                      serviceData.completedSections > 0 &&
                      serviceData.totalSections !==
                      serviceData.completedSections &&
                      hasQualifierCompleted && (
                        <div
                          className="d-flex hp-dashboard-resume-btn hp-cta-v1 mx-4"
                          onClick={this.resumeAssessment.bind(this, "service")}
                        >
                          {t("DASHBOARD.DASHBOARD_CARD_CTA_RESUME_TEXT")}
                        </div>
                      )}

                    {!hasQualifierCompleted && (
                      <div className="d-flex mb-3 hp-take-assessment-text">
                        {t("DASHBOARD.DASHBOARD_START_ASSESSMENT")}
                      </div>
                    )}
                    {!hasQualifierCompleted && (
                      <div className="d-flex hp-dashboard-resume-btn hp-cta-v1-disabled mb-3 mx-4">
                        {t("DASHBOARD.DASHBOARD_CARD_CTA_FINISH_TEXT")}
                      </div>
                    )}

                    <div className="d-flex hp-assessment-completed-retake flex-column">
                      {serviceData &&
                        serviceData.totalSections ===
                        serviceData.completedSections &&
                        !hasAssessmentSubmitted && (
                          <div className="d-flex justify-content-center hp-primary-color hp-regular hp-18 hp-retake-link">
                            <Link
                              to={`/assessment-retake2022/${serviceData.id}`}
                            >
                              {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                            </Link>
                          </div>
                        )}

                      {serviceData &&
                        serviceData.totalSections ===
                        serviceData.completedSections && (
                          <div className="d-flex justify-content-center align-items-center hp-lite hp-12 my-3 mx-4">
                            {t("DASHBOARD.DASHBOARD_CARD_SERVICES_DESC")}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}

            {!hasAssessmentSubmitted &&
              localStorage.getItem("reAssessmentOption") === "DIGITAL_ONLY" && (
                <div className="d-flex col-12 hp-completed-card-wrap">
                  <div className="hp-assessment-completed-graph-block d-flex col-sm-12 hp-completed-card flex-column pb-3">
                    <div className="d-flex hp-assessment-completed-top w-100 mt-3">
                      <div className="d-flex flex-column hp-50 hp-completed-card-title">
                        <div className="hp-bold hp-18">
                          {t("DASHBOARD.DASHBOARD_CARD_DIGITAL_TITLE")}
                        </div>
                        <div className="hp-regular hp-18">
                          {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_TEXT")}
                        </div>
                        {hasAssessmentSubmitted && (
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_COMPLETED_TEXT")}
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column hp-50">
                        <div className="hp-14 hp-bold hp-assessment-view-report">
                          {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT")}
                          <span className="ml-2">
                            <MDBIcon icon="arrow-right" />
                          </span>
                        </div>
                        <div className="hp-10 hp-regular flex-row d-flex justify-content-end mt-1">
                          <div className="d-flex hp-assessment-final-submit align-items-center mr-1">
                            <MDBIcon icon="lock" size="2x" />
                          </div>
                          <div className="d-flex flex-column hp-assessment-final-submit">
                            <div>
                              {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                            </div>
                            <div>
                              {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT_SMALL")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex hp-assessment-completed-chart justify-content-center my-4">
                      <RoundChart
                        value={digitalData.completedPer}
                        isStart={!digitalData.started}
                      />
                    </div>

                    {digitalData &&
                      digitalData.totalSections !==
                      digitalData.completedSections &&
                      hasQualifierCompleted && (
                        <div className="d-flex mb-3 hp-take-assessment-text">
                          {t("DASHBOARD.DASHBOARD_CARD_START_TEXT")}
                        </div>
                      )}

                    {digitalData &&
                      digitalData.completedSections === 0 &&
                      hasQualifierCompleted && (
                        <div
                          className="d-flex hp-dashboard-resume-btn hp-cta-v1 mx-4"
                          onClick={this.resumeAssessment.bind(this, "digital")}
                        >
                          {t("DASHBOARD.DASHBOARD_CARD_CTA_START_TEXT")}
                        </div>
                      )}

                    {digitalData &&
                      digitalData.completedSections > 0 &&
                      digitalData.totalSections !==
                      digitalData.completedSections &&
                      hasQualifierCompleted && (
                        <div
                          className="d-flex hp-dashboard-resume-btn hp-cta-v1 mx-4"
                          onClick={this.resumeAssessment.bind(this, "digital")}
                        >
                          {t("DASHBOARD.DASHBOARD_CARD_CTA_RESUME_TEXT")}
                        </div>
                      )}

                    {!hasQualifierCompleted && (
                      <div className="d-flex mb-3 hp-take-assessment-text">
                        {t("DASHBOARD.DASHBOARD_START_ASSESSMENT")}
                      </div>
                    )}
                    {!hasQualifierCompleted && (
                      <div className="d-flex hp-dashboard-resume-btn hp-cta-v1-disabled mb-3 mx-4">
                        {t("DASHBOARD.DASHBOARD_CARD_CTA_FINISH_TEXT")}
                      </div>
                    )}

                    <div className="d-flex hp-assessment-completed-retake flex-column">
                      {digitalData &&
                        digitalData.totalSections ===
                        digitalData.completedSections &&
                        !hasAssessmentSubmitted && (
                          <div className="d-flex justify-content-center hp-primary-color hp-regular hp-18 hp-retake-link">
                            <Link
                              to={`/assessment-retake2022/${digitalData.id}`}
                            >
                              {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                            </Link>
                          </div>
                        )}
                      {digitalData &&
                        digitalData.totalSections ===
                        digitalData.completedSections && (
                          <div className="d-flex justify-content-center align-items-center hp-lite hp-12 my-3 mx-4">
                            {t("DASHBOARD.DASHBOARD_CARD_DIGITAL_DESC")}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
            {!hasAssessmentSubmitted &&
              localStorage.getItem("reAssessmentOption") === "SERVICE_ONLY" && (
                <div className="d-flex col-12 hp-completed-card-wrap">
                  <div className="hp-assessment-completed-graph-block d-flex col-sm-12 hp-completed-card flex-column pb-3">
                    <div className="d-flex hp-assessment-completed-top w-100 mt-3">
                      <div className="d-flex flex-column hp-50 hp-completed-card-title">
                        <div className="hp-bold hp-18">
                          {t("DASHBOARD.DASHBOARD_CARD_SERVICES_TITLE")}
                        </div>
                        <div className="hp-regular hp-18">
                          {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_TEXT")}
                        </div>
                        {hasAssessmentSubmitted && (
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_COMPLETED_TEXT")}
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column hp-50">
                        <div className="hp-14 hp-bold hp-assessment-view-report">
                          {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT")}
                          <span className="ml-2">
                            <MDBIcon icon="arrow-right" />
                          </span>
                        </div>
                        <div className="hp-10 hp-regular flex-row d-flex justify-content-end mt-1">
                          <div className="d-flex hp-assessment-final-submit align-items-center mr-1">
                            <MDBIcon icon="lock" size="2x" />
                          </div>
                          <div className="d-flex flex-column hp-assessment-final-submit">
                            <div>
                              {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                            </div>
                            <div>
                              {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT_SMALL")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex hp-assessment-completed-chart justify-content-center my-4">
                      <RoundChart
                        value={serviceData.completedPer}
                        isStart={!serviceData.started}
                      />
                    </div>

                    {serviceData &&
                      serviceData.totalSections !==
                      serviceData.completedSections &&
                      hasQualifierCompleted && (
                        <div className="d-flex mb-3 hp-take-assessment-text">
                          {t("DASHBOARD.DASHBOARD_CARD_START_TEXT")}
                        </div>
                      )}

                    {serviceData &&
                      serviceData.completedSections === 0 &&
                      hasQualifierCompleted && (
                        <div
                          className="d-flex hp-dashboard-resume-btn hp-cta-v1 mx-4"
                          onClick={this.resumeAssessment.bind(this, "service")}
                        >
                          {t("DASHBOARD.DASHBOARD_CARD_CTA_START_TEXT")}
                        </div>
                      )}

                    {serviceData &&
                      serviceData.completedSections > 0 &&
                      serviceData.totalSections !==
                      serviceData.completedSections &&
                      hasQualifierCompleted && (
                        <div
                          className="d-flex hp-dashboard-resume-btn hp-cta-v1 mx-4"
                          onClick={this.resumeAssessment.bind(this, "service")}
                        >
                          {t("DASHBOARD.DASHBOARD_CARD_CTA_RESUME_TEXT")}
                        </div>
                      )}

                    {!hasQualifierCompleted && (
                      <div className="d-flex mb-3 hp-take-assessment-text">
                        {t("DASHBOARD.DASHBOARD_START_ASSESSMENT")}
                      </div>
                    )}
                    {!hasQualifierCompleted && (
                      <div className="d-flex hp-dashboard-resume-btn hp-cta-v1-disabled mb-3 mx-4">
                        {t("DASHBOARD.DASHBOARD_CARD_CTA_FINISH_TEXT")}
                      </div>
                    )}

                    <div className="d-flex hp-assessment-completed-retake flex-column">
                      {serviceData &&
                        serviceData.totalSections ===
                        serviceData.completedSections &&
                        !hasAssessmentSubmitted && (
                          <div className="d-flex justify-content-center hp-primary-color hp-regular hp-18 hp-retake-link">
                            <Link
                              to={`/assessment-retake2022/${serviceData.id}`}
                            >
                              {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                            </Link>
                          </div>
                        )}

                      {serviceData &&
                        serviceData.totalSections ===
                        serviceData.completedSections && (
                          <div className="d-flex justify-content-center align-items-center hp-lite hp-12 my-3 mx-4">
                            {t("DASHBOARD.DASHBOARD_CARD_SERVICES_DESC")}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}

            {/* TODO - assessment completed but not submitted yet to calculate the score */}
            {!hasAssessmentSubmitted &&
              digitalData.totalSections === digitalData.completedSections &&
              serviceData.totalSections === serviceData.completedSections && (
                <div className="hp-submit-v2 d-flex flex-column">
                  <div className="d-flex h-18 hp-submit-v2-text text-red">
                    {t("DASHBOARD.DASHBOARD_FINISH_TITLE")}
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center my-2 mt-2">
                    <div
                      className="hp-asmnt-submit-btn hp-cta-v1 hp-regular hp-18 hp-cta-v1h"
                      onClick={this.submitAssessment}
                    >
                      {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                    </div>
                    <div className="d-flex col-12 justify-content-center daashboard-download-responses-wrap">
                      <a
                        target="_blank"
                        className="daashboard-download-responses-btn"
                        href={`${API_BASE_URL}/assessment-statuses/downloadResponse?ppid=${ppid}&year=2022`}
                        rel="noreferrer"
                      >
                        {t("DASHBOARD.DASHBOARD_DOWNLOAD_RESPONSE")}
                        <span className="ml-2">
                          <img src={download_img} />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              )}

            {hasQualifierCompleted &&
              selectedLanguage &&
              this.hasMatchingLan(selectedLanguage) && (
                <div className="hp-guide-assessment-block">
                  <div className="hp-partnerprofile-block">
                    <div className="hp-regular hp-32 hp-primary-color">
                      {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_TITLE")}
                    </div>
                    <div className="hp-lite hp-18 hp-assessment-completed-text">
                      {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_COMPLETED_DESC")}
                    </div>

                    <div className="mt-2 d-flex flex-coloumn warmup-retake">
                      <div className="warmup-retake-icon">
                        <MDBIcon icon="check-circle" size="2x" />
                      </div>
                      <div className="warmup-retake-text">
                        {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_COMPLETED")}
                      </div>
                    </div>

                    {!hasAssessmentSubmitted && (
                      <a
                        className="mt-2 assessment-retake-link"
                        onClick={this.toggle}
                      >
                        {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_RETAKE")}
                      </a>
                    )}
                  </div>
                </div>
              )}

            {hasQualifierCompleted &&
              selectedLanguage &&
              this.hasNotMatchinglan(selectedLanguage) &&
              !hasAssessmentSubmitted && (
                <div className="hp-guide-assessment-block">
                  <div className="hp-partnerprofile-block">
                    <div className="hp-regular hp-32 hp-primary-color">
                      {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_TITLE")}
                    </div>
                    <div className="hp-lite hp-18 hp-assessment-completed-text">
                      {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_COMPLETED_DESC")}
                    </div>

                    <div className="mt-2 d-flex flex-coloumn warmup-retake">
                      <div className="warmup-retake-icon">
                        <MDBIcon icon="check-circle" size="2x" />
                      </div>
                      <div className="warmup-retake-text">
                        {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_COMPLETED")}
                      </div>
                    </div>

                    {!hasAssessmentSubmitted && (
                      <a
                        className="mt-2 assessment-retake-link"
                        onClick={this.toggle}
                      >
                        {t("DASHBOARD.DASHBOARD_PARTNER_PROFILE_RETAKE")}
                      </a>
                    )}
                  </div>
                </div>
              )}
            <div></div>

            {/* ---------------------- EDC section Starts ----------------------- */}
            {JSON.parse(localStorage?.getItem("userInfo"))?.showEDC &&
              hasAssessmentFinallySubmitted &&
              hasAssessmentFinallySubmitted === "true" &&
              selectedLanguage &&
              this.hasMatchingLan(selectedLanguage) && <GetConnectedEDC />}
            {/* ---------------------- EDC section Ends ----------------------- */}

            {/* Toolkit */}
            {hasAssessmentFinallySubmitted &&
              hasAssessmentFinallySubmitted === "true" &&
              selectedLanguage &&
              this.hasMatchingLan(selectedLanguage) && (
                <>
                  <div className="w-100 d-flex justify-content-between mt-3">
                    <div
                      className={this.state.isTab ? "tab tab-active" : "tab"}
                      onClick={() =>
                        this.setState({
                          isTab: true,
                        })
                      }
                    >
                      <p>Digital Toolkit Resources</p>
                    </div>

                    <div
                      className={this.state.isTab ? "tab" : "tab tab-active"}
                      onClick={() =>
                        this.setState({
                          isTab: false,
                        })
                      }
                    >
                      <p>Service Toolkit Resources</p>
                    </div>
                  </div>
                  {this.state.isTab ? (
                    <DigitalToolKitComponent />
                  ) : (
                    <ServiceToolKitComponent />
                  )}
                </>
              )}
          </div>
          <Footer />
        </div>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}></MDBModalHeader>
          <MDBModalBody>
            <div className="hp-reatake-alert-wrap">
              <div className="hp-retake-icon d-flex justify-content-center align-items-center">
                <img src={alarm} className="img-fluid" />
              </div>
              <div className="hp-retake-warn-text-1">
                <div className="retake-title">
                  {t("DASHBOARD.DASHBOARD_MODAL_RETAKE_TITLE")}
                </div>
                <div className="retake-body">
                  {t("DASHBOARD.DASHBOARD_MODAL_RETAKE_DESC_1")}
                </div>
              </div>
              <div className="hp-retake-warn-text-2">
                <div className="retake-confirm-text">
                  {t("DASHBOARD.DASHBOARD_MODAL_RETAKE_DESC_2")}
                </div>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <div
              className="hp-data-wipe-confirm"
              onClick={this.wipeAssessmentData}
            >
              {t("DASHBOARD.DASHBOARD_MODAL_RETAKE_CTA1")}
            </div>
            <div>
              <div
                className="hp-cta-v1 hp-data-wipe-cancel-cta"
                onClick={this.toggle}
              >
                {t("DASHBOARD.DASHBOARD_MODAL_RETAKE_CTA2")}
              </div>
            </div>
          </MDBModalFooter>
        </MDBModal>
        <IEModal />
        {showLoader && <ProgressLoader />}
      </div>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard2022)
);
