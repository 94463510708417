import { MDBCol, MDBRow, MDBCard } from "mdbreact";
import React from "react";
import bookmark from "../../assets/images/bookmark.png";
import comingSoon from "../../assets/images/coming-soon.png";
import play from "../../assets/images/play.png";
import "./grid.scss";

export default function DigiToolkitGrid({ data, sendGTMdata }) {
  return (
    <MDBRow className=" my-3 grid-wrapper">
      {data?.map((item) => (
        <MDBCol
          className="my-3 grid-card col-md-3 col-6"
          key={item?.id}
          onClick={() => {
            sendGTMdata(item, item?.Type);
            window.open(item?.Url, "_blank");
          }}
        >
          <MDBCard>
            {item?.New && (
              <img
                src={bookmark}
                alt="bookmark"
                style={{
                  height: 20,
                  width: 20,
                  objectFit: "contain",
                  position: "absolute",
                  right: 15,
                }}
              />
            )}
            {item?.ComingSoon && (
              <img
                src={comingSoon}
                alt="bookmark"
                style={{
                  height: 30,
                  width: 50,
                  objectFit: "contain",
                  position: "absolute",
                  right: 0,
                }}
              />
            )}
            <p className="hp-regular p-3 my-1" style={{ fontSize: 13 }}>
              {item?.Title}
            </p>

            <div className="grid-wrap-container">
              <div className="grid-wrap">
                <div className="d-flex w-100 justify-content-center">
                  <img src={item?.Icon?.url} alt="image1" />
                </div>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-end">
              <div className="d-flex align-items-center">
                <p className="mb-0" style={{ fontSize: 10, color: "#000" }}>
                  Explore
                </p>
                <img
                  src={play}
                  alt="play"
                  style={{
                    height: 25,
                    width: 25,
                  }}
                />
              </div>
            </div>
          </MDBCard>
        </MDBCol>
      ))}
    </MDBRow>
  );
}
