import React from "react";
import './technologyselector.scss';
import { connect } from 'react-redux';
import { MDBInput, MDBBtn } from 'mdbreact';

import timer from "../../../assets/icons/timer.svg";
import Header from '../../../components/BusinessPlanner/WizardHeader/Header';
import CurrencyDisclaimer from '../../../components/BusinessPlanner/CurrencyDisclaimer';
import { withTranslation } from 'react-i18next';

//redux
import {fetchBPMaterData, setBPActiveSection, setPlanSelectedState} from '../../../actions/bpAction';
import {getBPMasterData} from '../../../reducers/bpReducer';
import {sendPageViewGTM} from '../../../utils/util';


const mapStateToProps = (state) => {
    return {
     bpMasterData: getBPMasterData(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchBPMaterData: (ppid) => dispatch(fetchBPMaterData(ppid)),
        setBPActiveSection: (section) => dispatch(setBPActiveSection(section)),
        setPlanSelectedState: (key, val) => dispatch(setPlanSelectedState(key, val))
    }
}

class BusinessPlannerTechnologySelectorPage extends React.Component {

    state = {

    }

    componentDidMount =()=> {
        try {
            let ppid = localStorage.getItem('ppid');
            if(ppid) {
                this.props.fetchBPMaterData(ppid);
            }
        } catch(e){}

        //GTM PageLoad

        try {
            let pagePath = window.location ? window.location.pathname : "";
            if (window && window.dataLayer) {
               sendPageViewGTM("Business Planner Technology Selection Page", pagePath);
            }
        } catch (e) {}
    }

    handlePlanChk = (data, e) => {
        this.props.setPlanSelectedState(data.Key, e.target.checked)
    }

    ctaClick = (selectedTechnologiesCount) => {
        this.props.setBPActiveSection('Engage');
        this.props.history.push(`/bp/start-planner/`+selectedTechnologiesCount);
    }
    render() {
        const {bpMasterData} = this.props;

        let selectedTechnologiesCount = 0;
        if(bpMasterData) {
            bpMasterData.map((data,i)=> {
                if(data.HasPartnerSelected) {
                    selectedTechnologiesCount++;
                }
            })
        }

        const currency = localStorage.getItem('BP_CURRENCY');
        return (
            <div className="planner-timeline">
                <Header />
                <div className="container technology-selection-wrap">
                    <div className="timer-container">
                        <img src={timer} className="stopwatch" />
                        <span className="time-left">15 Minutes Left</span>
                    </div>

                    <div className="timeline">
                        <div className="heading">
                            <span className="head-count">1</span> <span className="head-text">Observation</span>
                        </div>
                        <div className="content">
                            <div className="content-body">
                                <p className="content-body-info">
                                    Based on your digital assessment listed below are the technologies you already have.
                                </p>
                                <div className="table-element">
                                    <div className="ele">
                                        <p className="h">
                                            Engage
                                        </p>
                                        {bpMasterData && bpMasterData.filter(bpMaster=> (bpMaster.Section === 'Engage' && bpMaster.HasPartnerSelected)).map((masterData, index) => (
                                            <p className="v" key={masterData.Key}>
                                                {masterData.Name}
                                            </p>
                                        ))}
                                    </div>
                                    <div className="ele">
                                        <p className="h">
                                            Transact
                                        </p>
                                        {bpMasterData && bpMasterData.filter(bpMaster=> (bpMaster.Section === 'Transact' && bpMaster.HasPartnerSelected)).map((masterData, index) => (
                                            <p className="v" key={masterData.Key}>
                                                {masterData.Name}
                                            </p>
                                        ))}
                                    </div>
                                    <div className="ele">
                                        <p className="h">
                                            Retain
                                        </p>
                                        {bpMasterData && bpMasterData.filter(bpMaster=> (bpMaster.Section === 'Retain' && bpMaster.HasPartnerSelected)).map((masterData, index) => (
                                            <p className="v" key={masterData.Key}>
                                                {masterData.Name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="heading">
                            <span className="head-count">2</span> <span className="head-text">Technology Selection</span>
                        </div>
                        <div className="content">
                            <div className="content-body">
                                <p className="content-body-info">
                                    Let's now add new technologies to your Digital business plan.
                                </p>
                                <p className="technology-selection-desc">
                                    You can add new technologies to your Digital Business Plan in this section.
                                    Check boxes to add them in the plan.
                                    <br />
                                    <br />
                                    <b>Note*</b> You can also consider adding existing technologies.
                                    Example, If you already have a website you can add a new website for your company, if you are considering for an upgrade.
                                </p>
                                <div>
                                    <p className="select-group-title">
                                        Engage
                                    </p>
                                    {bpMasterData && bpMasterData.filter(bpMaster=> bpMaster.Section === 'Engage').map((masterData, index) => (
                                        <div className="custom-control custom-checkbox" key={masterData.Key}>
                                            <input type="checkbox" className="custom-control-input" onChange={this.handlePlanChk.bind(this, masterData)} id={masterData.Key} checked={masterData.HasPartnerSelected ? true : false} />
                                            <label className="custom-control-label" htmlFor={masterData.Key} >{masterData.Name}</label>
                                        </div>
                                    ))}
                                    

                                    <p className="select-group-title">
                                        Transact
                                    </p>
                                    {bpMasterData && bpMasterData.filter(bpMaster=> bpMaster.Section === 'Transact').map((masterData, index) => (
                                        <div className="custom-control custom-checkbox" key={masterData.Key}>
                                            <input type="checkbox" className="custom-control-input" onChange={this.handlePlanChk.bind(this, masterData)} id={masterData.Key} checked={masterData.HasPartnerSelected ? true : false} />
                                            <label className="custom-control-label" htmlFor={masterData.Key} >{masterData.Name}</label>
                                        </div>
                                    ))}

                                    <p className="select-group-title">
                                        Retain
                                    </p>
                                    {bpMasterData && bpMasterData.filter(bpMaster=> bpMaster.Section === 'Retain').map((masterData, index) => (
                                        <div className="custom-control custom-checkbox" key={masterData.Key}>
                                            <input type="checkbox" className="custom-control-input" onChange={this.handlePlanChk.bind(this, masterData)} id={masterData.Key} checked={masterData.HasPartnerSelected ? true : false} />
                                            <label className="custom-control-label" htmlFor={masterData.Key} >{masterData.Name}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="heading">
                            <span className="head-count">3</span> <span className="head-text">Confirmation</span>
                        </div>
                        <div className="content final-step">
                            <div className="content-body">
                                <p className="content-body-info">
                                    {selectedTechnologiesCount} technologies added in the Digital Business Plan.
                                </p>
                                <p className="tc-desc">
                                    Don’t worry, you can change this later.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="btn-proceed" >
                        <div className="tech-selection-cta" onClick={this.ctaClick.bind(this, selectedTechnologiesCount)}>Okay, proceed</div>
                    </div>
                </div>
                {(currency === 'KRW' || currency === 'IDR' || currency === 'VND') &&
                    <CurrencyDisclaimer />
                }
            </div>
        )

    }


}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BusinessPlannerTechnologySelectorPage));