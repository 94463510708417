import React from 'react';
import GSpeedRoundChart from "../GSpeedRoundChart";
import { MDBIcon } from 'mdbreact';
import { withTranslation } from 'react-i18next';

class PageSpeedResult extends React.Component {

    render() {

        const {selectedDeviceData, report, fromPDF} = this.props;
        const { t } = this.props;

        let wrapClass = '';
        if(fromPDF) {
            wrapClass ='w-100';
        } else {
            wrapClass = 'hp-content-wrap';
        }
        return (
            <div className={`${wrapClass} hp-pagespeed`}>
                <div className="hp-pagespeed-chart">
                    <div className="hp-pagespeed-chart-graph">
                        <GSpeedRoundChart value={Math.round(selectedDeviceData.Score)} isStart={false} isPageSpeed={true} />
                    </div>
                    <div className="hp-pagespeed-chart-title">{report.Website}</div>
                    <div className="hp-pagespeed-chart-legend">
                    <div className="legend-tile">
                        <span className="red-bar"></span>
                        <span>0 - 49</span>
                        </div>
                    <div className="legend-tile">
                        <span className="yellow-bar"></span>
                        <span>50 - 89</span>
                        </div>
                    <div className="legend-tile">
                        <span className="green-bar"></span>
                        <span>90 -100</span>
                        </div>
                    </div>
                </div>
                <div className="hp-pagespeed-oppertunity">
                    <div className="oppertunity-header">
                        <div className="oppertunity-header-left">Opportunity</div>
                        <div className="oppertunity-header-right">Estimated savings</div>
                    </div>

                    {selectedDeviceData && selectedDeviceData.Opportunities && selectedDeviceData.Opportunities.length > 0 && selectedDeviceData.Opportunities.map((deviceData, index) => (
                        <div className="oppertunity-data" key={index}>
                            <div className="oppertunity-data-left">
                                <span>
                                    <MDBIcon icon="caret-up" size='2x'/>
                                </span>
                                {deviceData.title}
                            </div>
                            <div className="oppertunity-data-right">
                                {/*<div className="score-chart">
                                    <div className="chart-filling" style={{'width': '47%'}}></div>
                                </div>*/}
                                <div className="score-text">{((deviceData.numericValue)/1000).toFixed(2)}s</div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        )
    }
}
export default withTranslation()(PageSpeedResult);
