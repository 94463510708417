import axios from "axios";
import React from "react";
import digiDownloadBtn from "../../assets/images/digiDownloadBtn.svg";
import { API_BASE_URL } from "../../config/api-config";

export default function DigiToolKitAssementReport() {
  const downloadPDF = () => {
    let ppid = localStorage.getItem("ppid");
    axios
      .get(`${API_BASE_URL}/partners/getdetails?ppid=` + ppid, {
        headers: {
          "Content-Type": "application/pdf",
        },
      })
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.length > 0) {
          var rs = res.data.data;
          if (rs[0] && rs[0].ReportURL && rs[0].ReportURL.length > 0) {
            const url = (window.URL = rs[0].ReportURL);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.download = true;
            link.setAttribute("download", `${ppid}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
        }
      })
      .catch((err) => {
        // this.toggle();
      });
  };
  return (
    <div
      className="m-3 p-3 d-flex align-items-center"
      style={{ borderTop: ".5px solid #ccc", borderBottom: ".5px solid #ccc" }}
    >
      <p className="m-0 hp-lite" style={{ fontSize: "22px" }}>
        Your assessment results
      </p>
      <div
        className="ml-3 border-0 bg-transparent"
        style={{ cursor: "pointer" }}
      >
        <img src={digiDownloadBtn} alt="download-btn" onClick={downloadPDF} />
      </div>
    </div>
  );
}
