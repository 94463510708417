import React from "react";

import { Link } from "react-router-dom";
import { MDBCol, MDBContainer, MDBModal, MDBModalBody, MDBRow } from "mdbreact";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { MDBCollapse, MDBIcon } from "mdbreact";

import { connect } from "react-redux";
import {
 LoadAssessments,
 SaveCompletedSections,
 emptyCompletedSections,
 SaveCompletedAssessmentByType,
 SaveTotalAssessmentByType,
} from "./actions";
import { getAssessments } from "./homeReducer";
import { sendPageViewGTM } from "../../utils/util";

import test_video from "../../assets/videos/test.mp4";
import timer from "../../assets/icons/timer.svg";

import { withTranslation } from "react-i18next";

//Custom components
import HomeBanner from "../../components/HomeBanner";

/**
 * Custom style import
 * **/
import "./home.scss";

/**
 * Components import
 * **/

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import RadarChart from "../../components/RadarChart/RadarChart";
import IEModal from "../../components/IEDisclaimerModal";
import { API_BASE_URL } from "../../config/api-config";
import axios from "axios";

const mapStateToProps = (state) => {
 return {
  assessments: getAssessments(state),
 };
};

const mapDispatchToProps = (dispatch, ownProps) => {
 return {
  LoadAssessments: (ppid) => dispatch(LoadAssessments(ppid, ownProps, true)),
  saveComepletedSecetions: (data) => dispatch(SaveCompletedSections(data)),
  emptyCompletedSections: () => dispatch(emptyCompletedSections()),
  SaveCompletedAssessmentByType: (data, type) =>
   dispatch(SaveCompletedAssessmentByType(data, type)),
  SaveTotalAssessmentByType: (data, type) =>
   dispatch(SaveTotalAssessmentByType(data, type)),
 };
};

class HomeReAssessment extends React.Component {
 constructor(props) {
  super(props);

  this.state = {
   sectionID: "",
   sectionName: "",
   collapse1: false,
   collapseID: "",
   completedSections: [],
   sectionsList: [],
   modal: false,
   userInfo: null,
   benefitsmodal: false,
  };

  // Loading assessment from API
  if (this.props.match.params.ppid) {
   localStorage.setItem("ppid", this.props.match.params.ppid);
   this.props.LoadAssessments(this.props.match.params.ppid);
  }
 }

 toggleCollapse = (collapseID) => () => {
  this.setState((prevState) => ({
   collapseID: prevState.collapseID !== collapseID ? collapseID : "",
  }));
 };

 toggleSingleCollapse = (collapseId) => {
  this.setState({
   ...this.state,
   [collapseId]: !this.state[collapseId],
  });
 };

 componentDidMount() {
  let reOption = localStorage.getItem("reAssessmentOption");
  if (reOption === "NONE") {
   window.location.href = `/dashboard/${localStorage.getItem("ppid")}`;
  }
  if (this.props.match.params.ppid) {
   var savedPPID = localStorage.getItem("ppid");
   if (
    savedPPID &&
    savedPPID !== undefined &&
    savedPPID !== this.props.match.params.ppid
   ) {
    localStorage.removeItem("qParam");
    localStorage.removeItem("showSubmit");
    this.props.emptyCompletedSections();
   }
   if (!localStorage.getItem("ppid"))
    localStorage.setItem("ppid", this.props.match.params.ppid);
   let hasUserStartedAssessment = localStorage.getItem("hasStarted");

   let isRedirect = this.props.match.params.redirect;
   if (
    this.props.match.params.ppid &&
    (!isRedirect || isRedirect === undefined)
   ) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
     event: "Login",
     userId: this.props.match.params.ppid,
    });
   }

   if (
    hasUserStartedAssessment === "true" &&
    (isRedirect === undefined || !isRedirect || isRedirect === null)
   ) {
    //this.props.history.push(`/dashboard/${this.props.match.params.ppid}`);
   }

   let userData = localStorage.getItem("userInfo");
   if (userData) {
    this.setState({
     userInfo: JSON.parse(userData),
    });
   }
  }

  /*
   * Logic to get completed digital and service assessments id's
   */
  const { assessments } = this.props;
  let sectionsList = [];
  let completedDigitalAssessments = [],
   completedServiceAssessments = [],
   totalDigitalAssessments = 0,
   totalSericeAssessments = 0;
  if (assessments && assessments.length > 0) {
   this.props.assessments.map((assessment, index) => {
    if (assessment && assessment.sections) {
     if (assessment.Key.indexOf("Digital") > -1) {
      totalDigitalAssessments = assessment.sections.length;
     } else if (assessment.Key.indexOf("Service") > -1) {
      totalSericeAssessments = assessment.sections.length;
     }

     assessment.sections.map((section, index) => {
      if (assessment.Key.indexOf("Digital") > -1) {
       if (section.hasCompleted) {
        completedDigitalAssessments.push(section.Typeform_ID);
       }
      } else if (assessment.Key.indexOf("Service") > -1) {
       if (section.hasCompleted) {
        completedServiceAssessments.push(section.Typeform_ID);
       }
      }
      sectionsList = [...sectionsList, section];
     });
    }
   });

   let completedSections = [];
   if (sectionsList.length > 0) {
    sectionsList.map((section, index) => {
     if (section && section.hasCompleted) {
      completedSections.push(section.Typeform_ID);
     }
    });
   }
  }

  //Storing completed Digital and service assessment ID's to application store
  this.props.SaveCompletedAssessmentByType(
   completedDigitalAssessments,
   "digital"
  );
  this.props.SaveTotalAssessmentByType(totalDigitalAssessments, "digital");
  this.props.SaveCompletedAssessmentByType(
   completedServiceAssessments,
   "service"
  );
  this.props.SaveTotalAssessmentByType(totalSericeAssessments, "service");

  //GTM PageLoad
  try {
   let pagePath = window.location ? window.location.pathname : "";
   sendPageViewGTM("Home Page", pagePath);
  } catch (e) {}
 }

 /*
  * Start an assessment, the first assessment configired as Qualifier
  * With Qualifier assessment id, we will be calling the assessment route ('/assessment/')
  */
 startAssessment = () => {
  let ppidChk = localStorage.getItem("ppid");
  if (ppidChk === null || ppidChk === ":ppid") {
   alert("Please provide a valid PPID, for time being pass it with URL");
  }

  if (this.props.assessments && this.props.assessments.length > 0) {
   //logic to get the list of all assessment sections (Qulifier, Digital and Service)
   let sectionsList = [];
   this.props.assessments.map((assessment, index) => {
    if (assessment && assessment.sections) {
     assessment.sections.map((section, index) => {
      sectionsList = [...sectionsList, section];
     });
    }
   });

   this.setState({
    sectionsList: sectionsList,
   });

   // Logic to find list of all completed section ID's in all the assessments
   let completedSections = [];
   if (sectionsList.length > 0) {
    sectionsList.map((section, index) => {
     if (section && section.hasCompleted) {
      completedSections.push(section.Typeform_ID);
     }
    });
   }

   //Saving list of all completed sections in application store
   this.props.saveComepletedSecetions(completedSections);
   let sectionID, sectionName;
   if (
    this.props.assessments &&
    this.props.assessments[0].Key === "Qualifier"
   ) {
    sectionID =
     this.props.assessments[0].sections &&
     this.props.assessments[0].sections.length > 0
      ? this.props.assessments[0].sections[0].Typeform_ID
      : null;

    sectionName =
     this.props.assessments[0].sections &&
     this.props.assessments[0].sections.length > 0
      ? this.props.assessments[0].sections[0].Key
      : null;

    this.setState({
     sectionID: sectionID,
     sectionName: sectionName,
    });
   }
   this.props.history.push(`/reassessment/Q/${sectionID}/${sectionName}/false`);
  }
 };

 navigateToAssessment = (type) => {
  if (type === "NONE") {
   window.location.href = `/dashboard/${localStorage.getItem("ppid")}`;
  }
  let sectionID =
   this.props.assessments[0]?.sections &&
   this.props.assessments[0]?.sections?.length > 0
    ? this.props.assessments[0]?.sections[0]?.Typeform_ID
    : null;

  let sectionName =
   this.props.assessments[0]?.sections &&
   this.props.assessments[0]?.sections?.length > 0
    ? this.props.assessments[0]?.sections[0]?.Key
    : null;

  this.setState({
   sectionID: sectionID,
   sectionName: sectionName,
  });

  localStorage.setItem("assesment_type", type);
  localStorage.setItem("reAssessmentOption", type);
  const data = JSON.stringify({
   option: type,
  });

  const config = {
   method: "post",
   url: `${API_BASE_URL}/partners/reassess-option`,
   headers: {
    Authorization: localStorage.getItem("authorizationtoken"),
    "Content-Type": "application/json",
   },
   data: data,
  };
  console.log(config);
  axios(config)
   .then(function (response) {
    if (type === "NONE") {
     window.location.href = `/dashboard/${localStorage.getItem("ppid")}`;
    } else {
     window.location = `/assessment2022/Q/${sectionID}/${sectionName}/false`;
    }
   })
   .catch(function (error) {
    console.log(error);
   });
  //this.setAssessmentType(type);
 };

 setAssessmentType = (type) => {
  const data = JSON.stringify({
   option: type,
  });

  const config = {
   method: "post",
   url: `${API_BASE_URL}/partners/reassess-option`,
   headers: {
    Authorization: localStorage.getItem("authorizationtoken"),
    "Content-Type": "application/json",
   },
   data: data,
  };

  axios(config)
   .then(function (response) {
    console.log(JSON.stringify(response.data));
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 /**
  * Once the HomePage video completed, the function will be invoked,
  * it that assessment is not started yet, then user will be prompted with a start assessment modal
  */
 showAssessmentStartModal = (hasStarted) => {
  if (!hasStarted) {
   this.setState({
    modal: !this.state.modal,
   });
  }
 };

 /**
  * Assessment Start modal - Toggle view method
  */
 toggle = () => {
  this.setState({
   modal: !this.state.modal,
  });
 };

 bannerHandler = () => {
  this.setState({
   benefitsmodal: !this.state.benefitsmodal,
  });
 };

 render() {
  const { assessments, t, i18n } = this.props;
  const { userInfo } = this.state;

  let sectionsList = [];
  let completedSections = [];
  let hasAssessmentStarted = false;

  /**
   * Logic to identify whether the Qualifier is already satrted or not,
   * if not, the can see the start button in homepage, else it will be hidden
   */
  if (assessments && assessments.length > 0) {
   this.props.assessments.map((assessment, index) => {
    if (assessment && assessment.sections) {
     assessment.sections.map((section, index) => {
      sectionsList = [...sectionsList, section];
     });
    }
   });

   let completedSections = [];
   if (sectionsList.length > 0) {
    sectionsList.map((section, index) => {
     if (section && section.hasCompleted) {
      completedSections.push(section.Typeform_ID);
     }
    });
   }

   let qualifierAssessment = assessments[0];

   if (qualifierAssessment) {
    if (qualifierAssessment.sections && qualifierAssessment.sections[0]) {
     hasAssessmentStarted = qualifierAssessment.sections[0].hasStarted;
    }
   }
  }

  return (
   <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
    <div className="d-flex hp-layout hp-home">
     <Header isHome={true} />
     <div className="hp-content-wrap">
      {/** Home Banner **/}
      {/*<HomeBanner />*/}
      <div className="hp-welcome-section hp-section">
       <p className="main-title hp-lite">{t("HOME.HOME_TITLE")}</p>
       <p className="assement-survey-title hp-bold">
        {t("HOME.HOME_SUB_TITLE")}
       </p>
       {userInfo && userInfo.PartnerName && (
        <div className="hp-regular welcome-text">
         {t("HOME.HOME_WELCOME", { name: userInfo.PartnerName })}
        </div>
       )}
       <div className="hp-section-brief-v1">{t("HOME.HOME_WELCOME_MSG_2")}</div>
       <div className="hp-section-brief-v1">{t("HOME.HOME_WELCOME_MSG_3")}</div>
       {/* <div className="hp-section-brief-v1 mt-2">
                {t("HOME.HOME_WELCOME_MSG_1")}
              </div> */}
      </div>
      <div className="hp-content-block hp-section">
       <div className="main-title hp-lite">
        {t("HOME.HOME_WHAT_DIGITAL_TITLE1")}
       </div>
       <div className="hp-section-brief-v1">
        {t("HOME.HOME_WHAT_DIGITAL_DESC1")}
       </div>

       <div className="main-title hp-lite hp-section-space">
        {t("HOME.HOME_WHO_TAKE_DIGITAL_TITLE1")}
       </div>
       <div className="hp-section-brief-v1">
        {t("HOME.HOME_WHO_TAKE_DIGITAL_DESC1")}
       </div>
       <div className="main-title hp-lite hp-section-space">
        {t("HOME.HOME_TIME_INVESTMENT_LABEL")}
       </div>
       <div className="timer">
        <img src={timer} />
        <p className="hp-bold">{t("HOME.HOME_TIME_INVESTMENT_VALUE")}</p>
       </div>
      </div>

      <div className="hp-content-block hp-section">
       <div className="main-title hp-lite">
        {t("HOME.HOME_WHAT_SERVICE_TITLE1")}
       </div>
       <div className="hp-section-brief-v1">
        {t("HOME.HOME_WHAT_SERVICE_DESC1")}
       </div>

       <div className="main-title hp-lite hp-section-space">
        {t("HOME.HOME_WHO_TAKE_SERVICE_TITLE1")}
       </div>
       <div className="hp-section-brief-v1">
        {t("HOME.HOME_WHO_TAKE_SERVICE_DESC1")}
       </div>
       <div className="main-title hp-lite hp-section-space">
        {t("HOME.HOME_TIME_INVESTMENT_LABEL")}
       </div>
       <div className="timer">
        <img src={timer} />
        <p className="hp-bold">{t("HOME.HOME_TIME_INVESTMENT_VALUE")}</p>
       </div>
      </div>

      <div className="hp-assessment-steps">
       <div className="hp-assessment-steps-title">
        {t("HOME.HOME_GET_STARTED_TITLE")}
       </div>
       <div className="hp-assessment-step">
        <div className="hp-assessment-img">
         <MDBIcon icon="check-circle" size="2x" />
        </div>
        <div className="hp-assessment-step-text">
         <div className="hp-assessment-step-title">
          {t("HOME.HOME_STEP1_TITLE")}
         </div>
         <div className="hp-assessment-step-desc">
          {t("HOME.HOME_STEP1_DESC1")}
         </div>
        </div>
       </div>
       <div className="hp-assessment-step">
        <div className="hp-assessment-img">
         <MDBIcon icon="check-circle" size="2x" />
        </div>
        <div className="hp-assessment-step-text">
         <div className="hp-assessment-step-title">
          {t("HOME.HOME_STEP2_TITLE")}
         </div>
         <div className="hp-assessment-step-desc">
          {t("HOME.HOME_STEP2_DESC2")}
         </div>
        </div>
       </div>
       <div className="hp-assessment-step">
        <div className="hp-assessment-img">
         <MDBIcon icon="check-circle" size="2x" />
        </div>
        <div className="hp-assessment-step-text">
         <div className="hp-assessment-step-title">
          {t("HOME.HOME_STEP3_TITLE")}
          <span
           className="home-see-benefits-btn"
           onClick={() => {
            this.setState({
             benefitsmodal: !this.state.benefitsmodal,
            });
           }}
          >
           {t("UX.HOME_BANNER_SEE_BENEFITS")} <MDBIcon icon="arrow-right" />
          </span>
         </div>
         <div className="hp-assessment-step-desc">
          {t("HOME.HOME_STEP3_DESC3")}
         </div>
        </div>
       </div>
       <div className="hp-assessment-step">
        <div className="hp-assessment-img">
         <MDBIcon icon="check-circle" size="2x" />
        </div>
        <div className="hp-assessment-step-text">
         <div className="hp-assessment-step-title">
          {t("HOME.HOME_STEP4_TITLE")}
         </div>
         <div className="hp-assessment-step-desc">
          {t("HOME.HOME_STEP4_DESC")}
         </div>
        </div>
       </div>
       {/* <div className="hp-assessment-step">
                <div className="hp-assessment-img">
                  <MDBIcon icon="check-circle" size="2x" />
                </div>
                <div className="hp-assessment-step-text">
                  <div className="hp-assessment-step-title">
                    {t("HOME.HOME_STEP4_TITLE")}
                  </div>
                  <div className="hp-assessment-step-desc">
                    {t("HOME.HOME_STEP4_DESC")}
                  </div>
                </div>
              </div> */}
      </div>
      <div className="hp-assessment-details">
       <div className="hp-assessment-details-title">
        {t("HOME.HOME_WATCH_VIDEO_TITLE1")}
       </div>
       <div className="hp-assessment-details-video">
        {/*<img src={playIcon} className="img-fluid"/>*/}

        <video
         width="320"
         height="240"
         controls
         onEnded={() => this.showAssessmentStartModal(hasAssessmentStarted)}
         className="d-sm-block d-md-none"
        >
         <source src={test_video} type="video/mp4" />
         {t("HOME.HOME_WATCH_VIDEO_ERROR")}
        </video>

        <video
         width="640"
         height="360"
         controls
         onEnded={() => this.showAssessmentStartModal(hasAssessmentStarted)}
         className="d-none d-md-block"
        >
         <source src={test_video} type="video/mp4" />
         {t("HOME.HOME_WATCH_VIDEO_ERROR")}
        </video>
       </div>
       <div className="hp-assessment-details-instructions-title">
        {t("HOME.HOME_VIDEO_TEXT_TITLE")}
       </div>
       <div className="hp-assessment-details-instructions">
        <ReactReadMoreReadLess
         charLimit={355}
         readMoreClassName={"hp-assessment-details-expand-link"}
         readLessClassName={"hp-assessment-details-expand-link"}
         readMoreText={"Expand"}
         readLessText={"See less"}
        >
         {t("HOME.HOME_VIDEO_REASSIGNMENT_PARA1")}
        </ReactReadMoreReadLess>
       </div>
      </div>
      {/* {!hasAssessmentStarted &&
                            <div className="hp-assessment-start hp-section">
                                <div className="hp-section-title-v2">
                                {t('HOME.HOME_TAKE_ASSESSMENT_TITLE')}
                                </div>
                                <div className="hp-section-brief-v2 mt-2">
                                {t('HOME.HOME_TAKE_ASSESSMENT_DESC')}
                                </div>
                                <div className="hp-btn-v1" onClick={this.startAssessment}>
                                {t('HOME.HOME_TAKE_ASSESSMENT_CTA')}
                                </div>
                            </div>
                        } */}
      {/* {hasAssessmentStarted &&
                            <div className="d-flex w-100 justify-content-center">
                                <div className="hp-btn-v1 go-to-dashboard-btn">
                                    <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>
                                        {t('COMMON.COMMON_GO_TO_DASHBOARD')}
                                    </Link>
                                </div>
                            </div>
                        } */}

      <div className="hp-assessment-start hp-section">
       <div className="hp-section-title-v2">
        {t("HOME.HOME_TAKE_ASSESSMENT_TITLE")}
       </div>
       <div className="hp-section-brief-v2 mt-2">
        {t("HOME.HOME_TAKE_REASSESSMENT_DESC1")}
       </div>
       {/* <div className="hp-btn-v1" onClick={this.startAssessment}>
                {t("HOME.HOME_TAKE_ASSESSMENT_CTA")}
              </div> */}
       <MDBContainer className="my-3">
        <MDBRow>
         <MDBCol size="6">
          <div
           className="reassesmentBtn"
           onClick={() => this.navigateToAssessment("DIGITAL_ONLY")}
          >
           <p className="head">{t("HOME.HOME_TAKE_ASSESSMENT_CTA1")}</p>
           <p className="desc">{t("HOME.HOME_TAKE_ASSESSMENT_CTA1_SUB")}</p>
          </div>
         </MDBCol>
         <MDBCol size="6">
          <div
           className="reassesmentBtn"
           onClick={() => this.navigateToAssessment("SERVICE_ONLY")}
          >
           <p className="head">{t("HOME.HOME_TAKE_ASSESSMENT_CTA2")}</p>
           <p className="desc">{t("HOME.HOME_TAKE_ASSESSMENT_CTA2_SUB")}</p>
          </div>
         </MDBCol>
         <MDBCol size="6">
          <div
           className="reassesmentBtn mt-3"
           onClick={() => this.navigateToAssessment("BOTH")}
          >
           <p className="head">{t("HOME.HOME_TAKE_ASSESSMENT_CTA3")}</p>
           <p className="desc">{t("HOME.HOME_TAKE_ASSESSMENT_CTA3_SUB")}</p>
          </div>
         </MDBCol>
         <MDBCol size="6">
          <div
           className="reassesmentBtn mt-3"
           onClick={() => this.navigateToAssessment("NONE")}
          >
           <p className="head">{t("HOME.HOME_TAKE_ASSESSMENT_CTA4")}</p>
           <p className="desc">{t("HOME.HOME_TAKE_ASSESSMENT_CTA4_SUB")}</p>
          </div>
         </MDBCol>
        </MDBRow>
       </MDBContainer>
      </div>
     </div>
     <Footer />
    </div>
    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
     <MDBModalBody>
      <div className="modal-start-assessment">
       <div className="modal-assessment-title">
        {t("HOME.HOME_ASSESSMENT_MODAL_TITLE")}
       </div>
       <div className="modal-assessment-body">
        {t("HOME.HOME_ASSESSMENT_MODAL_DESC")}
       </div>
       <div className="modal-assessment-cta1">
        <div className="hp-cta-v1" onClick={this.startAssessment}>
         {t("HOME.HOME_ASSESSMENT_MODAL_CTA_START")}
        </div>
       </div>
       <div className="modal-assessment-cta2" onClick={this.toggle}>
        {t("HOME.HOME_ASSESSMENT_MODAL_CTA_NOT_NOW")}
       </div>
      </div>
     </MDBModalBody>
    </MDBModal>
    <MDBModal isOpen={this.state.benefitsmodal} className="banner-modal">
     <MDBModalBody>
      <HomeBanner bannerHandler={this.bannerHandler} />
     </MDBModalBody>
    </MDBModal>
    <IEModal />
   </div>
  );
 }
}

export default withTranslation()(
 connect(mapStateToProps, mapDispatchToProps)(HomeReAssessment)
);
