import React from 'react';
import './timer.scss';
import timer from '../../../assets/icons/timer.svg';

function Timer() {
    return (
        <div className='timer-container'>
            <img src={timer} className='stopwatch' />
            <span className='time-left'>15 Minutes Left</span>
        </div>
    )
}

export default Timer;