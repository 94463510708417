import React from 'react';
import './completeplanner.scss';
import Header from '../../../components/BusinessPlanner/WizardHeader/Header';
import { MDBBtn, MDBInput, MDBIcon } from 'mdbreact'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {sendPageViewGTM} from '../../../utils/util';

//redux
import {submitCompletePlanner} from '../../../actions/bpAction';
import {getCompletePlanner, getBPMasterData, getBPReachData, getReachRecommendations, getReachTotal, getPageLoadStatus} from '../../../reducers/bpReducer';

const mapStateToProps = (state) => {
    return {
        bpMasterData: getBPMasterData(state),
        bpCompletePlanner: getCompletePlanner(state),
        bpReach: getBPReachData(state),
        bpReachRec: getReachRecommendations(state),
        bpReachTotal: getReachTotal(state),
        bpLoading: getPageLoadStatus(state)

    }
  }
  
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        submitCompletePlanner: (data, ppid) => dispatch(submitCompletePlanner(data, ppid))
    }
}

class CompletePlanner extends React.Component {

    state = {

    };

    getBudgetType = (type) => {
        let qQuery = ['1st quarter', '2nd quarter', '3rd quarter', '4th quarter'];
        let qmapping = ['Q1', 'Q2', 'Q3', 'Q4'];
        let budgetIndex = qQuery.indexOf(type);
        if(budgetIndex > -1) {
            return qmapping[budgetIndex];
        }
        return type;
    }

    componentDidMount =()=> {
        let selectedTechnologies = [];
        if(this.props.bpMasterData) {
            this.props.bpMasterData.map((data, i) => {
                if(data.HasPartnerSelected) {
                    selectedTechnologies.push({
                        "Key": data.Key,
                        "BudgetTypeSelected": this.getBudgetType(data.Allocation),
                        "Budget": data.Recommended
                    })
                }
            })
            // Reach
            if(this.props.bpReachTotal) {
                selectedTechnologies.push({
                    "Key": 'DEMAND_GENERATION_BUDGET',
                    "BudgetTypeSelected": 'Monthly',
                    "Budget": this.props.bpReachTotal.Budget
                })
            }
        }

        let ppid = localStorage.getItem('ppid');
        let data = {};
        data.SelectedTechnologies = selectedTechnologies;
        this.props.submitCompletePlanner(data, ppid);
        window.scrollTo(0, 0);

        //GTM PageLoad

        try {
            let pagePath = window.location ? window.location.pathname : "";
            if (window && window.dataLayer) {
                sendPageViewGTM("Business Planner: Complete Planner", pagePath);
            }
        } catch (e) {}
    }

    editReach = () => {
        this.props.history.push('/bp/reach-planner/true');
    }

    editSection = (sectionName) => {
        this.props.history.push('/bp/budget-planner/'+sectionName);
    }

    handleProceed = () => {
        this.props.history.push('/bp/summary');
    }
    render() {

        const {bpCompletePlanner, bpLoading} = this.props;
        return (
            <div className="complete-planner-container">
                <div className="hp-nav w-100">
                    <Header />
                </div>
                {bpLoading &&
                    <div className="d-flex justify-content-center align-items-center loading-wrap-full">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                <div className="complete-planner-data">
                    <div className="complete-planner-title">
                        The complete planner for <span className="bold-title">Reach, Engage, Transact and Retain</span>
                    </div>
                    <div className="complete-planner-table-wrap">
                        <div className="cp-table">
                            <div className="cp-t-header">
                                <div className="one-row-header c1">Technologies</div>
                                <div className="one-row-header c2">One Time pay</div>
                                <div className="one-row-header c3">Subscription</div>
                                <div className="two-row-header cc">
                                    <div className="row-1">1st Quarter</div>
                                    <div className="row-2">
                                        <div className="row-rt">Month 1</div>
                                        <div className="row-rt">Month 2</div>
                                        <div>Month 3</div>
                                    </div>
                                </div>
                                <div className="two-row-header cc">
                                    <div className="row-1">2nd Quarter</div>
                                    <div className="row-2">
                                        <div className="row-rt">Month 4</div>
                                        <div className="row-rt">Month 5</div>
                                        <div>Month 6</div>
                                    </div>
                                </div>
                                <div className="two-row-header cc">
                                    <div className="row-1">3rd Quarter</div>
                                    <div className="row-2">
                                        <div className="row-rt">Month 7</div>
                                        <div className="row-rt">Month 8</div>
                                        <div>Month 9</div>
                                    </div>
                                </div>
                                <div className="two-row-header cc">
                                    <div className="row-1">4th Quarter</div>
                                    <div className="row-2">
                                        <div className="row-rt">Month 10</div>
                                        <div className="row-rt">Month 11</div>
                                        <div>Month 12</div>
                                    </div>
                                </div>
                                <div className="two-row-header c-total">
                                    <div className="row-1">Total</div>
                                    <div className="row-2">
                                        Annual
                                    </div>
                                </div>
                            </div>
                            <div className="cp-t-body">
                                {/* Reach */}
                                <div className="cp-t-section-header">
                                    <div className="t-section-title">Reach</div>
                                    <div className="t-section-actions">
                                        <span className="t-edit-label">Add/Remove Reach channels</span>
                                        <span className="t-edit-btn" onClick={this.editReach}>Edit</span>
                                    </div>
                                </div>
                                {bpCompletePlanner && bpCompletePlanner.technologies && bpCompletePlanner.technologies.map((data, i)=> {
                                    if(data && data.Section && data.Section === 'Reach') 
                                        return <div className="cp-t-row row-selected">
                                            <div className="t-r-technology">
                                                <div className="selected-icon">
                                                    <MDBIcon icon="check-circle" />
                                                </div>
                                                {data.Name}
                                            </div>
                                            <div className="t-r-pay">NA</div>
                                            <div className="t-r-subscription">Monthly</div>
                                            {data.MonthwiseBudget && data.MonthwiseBudget.map((budget, key)=>(
                                                <div className="t-r-cell" key={budget.MonthName}>
                                                    {bpCompletePlanner.CurrencySymbol}{(budget.Budget).toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}
                                                </div>
                                            ))}
                                            <div className="t-r-total">{bpCompletePlanner.CurrencySymbol}{(data.MonthwiseBudgetTotal).toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}</div>
                                        </div>
                                    })
                                }

                                {/* Engage */}
                                <div className="cp-t-section-header">
                                    <div className="t-section-title">Engage</div>
                                    <div className="t-section-actions">
                                        <span className="t-edit-label">Add/Remove Engage channels</span>
                                        <span className="t-edit-btn" onClick={this.editSection.bind(this, 'Engage')}>Edit</span>
                                    </div>
                                </div>
                                {bpCompletePlanner && bpCompletePlanner.technologies && bpCompletePlanner.technologies.map((data, i)=> {
                                    if(data && data.Section && data.Section === 'Engage') 
                                    return <div className={data.HasPartnerSelected ? 'cp-t-row row-selected': 'cp-t-row'}>
                                        <div className="t-r-technology">
                                            {data.HasPartnerSelected &&
                                                <div className="selected-icon">
                                                    <MDBIcon icon="check-circle" />
                                                </div>
                                            }
                                            {data.Name}
                                        </div>
                                        <div className="t-r-pay">
                                            {data.BudgetType === 'OneOff' ? data.BudgetTypeSelected : 'NA'}
                                        </div>
                                        <div className="t-r-subscription">
                                            {data.BudgetType === 'Regular' ? data.BudgetTypeSelected : 'NA'}
                                        </div>
                                        {data.MonthwiseBudget && data.MonthwiseBudget.map((budget, key)=>(
                                            <div className="t-r-cell" key={budget.MonthName}>
                                                {bpCompletePlanner.CurrencySymbol}{(budget.Budget).toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}
                                            </div>
                                        ))}
                                        <div className="t-r-total">{bpCompletePlanner.CurrencySymbol}{data.MonthwiseBudgetTotal.toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}</div>
                                    </div>
                                })}

                                {/* Transact */}
                                <div className="cp-t-section-header">
                                    <div className="t-section-title">Transact</div>
                                    <div className="t-section-actions">
                                        <span className="t-edit-label">Add/Remove Transact channels</span>
                                        <span className="t-edit-btn" onClick={this.editSection.bind(this, 'Transact')}>Edit</span>
                                    </div>
                                </div>
                                
                                {bpCompletePlanner && bpCompletePlanner.technologies && bpCompletePlanner.technologies.map((data, i)=> {
                                    if(data && data.Section && data.Section === 'Transact') 
                                    return <div className={data.HasPartnerSelected ? 'cp-t-row row-selected': 'cp-t-row'}>
                                        <div className="t-r-technology">
                                            {data.HasPartnerSelected &&
                                                <div className="selected-icon">
                                                    <MDBIcon icon="check-circle" />
                                                </div>
                                            }
                                            {data.Name}
                                        </div>
                                        <div className="t-r-pay">
                                            {data.BudgetType === 'OneOff' ? data.BudgetTypeSelected : 'NA'}
                                        </div>
                                        <div className="t-r-subscription">
                                            {data.BudgetType === 'Regular' ? data.BudgetTypeSelected : 'NA'}
                                        </div>
                                        {data.MonthwiseBudget && data.MonthwiseBudget.map((budget, key)=>(
                                            <div className="t-r-cell" key={budget.MonthName}>
                                                {bpCompletePlanner.CurrencySymbol}{(budget.Budget).toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}
                                            </div>
                                        ))}
                                        <div className="t-r-total">{bpCompletePlanner.CurrencySymbol}{data.MonthwiseBudgetTotal.toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}</div>
                                    </div>
                                })}

                                {/* Retain */}
                                <div className="cp-t-section-header">
                                    <div className="t-section-title">Retain</div>
                                    <div className="t-section-actions">
                                        <span className="t-edit-label">Add/Remove Retain channels</span>
                                        <span className="t-edit-btn" onClick={this.editSection.bind(this, 'Retain')}>Edit</span>
                                    </div>
                                </div>
                                
                                {bpCompletePlanner && bpCompletePlanner.technologies && bpCompletePlanner.technologies.map((data, i)=> {
                                    if(data && data.Section && data.Section === 'Retain') 
                                    return <div className={data.HasPartnerSelected ? 'cp-t-row row-selected': 'cp-t-row'}>
                                        <div className="t-r-technology">
                                            {data.HasPartnerSelected &&
                                                <div className="selected-icon">
                                                    <MDBIcon icon="check-circle" />
                                                </div>
                                            }
                                            {data.Name}
                                        </div>
                                        <div className="t-r-pay">
                                            {data.BudgetType === 'OneOff' ? data.BudgetTypeSelected : 'NA'}
                                        </div>
                                        <div className="t-r-subscription">
                                            {data.BudgetType === 'Regular' ? data.BudgetTypeSelected : 'NA'}
                                        </div>
                                        {data.MonthwiseBudget && data.MonthwiseBudget.map((budget, key)=>(
                                            <div className="t-r-cell" key={budget.MonthName}>
                                                {bpCompletePlanner.CurrencySymbol}{(budget.Budget).toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}
                                            </div>
                                        ))}
                                        <div className="t-r-total">{bpCompletePlanner.CurrencySymbol}{data.MonthwiseBudgetTotal.toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}</div>
                                    </div>
                                })}

                            </div>
                            <div className="cp-t-footer">
                                <div className="footer-row-1">
                                    <div className='footer-total-label'>
                                        Total
                                    </div>
                                    {bpCompletePlanner && bpCompletePlanner.totalMonthly && bpCompletePlanner.totalMonthly.map((data, i) => (
                                        <div className="footer-total-val" key={data.MonthName}>{bpCompletePlanner.CurrencySymbol}{(data.BudgetMonthly).toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}</div>
                                    ))}
                                    {bpCompletePlanner && bpCompletePlanner.totalAnnualy && 
                                        <div className="footer-grand-total-val">{bpCompletePlanner.CurrencySymbol}{(bpCompletePlanner.totalAnnualy).toLocaleString("en-US")}{bpCompletePlanner.CurrencySuffix}</div>
                                    }
                                </div>
                                <div className="footer-row-2">
                                    <div className="footer-empty-cell"></div>
                                    <div className="footer-quarter-label">1st Quarter</div>
                                    <div className="footer-quarter-label">2nd Quarter</div>
                                    <div className="footer-quarter-label">3rd Quarter</div>
                                    <div className="footer-quarter-label">4th Quarter</div>
                                    <div className="footer-annual-label">Annual</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="complete-planner-summary">
                        <div className="show-summary-title"> Do you want to generate summary, and download full planner?</div>
                        <div className="complete-planner-cta" onClick={this.handleProceed}>Yes</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CompletePlanner));