import React from 'react';
import './progressbar.scss';

import { API_BASE_URL } from "../../config/api-config";
import axios from "axios";
import active_icon from '../../assets/icons/UX/Progress/Group 594.svg';
import disabled_icon from '../../assets/icons/UX/Progress/Ellipse 80.svg';

export default class ProgressBar extends React.Component {

    state = {
        result: {}
    }

componentDidMount = () => {
    this.CallProgressAPI();
    this.CallProgressAPI1();
}
CallProgressAPI1 = () => {
    let ppid = localStorage.getItem('ppid');
    axios
        .get(`${API_BASE_URL}/partners/progress-status?year=2022&ppid=${ppid}`)
        .then((res) => {
        
            let q = res.data[1].status;
            let d = res.data[0].status;
            if(d === true){
              localStorage.setItem("started2022",false);
              localStorage.setItem("hasSubmited2022",true)
              //ownProps.history.push(`/reassessment-report/${localStorage.getItem('ppid')}`);
            }else if(q === true ){
              localStorage.setItem("started2022",true)
              //ownProps.history.push(`/dashboard2022/${localStorage.getItem('ppid')}`);
            }else{
                localStorage.setItem("started2022",true);              
              localStorage.setItem("hasSubmited2022",false)
            }
           
        });
    };
CallProgressAPI = () => {
    let ppid = localStorage.getItem('ppid');
    axios
        .get(`${API_BASE_URL}/partners/progress-status?ppid=${ppid}`)
        .then((res) => {
            let d = {
                data: res.data
            };
            d.data.sort((a, b) => (a.order > b.order) ? 1 : -1);
            this.setState({result: d})
        });
    };
getItemClass = (item, i) => {
    return 'hp-progress-points hp-progress-'+i;
    /*if(item.name === 'started') {
        return 'hp-progress-qualifier hp-progress-points';
    } else if(item.name === 'submitted') {
        return 'hp-progress-assessment hp-progress-points';
    } else if(item.name === 'insights') {
        return 'hp-progress-insights hp-progress-points';
    } else if(item.name === 'toolkit') {
        return 'hp-progress-toolkits hp-progress-points';
    } else if(item.name === 'edc') {
        return 'hp-progress-edc hp-progress-points';
    }*/
}
getItemTitle = (item) => {
    if(item.name === 'started') {
        return 'Qualifier';
    } else if(item.name === 'submitted') {
        return 'Assessment';
    } else if(item.name === 'insights') {
        return 'Insights';
    } else if(item.name === 'toolkit') {
        return 'Toolkits';
    } else if(item.name === 'edc') {
        return 'EDC';
    }
}

getActiveStatus = (item) => {
    return item.status ? 'active' : 'disabled';
}

getFilledPercentage = (rs) => {
    if(rs && rs.data && rs.data.length > 0) {
        let filledClass = '';
        rs.data.map((item,i)=>{
            if(item.status) {
                filledClass = 'hp-progress-bar-blue hp-progress-filled-'+i;
            }
        })
        return filledClass;
    }
}

render() {
    const {result} = this.state;
    return (
        <div className="hp-progress-bar">
           <div className="hp-progress-start">
               Start
           </div>
           <div className="hp-progress-bar-wrap">
               <div className="hp-progress-bar-gray">
                   {result && result.data &&
                       <div className={this.getFilledPercentage(result)}>
                       </div>
                   }
                   <div className="hp-progress-milestones">
                       
                       {result && result.data && result.data.map((item,i)=>(
                            <div className={this.getItemClass(item, i)}>
                                 {this.getItemTitle(item)}
                                <img className={this.getActiveStatus(item)} src={this.getActiveStatus(item) === 'active' ? active_icon: disabled_icon} alt="Qualifier completed" />
                            </div>  
                       ))}
                       
                   </div>
               </div>
               
           </div>
        </div>
    )
    } 
}