import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
/**
 * Component import
 */
import Slider from '../../../components/Slider/Slider';
import GlobalIcon from "../../../assets/icons/global.svg";
import { MDBBtn } from 'mdbreact';
import Header from '../../../components/BusinessPlanner/WizardHeader/Header';
import TimeLine from '../../../components/BusinessPlanner/TimeLine';
import {sendPageViewGTM} from '../../../utils/util';

/**
 * Style import
 */
import './businessplannerreach.scss';
import { Link } from 'react-router-dom';

//redux
import {setReachBudget, setReachVisitor, setReachTransaction, setReachASV, getReachMasterData} from '../../../actions/bpAction';
import {getBPReachData} from '../../../reducers/bpReducer';

const mapStateToProps = (state) => {
    return {
        bpReach: getBPReachData(state)
    }
  }
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setReachBudget: (data) => dispatch(setReachBudget(data)),
        setReachVisitor: (data) => dispatch(setReachVisitor(data)),
        setReachTransaction: (data) => dispatch(setReachTransaction(data)),
        setReachASV: (data) => dispatch(setReachASV(data)),
        getReachMasterData: (ppid) => dispatch(getReachMasterData(ppid))
    }
  }

  
class BugdetPlannerReach extends React.Component {

    state = {
        page: 1,
        reachBudget: 0,
        reachVisitor: 0,
        reachTransactions: 0,
        reachASV:0,
        sectionList: ['Engage', 'Transact', 'Retain', 'Reach'],
    }

    componentDidMount = () => {
        let ppid = localStorage.getItem('ppid');
        if(ppid) {
            this.props.getReachMasterData(ppid);
        }
        //GTM PageLoad

        try {
            let pagePath = window.location ? window.location.pathname : "";
            if (window && window.dataLayer) {
            sendPageViewGTM("Business Planner: Budget Planner Reach", pagePath);
            }
        } catch (e) {}
    }

    nextPage = (type, defaultVal) => {
        const {reachBudget, reachVisitor, reachTransactions, reachASV} = this.state;
        if(type === 'budget') {
            this.props.setReachBudget(reachBudget > 0 ? reachBudget: defaultVal);
        } else if(type === 'visitor') {
            this.props.setReachVisitor(parseInt(reachVisitor > 0 ? reachVisitor: defaultVal));
        } else if(type === 'transactions') {
            this.props.setReachTransaction(parseInt(reachTransactions > 0 ? reachTransactions : defaultVal));
        } else if(type === 'asv') {
            this.props.setReachASV(parseInt(reachASV > 0 ? reachASV: defaultVal));
        }

        if(this.state.page === 7) {
            this.props.history.push('/bp/reach-planner');
        }

        this.setState({
            page: this.state.page +1
        })
    }

    handleValueChange = (type, data) => {
        if(type === 'budget') {
            this.setState({
                reachBudget: data
            });
        } else if(type === 'visitor') {
            this.setState({
                reachVisitor: data
            });
        } else if(type === 'transactions') {
            this.setState({
                reachTransactions: data
            });
        } else if(type === 'asv') {
            this.setState({
                reachASV: data
            });
        } 
        
    }

    setDefult = (type, data) => {
        if(type === 'visitor') {
            this.props.setReachVisitor(parseInt(data));
        } else if(type === 'transactions') {
            this.props.setReachTransaction(parseInt(data));
        } else if(type === 'asv') {
            this.props.setReachASV(parseInt(data));
        }

        this.setState({
            page: this.state.page +1
        })
    }
    render() {
        const {page, sectionList} =  this.state;
        const {bpReach} = this.props;
        return(
            <div className="bp-progress-reach">
                <div className="hp-nav w-100">
                    <Header />
                </div>
                <TimeLine sectionList={sectionList} currentSection='Reach'/>
                {bpReach && 
                    <div className="container d-flex justify-content-center">
                        {page == 1 &&
                            <div className="bp-progress-reach-define-budget">
                                <div className="bp-progress-reach-define-budget-lets-start">
                                    <img src={GlobalIcon} alt="" />
                                    <span>Let’s start with <span>Reach</span></span>
                                </div>
                                <div className="bp-progress-reach-define-budget-content">
                                    <p className="bp-progress-reach-define-budget-content-title">
                                        How much do you want to budget for generating​ Demand?
                                    </p>
                                    <p className="bp-progress-reach-define-budget-content-info">
                                        My monthly budget is,
                                    </p>
                                    <Slider min={bpReach.budget_min} max={bpReach.budget_max} initialValue={bpReach.budget_min} displayValue={true} label="" handleValueChange={this.handleValueChange.bind(this,'budget')} valuePrefix={bpReach.CurrencySymbol} valueSuffix={bpReach.CurrencySuffix} roundValue={true}/>
                                    <MDBBtn className="bp-progress-reach-define-budget-next-btn" color="primary" onClick={this.nextPage.bind(this, 'budget', bpReach.budget_min)}>Next</MDBBtn>
                                </div>
                            </div>
                        }
                        {page == 2 &&
                            <div className="bp-progress-reach-website-perform">
                                <div className="bp-progress-reach-website-perform-content">
                                    <p className="bp-progress-reach-website-perform-content-title">
                                        LET’S UNDERSTAND HOW YOUR WEBSITE PERFORMS
                                    </p>
                                    <p className="bp-progress-reach-website-perform-content-description">
                                        On an average, approximately how many people would visit your website on a monthly basis?
                                    </p>
                                    <p className="bp-progress-reach-website-perform-content-info">
                                        Approximately,
                                    </p>
                                    <Slider min={bpReach.visits_min} max={bpReach.visits_max} step={500} initialValue={bpReach.visits_estimated} displayValue={true} handleValueChange={this.handleValueChange.bind(this,'visitor')} label="people would visit every month" valuePrefix="" valueSuffix="" roundValue={true}/>
                                    <div className="bp-progress-reach-website-perform-content-btns">
                                        <MDBBtn className="bp-progress-reach-website-perform-next-btn" color="primary" onClick={this.nextPage.bind(this, 'visitor', bpReach.visits_estimated)}>Next</MDBBtn>
                                        <Link className="not-sure-link" onClick={this.setDefult.bind(this, 'visitor', bpReach.visits_estimated)}>Not Sure</Link>
                                    </div>
                                </div>
                                <p className="bp-progress-reach-website-perform-budget-suggestion">
                                    * If you are not sure, we will give you an estimated budget
                                </p>
                            </div>
                        }
                        {page == 3 &&
                            <div className="bp-progress-reach-monthlyy-sales">
                                <div className="bp-progress-reach-website-perform-content">
                                    <p className="bp-progress-reach-website-perform-content-description">
                                        On an average, how many monthly sales transactions are generated by your website?
                                    </p>
                                    <p className="bp-progress-reach-website-perform-content-info">
                                        On our website on average we get around,
                                    </p>
                                    <Slider min={bpReach.transactions_min} max={bpReach.transactions_max} step={10} initialValue={bpReach.transactions_estimated} displayValue={true} handleValueChange={this.handleValueChange.bind(this,'transactions')} label="transactions each month" valuePrefix="" valueSuffix="" roundValue={true}/>
                                    <div className="bp-progress-reach-website-perform-content-btns">
                                        <MDBBtn className="bp-progress-reach-website-perform-next-btn" color="primary" onClick={this.nextPage.bind(this, 'transactions', bpReach.transactions_estimated)}>Next</MDBBtn>
                                        <Link className="not-sure-link" onClick={this.setDefult.bind(this, 'transactions', bpReach.transactions_estimated)}>Not Sure</Link>
                                    </div>
                                </div>
                                <p className="bp-progress-reach-website-perform-budget-suggestion">
                                    * If you are not sure, we will give you an estimated budget
                                </p>
                            </div>
                        }
                        {page == 4 &&
                            <div className="bp-progress-reach-transaction-value">
                                <div className="bp-progress-reach-website-perform">
                                    <div className="bp-progress-reach-website-perform-content">
                                        <p className="bp-progress-reach-website-perform-content-title">
                                            AND LASTLY,
                                        </p>
                                        <p className="bp-progress-reach-website-perform-content-description">
                                            What is the approximate value of each transaction? ( ASV )
                                        </p>
                                        <p className="bp-progress-reach-website-perform-content-info">
                                            On our website,
                                        </p>
                                        <Slider min={bpReach.asv_min} max={bpReach.asv_max} initialValue={bpReach.asv_estimated} displayValue={true} handleValueChange={this.handleValueChange.bind(this,'asv')} label="is on an average transaction value" valuePrefix={bpReach.CurrencySymbol} valueSuffix={bpReach.CurrencySuffix} roundValue={true}/>
                                        <div className="bp-progress-reach-website-perform-content-btns">
                                            <MDBBtn className="bp-progress-reach-website-perform-next-btn" color="primary" onClick={this.nextPage.bind(this, 'asv', bpReach.asv_estimated)}>Next</MDBBtn>
                                            <Link className="not-sure-link" onClick={this.setDefult.bind(this, 'asv', bpReach.asv_estimated)}>Not Sure</Link>
                                        </div>
                                    </div>
                                    <p className="bp-progress-reach-website-perform-budget-suggestion">
                                        * If you are not sure, we will give you an estimated budget
                                    </p>
                                </div>
                            </div>
                        }
                        {page == 5 &&
                            <div className="bp-progress-reach-cpc">
                                <div className="bp-progress-reach-website-perform-content">
                                    <p className="bp-progress-reach-website-perform-content-description">
                                        We have set CPC ( Cost per click ) for you, considering multiple factors.
                                        <span>The CPC has been set to {bpReach.CurrencySymbol}{bpReach.cpc}{bpReach.CurrencySuffix}</span>
                                        <br /><br />
                                        You can change/ edit CPC later.
                                    </p>
                                    <div className="bp-progress-reach-website-perform-content-btns">
                                        <MDBBtn className="bp-progress-reach-website-perform-next-btn" color="primary" onClick={this.nextPage}>Understood, next</MDBBtn>
                                    </div>
                                </div>
                            </div>
                        }
                        {page == 6 &&
                            <div className="bp-progress-reach-summary">
                                <div className="bp-progress-reach-summary-content">
                                    <p className="bp-progress-reach-summary-content-title">
                                        IN SUMMARY
                                    </p>
                                    <p className="bp-progress-reach-summary-content-description">
                                        Your digital marketing <span>budget</span> for each month is <span>{bpReach.CurrencySymbol}{(bpReach.budget).toLocaleString("en-US")}{bpReach.CurrencySuffix}</span>.
                                    </p>
                                    <p className="bp-progress-reach-summary-content-description">
                                        Your website currently gets around <span>{bpReach.visitor} visitors</span> each month.
                                    </p>
                                    <p className="bp-progress-reach-summary-content-description">
                                        Generates approximately <span>{bpReach.transactions} sales</span>, at an average value per <span>transaction of {bpReach.CurrencySymbol}{bpReach.asv}{bpReach.CurrencySuffix}</span>.
                                    </p>
                                    <p className="bp-progress-reach-summary-content-title">
                                        SO, OUR ASSUMPTIONS
                                    </p>
                                    <p className="bp-progress-reach-summary-content-description">
                                        Your website generates revenue of <span>{bpReach.CurrencySymbol}{bpReach.transactions*bpReach.asv}{bpReach.CurrencySuffix}/month</span>
                                    </p>
                                    <p className="bp-progress-reach-summary-content-info">
                                        (revenue= total sales x average transaction value)
                                    </p>
                                    <p className="bp-progress-reach-summary-content-description">
                                        And has a visitor-sale <span>conversion-rate of {((bpReach.transactions/bpReach.visitor)*100).toFixed(2)}%</span>
                                    </p>
                                    <p className="bp-progress-reach-summary-content-info">
                                        (conversion-rate = sales ÷ monthly visitors)
                                    </p>
                                    <div className="bp-progress-reach-summary-content-btns">
                                        <MDBBtn className="bp-progress-reach-summary-next-btn" color="primary" onClick={this.nextPage}>Okay, understood</MDBBtn>
                                    </div>
                                </div>
                            </div>
                        }
                        {page == 7 &&
                            <div className="bp-progress-reach-final">
                                <div className="bp-progress-reach-website-perform-content">
                                    <p className="bp-progress-reach-website-perform-content-description">
                                        In the next step you shall see an in-depth planner for Reach. You can define and edit values to fit your requirement.
                                    </p>
                                    <div className="bp-progress-reach-website-perform-content-btns">
                                        <MDBBtn className="bp-progress-reach-website-perform-next-btn" color="primary" onClick={this.nextPage}>Okay, show me</MDBBtn>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BugdetPlannerReach));