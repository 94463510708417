import React from "react";
import { connect } from 'react-redux';
import { createBrowserHistory } from "history";
import "./recommendations.scss";
import { withTranslation } from 'react-i18next';
import { MDBIcon } from 'mdbreact';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { sendPageViewGTM } from "../../utils/util";
import {loadAssessmentReport, loadRecommendationsBySection, loadRecommendationsBySubSection, loadSiteList} from '../../actions/reportAction';
import {getDetailedReport, getSectionRecommendation, getSubSectionRecommendation, getSites} from '../../reducers/reportReducer';
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        reportData: getDetailedReport(state),
        sectionRecommendation: getSectionRecommendation(state),
        subSectionRecommendation: getSubSectionRecommendation(state),
        sites: getSites(state)
    }
}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        loadAssessmentReport: (sectionID) => dispatch(loadAssessmentReport(null, ownProps, sectionID)),
        loadSectionRecommendation: (sectionID) => dispatch(loadRecommendationsBySection(sectionID)),
        loadSubSectionRecommendation: (subsectionID) => dispatch(loadRecommendationsBySubSection(subsectionID)),
        loadSiteList: () => dispatch(loadSiteList())
    }
}

class Recommendations extends React.Component {



    componentDidMount() {

        const customHistory = createBrowserHistory();
        var sectionId  = this.props.match.params.sectionID;
        var assessmentId = this.props.match.params.assessment;
        let ppid = localStorage.getItem('ppid');
        
        if(this.props.match.params.sectionID) {
            window.scrollTo(0, 0);
            let ppid = localStorage.getItem('ppid');
            this.props.loadAssessmentReport(this.props.match.params.sectionID);
        }

        if(this.props.match.params.showSiteReport) {
            this.props.loadSiteList();
        }

        //GTM PageLoad
        try {
            let pagePath = window.location ? window.location.pathname: '';
            sendPageViewGTM('Recommendations Page', pagePath);
        } catch(e){}
    }

    getScore = (currentSection, scoreObj) => {
        let currentScore = 0;
        if(scoreObj) {
            for(let i=0;i<scoreObj.length;i++) {
                if(scoreObj[i].id == currentSection.id) {
                    currentScore = scoreObj[i].score;
                    currentScore = Math.round( parseFloat(currentScore) * 100)
                    break;
                }
            }
        }
        return currentScore ?  currentScore : 0;
    }

    render() {

        const {sectionRecommendation, reportData, sites} = this.props;
        const { t } = this.props;
        let selectedSectionData;
        if(this.props.match.params.subSectionID && sectionRecommendation 
            && sectionRecommendation.sub_sections && sectionRecommendation.sub_sections.length > 0) {
                for(let i=0;i<sectionRecommendation.sub_sections.length;i++) {
                    if(sectionRecommendation.sub_sections[i].id == this.props.match.params.subSectionID) {
                        selectedSectionData = sectionRecommendation.sub_sections[i];
                        break;
                    }
                }  
        }



        let selectedReportData; 
        let selectedScoreData;
        if(reportData && reportData.length > 0 && sectionRecommendation && selectedSectionData) {

            for(let k=0;k<reportData.length;k++) {
                if(sectionRecommendation && sectionRecommendation.assessment_category) {
                    if(sectionRecommendation.assessment_category.id == reportData[k].id) {
                        selectedReportData =  reportData[k];
                        break;
                    }
                }
            }


            if(selectedReportData && selectedReportData.sections && selectedReportData.sections.length > 0) {
                let selectedSection;
                for (let i=0; i<selectedReportData.sections.length;i++) {
                    if(selectedReportData.sections[i].id == this.props.match.params.sectionID) {
                        selectedSection = selectedReportData.sections[i];
                        break;
                    }
                }


                if(selectedSection && selectedSection.sub_sections && selectedSection.sub_sections.length > 0) {
                    for(let j=0;j<selectedSection.sub_sections.length;j++) {
                        if(selectedSection.sub_sections[j].id == this.props.match.params.subSectionID) {
                            selectedScoreData = selectedSection.sub_sections[j].ScorePercentage ? Math.round(selectedSection.sub_sections[j].ScorePercentage * 100): 0;
                            break;
                        }
                    }
                }
            }

        }

        let assessmentId = '';
        if(this.props.match.params.assessment) {
            assessmentId = this.props.match.params.assessment;
        }

        return (
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                <div className="d-flex hp-layout hp-home">
                    <Header />
                    <p className=" ml-3 bread-crumb hp-lite">
                    <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;  <Link to={`/report/${localStorage.getItem('ppid')}/${assessmentId}/${sectionRecommendation.id}`}>Results</Link> &gt; <span>Recommendations</span>
                    </p>
                    {selectedSectionData && 
                        <div className="hp-content-wrap hp-rec-wrap flex-column">
                            <div className="hp-rec-page-title d-flex">
                                    <div className="hp-rec-page-title-left">{selectedSectionData.Name}</div>
                                    <div className="hp-rec-page-title-right">
                                        <Link to={`/report/${localStorage.getItem('ppid')}/${assessmentId}/${sectionRecommendation.id}`}>
                                            <div className="hp-back-link">
                                                <MDBIcon icon="arrow-circle-left" />
                                                <span>{t('RECOMMENDATION.RECOMMENDATION_BACK')}</span>
                                            </div>
                                        </Link>
                                    </div>
                            </div>
                            <div className="hp-rec-score d-flex">
                                <div className="hp-assessment-scores-section-bar-wrap">
                                    <div className="hp-report-score">
                                        <span className="hp-report-score-left"></span>
                                        <span className="hp-report-score-right">Scored {selectedScoreData} out of 100</span>
                                    </div>
                                    <div className="hp-assessment-scores-section-bar">
                                        <div className="hp-assessment-scores-section-bar-fill" style={{width:`${selectedScoreData}%`}}></div>
                                    </div>
                                </div>
                            </div>

                            {/* Wesite Recommendations */}
                            
                            {selectedSectionData.Name === 'Website Experience' && 
                                <div className="hp-rec-sitesList">
                                    <div className="hp-rec-sitesList-title">{t('RECOMMENDATION.RECOMMENDATION_SPEED_TEST_TITLE')}</div>

                                    {sites && sites.length > 0 && sites[0].websites && sites[0].websites.length > 0 && sites[0].websites.map((site, index) => (
                                        <div className="hp-rec-sitesList-item" key={index}>
                                            <div className="hp-rec-site">{site}</div>
                                            <div className="hp-rec-site-see-result">
                                                
                                                <Link to={`/pagespeed-report/?url=${site}`} className="hp-rec-site-icon"><span>{t('RECOMMENDATION.RECOMMENDATION_SEE_RESULTS')}</span> <MDBIcon icon="arrow-circle-right"  size='2x'/></Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            {selectedSectionData && selectedSectionData.RecommmendationTexts.length === 0 &&
                                <div className="hp-rec-default-section d-flex align-items-center mt-4 w-100 justify-content-center">
                                        {t('RECOMMENDATION.RECOMMENDATION_NOT_APPLICABLE')}
                                </div>
                            }

                            {selectedSectionData && selectedSectionData.RecommmendationTexts &&
                                <div className="hp-rec-title d-flex">
                                    {t('RECOMMENDATION.RECOMMENDATION_TITLE')}
                                </div>
                            }
                            
                            {selectedSectionData && selectedSectionData.RecommmendationTexts &&
                                <div className="hp-rec-desc d-flex">
                                    {t('RECOMMENDATION.RECOMMENDATION_MORE_FEEDBACK')}
                                </div>
                            }
                            <div className="hp-rec-content-wrap d-flex flex-column">
                                {selectedSectionData && selectedSectionData.RecommmendationTexts &&
                                    <div className="hp-rect-content-unit d-flex">
                                        <div className="hp-rec-content-title d-flex">
                                            {selectedSectionData.Name}
                                        </div>
                                        { selectedSectionData && selectedSectionData.RecommmendationTexts &&
                                        <div className="hp-rec-content-para1 d-flex" dangerouslySetInnerHTML={{ __html: selectedSectionData.RecommmendationTexts}}>
                                        </div>
                                        }
                                        { selectedSectionData && selectedSectionData.RecommmendationTexts && selectedSectionData.RecommmendationTexts.length === 0 &&
                                        <div className="hp-rec-content-para1 d-flex">
                                            TEXT HERE
                                        </div>
                                        }
                                        
                                    </div>
                                }
                                {/*
                                <div className="hp-rect-content-unit d-flex">
                                    <div className="hp-rec-content-title d-flex">
                                    Tech Stack
                                    </div>
                                    <div className="hp-rec-content-para1 d-flex">
                                        Use brand sentiment or market research tools to help understand your customers and their needs, your products and even your competitors There are many in market research tools that can help you understand your customer behaviour, their sentiment towards your brand or how they feel about your products or services. Tools like SurveyMonkey or Qualtrics can help you survey user groups, where as products like Social Studio or Brand24 can let you listen and learn to what people say in the social media landscape. Its important to check in and understand what people think about you and your brand, so that you can learn about short comings, or agressive competitor behaviour and therfore make changes to improve your service or offerings. For further information around understanding consumer sentiment towards your brand, or doing basic online market research check out these resources:
                                    </div>
                                    <div className="hp-rec-content-para2 d-flex"></div>
                                </div>
                                */}
                                
                            </div>
                        </div>   
                    }
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Recommendations));