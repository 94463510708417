import React from 'react';
import GaugeChart from 'react-gauge-chart';

import { BrowserRouter, Switch, Redirect, Route, Link } from 'react-router-dom';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { MDBIcon } from 'mdbreact';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import axios from "axios";
import {API_BASE_URL} from '../../config/api-config';
import { partnerRegistrationRedirect } from '../../actions/authAction';

import report from "../../assets/images/report/002-analytics.svg";
import gobal_active from "../../assets/images/report/global.svg";
import employee from "../../assets/images/report/employee.svg";
import solidarity from "../../assets/images/report/solidarity.svg";
import transaction from "../../assets/images/report/transaction.svg";

import gobal_disabled from "../../assets/images/report/global_disabled.svg";
import employee_disabled from "../../assets/images/report/employee_disabled.svg";
import solidarity_disabled from "../../assets/images/report/solidarity_disabled.svg";
import transaction_disabled from "../../assets/images/report/transaction_disabled.svg";

import portfolio_active from "../../assets/images/report/portfolio_active.svg";
import lorry_active from "../../assets/images/report/lorry_active.svg";
import revenue_active from "../../assets/images/report/revenue_active.svg";

import portfolio_disabled from "../../assets/images/report/portfolio_disabled.svg";
import lorry_disabled from "../../assets/images/report/lorry_disabled.svg";
import revenue_disabled from "../../assets/images/report/revenue_disabled.svg";

import download_img from "../../assets/icons/download.svg";


import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import DigitalToolKitComponent from '../../components/DigitalToolkit';

import {getUserName} from '../../utils/util';

import "./reportPage.scss";

import { sendPageViewGTM } from "../../utils/util";
import {loadAssessmentReport, loadRecommendationsBySection, loadRecommendationsBySubSection, loadSiteList} from '../../actions/reportAction';
import {getDetailedReport, getSectionRecommendation, getSubSectionRecommendation, getLoadingStatus, getSites, getErrorState} from '../../reducers/reportReducer';

import Recommendation from "../../components/Recommendation/Recommendation";
import Tookit from "../../components/Toolkit/Toolkit";
import RadarComponent from '../../components/RadarComponent';
import GetConnectedEDC from '../../components/GetConnectedEDC';


const mapStateToProps = (state) => {
    return {
        reportData: getDetailedReport(state),
        sectionRecommendation: getSectionRecommendation(state),
        subSectionRecommendation: getSubSectionRecommendation(state),
        loading: getLoadingStatus(state),
        sites: getSites(state),
        Error: getErrorState(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        partnerRegistrationRedirect: (token,pathName) => dispatch(partnerRegistrationRedirect(token, pathName, ownProps)),
        loadAssessmentReport: (ppid) => dispatch(loadAssessmentReport(ppid, ownProps)),
        loadSectionRecommendation: (sectionID) => dispatch(loadRecommendationsBySection(sectionID)),
        loadSubSectionRecommendation: (subsectionID) => dispatch(loadRecommendationsBySubSection(subsectionID)),
        loadSiteList: () => dispatch(loadSiteList())
    }
}

class ReportPage extends React.Component {

    state = {
        selectedReport : undefined,
        selectedSection: undefined,
        selectedSectionScoreDetails: [],
        pdfError: false,
        modal: false,
        imgObj: [ 
            {
                paths: [
                    {
                        Name: 'Reach',
                        activePath: gobal_active,
                        disabledPath: gobal_disabled
                    },{
                        Name: 'Engage',
                        activePath: employee,
                        disabledPath: employee_disabled
                    },{
                        Name: 'Transact',
                        activePath: transaction,
                        disabledPath: transaction_disabled
                    },{
                        Name: 'Retain',
                        activePath: solidarity,
                        disabledPath: solidarity_disabled
                    }
                ]
            }, {
                paths: [    
                    {
                        Name: 'Offering Portfolio',
                        activePath: portfolio_active,
                        disabledPath: portfolio_disabled
                    },{
                        Name: 'Revenue Growth',
                        activePath: revenue_active,
                        disabledPath: revenue_disabled
                    },{
                        Name: 'Services & Solutions Delivery',
                        activePath: lorry_active,
                        disabledPath: lorry_disabled
                    }
                ]
            }
            
        ]
    };

    componentDidMount() {
        if(this.props.match.params.ppid) {
            localStorage.setItem('ppid', this.props.match.params.ppid);
            this.props.loadAssessmentReport(this.props.match.params.ppid);
        }else{
            let {search} = this.props.location;
            let splitAr = search.trim().split('=');
            let qp = splitAr.length > 0 ? splitAr[1]: null;
            if(qp && qp !== null) {
                this.props.partnerRegistrationRedirect(qp,"report");
            }
            axios.get(`${API_BASE_URL}/partners/register?tokenId=`+qp)
            .then(res => {
                console.log(res.data.data[0].ppid);
                localStorage.setItem('ppid', res.data.data[0].ppid);
                this.props.loadAssessmentReport(res.data.data[0].ppid);
            })
    
        }
        
        const selectedReportVal = this.props.match.params.assessmentId ? this.props.match.params.assessmentId : null;
        if(selectedReportVal === null) {
            if(this.props.reportData) {
                this.setState({
                    selectedReport: this.props.reportData[0]
                })
            }
        } else {
            if(this.props.reportData) {
                let selectedReportData = this.props.reportData[0];
                for(let report in this.props.reportData) {
                    if(this.props.reportData[report].id === selectedReportVal) {
                        selectedReportData = this.props.reportData[report];
                        break;
                    }
                }
                this.setState({
                    selectedReport: selectedReportData
                })
            }
        }

        //GTM PageLoad
        try {
            let pagePath = window.location ? window.location.pathname: '';
            sendPageViewGTM('Report Page', pagePath);
        } catch(e){}
    }

    loadSelectedAssessmentReport = (type, data) => {
        let selectedReport = {};
        let selectedSection = {};
        
        if(data) {
            if(type === 'digital') {
                selectedReport = data[0];
            } else if(type === 'service') {
                selectedReport = data[1];
            }
        }
        if(selectedReport && selectedReport.sections && selectedReport.sections.length > 0) {
            selectedSection = selectedReport.sections[0];
        }

        this.props.loadSectionRecommendation(selectedSection.id);
        let selectedSectionScoreDetails = [];
        for(let i=0; i<selectedSection.sub_sections.length; i++) {
            let currentObj = {
                id: selectedSection.sub_sections[i].id,
                score: (selectedSection.sub_sections[i].ScorePercentage ? selectedSection.sub_sections[i].ScorePercentage : 0),
                peer_score: (selectedSection.sub_sections[i].ScorePercentagePeerScore ? selectedSection.sub_sections[i].ScorePercentagePeerScore : 0)
            };
            selectedSectionScoreDetails.push(currentObj);
        }


        this.setState({
            selectedReport: selectedReport,
            selectedSection: selectedSection,
            selectedSectionScoreDetails: selectedSectionScoreDetails
        });
        
    }

    selectReportSection = (section) => {

        this.setState({
            selectedSection: section
        });
        this.props.loadSectionRecommendation(section.id);
        let selectedSectionScoreDetails = [];
        for(let i=0; i<section.sub_sections.length; i++) {
            let currentObj = {
                id: section.sub_sections[i].id,
                score: (section.sub_sections[i].ScorePercentage ? section.sub_sections[i].ScorePercentage : 0),
                peer_score: (section.sub_sections[i].ScorePercentagePeerScore ? section.sub_sections[i].ScorePercentagePeerScore : 0)
            };
            selectedSectionScoreDetails.push(currentObj);
        }

        this.setState({
            selectedSectionScoreDetails: selectedSectionScoreDetails
        });
    }

    downloadPDF = () => {
        this.setState({
            loading: true
        });
        let ppid = localStorage.getItem('ppid');
        axios.get(`${API_BASE_URL}/partners/getdetails?ppid=`+ppid, {
            headers: {
                'Content-Type': 'application/pdf'
            }
        })
        .then(res => {
            this.setState({
                loading: false
            });
            if(res && res.data && res.data.data && res.data.data.length > 0) {
                var rs = res.data.data;
                if(rs[0] && rs[0].ReportURL && rs[0].ReportURL.length > 0) {
                    const url = window.URL = rs[0].ReportURL;
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank';
                    link.download = true;
                    link.setAttribute('download', `${ppid}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                }
                
            }
        }).catch((err) => {
            this.toggle();
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {

        const {reportData, sectionRecommendation, loading, Error} = this.props;
        let {selectedReport, selectedSection, selectedSectionScoreDetails} =  this.state;
        let hasAssessmentSubmitted = localStorage.getItem('hasSubmited') ? localStorage.getItem('hasSubmited') : false;
        const { t } = this.props;

        if((reportData && reportData.length > 0) && selectedReport === undefined) {
            const selectedReportVal = this.props.match.params.assessmentId ? this.props.match.params.assessmentId : null;
            
            if(selectedReportVal !== null) {
                for(let report in reportData) {
                    if(reportData[report].id === selectedReportVal) {
                        selectedReport = reportData[report];
                        break;
                    }
                }
            } else {
                selectedReport = reportData[0];
                
            }
            
        }

        if(selectedSection === undefined && selectedReport && selectedReport.sections && selectedReport.sections.length > 0) {
            const selectedSectionVal = this.props.match.params.secId ? this.props.match.params.secId : null;
            if(selectedSectionVal === null) {
                selectedSection = selectedReport.sections[0];
            } else {
                for(var section in selectedReport.sections) {
                    if(selectedReport.sections[section].id === selectedSectionVal) {
                        selectedSection = selectedReport.sections[section];
                        break;
                    }
                }
            }
            
            selectedSectionScoreDetails = [];
            for(let i=0; i<selectedSection.sub_sections.length; i++) {
                let currentObj = {
                    id: selectedSection.sub_sections[i].id,
                    score: (selectedSection.sub_sections[i].ScorePercentage ? selectedSection.sub_sections[i].ScorePercentage : 0),
                    peer_score: (selectedSection.sub_sections[i].ScorePercentagePeerScore ? selectedSection.sub_sections[i].ScorePercentagePeerScore : 0)
                };
                selectedSectionScoreDetails.push(currentObj);
            }
        }

        const getImgPath = (section) => {
            let selectedImg;
            let imgData;
            if(selectedReport && selectedReport.Key) {
                imgData = selectedReport.Key.indexOf('Digital') > -1 ? this.state.imgObj[0].paths : this.state.imgObj[1].paths;
            }

            for(let i=0;i<imgData.length;i++) {
                if(imgData[i].Name === section.Name) {
                    if(selectedSection && selectedSection.Name === section.Name) {
                        selectedImg = imgData[i].activePath;
                    } else {
                        selectedImg = imgData[i].disabledPath;
                    }
                    break;
                }
            }
            return selectedImg;
        }

        const chartDesktopStyle = {
            width: '200px'
        };

        const chartMobStyle = {
            width: '147px'
        }

        let assessmentSectionScore = 0;
        let partnerPosition = '';
        if(selectedReport && selectedReport.scorePercentage) {
            assessmentSectionScore = Math.round(selectedReport.scorePercentage);
        }

        if(assessmentSectionScore) {
            if(assessmentSectionScore <= 25) {
                partnerPosition = 'Lagging';
            } else if(assessmentSectionScore > 25 && assessmentSectionScore <= 50) {
                partnerPosition = 'Intermediate';
            } else if(assessmentSectionScore > 50 && assessmentSectionScore <= 75) {
                partnerPosition = 'Advanced';
            } else if(assessmentSectionScore > 75 && assessmentSectionScore <=100) {
                partnerPosition = 'Leader';
            }
        }
        return(
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                <div className="d-flex hp-layout hp-home">
                    <Header />
                    <p className=" ml-3 bread-crumb hp-lite">
                    <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;<span>Report</span>
                    </p>
                    {Error ?
                        <div className="hp-report-page hp-content-wrap">
                            <div className="hp-report-error">
                                <div className="hp-error-title">{t('REPORT.REPORT_ASSESSMENT_NOT_COMPLETED')}</div>
                                <div className="hp-error-desc">
                                {t('REPORT.REPORT_ASSESSMENT_NOT_COMPLETED_DESC')}
                                </div>
                            </div>
                        </div>
                    :
                        <div className="hp-report-page hp-content-wrap">
                            {selectedReport && selectedSection && 
                                <div className="d-flex hp-report-top">
                                    <div className="d-flex hp-report-top-left-panel">
                                        <div className="d-flex">
                                            <img src={report} className="img-fluid report-image"/>
                                        </div>
                                        <div className="d-flex flex-column ml-2">
                                            <div className="hp-report-page-link1">{t('DASHBOARD.DASHBOARD_CARD_VIEW_REPORT')}</div>
                                            <div className="hp-report-page-link2">{t('REPORT.REPORT_ASSESSMENT_COMPLETED')}</div>
                                            <div className="hp-report-page-link3">{t('REPORT.REPORT_LAST_DATE')}: {new Date().toDateString()}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column hp-report-top-right-panel">
                                        <div className="d-flex hp-report-heading">
                                            {t('REPORT.REPORT_USER_POSITION', {name: getUserName(), position: partnerPosition})}
                                        </div>
                                        <div className="d-flex hp-report-content">
                                        {t('REPORT.REPORT_DESC')}
                                        </div>
                                         {/* -------------- Score Bar Starts-------------- */}
                               
                                         <div className="score-bar">
                                            <div className="report-score-dflex">
                                                <p className="title-head-text-left">{t('REPORT.REPORT_DIGITAL_SCORE', {assessment: selectedReport.Key.indexOf('Digital') > -1?'digital':'service'})} <br/>{t('REPORT.REPORT_DIGITAL_ASSESSMENT_TEXT')}</p>
                                                <p className="title-head-text-right">{t('REPORT.REPORT_SCORE_TITLE', {score: Math.ceil(selectedReport?.scorePercentage||0)})}</p>
                                            </div>
                                            <div className="score-bar">
                                                <div className="score-bar-fill" style={{ width: `${selectedReport?.scorePercentage||0}%` }}></div>
                                            </div>
                                        </div>
                                        {/* -------------- Score Bar Ends -------------- */}
                                    
                                    </div>
                                </div>
                            }
                            {loading &&
                            <div className="d-flex justify-content-center align-items-center loading-wrap">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            }
                            {selectedReport && selectedSection && 
                                <div className="d-flex hp-report-switch">
                                    <div className={`d-flex hp-report-switch-digital flex-column ${selectedReport.Key.indexOf('Digital') > -1 ? 'hp-switch-active': ''}`} onClick={this.loadSelectedAssessmentReport.bind(this, 'digital', reportData)}>
                                    
                                        <div className="d-flex hp-report-switch-digital-text-wrap">
                                            {/* MOBILE*/}
                                            <div className="hp-switch-title d-md-none">Digital</div>
                                            <div className={selectedReport.Key.indexOf('Digital') > -1 ?"hp-switch-info d-md-none":"hp-switch-info-disabled d-md-none"}>{selectedReport.Key.indexOf('Digital') > -1 ?'See below':'Click to see' }</div>

                                            {/* DESKTOP*/}
                                            <div className="hp-switch-title d-none d-md-block">Digital assessment</div>
                                            <div className="hp-switch-title1 d-none d-md-block">{t('REPORT.REPORT_RESULTS_TEXT')}</div>
                                            <div className={selectedReport.Key.indexOf('Digital') > -1 ?"hp-switch-info d-none d-md-block":"hp-switch-info-disabled d-none d-md-block"}>{selectedReport.Key.indexOf('Digital') > -1 ?'Scroll down to see':'Click to find out the detail of results'}</div>
                                        

                                            <div className={selectedReport.Key.indexOf('Digital') > -1 ?"triangle":'d-none'}/>
                                            
                                            <div className={selectedReport.Key.indexOf('Digital') > -1 ? "semi-circle" :"semi-circle opacity-50"}>
                                                <div className="semi-circle-middle">
                                                    <div className="semi-circle-inner">
                                                    </div>
                                                </div> 
                                            </div>                               
                                        </div>
                                    </div>
                                    <div className={`d-flex hp-report-switch-service ${selectedReport.Key.indexOf('Service') > -1 ? 'hp-switch-active': ''}`} onClick={this.loadSelectedAssessmentReport.bind(this, 'service', reportData)}>
                                    
                                        <div className="d-flex hp-report-switch-service-text-wrap">
                                            
                                            {/* MOBILE*/}
                                            <div className="hp-switch-title d-md-none">Services</div>
                                            <div className={selectedReport.Key.indexOf('Service') > -1 ?"hp-switch-info d-md-none":"hp-switch-info-disabled d-md-none"}>{selectedReport.Key.indexOf('Service') > -1 ?'Click to see':'See below'}</div>

                                            {/* DESKTOP*/}
                                            <div className="hp-switch-title d-none d-md-block">Services assessment</div>
                                            <div className="hp-switch-title1 d-none d-md-block">{t('REPORT.REPORT_RESULTS_TEXT')}</div>
                                            <div className={selectedReport.Key.indexOf('Service') > -1 ?"hp-switch-info d-none d-md-block":"hp-switch-info-disabled d-none d-md-block"}>{selectedReport.Key.indexOf('Service') > -1 ?'Scroll down to see':'Click to find out the detail of results'}</div>
                                            
                                            <div className={selectedReport.Key.indexOf('Service') > -1 ?"triangle":"d-none"}/>
                                            <div className={selectedReport.Key.indexOf('Service') > -1 ?"semi-circle":"semi-circle opacity-50"}>
                                                <div className="semi-circle-middle">
                                                    <div className="semi-circle-inner">
                                                    </div>
                                                </div> 
                                            </div> 

                                        </div>
                                    </div>
                                </div>
                            }   
                            {selectedReport && selectedSection && 
                                (
                                    <div className="hp-report-survey d-flex flex-column">
                                        <div className="hp-report-survey-heading d-flex">
                                            <div className="hp-report-survey-title">{t('REPORT.REPORT_DIGITAL_ASSESSMENT_SURVEY')}</div>
                                            <div className="hp-report-survey-download-link" onClick={this.downloadPDF}>
                                            {t('REPORT.REPORT_DOWNLOAD')}
                                                <span className="ml-2"><img src={download_img}/></span>
                                            </div>
                                        </div>
                                        <div className="hp-report-survey-chart d-flex flex-column">
                                            <div className="hp-report-survey-chart-title d-flex w-100 justify-content-center">
                                            {t('REPORT.REPORT_YOUR_POSITION')}: <span className="hp-report-partner-position">{partnerPosition}</span>
                                            </div>
                                            
                                            <div className="w-100 d-flex align-items-center justify-content-center mt-2">
                                                <RadarComponent data1={selectedReport} />
                                            </div>
                                            <div className="tabs-container mb-3">
                                                <div className="tabs">
                                                    <span className="tab1 hp-lite">{t('REPORT.REPORT_LAGGING')}</span>
                                                    <span className="hp-lite">0%-25%</span>
                                                </div>
                                                <div className="tabs">
                                                    <span className="tab2 hp-lite">{t('REPORT.REPORT_INTERMEDIATE')}</span>
                                                    <span className="hp-lite">26%-50%</span>
                                                </div>
                                                <div className="tabs">
                                                    <span className="tab3 hp-lite">{t('REPORT.REPORT_ADVANCED')}</span>
                                                    <span className="hp-lite">51%-75%</span>
                                                </div>
                                                <div className="tabs">
                                                    <span className="tab4 hp-lite">{t('REPORT.REPORT_LEADER')}</span>
                                                    <span className="hp-lite">76%-100%</span>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center p-2 peer-rating-disclaimer">
                                                <div className="label-wrap">
                                                    <span className="disclaimer-label">{t('REPORT.REPORT_DISCLAIMER_LABEL')}</span> {t('REPORT.REPORT_DISCLAIMER_TEXT')}
                                                </div>
                                            </div>
                                        <div className="hp-report-survey-tabs d-flex">
                                            { selectedReport && selectedReport.sections && selectedReport.sections.length > 0 && selectedReport.sections.map((data, index) => (
                                                <div className={`hp-report-survey-tab d-flex flex-column ${data.Name === selectedSection.Name ? 'hp-report-survey-tab-active': ''} ${selectedReport.Key.indexOf('Digital') > -1 ? 'hp-tab-digital': 'hp-tab-service'}`} key={index} onClick={this.selectReportSection.bind(this, data)}>
                                                    <img src={getImgPath(data)} className="d-flex img-fluid"/>
                                                    <div className="d-flex hp-report-tab-text hp-report-tab-text-active">{data.Name === 'Services & Solutions Delivery' ? 'Service Delivery' : data.Name}</div>
                                                </div>
                                            )) 
                                            }
                                            
                                        </div>
                                        <div className="hp-report-survey-tab-content d-flex flex-column">
                                            <div className="d-flex hp-report-survey-tab-content-heading">
                                                {t('REPORT.REPORT_SCORED_COMPARE_PEER', {sectionName: selectedSection.Name})}
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="hp-report-guage-chart-wrap d-flex">

                                                    {/* DESKTOP */}
                                                    <div className="guage-chart d-none d-md-block flex-column">
                                                        <GaugeChart 
                                                            id="gauge-chart1"
                                                            nrOfLevels={8}
                                                            cornerRadius={0}
                                                            hideText={true}
                                                            colors={["#D3F2FF", "#007DB2"]} 
                                                            arcWidth={0.2} 
                                                            percent={selectedSection.scorePercentage}
                                                            style={chartDesktopStyle}
                                                        />
                                                        <div className="hp-report-guage-title">
                                                        {t('REPORT.REPORT_YOUR_SCORE')}
                                                        </div>
                                                        <div className="hp-report-guage-title-score">
                                                            {(selectedSection.scorePercentage * 100).toFixed(2)}%
                                                        </div>
                                                    </div>

                                                    {/* MOBILE */}
                                                    <div className="guage-chart d-md-none flex-column">
                                                        <GaugeChart 
                                                            id="gauge-chart1"
                                                            nrOfLevels={8}
                                                            cornerRadius={0}
                                                            hideText={true}
                                                            colors={["#D3F2FF", "#007DB2"]} 
                                                            arcWidth={0.2} 
                                                            percent={selectedSection.scorePercentage}
                                                            style={chartMobStyle}
                                                        />
                                                        <div className="hp-report-guage-title">
                                                        {t('REPORT.REPORT_YOUR_SCORE')}
                                                        </div>
                                                        <div className="hp-report-guage-title-score">
                                                            {(selectedSection.scorePercentage * 100).toFixed(2)}%
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="hp-report-guage-chart-wrap d-flex">
                                                    {/* DESKTOP */}
                                                    <div className="guage-chart d-none d-md-block flex-column">
                                                        <GaugeChart 
                                                            id="gauge-chart1"
                                                            nrOfLevels={8}
                                                            cornerRadius={0}
                                                            hideText={true}
                                                            colors={["#D3F2FF", "#007DB2"]} 
                                                            arcWidth={0.2} 
                                                            percent={selectedSection.ScorePercentagePeerScore ? selectedSection.ScorePercentagePeerScore : 0}
                                                            style={chartDesktopStyle}
                                                        />
                                                        <div className="hp-report-guage-title">
                                                        {t('REPORT.REPORT_PEER_SCORE')}
                                                        </div>
                                                        <div className="hp-report-guage-title-score">
                                                        {selectedSection.ScorePercentagePeerScore ? (selectedSection.ScorePercentagePeerScore*100).toFixed(2) : 0}%
                                                        </div>
                                                    </div>

                                                    {/* MOBILE */}
                                                    <div className="guage-chart d-md-none flex-column">
                                                        <GaugeChart 
                                                            id="gauge-chart1"
                                                            nrOfLevels={8}
                                                            cornerRadius={0}
                                                            hideText={true}
                                                            colors={["#D3F2FF", "#007DB2"]} 
                                                            arcWidth={0.2} 
                                                            percent={selectedSection.ScorePercentagePeerScore ? selectedSection.ScorePercentagePeerScore : 0}
                                                            style={chartMobStyle}
                                                        />
                                                        <div className="hp-report-guage-title">
                                                        {t('REPORT.REPORT_PEER_SCORE')}
                                                        </div>
                                                        <div className="hp-report-guage-title-score">
                                                            {selectedSection.ScorePercentagePeerScore ? (selectedSection.ScorePercentagePeerScore*100).toFixed(2) : 0}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center p-2 peer-rating-disclaimer">
                                                <div className="pt-2 label-wrap">
                                                <span className="disclaimer-label">{t('REPORT.REPORT_DISCLAIMER_LABEL')}</span> {t('REPORT.REPORT_DISCLAIMER_TEXT')}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Recommendation */}
                                        <Recommendation sectionRecommendation={selectedSection} selectedSectionScoreDetails={selectedSectionScoreDetails} assessment={selectedReport.id}/>
                                       {JSON.parse(localStorage?.getItem('userInfo'))?.showEDC &&  <GetConnectedEDC/>}
                                        
                                        {/*HP TOOLKIT*/}
                                        {hasAssessmentSubmitted && hasAssessmentSubmitted === 'true' &&
                                            
                                            <DigitalToolKitComponent />
                                            
                                        }
                                        
                                    </div>
                                )
                            }
                            
                        </div>
                    }
                    
                    <Footer />
                </div>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}></MDBModalHeader>
                    <MDBModalBody>
                       <div className="hp-report-not-found-title">
                       {t('REPORT.REPORT_NO_REPORT_FOUND')}
                       </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div className="hp-btn-v1" onClick={this.toggle}>{t('REPORT.REPORT_OK')}</div>
                    </MDBModalFooter>
                </MDBModal>
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportPage));