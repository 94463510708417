import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

function Disclaimer() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
      <div className="d-flex hp-layout hp-home">
        <Header />
        <div className="hp-content-wrap faq-container">
          <p className="bread-crumb hp-lite">
          <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;{" "}
            <span>Disclaimer</span>
          </p>
          <h6 className="hp-regular">Data Security</h6>
          <p>
            We have put in place appropriate industry standard security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
            We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline
          </p>
          <h6 className="hp-regular">Opt out to Communication</h6>
          <p>You may choose to opt out from any communication from us in relation to HP product and services by  sending an email to info@ethinos.com.</p>
          <h6 className="hp-regular">Changes in the policy</h6>
          <p>
            This policy may be updated keeping your privacy as our priority.
            Any updates on the same will be updated here for your reference.
          </p>
          <h6 className="hp-regular">Grievance Redressal</h6>
          <p>
            Any grievance or complaint, in relation to processing of information, should be sent to Ethinos at info@ethinos.com ,  Grievance shall be redressed as expeditiously as possible.
          </p>
          <h6 className="hp-regular">Grievance Redressal</h6>
          <p>
            In relation to any queries and concerns in relation to your personal data, please feel free to reach out to pavan.kumar@ethinos.com 
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Disclaimer;
