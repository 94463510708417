import axios from "axios";
import { MDBIcon } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EDCModal from "../../components/EDCModal";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { API_BASE_URL } from "../../config/api-config";
import { sendPageViewGTM } from "../../utils/util";
// styles
import "./edc.scss";
import { useTranslation } from 'react-i18next';

function EDCFilterResult({ match }) {
  let { countryId } = match?.params;
  useEffect(() => {
    callAPI();
     //GTM PageLoad
     try {
      let pagePath = window.location ? window.location.pathname: '';
      sendPageViewGTM('EDC Search Result Page', pagePath);
    } catch(e){}
  }, []);
  const [result, setResult] = useState([]);

  const callAPI = async () => {
    await axios
      .get(`${API_BASE_URL}/edcs?country.id=${countryId}`)
      .then((res) => {
        if (res) {
          setResult(res?.data);
        }
      });
  };
  const [selecetedEDC, setSelection] = useState([]);
  const [showModal, setShow] = useState(false);
  const handleChecked = (checked, id, country, edcName) => {
    if (checked) {
      setSelection([...selecetedEDC, ...[id]]);

      //GTM
      try {
        if(window && window.dataLayer) {
            window.dataLayer.push({
              'event': 'Contact Me',
              'CountryName': country,  // Country name which is shown
              'ParnerName': edcName  // Parner name

            })
        }
      } catch(e){}
    } else {
      let arr = selecetedEDC;
      let filterData = arr.filter((item) => item !== id);
      setSelection(filterData);
    }
  };
  const { t, i18n } = useTranslation();

  const handleSubmit = () => {
    let body = { ppid: localStorage.getItem("ppid"), edc_id: selecetedEDC };
    let config = {
      method: "post",
      url: `${API_BASE_URL}/edcs/contact`,
      data: body,
    };
    axios(config)
      .then(function (response) {
        setShow(true);
        setSelection([]);
      })
      .catch(function (error) {
        // Error
        setSelection([]);
      });
  };
  var servicesList = [];
  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
      <div className="d-flex hp-layout hp-home">
        <Header />
        <div
          className={
            showModal
              ? "hp-content-wrap edc-container filter"
              : "hp-content-wrap edc-container"
          }
        >
          <p className="bread-crumb hp-lite">
          <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;{" "}
            <Link to="/edc">EDC</Link> &gt; <span>EDC search results</span>
          </p>
          <div className="edc-wrapper">
            <p className="search-result hp-lite">{t('EDC.EDC_SEARCH')}</p>
            <p className="country hp-bold">{t('EDC.EDC_COUNTRY')}</p>
            <p className="country hp-lite">{result?.[0]?.country?.Country}</p>

            <div className="edc-button mt-4" onClick={handleSubmit}>
              <p className="hp-lite">{t('EDC.EDC_SELECT')} {selecetedEDC?.length}</p>
              <div className="d-flex align-items-center justify-content-between">
                <div className="info">
                  <p className="hp-lite">i</p>
                </div>
                <p className="hp-lite">{t('EDC.EDC_CONTACT_ME')}</p>
                <MDBIcon
                  icon="arrow-right"
                  size="1x"
                  style={{ color: "#FFF", fontSize: "10px", marginLeft: "5px" }}
                />
              </div>
            </div>
          </div>
          {result?.length > 0 ? (
            result?.map((item) => (
              <div className="edc-wrapper" key={item?._id}>
                <p className="edc-name hp-regular">{item?.Name}</p>
                <p className="edc-desc hp-lite">{item?.Description}</p>
                <p className="edc-country hp-regular">
                {t('EDC.EDC_COUNTRY_LABEL')}: {item?.country?.Country}
                </p>
                <p className="edc-contact hp-bold">{t('EDC.EDC_CONTACT')}</p>
                <p className="edc-contact-name hp-lite">{item?.PhoneNo}</p>
                <p className="edc-contact-name hp-lite">{item?.Email}</p>
                <p className="edc-contact-name hp-lite">{item?.Email2}</p>

                <div className="custom-control custom-checkbox mt-3 checkbox-wrap">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`defaultChecked${item?._id}`}
                    onChange={(e) =>
                      handleChecked(e?.target?.checked, item?._id, item?.country?.Country, item?.Name)
                    }
                    checked={selecetedEDC.find((x) => x === item?._id)}
                  />
                  <label
                    className="custom-control-label hp-regular"
                    for={`defaultChecked${item?._id}`}
                  >
                    {t('EDC.EDC_CONTACT_ME')}
                  </label>
                </div>
                {(()=>{
                  if(item?.ServicesOffered && item?.ServicesOffered.length > 0) {
                    servicesList = item.ServicesOffered.split(',');
                  }
                })()}

                {servicesList && servicesList.length > 0 &&
                  <div className="edc-services-wrap">
                      <div>
                        We recommend EDC - <span className="edc-service-text-blue">{item?.Name}</span> for these list of services. For the full list, please click <span className="edc-service-text-blue">'contact me'</span> to let the EDC advise further
                      </div>
                      <ul className="services-list">
                        {servicesList.map((service)=>(
                          <li className="services-item">{service}</li>
                        ))}
                      </ul>
                  </div>
                }
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center loading-wrap">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
      <EDCModal show={showModal} handleClose={() => setShow(false)} />
    </div>
  );
}

export default EDCFilterResult;
