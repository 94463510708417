import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import { FETCH_BP_MASTER_DATA,
        FETCH_BP_MASTER_DATA_SUCCESS,
        FETCH_BP_MASTER_DATA_ERROR,
        BP_PLANNER_ACTIVE_SECTION,
        BP_PLANNER_SET_PLAN_STATE,
        BP_PLANNER_SET_PLAN_BUDGET,
        BP_PLANNER_SET_PLAN_ALLOCATION,
        BP_PLANNER_SET_PLAN_BULK,
        BP_REACH_SET_BUDGET,
        BP_REACH_SET_VISITOR,
        BP_REACH_SET_TRANSACTIONS,
        BP_REACH_SET_ASV,
        BP_REACH_GET_MASTER,
        BP_REACH_GET_MASTER_SUCCESS,
        BP_REACH_GET_MASTER_ERROR,
        BP_POST_REACH_SUCCESS,
        BP_POST_REACH_ERROR,
        BP_UPDATE_REACH_REC,
        BP_COMPLETE_PLANNER_SUCCESS,
        BP_COMPLETE_PLANNER_ERROR,
        BP_SUMMARY_SUCCESS,
        BP_SUMMARY_ERROR,
        BP_SET_REACH_CHANNEL_STATE,
        BP_REACH_SET_CR,
        BP_REACH_SET_CPC,
        BP_SET_REACH_PLANNER_STATE,
        FETCH_PDF_SUCCESS,
        FETCH_PDF_ERROR,
        BP_SUMMARY_START,
        BP_COMPLETE_PLANNER_START} from "../actions/bpAction";


const initialState = {
    loading: false,
    bpMaster: null,
    bpCurrentSection: null,
    Error: null,
    bpReach: null,
    reachRec: null,
    reachTotal: {},
    bpCompletePlanner: null,
    bpSummary: null,
    appState: {
        showPlanner: false
    },
    bpPDF: null
}

export const bpReducer = (state = initialState, action) => {
    /*if (!state[action.listId]) {
        return state;
    }*/
    switch(action.type) {
        case FETCH_BP_MASTER_DATA:
            return {
                ...state,
                loading: true
            }
        case FETCH_BP_MASTER_DATA_SUCCESS:
            let bpMaster = action.payload;
            return {
                ...state,
                bpMaster: bpMaster,
                Error: false,
                loading: false
            }
        case FETCH_BP_MASTER_DATA_ERROR:
            return {
                ...state,
                Error: true,
                loading: false
            }
        case BP_PLANNER_ACTIVE_SECTION:
            let activeSection = action.payload;
            return {
                ...state,
                bpCurrentSection: activeSection
            }
        case BP_PLANNER_SET_PLAN_STATE:
            let plandata = action.payload;
            let tempData = [...state.bpMaster];
            let itemIndex ;
            state.bpMaster.map((item, i)=>{
                if(item.Key === plandata.Key) {
                    itemIndex = i;
                }
            })
            tempData[itemIndex].HasPartnerSelected = plandata.Val;
            return {
                ...state,
                bpMaster: tempData
            }
        case BP_PLANNER_SET_PLAN_BUDGET:
            let bpData = action.payload;
            let tempData1 = [...state.bpMaster];
            let itemIndex1 ;
            state.bpMaster.map((item, i)=>{
                if(item.Key === bpData.Key) {
                    itemIndex1 = i;
                }
            })
            tempData1[itemIndex1].Recommended = bpData.Val;
            return {
                ...state,
                bpMaster: tempData1
            }
        case BP_PLANNER_SET_PLAN_ALLOCATION:
            let plandata2 = action.payload;
            let tempData2 = [...state.bpMaster];
            let itemIndex2 ;
            state.bpMaster.map((item, i)=>{
                if(item.Key === plandata2.Key) {
                    itemIndex2 = i;
                }
            })
            tempData2[itemIndex2].Allocation = plandata2.Val;
            return {
                ...state,
                bpMaster: tempData2
            }
        case BP_PLANNER_SET_PLAN_BULK:
            let bulkData = action.payload;
            let tempMasterData = [...state.bpMaster];
            if(bulkData.length > 0) {
                for(let j=0;j<bulkData.length;j++) {
                    for(let i=0;i< state.bpMaster.length;i++) {
                        if(bulkData[j].Key === state.bpMaster[i].Key) {
                            tempMasterData[i].Recommended = bulkData[j].Recommended;
                            tempMasterData[i].Allocation = bulkData[j].Allocation;
                        }
                    }
                }
            }

            return {
                ...state,
                bpMaster: tempMasterData
            }
        case BP_REACH_SET_BUDGET:
            let budgetTemp = state.bpReach;
            budgetTemp.budget  = action.payload;
            return {
                ...state,
                bpReach: budgetTemp
            }
        case BP_REACH_SET_VISITOR:
            let visitorTemp = state.bpReach;
            visitorTemp.visitor  = action.payload;
            return {
                ...state,
                bpReach: visitorTemp
            }
        case BP_REACH_SET_TRANSACTIONS:
            let transactionsTemp = state.bpReach;
            transactionsTemp.transactions  = action.payload;
            return {
                ...state,
                bpReach: transactionsTemp
            }
        case BP_REACH_SET_ASV:
            let asvTemp = state.bpReach;
            asvTemp.asv  = action.payload;
            return {
                ...state,
                bpReach: asvTemp
            }
        case BP_REACH_SET_CR:
            let crTemp = state.bpReach;
            crTemp.cr = action.payload;
            return {
                ...state,
                bpReach: crTemp
            }
        case BP_REACH_SET_CPC:
            let cpcTemp = state.bpReach;
            cpcTemp.cpc = action.payload;
            return {
                ...state,
                bpReach: cpcTemp
            }
        case BP_REACH_GET_MASTER_SUCCESS:
            return {
                ...state,
                bpReach: action.payload
            }
        case BP_REACH_GET_MASTER_ERROR:
            return {
                ...state
            }
        case BP_POST_REACH_SUCCESS:
            let tempReachRec1;
            if(state.reachRec === null) {
                tempReachRec1 =  action.payload.reachTypes;
            } else {
                tempReachRec1 = [...state.reachRec];
                tempReachRec1.map((item, i)=> {
                    if(item.Key ===  action.payload.reachTypes[i].Key) {

                        item.Budget = action.payload.reachTypes[i].Budget;
                        item.Visits = action.payload.reachTypes[i].Visits;
                        item.CR = action.payload.reachTypes[i].CR;
                        item.CPC = action.payload.reachTypes[i].CPC; 
                        item.Sales = action.payload.reachTypes[i].Sales;
                        item.ROI = action.payload.reachTypes[i].ROI;
                        item.Revenue = action.payload.reachTypes[i].Revenue;
                        item.HasSelected = action.payload.reachTypes[i].HasSelected;
                    }
                })
            }


            let tempReachTotal = {};
            if(Object.keys(state.reachTotal).length === 0) {
                tempReachTotal = action.payload.total;
            } else {
                tempReachTotal = Object.assign({}, state.reachTotal);
                tempReachTotal.Budget = action.payload.total.Budget;
                tempReachTotal.Visits = action.payload.total.Visits;
                tempReachTotal.CR = action.payload.total.CR;
                tempReachTotal.CPC = action.payload.total.CPC; 
                tempReachTotal.Sales = action.payload.total.Sales;
                tempReachTotal.ROI = action.payload.total.ROI;
                tempReachTotal.Revenue = action.payload.total.Revenue;
            }
            return {
                ...state,
                reachRec: tempReachRec1,
                reachTotal: tempReachTotal
            }
        case BP_POST_REACH_ERROR:
            return {
                ...state
            }
        case BP_UPDATE_REACH_REC:
            let tempRec = [...state.reachRec];
            tempRec.map((rec,i)=> {
                if(rec.Key === action.payload.Key) {
                    if(action.key === 'HasSelected') {
                        rec[action.key] = !rec[action.key];
                    } else if(action.key === 'Budget') {
                        rec[action.key] = parseInt(action.payload.cBudget);
                    }
                }
            });
            return {
                ...state,
                reachRec: tempRec
            }
        case BP_COMPLETE_PLANNER_SUCCESS: 
            let completeData = action.payload;
            return {
                ...state,
                bpCompletePlanner: completeData,
                loading: false
            }
        case BP_COMPLETE_PLANNER_ERROR: 
            return {
                ...state,
                loading: true
            }
        case BP_COMPLETE_PLANNER_START:
            return {
                ...state,
                loading: true
            }
        case BP_SUMMARY_SUCCESS:
            let bpSummary = action.payload;
            return {
                ...state,
                loading: false,
                bpSummary
            }
        case BP_SUMMARY_ERROR:
            return {
                ...state,
                loading: true
            }
        case BP_SUMMARY_START:
            return {
                ...state,
                loading: true
            }
        case BP_SET_REACH_CHANNEL_STATE:
            
            let reachRecData = action.payload;
            let tempReachRec = [...state.reachRec];
            let reachRecIndex ;
            if(tempReachRec !==null) {
                tempReachRec.map((item, i)=>{
                    if(item.Key === reachRecData) {
                        reachRecIndex = i;
                    }
                })
                tempReachRec[reachRecIndex].HasSelected = !tempReachRec[reachRecIndex].HasSelected;
            }
            return {
                ...state,
                reachRec: tempReachRec
            }
        case BP_SET_REACH_PLANNER_STATE:
            let tempAppState = state.appState;
            tempAppState.showPlanner = action.payload;
            return {
                ...state,
                appState: tempAppState
                
            }
        case FETCH_PDF_SUCCESS:
            let pdfData = action.payload;
            return {
                ...state,
                bpPDF: pdfData
            }
        default:
            return state;
    }
}

export const getBPMasterData = state => state['bpReducer'].bpMaster;
export const getActiveSection = state => state['bpReducer'].bpCurrentSection;
export const getBPReachData = state => state['bpReducer'].bpReach;
export const getReachRecommendations = state => state['bpReducer'].reachRec;
export const getReachTotal = state => state['bpReducer'].reachTotal;

export const getCompletePlanner = state => state['bpReducer'].bpCompletePlanner;
export const getSummary = state => state['bpReducer'].bpSummary;

export const getAppState = state => state['bpReducer'].appState;
export const getPDFData = state => state['bpReducer'].bpPDF;
export const getPageLoadStatus = state => state['bpReducer'].loading;

