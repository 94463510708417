import axios from "axios";
import {API_BASE_URL} from '../config/api-config';

export const FETCH_REPORT = 'FETCH_REPORT';
export const FETCH_RECOMMENDATIONS_SECTION = 'FETCH_RECOMMENDATIONS_SECTION';
export const FETCH_RECOMMENDATIONS_SECTION_SUCCESS = 'FETCH_RECOMMENDATIONS_SECTION_SUCCESS';
export const FETCH_RECOMMENDATIONS_SUB_SECTION = 'FETCH_RECOMMENDATIONS_SUB_SECTION';
export const FETCH_RECOMMENDATIONS_SUB_SECTION_SUCCESS = 'FETCH_RECOMMENDATIONS_SUB_SECTION_SUCCESS';
export const FETCH_RECOMMENDATIONS_ERROR = 'FETCH_RECOMMENDATIONS_ERROR';

export const FETCH_FULL_REPORT = 'FETCH_FULL_REPORT';
export const FETCH_FULL_REPORT_SUCCESS = 'FETCH_FULL_REPORT_SUCCESS';

export const FETCH_SITE_LIST = 'FETCH_SITE_LIST';
export const FETCH_SITE_LIST_SUCCESS = 'FETCH_SITE_LIST_SUCCESS';
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';

export const fetchDetailedReport  = (data) => {
    return{
        type: FETCH_REPORT,
        payload: data
    }
}

export const loadSectionRecommendationStart = () => {
    return{
        type: FETCH_RECOMMENDATIONS_SECTION
    }
}

export const loadSectionRecommendation = (data) => {
    return{
        type: FETCH_RECOMMENDATIONS_SECTION_SUCCESS,
        payload: data
    }
}


export const loadAssessmentReportError = () => {
    return {
        type: FETCH_REPORT_ERROR
    }
}

export const loadRecommendationError = () => {
    return {
        type: FETCH_RECOMMENDATIONS_ERROR
    }
}

export const loadSubSectionRecommendation = (data) => {
    return{
        type: FETCH_RECOMMENDATIONS_SUB_SECTION_SUCCESS,
        payload: data
    }
}

export const loadSubSectionRecommendationStart = () => {
    return {
        type: FETCH_RECOMMENDATIONS_SUB_SECTION
    }
}

export const fetchFullreportStart = () => {
    return {
        type: FETCH_FULL_REPORT
    }
}

export const fetchFullreport = (data) => {
    return {
        type: FETCH_FULL_REPORT_SUCCESS,
        payload: data
    }
}

export const fetchSiteListSuccess = (data) => {
    return {
        type: FETCH_SITE_LIST_SUCCESS,
        payload: data
    }
}

export const loadAssessmentReport = (id, ownProps, sectionId) => {
    
    let ppid = localStorage.getItem('ppid');
    let secId = sectionId ? sectionId : null;
    return dispatch => {
        dispatch(loadSectionRecommendationStart());
        axios.get(`${API_BASE_URL}/assessment-statuses/getscore?ppid=`+ppid)
        .then(res => {
            return res.data;
        })
        .then(data => dispatch(loadRecommendationsBySection(secId, data)))
        .catch((err) => {
            dispatch(loadAssessmentReportError())
            if(ownProps) {
                ownProps.history.push('/');
            }
        })
    };
}

export const loadRecommendationsBySection = (id, reportData) => {
    let ppid = localStorage.getItem('ppid');
    let secId;
    
    if(reportData && reportData.length > 0 && id === null) {

        secId = (reportData[0].sections && reportData[0].sections.length > 0) ? reportData[0].sections[0].id: null;
    } else {
        secId = id;
    }
    return dispatch => {
        dispatch(loadSectionRecommendationStart());
        axios.get(`${API_BASE_URL}/sections/recommendations?ppid=${ppid}&sectionId=${secId}`)
        .then(res => {
            let rs = {
                data: res.data
            }
            if(reportData) {
                rs.reportData = reportData
            }
            return rs;
        })
        .then(data => dispatch(loadSectionRecommendation(data)))
        .catch(err => dispatch(loadRecommendationError()) )
    };
}

export const loadRecommendationsBySubSection = (id) => {
    let ppid = localStorage.getItem('ppid');
    return dispatch => {
        dispatch(loadSubSectionRecommendationStart());
        axios.get(`${API_BASE_URL}/sections/recommendations?ppid=${ppid}&subsectionId=${id}`)
        .then(res => {
            return res.data;
        })
        .then(data => dispatch(loadSubSectionRecommendation(data)))
        .catch(err => dispatch(loadRecommendationError()) )
    };
}

export const loadFullreport = (ppid) => {
    return dispatch => {
        dispatch(fetchFullreportStart());
        axios.get(`${API_BASE_URL}/partners/scoreandrecommendation?ppid=`+ppid)
        .then(res => {
            return res.data;
        })
        .then(data => dispatch(fetchFullreport(data)))
    };
}

export const loadSiteList = () => {
    let ppid = localStorage.getItem('ppid');
    return dispatch => {
        axios.get(`${API_BASE_URL}/partners/websites?ppid=${ppid}`)
        .then(res => {
            return res.data;
        })
        .then(data => dispatch(fetchSiteListSuccess(data)))
    };
}
