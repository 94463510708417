import { act } from "react-dom/test-utils";
import { FETCH_ASSESSMENT,
        SAVE_COMPLETED_SECTIONS ,
        SUBMIT_ASSESSMENT,
        FETCH_ASSESSMENT_REPORT,
        FETCH_ASSESSMENT_START,
        EMPTY_COMPLETED_SECTIONS,
        CLEAR_ASSESSMENT_DATA, UPDATE_ASSESSMENT_DATA,
        SAVE_COMPLETED_SECTIONS_BY_TYPE,
        SAVE_TOTAL_SECTIONS_BY_TYPE,
        FETCH_ASSESSMENT_ERROR,
        FETCH_ASSESSMENT_REPORT_START,
        FETCH_ASSESSMENT_REPORT_ERROR} from "./actions";

const initialState = {
    assessments: [],
    completedSections: [],
    report: [],
    loading: false,
    loadingScore: true,
    auth: null,
    CompletedDigitalSections: [],
    totalDigitalSections: null,
    CompletedServiceSections: [],
    totalServiceSections: null,
    Home_Error: false,
    errorMsg: ''
}

export const homeReducer = (state = initialState, action) => {
    /*if (!state[action.listId]) {
        return state;
    }*/
    switch(action.type) {
        
        case FETCH_ASSESSMENT:
            let assessments = [...state.assessments];
            assessments = action.payload;
            return {
                ...state,
                assessments,
                loading: false,
                Home_Error: false
            };
        case EMPTY_COMPLETED_SECTIONS:
            let completedItems = [];
            return {
                ...state,
                completedSections: completedItems,
                Home_Error: false
            }
        case SAVE_COMPLETED_SECTIONS:
            let completedSections = [...state.completedSections];
            if (typeof action.payload === 'string'){
                completedSections.push(action.payload);
            } else {
                completedSections = [...action.payload];
            }
            return {
                ...state,
                completedSections,
                Home_Error: false
            };
        case SUBMIT_ASSESSMENT:
            return {
                ...state,
                Home_Error: false
            };
        case FETCH_ASSESSMENT_REPORT:
            let report = action.payload;
            return {
                ...state,
                report,
                loading: false,
                loadingScore: false,
                Home_Error: false
            };
        case FETCH_ASSESSMENT_START:
            return {
                ...state,
                loading: true,
                Home_Error: false
            }
        case CLEAR_ASSESSMENT_DATA:
            return {
                ...state,
                assessments: [],
                completedSections: [],
                report: [],
                Home_Error: false
            }
        case UPDATE_ASSESSMENT_DATA:
            return {
                ...state,
                Home_Error: false
            }
        case SAVE_COMPLETED_SECTIONS_BY_TYPE:

            let CompletedDigitalSections = [...state.CompletedDigitalSections];
            let CompletedServiceSections = [...state.CompletedServiceSections];

            if(action.key === 'digital') {
                if (typeof action.payload === 'string'){
                    CompletedDigitalSections.push(action.payload);
                } else {
                    CompletedDigitalSections = [...action.payload];
                }
            } else if(action.key === 'service') {
                if (typeof action.payload === 'string'){
                    CompletedServiceSections.push(action.payload);
                } else {
                    CompletedServiceSections = [...action.payload];
                }
            }

            return {
                ...state,
                CompletedServiceSections,
                CompletedDigitalSections,
                Home_Error: false
            }
        case SAVE_TOTAL_SECTIONS_BY_TYPE:

            let totalDigitalSections =  state.totalDigitalSections;
            let totalServiceSections = state.totalServiceSections;
            if(action.key === 'digital') {
                totalDigitalSections =  action.payload;
            } else if(action.key === 'service') {
                totalServiceSections = action.payload;
            }

            return {
                ...state,
                totalServiceSections,
                totalDigitalSections,
                Home_Error: false
            }
        case FETCH_ASSESSMENT_ERROR:
            return {
                ...state,
                Home_Error: true,
                errorMsg: action.payload ? action.payload : 'Something unexpected happened, please try again'
            }
        case FETCH_ASSESSMENT_REPORT_START:
            return {
                ...state,
                loadingScore: true
            }
        case FETCH_ASSESSMENT_REPORT_ERROR:
            return {
                ...state,
                loadingScore: false
            }
        default:
            return state;
    }
}

export const getAssessments = state => state['homeReducer'].assessments;
export const getCompletedSections = state => state['homeReducer'].completedSections;
export const getAssessmentReport = state => state['homeReducer'].report;
export const getLoadingStatus = state => state['homeReducer'].loading;
export const getLoadingScoreStatus = state => state['homeReducer'].loadingScore;

export const getAuthData = state => state['homeReducer'].auth;

export const getCompletedDigitalSection = state => state['homeReducer'].CompletedDigitalSections;
export const getTotalDigitalSections = state => state['homeReducer'].totalDigitalSections;
export const getCompletedServiceSections = state => state['homeReducer'].CompletedServiceSections;
export const getTotalServiceSections = state => state['homeReducer'].totalServiceSections;
export const getHomeActionError = state => state['homeReducer'].Home_Error;


