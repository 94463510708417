import axios from "axios";
import { API_BASE_URL } from "../config/api-config";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export const FETCH_LOGIN_DATA = "FETCH_LOGIN_DATA";
export const FETCH_LOGIN_DATA_SUCCESS = "FETCH_LOGIN_DATA_SUCCESS";
export const FETCH_LOGIN_DATA_ERROR = "FETCH_LOGIN_DATA_ERROR";

export const fetchLoginDataSuccess = (data) => {
  return {
    type: FETCH_LOGIN_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchLoginDataError = (data) => {
  //NotificationManager.error('Invalid token: User autherization failed!');
  return {
    type: FETCH_LOGIN_DATA_ERROR,
  };
};

export const partnerRegistration = (token, ownProps) => {
  return (dispatch) => {
    if (token) {
      axios
        .get(`${API_BASE_URL}/partners/register?tokenId=` + token)
        .then((res) => {
          let authData = res.data;
          let ppid;
          let userId = "";
          if (authData && authData.data && authData.data.length > 0) {
            let savedPPID = localStorage.getItem("ppid");
            if (
              savedPPID &&
              savedPPID !== undefined &&
              savedPPID !== authData.data[0].ppid
            ) {
              localStorage.removeItem("qParam");
              localStorage.removeItem("showSubmit");
            }
            localStorage.setItem(
              "reAssessmentOption",
              authData.data[0].reAssessmentOption
                ? authData.data[0].reAssessmentOption
                : "null"
            );
            localStorage.setItem(
              "assesment_type",
              authData.data[0].reAssessmentOption
                ? authData.data[0].reAssessmentOption
                : "null"
            );
            localStorage.setItem("ppid", authData.data[0].ppid);
            localStorage.setItem("userId", authData.data[0].userId);
            localStorage.setItem(
              "authorizationtoken",
              authData.data[0].authorizationtoken
            );

            ppid = authData.data[0].ppid;
            userId = authData.data[0].userId;
            localStorage.setItem("reAssesment", authData.data[0]?.reAssessment);
            localStorage.setItem(
              "reAssesment1",
              authData.data[0]?.reAssessment
            );
          }
          dispatch(fetchLoginDataSuccess(res.data));
          dispatch(getuserData(userId, ownProps, ppid));
        })
        .catch((err) => {
          console.info("Error:", err);
          dispatch(fetchLoginDataError());
        });
    } else {
      let ppid = localStorage.getItem("ppid");
      let userId = localStorage.getItem("userId");

      if (ppid && userId) {
        dispatch(getuserData(userId, ownProps, ppid));
      } else {
        dispatch(fetchLoginDataError());
      }
    }
  };
};

export const getuserData = (userId, ownProps, ppid) => {
  return (dispatch) => {
    axios
      .get(`${API_BASE_URL}/partners/user-profile?userId=` + userId)
      .then((res) => {
        let rs = res.data;
        let authData;
        try {
          if (rs && rs.data) {
            authData = rs.data;
            if (authData && authData.length > 0) {
              localStorage.setItem("userInfo", JSON.stringify(authData[0]));
            }
          }
          if (
            rs &&
            rs.data &&
            rs.data.length > 0 &&
            rs.data[0].Language !== null
          ) {
            localStorage.setItem("lan", rs.data[0].Language);
            ownProps.i18n.changeLanguage(rs.data[0].Language);
            axios
              .get(`${API_BASE_URL}/assessment-categories/status?ppid=` + ppid)
              .then((res) => {
                if (res.data && res.data.length > 0) {
                  let d = res.data[0];
                  let hasStarted = false;
                  let hasSubmited = false;
                  if (d.sections && d.sections.length > 0) {
                    hasStarted = d.sections[0].hasStarted;
                    hasSubmited = d.sections[0].hasFinallySubmitted;
                    if (hasSubmited === false && hasStarted === true) {
                      ownProps.history.push(`/${ppid}`);
                    }
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
            if (localStorage.getItem("reAssesment1")) {
              ownProps.history.push(`/reassessment/${ppid}`);
            } else {
              ownProps.history.push(`/${ppid}`);
            }
          } else {
            console.log("Language is not set for the user");
          }
        } catch (e) {
          console.info(e);
        }
      })
      .catch((err) => {
        console.info("Error:", err);
        dispatch(fetchLoginDataError());
      });
  };
};

//Action for EDC,Toolkit and report

export const partnerRegistrationRedirect = (token, pathName, ownProps) => {
  return (dispatch) => {
    if (token) {
      axios
        .get(`${API_BASE_URL}/partners/register?tokenId=` + token)
        .then((res) => {
          let authData = res.data;
          let ppid;
          let userId = "";
          if (authData && authData.data && authData.data.length > 0) {
            let savedPPID = localStorage.getItem("ppid");
            if (
              savedPPID &&
              savedPPID !== undefined &&
              savedPPID !== authData.data[0].ppid
            ) {
              localStorage.removeItem("qParam");
              localStorage.removeItem("showSubmit");
            }
            localStorage.setItem("ppid", authData.data[0].ppid);
            localStorage.setItem("userId", authData.data[0].userId);
            localStorage.setItem(
              "authorizationtoken",
              authData.data[0].authorizationtoken
            );

            ppid = authData.data[0].ppid;
            userId = authData.data[0].userId;
          }
          dispatch(fetchLoginDataSuccess(res.data));
          dispatch(getuserDataRedirect(userId, pathName, ownProps, ppid));
        })
        .catch((err) => {
          console.info("Error:", err);
          dispatch(fetchLoginDataError());
        });
    } else {
      let ppid = localStorage.getItem("ppid");
      let userId = localStorage.getItem("userId");

      if (ppid && userId) {
        dispatch(getuserDataRedirect(userId, ownProps, ppid));
      } else {
        dispatch(fetchLoginDataError());
      }
    }
  };
};

export const getuserDataRedirect = (userId, pathName, ownProps, ppid) => {
  return (dispatch) => {
    axios
      .get(`${API_BASE_URL}/partners/user-profile?userId=` + userId)
      .then((res) => {
        let rs = res.data;
        let authData;
        try {
          if (rs && rs.data) {
            authData = rs.data;
            if (authData && authData.length > 0) {
              localStorage.setItem("userInfo", JSON.stringify(authData[0]));
            }
          }
          if (
            rs &&
            rs.data &&
            rs.data.length > 0 &&
            rs.data[0].Language !== null
          ) {
            if (pathName === "edc") {
              window.location = `/${pathName}`;
            }
            localStorage.setItem("lan", rs.data[0].Language);
            ownProps.i18n.changeLanguage(rs.data[0].Language);
            // alert(`/${pathName}/${ppid}`);
            //alert(ppid);

            if (pathName === "bp/home") {
              ownProps.history.push(`/bp/home`);
              return;
            }

            if (pathName === "report") {
              window.location = `/${pathName}/${ppid}`;
            } else {
              window.location = `/${pathName}`;
            }
            //ownProps.history.push(`/${pathName}/${ppid}`);
          } else {
            console.log("Language is not set for the user");
          }
        } catch (e) {
          console.info(e);
        }
      })
      .catch((err) => {
        console.info("Error:", err);
        //dispatch(fetchLoginDataError())
      });
  };
};

export const setLanguageForUser = (ppid, lan, ownProps) => {
  return (dispatch) => {
    if (ppid && lan) {
      axios
        .post(
          `${API_BASE_URL}/partners/set-language?ppid=${ppid}&language=${lan}`
        )
        .then((res) => {
          localStorage.setItem("lan", lan);
          if (ownProps?.auth?.[0]?.reAssessment) {
            ownProps.history.push(`/reassessment/${ppid}`);
            localStorage.setItem("ppid", ppid);
          } else {
            ownProps.history.push(`/${ppid}`);
          }
        })
        .catch((err) => {
          console.info("Error:", err);
          NotificationManager.error(
            "Sorry, your request to set the language is failed"
          );
        });
    } else {
      NotificationManager.error("Missing required details");
    }
  };
};
