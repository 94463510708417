import React from 'react';
import { BrowserRouter, Switch, Redirect, Route, Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { withRouter } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { MDBIcon } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import "./recommendation.scss";

class Recommendation extends React.Component {

    constructor(props) {
        super(props);
    }

    getScore = (currentSection, scoreObj) => {
        let currentScore = 0;
        if(scoreObj) {
            for(let i=0;i<scoreObj.length;i++) {
                if(scoreObj[i].id == currentSection.id) {
                    currentScore = scoreObj[i].score;
                    currentScore = Math.round( parseFloat(currentScore) * 100);
                    break;
                }
            }
        }
        return currentScore ?  currentScore : 0;
    }

    getPeerScore = (currentSection, scoreObj) => {
        let currentScore = 0;
        if(scoreObj) {
            for(let i=0;i<scoreObj.length;i++) {
                if(scoreObj[i].id == currentSection.id) {
                    currentScore = scoreObj[i].peer_score;
                    currentScore = Math.round( parseFloat(currentScore) * 100)
                    break;
                }
            }
        }
        return currentScore ?  currentScore : 0;
    }

    gotoRecommendationPage = (subsecId, secId, showreport, assessment, that, secName, title) => {
        const { location, history } = this.props;
        let url = window.location.origin+'/report/'+localStorage.getItem('ppid');
        if((window.location.href.lastIndexOf('/')+1) === window.location.href.length) {
            url = url + assessment + '/'+subsecId;
        } else {
            url = url + '/' +assessment+'/'+subsecId;
        }
    	window.history.replaceState({}, "", url);
        history.push(`/recommendations/${subsecId}/${secId}/${showreport}/${assessment}`);

        try {
            if(window && window.dataLayer) {
                window.dataLayer.push({
                    'event': 'Show Details',
                    'Title': title,
                    'Toolkit': this.props.sectionRecommendation ? this.props.sectionRecommendation.Name: '',
                    'Service': secName
                })
            }
        } catch(e) {}
    }

    render() {
        const {sectionRecommendation, selectedSectionScoreDetails, assessment} = this.props;
        const { t } = this.props;
        let showSiteReport = false;
        let that =  this;
        let score = -1;
        let recText = '';
        return(
            <div className="hp-report-recommendation">
                <div className="hp-report-recommendation-title d-flex justify-content-center">{t('PDF.PDF_FEEDBACK_TITLE')}</div>
                <div className="hp-report-recommendations-wrap">

                {sectionRecommendation && sectionRecommendation.sub_sections && sectionRecommendation.sub_sections.length > 0 
                    && sectionRecommendation.sub_sections.map((sec, index) => (
                        <div className="hp-report-recommendation-item" key={index}>
                            <div className="hp-recommend-item-title">
                                {sec.Name}
                            </div>
                            <div className="hp-recommend-item-score">
                                <div className="hp-assessment-scores-section-bar-wrap">
                                    <div className="hp-report-score">
                                        <span className="hp-report-score-left"></span>
                                        <span className="hp-report-score-right">Scored {this.getScore(sec, selectedSectionScoreDetails)} out of 100</span>
                                    </div>
                                    <div className="hp-assessment-scores-section-bar">
                                        <div className="hp-assessment-scores-section-bar-fill" style={{width:`${this.getScore(sec, selectedSectionScoreDetails)}%`}}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="hp-recommend-item-content">
                                {(() => {
                                score = this.getScore(sec, selectedSectionScoreDetails);
                                let peerScore = this.getPeerScore(sec, selectedSectionScoreDetails);

                                if(sec.Key.trim().indexOf('Website Experience') > -1) {
                                    showSiteReport = true;
                                }

                                if(score <=25) {
                                    recText = t('PDF.PDF_POSITION_LAGGING_MSG', {sectionName: sec.Name,score: score,peerScore: peerScore});
                                    return(
                                        <p>
                                            {t('PDF.PDF_POSITION_LAGGING_MSG', {sectionName: sec.Name,score: score,peerScore: peerScore})}
                                            
                                        </p>
                                    )
                                } else if(score > 25 && score <= 50) {
                                    recText = t('PDF.PDF_POSITION_INTERMEDIATE_MSG', {sectionName: sec.Name,score: score,peerScore: peerScore});
                                    return (
                                        <p>
                                            {t('PDF.PDF_POSITION_INTERMEDIATE_MSG', {sectionName: sec.Name,score: score,peerScore: peerScore})}
                                        </p>
                                    )
                                } else if(score > 50 && score <= 75) { 
                                    recText = t('PDF.PDF_POSITION_GOOD_MSG', {sectionName: sec.Name,score: score,peerScore: peerScore});
                                    return (
                                        <p>
                                            {t('PDF.PDF_POSITION_GOOD_MSG', {sectionName: sec.Name,score: score,peerScore: peerScore})}
                                        </p>
                                    )
                                } else if(score > 75 && score <=100) {
                                    recText = t('PDF.PDF_POSITION_EXCELLENT_MSG', {sectionName: sec.Name,score: score,peerScore: peerScore});
                                    return (
                                        <p>
                                            {t('PDF.PDF_POSITION_EXCELLENT_MSG', {sectionName: sec.Name,score: score,peerScore: peerScore})}
                                        </p>
                                    )
                                }
                                })()}
                            </div>
                            <div className="hp-recommend-item-link">    
                                <span className="hp-recommend-item-link-text" onClick={this.gotoRecommendationPage.bind(this, sectionRecommendation.id, sec.id, showSiteReport, assessment, that, sec.Name, recText)}>Show details</span><span className="hp-recommend-item-link-icon"><MDBIcon icon="arrow-right" /></span>
                            </div>
                            
                        </div>
                    )) }
                </div>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Recommendation));