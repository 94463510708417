import React from 'react';
import { Link } from 'react-router-dom';


import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageSpeedResult from '../../components/PageSpeedResult/PageSpeedResult';
import GSpeedRoundChart from "../../components/GSpeedRoundChart";
import { MDBIcon } from 'mdbreact';
import { sendPageViewGTM } from "../../utils/util";

import './pagespeed.scss';

import { getPerformanceReport, getLoadindStatus } from "../../reducers/pspeedReducer";
import { loadPSpeedReportByURL } from "../../actions/pspeedAction";


const mapStateToProps = (state) => {
    return {
        pspeedReport: getPerformanceReport(state),
        loading: getLoadindStatus(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPSpeedReportByURL: (url) => dispatch(loadPSpeedReportByURL(url))
    }
}

class PageSpeed extends React.Component {

    state = {
        selectedDevice: 'mobile',
        selectedData: null
    }

    componentDidMount = () => {
        let { search } = this.props.location;
        let splitAr = search.trim().split('=');
        let qp = splitAr.length > 0 ? splitAr[1]: null;
        if(qp !== null) {
            this.props.loadPSpeedReportByURL(qp);
        }

        //GTM PageLoad
        try {
            let pagePath = window.location ? window.location.pathname: '';
            sendPageViewGTM('Google Page Speed Report Page', pagePath);
        } catch(e){}
    }

    selectDevice = (device) => {

        let pspeedReport = this.props.pspeedReport;
        let selectedReport;

        let report;
        if(pspeedReport && pspeedReport.length > 0) {
            report = pspeedReport[0];
            if(report && report.Results && report.Results.length >0) {
                report.Results.map((data, i) => {
                    if(data.Type === device) {
                        selectedReport = data;
                    }
                })
            }

            this.setState({
                selectedData: selectedReport,
                selectedDevice: device
            });
        }

    }

    render() {

        const {loading, pspeedReport} = this.props;
        const {selectedData, selectedDevice} = this.state;
        const { t } = this.props;

        let report;
        let selectedDeviceData;
        if(pspeedReport && pspeedReport.length > 0) {
            report = pspeedReport[0];
            if(report && report.Results && report.Results.length >0 && selectedData === null) {
                report.Results.map((device, i) => {
                    if(device.Type === this.state.selectedDevice) {
                        selectedDeviceData = device;
                    }
                })
            } else {
                selectedDeviceData = selectedData;
            }
        }
        return (
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                <div className="d-flex hp-layout hp-home">
                    <Header />
                    <p className=" ml-3 bread-crumb hp-lite">
                    <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;  <span>PageSpeed Report</span>
                    </p>
                    {loading &&
                        <div className="d-flex justify-content-center align-items-center loading-wrap">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {report &&
                    <div className="hp-pagespeed-header">
                        <div className="hp-pagespeed-header-title">{t('RECOMMENDATION.RECOMMENDATION_SPEED_TEST_TITLE')}</div>
                        <div className="hp-pagespeed-header-url">{report.Website}</div>
                    </div>
                    }
                    {report &&
                    <div className="hp-pagespeed-tabs">
                        <div className="tab-wrap">
                            <div className={`hp-pagespeed-tab ${selectedDevice === 'mobile' ? 'tab-selected' : ''}`} onClick={this.selectDevice.bind(this, 'mobile')}>
                            <MDBIcon icon="mobile-alt" />
                                {t('PDF.PDF_MOBILE')}
                            </div>
                            <div className={`hp-pagespeed-tab ${selectedDevice === 'desktop' ? 'tab-selected' : ''} `} onClick={this.selectDevice.bind(this, 'desktop')}>
                                <MDBIcon icon="desktop" />
                                {t('PDF.PDF_DESKTOP')}
                            </div>
                        </div>
                    </div>
                    }
                    {report && selectedDeviceData &&
                        <PageSpeedResult report={report} selectedDeviceData={selectedDeviceData} />
                    }
                    {report && 
                        <div className="hp-pagespeed-see-report">
                            <a href={`https://developers.google.com/speed/pagespeed/insights/?url=${report.Website}`} target="_blank">
                                To read the full speed test report
                                <MDBIcon icon="arrow-circle-right"  size='2x'/>
                            </a>
                        </div>
                    }
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageSpeed));