import React from "react";

import { connect } from "react-redux";
import { MDBIcon } from "mdbreact";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { Document, Page, pdfjs } from "react-pdf";
import { withTranslation } from "react-i18next";

import Iframe from "react-iframe";

import gobal_active from "../../assets/images/report/global.svg";
import employee from "../../assets/images/report/employee.svg";
import solidarity from "../../assets/images/report/solidarity.svg";
import transaction from "../../assets/images/report/transaction.svg";

import gobal_disabled from "../../assets/images/report/global_disabled.svg";
import employee_disabled from "../../assets/images/report/employee_disabled.svg";
import solidarity_disabled from "../../assets/images/report/solidarity_disabled.svg";
import transaction_disabled from "../../assets/images/report/transaction_disabled.svg";
import filter from "../../assets/icons/filter.svg";

import test_video from "../../assets/videos/test.mp4";
import test_audio from "../../assets/videos/audio.ogv";

import {
  getDetailedReport,
  getToolkitSection,
} from "../../reducers/toolkitReducer";
import { loadToolkit, loadSectionDetails } from "../../actions/toolkitAction";
import { sendPageViewGTM } from "../../utils/util";
import axios from "axios";
import { API_BASE_URL } from "../../config/api-config";

import "./digitaltoolkit.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    toolkit: getDetailedReport(state),
    section: getToolkitSection(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadToolkit: (id) => dispatch(loadToolkit(id)),
    loadSectionDetails: () => dispatch(loadSectionDetails()),
  };
};

class DigitalToolKitComponent extends React.Component {
  state = {
    isLoading: true,
    isModalOpen: false,
    selectedSection: null,
    selectedSubSection: null,
    selectedUrl: "",
    selectedFilter: null,
    iframeCode: null,
    contenType: null,
    videoName: null,
    videoLink: null,
    imgObj: {
      Reach: {
        active: gobal_active,
        disabled: gobal_disabled,
      },
      Engage: {
        active: employee,
        disabled: employee_disabled,
      },
      Transact: {
        active: transaction,
        disabled: transaction_disabled,
      },
      Retain: {
        active: solidarity,
        disabled: solidarity_disabled,
      },
    },
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.loadSectionDetails();
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const { section } = this.props;
    let selectedSubSection =
      section &&
      section.sections &&
      section.sections.length > 0 &&
      section.sections[0].SubSections
        ? section.sections[0].SubSections[0]
        : null;
    let selectedSection =
      section && section.sections && section.sections.length > 0
        ? section.sections[0]
        : null;
    this.setState({
      selectedSubSection: selectedSubSection,
      selectedSection: selectedSection,
    });
    setTimeout(this.setLoader, 3000);
  }

  setLoader = () => {
    if(this.props.section.sections){
    const sec = this.props.section.sections[0];
    this.setState({
      isLoading: false,
      selectedSection: sec,
      selectedSubSection:
        sec && sec.SubSections && sec.SubSections.length > 0
          ? sec.SubSections[0]
          : null,
    });
  }
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  applyFilter = (e) => {
    this.props.loadToolkit(e.target.value);
    let selectedFilter;
    if (
      this.state.selectedSection &&
      this.state.selectedSection.SubSections &&
      this.state.selectedSection.SubSections.length > 0
    ) {
      this.state.selectedSection.SubSections.map((sec, index) => {
        if (sec.id === e.target.value) {
          selectedFilter = sec;
        }
      });
    }
    if (selectedFilter) {
      this.setState({
        selectedSubSection: selectedFilter,
      });
    }
  };

  loadSectionData = (sec) => {
    this.setState({
      selectedSection: sec,
      selectedSubSection:
        sec && sec.SubSections && sec.SubSections.length > 0
          ? sec.SubSections[0]
          : null,
    });

    if (
      sec &&
      sec.SubSections &&
      sec.SubSections.length > 0 &&
      sec.SubSections[0]
    ) {
      this.props.loadToolkit(sec.SubSections[0].id);
    }
  };

  markToolKitVisit = () => {
    try {
      let ppid = localStorage.getItem("ppid");
      if (ppid) {
        axios
          .get(`${API_BASE_URL}/partners/is-toolkit-visited?ppid=${ppid}`)
          .then((res) => {
            if (res) {
              console.log("Toolkit accessed");
            }
          });
      }
    } catch (e) {}
  };

  sendGTMdata = (kit, contenType) => {
    this.markToolKitVisit();
    //GTM
    try {
      if (window && window.dataLayer) {
        let eventName = "";
        if (contenType === "Url") {
          eventName = "Open Link";
        } else if (contenType === "Zip") {
          eventName = "Download File";
        } else {
          eventName = "Open Document";
        }
        const { section } = this.props;
        let selectedSubSection =
          section &&
          section.sections &&
          section.sections.length > 0 &&
          section.sections[0].SubSections
            ? section.sections[0].SubSections[0]
            : null;
        let selectedSection =
          section && section.sections && section.sections.length > 0
            ? section.sections[0]
            : null;

        let toolkit = "";
        let service = "";
        if (
          !this.state.selectedSection ||
          this.state.selectedSection === null
        ) {
          toolkit = selectedSection ? selectedSection.Name : "";
        } else {
          toolkit = this.state.selectedSection
            ? this.state.selectedSection.Name
            : "";
        }

        if (
          !this.state.selectedSubSection ||
          this.state.selectedSubSection === null
        ) {
          service = selectedSubSection ? selectedSubSection.Name : "";
        } else {
          service = this.state.selectedSubSection
            ? this.state.selectedSubSection.Name
            : "";
        }

        window.dataLayer.push({
          event: eventName,
          Title: kit.Title ? kit.Title : null,
          Toolkit: toolkit, //Reach / Engage / Transact / Retain
          Service: service, // Social  / Paid / SEO / Tech Stack
          Tookit_type:"Digital",
          Asset_Source: kit.AssetSource ? kit.AssetSource : "",
        });
      }
    } catch (e) {}
  };

  loadDocument = (kit, contenType, iframeCode) => {
    this.setState({
      selectedUrl: kit.Url,
      iframeCode: iframeCode,
      contenType: contenType,
      videoLink: kit.VideoLink ? kit.VideoLink : null,
      videoName: kit.VideoLabel ? kit.VideoLabel : null,
    });

    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });

    this.markToolKitVisit();

    //GTM
    try {
      if (window && window.dataLayer) {
        let eventName = "";
        if (contenType === "Video") {
          eventName = "ViewVideo";
        } else if (
          contenType !== "Url" &&
          contenType !== "Zip" &&
          contenType !== "Video"
        ) {
          eventName = "Open Document";
        }
        window.dataLayer.push({
          event: eventName,
          Title: kit.Title ? kit.Title : null,
          Toolkit: this.state.selectedSection
            ? this.state.selectedSection.Name
            : "", //Reach / Engage / Transact / Retain
          Service: this.state.selectedSubSection
            ? this.state.selectedSubSection.Name
            : "", // Social  / Paid / SEO / Tech Stack
          Asset_Source: kit.AssetSource ? kit.AssetSource : "",
          Tookit_type:"Digital",
        });
      }
    } catch (e) {}
  };

  openBP = async () => {
    //this.props.history.push(`/bp/home`);
    let ppid = await localStorage.getItem("ppid");
    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: "BannerClick",
        Title: "Digital Business Planner",
        ButtonTitle: "Let’s start",
        userId: ppid,
      });
    }
    window.location = "/bp/home";
  };

  render() {
    const { toolkit, section } = this.props;
    const { imgObj, selectedFilter, videoName, videoLink, isLoading } =
      this.state;
    const { t } = this.props;
    let selectedSection;
    let selectedSubSection;
    let docClass = "";
    let iFrameCode = null;
    let contenType = null;

    if (section && section.sections && section.sections.length > 0) {
      selectedSection =
        this.state.selectedSection !== null
          ? this.state.selectedSection
          : section.sections[0];
    }

    if (section && section.sections && section.sections.length > 0) {
      selectedSubSection =
        this.state.selectedSubSection !== null
          ? this.state.selectedSubSection
          : section.sections[0].SubSections[0];
    }

    return (
      <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100 toolkit-component-wrap">
        <div className="d-flex hp-layout hp-home">
          <div className="hp-digitalkit-wrap">
            <div className="hp-digitalkit-title d-flex">
              {t("TOOLKIT.TOOLKIT_TITLE")}
            </div>
            <div className="hp-digitalkit-desc d-flex">
              {t("TOOLKIT.TOOLKIT_DESC")}
            </div>

            {/* BP banner */}
            {/* <div className="bp-banner">
                            <div className="hp-bp-banner-icon"></div>
                            <div className="hp-bp-banner-title">Digital Business Planner</div>
                            <div className="hp-bp-banner-desc">Need help planning budgets for digitising your business use this simple planner to help budget, plan spend and potential return on investment basis our Reach, Engage, Transact and Retain framework.</div>
                            <div className="hp-bp-banner-time"> <MDBIcon far icon="clock" /> <span>14 mins activity</span></div>
                            <div className="hp-bp-banner-start" onClick={this.openBP.bind(this)}>Let’s start <MDBIcon icon="arrow-circle-right" /></div>
                        </div> */}

            {/*HP digitalkit TABS*/}

            {isLoading ? (
              <div className="w-100 d-flex align-items-center justify-content-center my-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="hp-digitalkit-tabs d-flex">
                {section &&
                  section?.sections &&
                  section?.sections?.length > 0 &&
                  section?.sections?.map((sec, index) => (
                    <div
                      onClick={this.loadSectionData.bind(this, sec)}
                      className={`hp-digitalkit-tab d-flex flex-column ${
                        sec?.Name === selectedSection?.Name
                          ? "hp-digitalkit-tab-active"
                          : ""
                      }`}
                      key={index}
                    >
                      <img
                        src={
                          sec?.Name === selectedSection?.Name
                            ? imgObj[sec?.Name]?.active
                            : imgObj[sec?.Name]?.disabled
                        }
                        className="d-flex img-fluid"
                      />
                      <div
                        className={`d-flex hp-digitalkit-tab-text ${
                          sec?.Name === selectedSection?.Name
                            ? "hp-digitalkit-tab-text-active"
                            : ""
                        }`}
                      >
                        {sec?.Name}
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className="toolkit-filter-msg">
              <p>{t("TOOLKIT.TOOKIT_FILTER_MSG_1")}</p>
              {selectedSection && selectedSection.Name === "Reach" && (
                <p>{t("TOOLKIT.TOOKIT_REACH")}</p>
              )}
              {selectedSection && selectedSection.Name === "Engage" && (
                <p>{t("TOOLKIT.TOOLKIT_ENGAGE")}</p>
              )}
              {selectedSection && selectedSection.Name === "Transact" && (
                <p>{t("TOOLKIT.TOOLKIT_TRANSACT")}</p>
              )}
              {selectedSection && selectedSection.Name === "Retain" && (
                <p>{t("TOOLKIT.TOOLKIT_RETAIN")}</p>
              )}
            </div>
            <div className="hp-digitalkit-tab-content d-flex">
              <div className="hp-digitalkit-filter-wrap d-flex">
                <select
                  className="toolkit-filter"
                  onChange={this.applyFilter.bind(this)}
                >
                  {selectedSection &&
                    selectedSection.SubSections &&
                    selectedSection.SubSections.length > 0 &&
                    selectedSection.SubSections.map((sub, index) => (
                      <option value={sub._id} key={index}>
                        {sub.Name}
                      </option>
                    ))}
                </select>
                {/* <MDBIcon icon="filter"/> */}
                <img src={filter} alt="filter" />
              </div>
              {/* Toolkit PPT*/}

              <div
                className="hp-digitalkit-content-wrap d-flex col-12"
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {toolkit &&
                  toolkit.length > 0 &&
                  toolkit.map((kit, i) => (
                    <div
                      className="hp-digitalkit-content-tile col-md-3 col-6"
                      key={i}
                    >
                      {(() => {
                        let docType = kit.Type;

                        if (docType === "Pdf") {
                          docClass = "img-pdf hp-digitalkit-content-img-pdf";
                          contenType = kit.Type;
                        } else if (docType === "Word") {
                          docClass = "img-file hp-digitalkit-content-img-file";
                          iFrameCode = kit.Code;
                          contenType = kit.Type;
                        } else if (docType === "Presentation") {
                          docClass = "img-ppt hp-digitalkit-content-img";
                          iFrameCode = kit.Code;
                          contenType = kit.Type;
                        } else if (docType === "Video") {
                          docClass =
                            "img-video hp-digitalkit-content-img-video";
                          contenType = kit.Type;
                        } else if (docType === "Audio") {
                          docClass = "img-audio hp-digitalkit-content-img";
                          contenType = kit.Type;
                        } else if (docType === "Zip") {
                          docClass = "img-file hp-digitalkit-content-img-file";
                          contenType = kit.Type;
                        } else {
                          docClass = "img-link hp-digitalkit-content-img";
                          contenType = kit.Type;
                        }
                      })()}
                      <div className={`${docClass}`}></div>
                      <div className="hp-digitalkit-content-title">
                        {kit.Title}
                      </div>
                      <div className="hp-digitalkit-content-desc">
                        {kit.Description}
                      </div>
                      {contenType === "Url" && (
                        <a
                          className="hp-digitalkit-content-link"
                          href={kit.Url}
                          target="_blank"
                          onClick={this.sendGTMdata.bind(this, kit, contenType)}
                          rel="noreferrer"
                        >
                          {t("TOOLKIT.TOOLKIT_OPEN_LINK")}
                        </a>
                      )}
                      {contenType === "Zip" && (
                        <a
                          className="hp-digitalkit-content-link"
                          href={kit.Url}
                          target="_blank"
                          onClick={this.sendGTMdata.bind(this, kit, contenType)}
                          rel="noreferrer"
                        >
                          {t("TOOLKIT.TOOLKIT_DOWNLOAD_FILE")}
                        </a>
                      )}

                      {contenType === "Video" && (
                        <div
                          className="hp-digitalkit-content-link"
                          onClick={this.loadDocument.bind(
                            this,
                            kit,
                            contenType,
                            iFrameCode
                          )}
                        >
                          {t("TOOLKIT.TOOLKIT_VIEW_VIDEO")}
                        </div>
                      )}

                      {contenType !== "Url" &&
                        contenType !== "Zip" &&
                        contenType !== "Video" && (
                          <div
                            className="hp-digitalkit-content-link"
                            onClick={this.loadDocument.bind(
                              this,
                              kit,
                              contenType,
                              iFrameCode
                            )}
                          >
                            {t("TOOLKIT.TOOLKIT_OPEN_DOCUMENT")}
                          </div>
                        )}
                    </div>
                  ))}
              </div>
            </div>

            {section && section?.sections && section?.sections?.length > 0 && (
              <div className="w-100 d-flex justify-content-end pt-5">
                <div className="floating-btn">
                  {section?.sections?.map((sec, index) => (
                    <div
                      onClick={this.loadSectionData.bind(this, sec)}
                      className={`hp-digitalkit-tab d-flex flex-column align-items-center mx-1 ${
                        sec?.Name === selectedSection?.Name
                          ? "hp-digitalkit-tab-active"
                          : ""
                      }`}
                      key={index}
                    >
                      <img
                        src={
                          sec?.Name === selectedSection?.Name
                            ? imgObj[sec?.Name]?.active
                            : imgObj[sec?.Name]?.disabled
                        }
                        className="d-flex img-fluid"
                      />
                      <div
                        className={`d-flex hp-digitalkit-tab-text ${
                          sec?.Name === selectedSection?.Name
                            ? "hp-digitalkit-tab-text-active"
                            : ""
                        }`}
                      >
                        {sec?.Name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Modal Window */}
          <MDBModal
            isOpen={this.state.isModalOpen}
            toggle={this.toggleModal}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {this.state.iframeCode !== null &&
              this.state.contenType !== "Audio" && (
                <MDBModalBody
                  style={{
                    backgroundColor: "#fff",
                    width: "768px",
                    height: "800px",
                  }}
                  className="d-none d-md-block modal-iframe-content"
                  dangerouslySetInnerHTML={{ __html: this.state.iframeCode }}
                ></MDBModalBody>
              )}
            {this.state.iframeCode !== null &&
              this.state.contenType !== "Audio" && (
                <MDBModalBody
                  style={{
                    backgroundColor: "#fff",
                    width: "350px",
                    height: "500px",
                  }}
                  className="d-sm-block d-md-none modal-iframe-content"
                  dangerouslySetInnerHTML={{ __html: this.state.iframeCode }}
                ></MDBModalBody>
              )}

            {this.state.iframeCode === null &&
              this.state.contenType !== "Audio" && (
                <MDBModalBody
                  style={{
                    backgroundColor: "#fff",
                    width: "768px",
                    height: "800px",
                  }}
                  className="d-none d-md-block"
                >
                  <iframe
                    src={this.state.selectedUrl}
                    width="740px"
                    height="740px"
                  />
                  {this.state.contenType === "Video" && (
                    <a
                      href={videoLink}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center toolkit-video-link"
                      rel="noreferrer"
                    >
                      {videoName}
                    </a>
                  )}
                </MDBModalBody>
              )}

            {this.state.iframeCode === null &&
              this.state.contenType !== "Audio" && (
                <MDBModalBody
                  style={{
                    backgroundColor: "#fff",
                    width: "350px",
                    height: "500px",
                  }}
                  className="d-sm-block d-md-none"
                >
                  <iframe
                    src={this.state.selectedUrl}
                    width="320px"
                    height="440px"
                  />
                  {this.state.contenType === "Video" && (
                    <a
                      href={videoLink}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center toolkit-video-link"
                      rel="noreferrer"
                    >
                      {videoName}
                    </a>
                  )}
                </MDBModalBody>
              )}

            {this.state.contenType === "Audio" && (
              <MDBModalBody
                style={{
                  backgroundColor: "#fff",
                  width: "350px",
                  height: "500px",
                }}
                className=""
              >
                <audio controls style={{ width: "100%", height: "100%" }}>
                  <source src={this.state.selectedUrl} type="audio/mpeg" />
                  Your browser does not support the audio tag.
                </audio>
              </MDBModalBody>
            )}
          </MDBModal>
        </div>
      </div>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DigitalToolKitComponent)
);
