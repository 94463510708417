
import React from "react";
import { MDBIcon } from "mdbreact";
import ReportIcon from "../../../assets/icons/report.svg";
import SalesFunnelIcon from "../../../assets/icons/sales-funnel.svg";
import DownloadPdfIcon from "../../../assets/icons/feather-download.svg";
import RightArrowIcon from "../../../assets/icons/right-arrow.svg";

import {sendPageViewGTM} from '../../../utils/util';

/**
 * Style import
 */
import './businessplannersummary.scss';
import Header from "../../../components/BusinessPlanner/WizardHeader/Header";

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


//redux
import {calculateSummary} from '../../../actions/bpAction';
import {getSummary, getCompletePlanner, getBPMasterData, getBPReachData, getReachRecommendations, getReachTotal, getPageLoadStatus} from '../../../reducers/bpReducer';

const mapStateToProps = (state) => {
    return {
        bpSummary: getSummary(state),
        bpLoading: getPageLoadStatus(state)
    }
  }
  
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        calculateSummary: (ppid) => dispatch(calculateSummary(ppid))
    }
}

class BusinessPlannerSummary extends React.Component {

    state = {

    }

    componentDidMount = () => {
        let ppid = localStorage.getItem('ppid');
        if(ppid) {
            this.props.calculateSummary(ppid);
        }

         //GTM PageLoad

         try {
            let pagePath = window.location ? window.location.pathname : "";
            if (window && window.dataLayer) {
                sendPageViewGTM("Business Planner: Summary Page", pagePath);
            }
        } catch (e) {}
    }

    render() {

        const {bpSummary, bpLoading} = this.props;
        return (
            <div className="business-planner-summary-page">
                <div className="hp-nav w-100">
                    <Header />
                </div>
                {bpLoading &&
                    <div className="d-flex justify-content-center align-items-center loading-wrap-full">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {bpSummary &&
                    <div className="container">
                        <div className="bp-smry-investment">
                            <div className="bp-smry-invst-icon">
                                <img src={ReportIcon} />
                            </div>
                            <div className="bp-smry-invst-ttls">
                                <p className="bp-smry-invst-ttl">SUMMARY</p>
                                <p className="bp-smry-invst-ttl right">QUARTERLY INVESTMENT</p>
                            </div>
                            <div className="bp-smry-invst-data">
                                <div className="bp-smry-invst-data-left">
                                    <div className="bp-smry-invst-data-items">
                                        <div className="bp-smry-invst-data-item">
                                            <p className="bp-smry-invst-data-itm-ttl">First year budget</p>
                                            <p className="bp-smry-invst-data-itm-desc">
                                                {bpSummary.firstYearBudget ? bpSummary.CurrencySymbol+''+Math.round((bpSummary.firstYearBudget)).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                            </p>
                                        </div>
                                        <div className="bp-smry-invst-data-item">
                                            <p className="bp-smry-invst-data-itm-ttl">Estimated ROI</p>
                                            <p className="bp-smry-invst-data-itm-desc">
                                                {bpSummary.estimatedROI ? Math.round(bpSummary.estimatedROI)+'%' :'NA'}
                                            </p>
                                        </div>
                                        <div className="bp-smry-invst-data-item">
                                            <p className="bp-smry-invst-data-itm-ttl">Estimated first year revenue</p>
                                            <p className="bp-smry-invst-data-itm-desc">
                                                {bpSummary.estimatedFirstYearRevenue ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.estimatedFirstYearRevenue).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bp-smry-invst-data-right">
                                    <div className="bp-smry-invst-data-items">
                                        <div className="bp-smry-invst-data-item">
                                            <p className="bp-smry-invst-data-itm-ttl">1<sup>st</sup></p>
                                            <p className="bp-smry-invst-data-itm-desc">
                                                {bpSummary.investmentQ1 ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.investmentQ1).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                            </p>
                                        </div>
                                        <div className="bp-smry-invst-data-item">
                                            <p className="bp-smry-invst-data-itm-ttl">2<sup>nd</sup></p>
                                            <p className="bp-smry-invst-data-itm-desc">
                                                {bpSummary.investmentQ2 ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.investmentQ2).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                            </p>
                                        </div>
                                        <div className="bp-smry-invst-data-item">
                                            <p className="bp-smry-invst-data-itm-ttl">3<sup>rd</sup></p>
                                            <p className="bp-smry-invst-data-itm-desc">
                                                {bpSummary.investmentQ3 ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.investmentQ3).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                            </p>
                                        </div>
                                        <div className="bp-smry-invst-data-item">
                                            <p className="bp-smry-invst-data-itm-ttl">4<sup>th</sup></p>
                                            <p className="bp-smry-invst-data-itm-desc">
                                                {bpSummary.investmentQ4 ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.investmentQ4).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bp-smry-breakeven">
                            <div className="bp-smry-brkevn-icon">
                                <img src={SalesFunnelIcon} />
                                <p>ROI AND BREAKEVEN POINT</p>
                            </div>
                            <div className="bp-smry-brkevn-data">
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key font-regular">Main Channels</p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key">Reach</p>
                                    <p className="bp-smry-brkevn-itm-value">
                                        {bpSummary.reach ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.reach).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                    </p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key">Engage</p>
                                    <p className="bp-smry-brkevn-itm-value">
                                        {bpSummary.engage ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.engage).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                    </p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key">Transact</p>
                                    <p className="bp-smry-brkevn-itm-value">
                                        {bpSummary.transact ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.transact).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                    </p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key">Retain</p>
                                    <p className="bp-smry-brkevn-itm-value">
                                        {bpSummary.retain ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.retain).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                    </p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm bgnd">
                                    <p className="bp-smry-brkevn-itm-key font-regular">Total cost year 1 *</p>
                                    <p className="bp-smry-brkevn-itm-value font-regular p-clr">
                                        {bpSummary.totalCostYear1 ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.totalCostYear1).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                    </p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key-ico font-regular">Predicted average monthly revenue</p>
                                    <p className="bp-smry-brkevn-itm-value font-regular p-clr">
                                        {bpSummary.predictedAverageMonthlyRevenue ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.predictedAverageMonthlyRevenue).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                    </p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key-ico font-regular">Predicted annual revenue year 1 *</p>
                                    <p className="bp-smry-brkevn-itm-value font-regular p-clr">
                                        {bpSummary.predictedAnnualRevenueYear1 ? bpSummary.CurrencySymbol+''+Math.round(bpSummary.predictedAnnualRevenueYear1).toLocaleString()+''+bpSummary.CurrencySuffix:'NA'}
                                    </p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key-ico font-regular">Net ROI year 1</p>
                                    <p className="bp-smry-brkevn-itm-value font-regular p-clr">
                                        {bpSummary.netROIYear1 ? Math.round(bpSummary.netROIYear1)+'%':'NA'}
                                    </p>
                                </div>
                                <div className="bp-smry-brkevn-dt-itm">
                                    <p className="bp-smry-brkevn-itm-key-ico font-regular">Predicted breakeven point *</p>
                                    <p className="bp-smry-brkevn-itm-value font-regular p-clr">
                                        {bpSummary.predictedBreakEvenPoint ? bpSummary.predictedBreakEvenPoint :'NA'}
                                    </p>
                                </div>
                            </div>
                            <div className="bp-reach-budget-recommendation-expanded-disclaimer">
                                <p className="bp-reach-budget-recommendation-expanded-disclaimer-title">
                                    Disclaimer
                                </p>
                                <p className="bp-reach-budget-recommendation-expanded-disclaimer-description">
                                The ROI numbers displayed in the calculation are indicative and are provided for your information purposes only, as a free-of-charge service. Your actual realized ROI may differ significantly from the ROI numbers shown in the calculation, as the numbers used in preparation of the calculation are based on various factors (e.g. Conversion Rate percentages, the Average Sales Value, the Cost per Click, the price and quality of the media/technologies purchased) and are based on inputs that you provided in the tool. You should make your own independent judgment when using the tool and employ them as indicative information to evaluate cost and performance, as actual numbers will vary. 
                                </p>
                            </div>
                        </div>
                        
                        <div className="bp-smry-download">
                            <p className="dwn-info">Download summary and full planner</p>
                            <div className="dwn-btn">
                                <a href={bpSummary.BPPdfReportUrl} target="_blank"><div className="download-icon_wrap"><MDBIcon icon="download" size="1x" /></div><div className="download-text">Download PDF</div></a>
                            </div>
                        </div>
                        <div className="bp-smry-send">
                            <p className="snd-info">Send the report PDF to Industry experts and Achieve your sales target</p>
                            <div className="snd-btn">
                                <a href="/edc" target="_blank"><p>EDCs Available for you</p></a>
                                <img src={RightArrowIcon} alt="" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BusinessPlannerSummary));