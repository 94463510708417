import axios from "axios";
import {API_BASE_URL} from '../config/api-config';

export const FETCH_PSPEED_REPORT = 'FETCH_PSPEED_REPORT';
export const FETCH_PSPEED_REPORT_SUCCESS = 'FETCH_PSPEED_REPORT_SUCCESS';
export const FETCH_PSPEED_REPORT_ERROR = 'FETCH_PSPEED_REPORT_ERROR';

export const FETCH_PSPEED_FULL_REPORT = 'FETCH_PSPEED_FULL_REPORT';
export const FETCH_PSPEED_FULL_REPORT_SUCCESS = 'FETCH_PSPEED_FULL_REPORT_SUCCESS';


export const fetchReport = () => {
    return {
        type: FETCH_PSPEED_REPORT
    }
}

export const fetchReportSuccess = (data) => {
    return {
        type: FETCH_PSPEED_REPORT_SUCCESS,
        payload: data
    }
}


export const loadPSpeedReportByURL = (url) => {
    return dispatch => {
        dispatch(fetchReport());
        axios.get(`${API_BASE_URL}/partners/pagespeed?url=${url}`)
        .then(res => {
            if(res.data) {
                let data = res.data;
                dispatch(fetchReportSuccess(data));
            }
        })
    };
}

export const fetchFullReportSuccess = (data) => {
    return {
        type: FETCH_PSPEED_FULL_REPORT_SUCCESS,
        payload: data
    }
}


export const loadPageSpeedReport = (ppid) => {
    return dispatch => {
        dispatch(fetchReport());
        axios.get(`${API_BASE_URL}/partners/pagespeed?ppid=${ppid}`)
        .then(res => {
            if(res) {
                let data = res.data;
                dispatch(fetchFullReportSuccess(data));
            }
        })
    };
}