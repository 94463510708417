import React from 'react';
import "./gspeedroundchart.scss";

class GSpeedRoundChart extends React.Component {

    state = {
        donutval: 0
    };

    render() {

        const {value, isStart, width, stroke} = this.props;

        let cSize = 173, cStroke = 8;

        if(width) {
          cSize = width;
        }
        
        if(stroke) {
          cStroke = stroke;
        }

        let wrapClass = '';
        if(value < 50) {
          wrapClass = "hp-report-graph-wrap-red";
        } else if(value >=50 && value <90) {
          wrapClass = "hp-report-graph-wrap-yellow";
        } else if(value >= 90) {
          wrapClass = "hp-report-graph-wrap-green";
        }

        return(
            <div className="hp-report-status__graph">
                <div className={`hp-report-graph-wrap ${wrapClass}`}>
                    <DonutChart value={value} size={cSize} valuelabel="Completed" strokewidth={cStroke} isStart={isStart}/>
                </div>
            </div>
        )
    }
}


class DonutChart extends React.Component {

    render() {

      const {value} = this.props;
      let strokeClass = '';
      let textClass = '';
      if(value < 50) {
        strokeClass = "donutchart-indicator-red";
        textClass = 'donutchart-text-red';
      } else if(value >=50 && value <90) {
        strokeClass = "donutchart-indicator-yellow";
        textClass = 'donutchart-text-yellow';
      } else if(value >= 90) {
        strokeClass = "donutchart-indicator-green";
        textClass = 'donutchart-text-green';
      }
  
      const halfsize = (this.props.size * 0.5);
      const radius = halfsize - (this.props.strokewidth * 0.5);
      const circumference = 2 * Math.PI * radius;
      const strokeval = ((this.props.value * circumference) / 100);
      const dashval = (strokeval + ' ' + circumference);
  
      const trackstyle = {strokeWidth: this.props.strokewidth};
      const indicatorstyle = {strokeWidth: this.props.strokewidth, strokeDasharray: dashval}
      const rotateval = 'rotate(-90 '+halfsize+','+halfsize+')';
  
      return (
        <svg width={this.props.size} height={this.props.size} className="donutchart">
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track"/>
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className={`donutchart-indicator ${strokeClass}`}/>

          {!this.props.isStart && 
            <text className={`donutchart-text d-none d-md-block ${textClass}`} x={halfsize} y={halfsize * 1.15} style={{textAnchor:'middle'}} >
                <tspan className="donutchart-text-val">{this.props.value}</tspan>
            </text>
          }

          {!this.props.isStart && 
            <text className={`donutchart-text d-sm-block d-md-none ${textClass}`} x={halfsize} y={halfsize * 1.15} style={{textAnchor:'middle'}} >
                <tspan className="donutchart-text-val">{this.props.value}</tspan>
            </text>
          }

          {this.props.isStart && 
            <text className="donutchart-text" x={halfsize} y={halfsize * 1.25} style={{textAnchor:'middle'}} >
                <tspan className="donutchart-start-text">Start</tspan>
            </text>
          }
        </svg>
      );
    }
  }

export default GSpeedRoundChart;