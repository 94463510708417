import React from 'react';
import { MDBIcon } from 'mdbreact';

import lanConfig from "../../config/languages.json";

import "./header.scss";
import logo from '../../assets/icons/HP_Blue_RGB_150_MN.png';
import { Link } from 'react-router-dom';
import ProgressBar from "../../components/ProgressBar";

import { withTranslation } from 'react-i18next';

class Header extends React.Component {

    state = {
        isMenuOpen: false,
        ppid:''
    }

    componentDidMount() {
        let ppid = localStorage.getItem('ppid');
        this.setState({
            ppid: ppid
        });
    }

    openMenu = () => {
        let menuAction = !this.state.isMenuOpen;
        this.setState({
            isMenuOpen: menuAction
        });
    }

    updateLanguage = e => {
        localStorage.setItem('lan', e.target.value);
        this.props.i18n.changeLanguage(e.target.value);
    }
    
    render() {
        let {isHome} = this.props;
        const {isMenuOpen, ppid} = this.state;
        const { t } = this.props;
        const selectedlan = localStorage.getItem('lan') || 'en';

        const hasAssessmentSubmitted = localStorage.getItem('hasSubmited') ? localStorage.getItem('hasSubmited') : false;
        
        return (
            <div className="hp-nav w-100">
                <div className="hp-navbar d-flex justify-content-center">
                    <div className="hp-logo-left">
                        <span><img src={logo} className="hp-logo"/></span>
                        <span className="hp-logo-text">{t('COMMON.COMMON_SURVEY_TEXT')}</span>
                    </div>
                    <div className="hp-logo-right">
                        <span className="nav-links d-none d-md-block">
                            {isHome ?<Link to={`/dashboard/${ppid}`} >{t('COMMON.COMMON_GO_TO_DASHBOARD')}</Link>:<Link to={`/${ppid}/true`} >Assessment guide</Link>}
                        </span>
                       {/* <span className="nav-links d-none d-md-block">
                            Select language
                            <i className="icon-wrap">
                                <MDBIcon icon="arrow-right"/>
                            </i>
                         </span>*/}
                        <span className="nav-links">
                            <Link to={`/faqs`}>
                                {t('COMMON.COMMON_HELP')}
                                <i className="icon-wrap">
                                    <MDBIcon icon="question-circle" />
                                </i>
                            </Link>
                        </span>
                        <span className="nav-links nav-hamburger" style={{'marginRight': '0'}}>
                            {t('COMMON.COMMON_MENU')} 
                            <i className="icon-wrap-hamburger" onClick={this.openMenu}>
                                <MDBIcon icon="bars" size="1x" style={{fontSize:'25px'}} />
                            </i>
                            <div className="hp-nav-list" style={{'display': isMenuOpen ? 'flex' : 'none'}}>
                    
                                    <div className="hp-nav-close-btn" onClick={this.openMenu}>
                                        <MDBIcon icon="times" size="1x" style={{fontSize:'25px'}}/>
                                    </div>
                                    
                                    <Link to={`/${ppid}/true`}>
                                    <div className="hp-nav-listitem">
                                        <div className="d-flex hp-nav-listitem-left">
                                            <span>{t('COMMON.COMMON_HAMBURGER_ASSESSMENT_GUIDE')}</span>
                                        </div>
                                        <div className="d-flex hp-nav-listitem-right">
                                            <MDBIcon icon="arrow-right"/>
                                        </div>
                                    </div>
                                    </Link>
                                    <Link to={(localStorage.getItem('reAssesment')===true && localStorage.getItem('reAssessmentOption')!== null) ? `/dashboard/${ppid}`:`/dashboard2022/${ppid}`}>
                                    <div className="hp-nav-listitem">
                                        <div className="d-flex hp-nav-listitem-left">
                                            <span>{t('COMMON.COMMON_HAMBURGER_DASHBOARD')}</span>
                                            
                                        </div>
                                        <div className="d-flex hp-nav-listitem-right">
                                            <MDBIcon icon="arrow-right"/>
                                        </div>
                                    </div>
                                    </Link>
                                    {selectedlan && selectedlan === 'en' &&
                                        <Link to={`/report/${ppid}`}>
                                            <div className="hp-nav-listitem">
                                                <div className="d-flex hp-nav-listitem-left">
                                                    <span>{t('COMMON.COMMON_HAMBURGER_SCORE')}</span>
                                                </div>
                                                <div className="d-flex hp-nav-listitem-right">
                                                    <MDBIcon icon="arrow-right"/>
                                                </div>
                                            </div>
                                        </Link>
                                    }
                                    
                                    {hasAssessmentSubmitted && hasAssessmentSubmitted === 'true' && selectedlan && selectedlan === 'en' &&
                                        <Link to={`/toolkit`}>
                                            <div className="hp-nav-listitem">
                                                <div className="d-flex hp-nav-listitem-left hp-menu-with-desc">
                                                    <div>
                                                    <span>{t('COMMON.COMMON_HAMBURGER_TOOLKIT')}</span>
                                                        
                                                    </div>
                                                    
                                                    <div className="hp-menu-desc">
                                                    {t('COMMON.COMMON_HAMBURGER_TOOLKIT_DESC')}
                                                    </div>
                                                </div>
                                                <div className="d-flex hp-nav-listitem-right">
                                                    <MDBIcon icon="arrow-right"/>
                                                </div>
                                            </div>
                                        </Link>
                                    }
                                    
                                   
                                    {JSON.parse(localStorage?.getItem('userInfo'))?.showEDC && hasAssessmentSubmitted && hasAssessmentSubmitted === 'true' && selectedlan && selectedlan === 'en' &&
                                        <Link  to={`/edc`}>
                                            <div className="hp-nav-listitem">
                                                <div className="d-flex hp-nav-listitem-left hp-menu-with-desc">
                                                    <div>
                                                        <span>{t('COMMON.COMMON_HAMBURGER_EDC')}</span>
                                                    </div>
                                                    
                                                    <div className="hp-menu-desc">
                                                    {t('COMMON.COMMON_HAMBURGER_EDC_DESC')}
                                                    </div>
                                                </div>
                                                <div className="d-flex hp-nav-listitem-right">
                                                    <MDBIcon icon="arrow-right"/>
                                                </div>
                                            </div>
                                        </Link>
                                    }
                                   
                                    <Link to={`/profile-settings`}>
                                    <div className="hp-nav-listitem">
                                        <div className="d-flex hp-nav-listitem-left">
                                            
                                            <span>{t('COMMON.COMMON_HAMBURGER_PROFILE')}</span>
                                        </div>
                                        <div className="d-flex hp-nav-listitem-right">
                                            <MDBIcon icon="arrow-right"/>
                                        </div>
                                    </div>
                                    </Link>
                                    
                                    {/*
                                        <div className="hp-nav-listitem">
                                        <div className="d-flex hp-nav-listitem-left hp-menu-with-desc">
                                            <div>
                                                Help
                                            </div>
                                            
                                            <div className="hp-menu-desc">
                                            Nullam quis accumsan ante, vitae varius neque. Cras sit amet ligula ipsum. Quisque quis tincidunt turpis, eu scelerisque tellus.
                                            </div>
                                        </div>
                                        <div className="d-flex hp-nav-listitem-right">
                                            <MDBIcon icon="arrow-right"/>
                                        </div>
                                    </div>
                                    */}
                                    



                                    {/*<div className="hp-nav-listitem">
                                        <div className="d-flex hp-nav-listitem-left">
                                            FAQ
                                        </div>
                                        <div className="d-flex hp-nav-listitem-right">
                                            <MDBIcon icon="arrow-right"/>
                                        </div>
                                    </div>*/}
                                
                            </div>
                        </span>
                    </div>
                </div>
                <ProgressBar />
            </div>
        )
    }
}


export default withTranslation()(Header);