import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ReactPlayer from "react-player/youtube";
/**
 * Component imports
 */
import Header from '../../../components/BusinessPlanner/WizardHeader/Header';

/**
 * iconn imports
 */
import TimerIcon from '../../../assets/icons/timer.svg';
import RightArrowIcon from "../../../assets/icons/right-arrow.svg";
import DocumentIcon from "../../../assets/images/report/document-3.svg";
import DigitalBusinessPlannerImage from '../../../assets/images/businessplanner/landing_page/2.png';
import BenefitOfThePlannerHeadlineImage from '../../../assets/images/businessplanner/landing_page/3.png';
import ShareThePlanWithExpertToImplementImage from '../../../assets/images/businessplanner/landing_page/1.png';
import selectTechnologyImg from '../../../assets/images/businessplanner/landing_page/Component 71 – 2.svg';
import allocateBudgetImg from '../../../assets/images/businessplanner/landing_page/Component 71 – 1.svg';
import useCalculatorImg from '../../../assets/images/businessplanner/landing_page/Component 72 – 1.svg';
import fullPlannerImg from '../../../assets/images/businessplanner/landing_page/Component 73 – 1.svg';
// import bp_video from '../../../assets/videos/10_12_DECEMBER_HP.mp4';̦

import { partnerRegistrationRedirect } from "../../../actions/authAction";

import {sendPageViewGTM} from '../../../utils/util';

/**
 * style imports
 */
import './businessplannerlandingpage.scss';

const mapStateToProps = (state) => {
    return {
      
    };
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      partnerRegistrationRedirect: (token, pathName) =>
        dispatch(partnerRegistrationRedirect(token, pathName, ownProps))
    };
  };

class BusinessPlannerLandingPage extends React.Component {

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let { search } = this.props.location;
        let splitAr = search.trim().split("tokenId=");
        let qp = splitAr.length > 0 ? splitAr[1] : null;
        if (qp && qp !== null) {
            this.props.partnerRegistrationRedirect(qp, "bp/home");
        }

        //GTM PageLoad

        try {
            let pagePath = window.location ? window.location.pathname : "";
            if (window && window.dataLayer) {
               sendPageViewGTM("Business Planner Home", pagePath);
            }
        } catch (e) {}
    }

    render() {

        return (
            <div className="business-planner-landing-page">
                <Header />
                <div className="business-planner-lp-container">
                    <div className="content">
                        <div className="item">
                            <div className="item-video video-tab">
                                {/* <video controls
                                    poster={DigitalBusinessPlannerImage}
                                    height="500"
                                    width="500">
                                     <source
                                        src={bp_video}
                                        type="video/mp4"></source>
                                    Sorry, your browser doesn't support embedded videos

                                </video> */}
                                <ReactPlayer
                                 height="500px"
                                 width="500px"  loop={true} 
                                 playing={true}
                                 className="ytb-video"
                                 config={{
                                    youtube: {
                                      playerVars: { showinfo: 0,rel:0,disablekb: 0,modestbranding:1,fs:0 }
                                    }}}
                                url='https://www.youtube.com/embed/YqqvbYE5PMk' />
                                
                            </div>
                            <div className="item-video video-desktop">
                                {/* <video controls
                                    poster={DigitalBusinessPlannerImage}
                                    height="700"
                                    width="800">
                                     <source
                                        src={bp_video}
                                        type="video/mp4"></source>
                                    Sorry, your browser doesn't support embedded videos

                                </video> */}
                                 <ReactPlayer
                                 height="700px"
                                 width="800px"  loop={true} 
                                 playing={true}
                                 className="ytb-video"
                                 config={{
                                    youtube: {
                                      playerVars: { showinfo: 0,rel:0,disablekb: 0,modestbranding:1,fs:0 }
                                    }}}
                                url='https://www.youtube.com/embed/YqqvbYE5PMk' />
                            </div>
                            <div className="item-video video-mobile">
                                {/* <video controls
                                    poster={DigitalBusinessPlannerImage}
                                    height="300"
                                    width="350">
                                     <source
                                        src={bp_video}
                                        type="video/mp4"></source>
                                    Sorry, your browser doesn't support embedded videos

                                </video> */}
                                 <ReactPlayer
                                 className="ytb-video"
                                 playing={true}
                                url='https://www.youtube.com/embed/YqqvbYE5PMk' loop={true} 
                                config={{
                                    youtube: {
                                      playerVars: { showinfo: 0,rel:0,disablekb: 0,modestbranding:1,fs:0 }
                                    }}}/>
                            </div>
                            <div className="item-content">
                                <img className="icon" alt="" src={DocumentIcon} />
                                <p className="content-title">
                                    Digital Business Planner
                                </p>
                                <p className="content-description">
                                    Need help planning budgets for digitising your business use this simple planner to help budget,
                                    plan spend and potential return on investment basis our Reach, Engage, Transact and Retain framework.
                                </p>
                                <div className="time-info">
                                    <img src={TimerIcon} className="stopwatch" />
                                    <span className="time-need">14 Minutes Activity</span>
                                </div>
                                <a className="redirection" href="/bp/tech-selection">Let's Start
                                    <img src={RightArrowIcon} alt="right-arrow" className="right-arrow" />
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-content">
                                <p className="content-title">
                                    Select technologies
                                </p>
                                <p className="content-description">
                                    Create a list of technologies that you already have as well the technologies you wish to invest in in the next 12 months. These will be added to your financial plan.
                                </p>
                                <a className="redirection" href="/bp/tech-selection">Let's Start
                                    <img src={RightArrowIcon} alt="right-arrow" className="right-arrow" />
                                </a>
                            </div>
                            <div className="item-img">
                                <img src={selectTechnologyImg} />
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-img">
                                <img src={allocateBudgetImg} />
                            </div>
                            <div className="item-content">
                                <p className="content-title">
                                    Customise and allocate Your budget
                                </p>
                                <p className="content-description">
                                    Understand the feature sets and indicative budgets for new systems and services. Set your budget for the year and the pacing, whether you pay annually, monthly, or quarterly.
                                </p>
                                <a className="redirection" href="/bp/tech-selection">Let's Start
                                    <img src={RightArrowIcon} alt="right-arrow" className="right-arrow" />
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-content">
                                <p className="content-title">
                                    Get business outcomes in a few clicks
                                </p>
                                <p className="content-description">
                                    The business planner will help you and your team navigate the digital path, giving suggested outcomes and indicative revenue returns for your technology stack and demand generation budgets.
                                </p>
                                <a className="redirection" href="/bp/tech-selection">Let's Start
                                    <img src={RightArrowIcon} alt="right-arrow" className="right-arrow" />
                                </a>
                            </div>
                            <div className="item-img">
                                <img src={BenefitOfThePlannerHeadlineImage} />
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-img">
                                <img src={useCalculatorImg} />
                            </div>
                            <div className="item-content">
                                <p className="content-title">
                                    Use the calculator to set parameters
                                </p>
                                <p className="content-description">
                                    Plan your demand generation campaigns, either through the automated suggested planner or customized basis your existing campaigns.
                                </p>
                                <a className="redirection" href="/bp/tech-selection">Let's Start
                                    <img src={RightArrowIcon} alt="right-arrow" className="right-arrow" />
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-content">
                                <p className="content-title">
                                    Get the full planner
                                </p>
                                <p className="content-description">
                                    Once you have selected your tools and technologies, budgeted your costs and generated your demand generation plan, you will generate a full budget plan for the year.
                                </p>
                                <a className="redirection" href="/bp/tech-selection">Let's Start
                                    <img src={RightArrowIcon} alt="right-arrow" className="right-arrow" />
                                </a>
                            </div>
                            <div className="item-img">
                                <img src={fullPlannerImg} />
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-img">
                                <img src={ShareThePlanWithExpertToImplementImage} />
                            </div>
                            <div className="item-content">
                                <p className="content-title">
                                    Share the plan with expert to implement
                                </p>
                                <p className="content-description">
                                    Download a custom PDF of your digital business plan, outlining the tools and technologies you are investing in and the demand generation plan to drive traffic and sales from your website. Share your plan with your internal marketing teams or with one of our expert digital consultants to implement.
                                </p>
                                <a className="redirection" href="/bp/tech-selection">Let's Start
                                    <img src={RightArrowIcon} alt="right-arrow" className="right-arrow" />
                                </a>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(BusinessPlannerLandingPage)
  );