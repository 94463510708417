import React from "react";
import { connect } from "react-redux";
import axios from "axios";

import { API_BASE_URL } from "../../config/api-config";
import { MDBSpinner } from "mdbreact";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import RoundChart from "../../components/RoundChart/RoundChart";
import { withTranslation } from "react-i18next";
import { sendPageViewGTM } from "../../utils/util";

import icon1 from "../../assets/icons/UX/msg/Icon ionic-md-lock.svg";
import icon2 from "../../assets/icons/UX/msg/004-analytics.svg";
import icon3 from "../../assets/icons/UX/msg/001-new-product.svg";
import icon4 from "../../assets/icons/UX/msg/003-advice.svg";

import CompletionAnimation from "../../components/CompletionAnimation";
import CelebrationAnimation from "../../components/CelebrationAnimation";
import GetConnectedEDC from "../../components/GetConnectedEDC";
import DigitalToolKitComponent from "../../components/DigitalToolkit";
import ServiceToolKitComponent from "../../components/ServiceToolkit";

import {
  LoadAssessments,
  SaveCompletedSections,
  submitAssessment,
  wipeAssessmentData,
} from "../HomeReAssessment/actions";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { getAssessments, getCompletedSections } from "../Home/homeReducer";

import { MDBIcon } from "mdbreact";
import alarm from "../../assets/images/alarm.svg";

import "./AssessmentCompletion.scss";

import SectionEndImage from "../../assets/images/XMLID_1840_.svg";
import { Link } from "react-router-dom";
import ProgressLoader from "../../components/ProgressLoader";

import { getUserName } from "../../utils/util";
import download_img from "../../assets/icons/download.svg";

const mapStateToProps = (state) => {
  return {
    assessments: getAssessments(state),
    completedSections: getCompletedSections(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    LoadAssessments: (ppid) => dispatch(LoadAssessments(ppid, ownProps)),
    SaveCompletedSections: (data) => dispatch(SaveCompletedSections(data)),
    submitAssessment: () => dispatch(submitAssessment()),
    wipeAssessmentData: (ppid) => dispatch(wipeAssessmentData(ppid, ownProps)),
  };
};

class ReAssessmentCompletionPage extends React.Component {
  state = {
    sections: [],
    nextSectionID: "",
    nextSection: {},
    isQualifierSection: false,
    DigitalSection: [],
    ServiceSection: [],
    CurrentType: "",
    dataLoaded: false,
    modal: false,
    showLoader: false,
    showSpinner: true,
    showSubmit: false,
    openCelebrationPopup: false,
    isTab: true,
    stopCall:true
  };
  constructor(props) {
    super(props);
    this.props.LoadAssessments(localStorage.getItem("ppid"));
  }

  /**
   * Recursive API call to check the status of Qualifier completion status
   */
  getAssessmentStatus = (ppid) => {
    var hasCompleted = false;
    axios
      .get(`${API_BASE_URL}/assessment-categories/status?ppid=${ppid}&year=2022`)
      .then((res) => {
        if (res && res.data) {
          var data = res.data;
          if (
            data &&
            data.length > 0 &&
            data[0].sections &&
            data[0].sections.length > 0
          ) {
            hasCompleted = data[0].sections[0].hasCompleted;

            if (hasCompleted) {
              let sec = data[0].sections[0].params;
              localStorage.setItem("qParam", sec);

              this.setState({
                dataLoaded: true,
              });
              this.props.LoadAssessments(localStorage.getItem("ppid"));
            } else {
              this.getAssessmentStatus(ppid);
            }
          }
        }
      });
  };

  componentDidMount() {
    let userData = localStorage.getItem("userInfo");
    if (userData) {
      this.setState({
        userInfo: JSON.parse(userData),
      });
    }

    if (this.props.match.params && this.props.match.params.formId) {
      let lastSectionId = this.props.match.params.formId;

      let sectionURLID = this.props.match.params.section
        ? this.props.match.params.section
        : null;
      let ppid = localStorage.getItem("ppid");

      /**
       * If last section completed was Qualifier, we will have to wait for the assessment status to be updated in the backend,
       * before proceeding with further assessments.
       * So for that we are calling the status API back to band until we get the hasCompleted flag as true.
       * Once the status is true, we will pass the params along with all the assessments URL's
       */
      if (sectionURLID === "Q") {
        var hasCompleted = false;
        axios
          .get(`${API_BASE_URL}/assessment-categories/status?ppid=${ppid}&year=2022`)
          .then((res) => {
            if (res && res.data) {
              var data = res.data;
              if (
                data &&
                data.length > 0 &&
                data[0].sections &&
                data[0].sections.length > 0
              ) {
                hasCompleted = data[0].sections[0].hasCompleted;
                if (hasCompleted) {
                  let sec = data[0].sections[0].params;
                  localStorage.setItem("qParam", sec);
                  this.setState({
                    dataLoaded: true,
                    showSpinner: false
                  });
                  this.props.LoadAssessments(localStorage.getItem("ppid"));
                } else {
                  this.getAssessmentStatus(ppid);
                }
              }
            }
          });
      } else {
        this.props.LoadAssessments(localStorage.getItem("ppid"));
        this.setState({
          dataLoaded: true,
          showSpinner: false
        });
      }

      let submitButtonStatus = localStorage.getItem("showSubmit");
      if (submitButtonStatus) {
        this.setState({
          showSubmit: submitButtonStatus,
        });
      }
      // save completed assessment id's to application store
      this.props.SaveCompletedSections(lastSectionId);

      let isQualifierSection = false;
      if (this.props.assessments && this.props.assessments.length > 0) {
        if (
          this.props.assessments[0].sections[0].Typeform_ID === lastSectionId
        ) {
          isQualifierSection = true;
        }
      }

      this.setState({
        isQualifierSection: isQualifierSection,
      });

      let sections = [];
      let DigitalSection = [];
      let ServiceSection = [];
      /**
       * Logic to get the Digital and Service sections
       */
      if (this.props.assessments && this.props.assessments.length > 0) {
        this.props.assessments.map((assessment, index) => {
          if (assessment.Key === "Digital Assessment") {
            DigitalSection = assessment.sections;
          } else if (assessment.Key === "Service Assessment") {
            ServiceSection = assessment.sections;
          }

          if (assessment && assessment.sections) {
            sections = [...sections, ...assessment.sections];
          }
        });
      }

      this.setState({
        sections: sections,
        DigitalSection: DigitalSection,
        ServiceSection: ServiceSection,
        showSpinner: false
      });

      let currentAssessment = "";
      if (this.props.match.params.section) {
        if (this.props.match.params.section === "D") {
          currentAssessment = "Digital";
        } else if (this.props.match.params.section === "S") {
          currentAssessment = "Service";
        } else {
          currentAssessment = "Qualifier";
        }
      }
      this.setAssessment(currentAssessment, DigitalSection, ServiceSection);
    }

    //GTM PageLoad
    try {
      let pagePath = window.location ? window.location.pathname : "";
      sendPageViewGTM("Assessment Sub-Section Completed Page ", pagePath);
    } catch (e) {}
  }

  useFilter = (arr) => {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  };

  /**
   * This method will find the next assessment section to load.
   *  Cases handled: Chek for the next section in current assessment, which is not started
   *  IF all sections in current section are handled, then will call the same metho with next assessment type. Then the not completed section will be loaded next
   *  If all the sections are completed, then we will show the assessment completion page, with option to submit the assessment
   *
   */
  setAssessment = (type, DigitalSection, ServiceSection, redirect) => {
    let assessmentObj;
    let ifCompleted = "";
    let SecAbbr = "";
    let CurrentType = "";
    if (type === "Digital") {
      assessmentObj = DigitalSection;
      CurrentType = "Digital";
      ifCompleted = "Service";
      SecAbbr = "D";
    } else if (type === "Service") {
      assessmentObj = ServiceSection;
      ifCompleted = "Digital";
      CurrentType = "Service";
      SecAbbr = "S";
    } else {
    }

    if (CurrentType) {
      this.setState({
        CurrentType: CurrentType,
        showSpinner:true
      });
    }

    let nextSectionID = "";
    let nextSection = {};
    let lastSectionId = this.props.match.params.formId;
    var isQualifierLastAssessment = false;

    // if (assessmentObj && assessmentObj.length > 0) {
    //   let isSectionCompleted = true;
    //   for (const section of assessmentObj) {
    //     if (
    //       section &&
    //       this.props.completedSections.indexOf(section.Typeform_ID) === -1 &&
    //       section.Typeform_ID !== lastSectionId
    //     ) {
    //       nextSectionID = section.Typeform_ID;
    //       nextSection = section;
    //       isSectionCompleted = false;
    //       break;
    //     }
    //   }

    //   this.setState({
    //     nextSectionID: nextSectionID,
    //     nextSection: nextSection,
    //   });

    //   if(nextSectionID === "" && nextSectionID.length === 0 && !this.state.isQualifierSection) {
    //     this.setState({
    //       openCelebrationPopup: !this.state.openCelebrationPopup
    //     });
    //   }
    //   //Get list of all sections
    //   let sectionsList = [];
    //   this.props.assessments.map((assessment, index) => {
    //     if (assessment && assessment.sections) {
    //       assessment.sections.map((section, index) => {
    //         sectionsList = [...sectionsList, section];
    //       });
    //     }
    //   });

    //   let completedSec = this.props.completedSections;
    //   completedSec.push(this.props.match.params.formId);
    //   //let uniqueCompletdSectionIds = [...new Set(this.props.completedSections)];

    //   /**
    //    * taking the unique list of completed sections, if all sections are completed
    //    * then we will load the assessment completed page
    //    */
    //   const uniqueCompletdSectionIds = this.useFilter(completedSec);
    //   let isAllSectionsCompleted =
    //     uniqueCompletdSectionIds.length === sectionsList.length ? true : false;

    //   try {
    //     if (isSectionCompleted && !isAllSectionsCompleted) {
    //       CurrentType = ifCompleted;
    //       this.setState({
    //         CurrentType: CurrentType,
    //       });
    //       this.setAssessment(ifCompleted, DigitalSection, ServiceSection);
    //     }
    //   } catch (e) {}

    //   if (redirect) {
    //     if(SecAbbr === "D" &&   nextSectionID  === ''){ // myVar is undefined or null }
    //         this.props.history.push(
    //           `/reassessment-introduction/${SecAbbr}/${this.state.sections[1].Typeform_ID}/${isQualifierLastAssessment}`
    //         );
    //       }else if(SecAbbr === "S" &&   nextSectionID  === ''){ // myVar is undefined or null }
    //         this.props.history.push(
    //           `/reassessment-introduction/${SecAbbr}/${this.state.sections[2].Typeform_ID}/${isQualifierLastAssessment}`
    //         );

    //     }else{
    //       this.props.history.push(
    //         `/reassessment-introduction/${SecAbbr}/${nextSectionID}/${isQualifierLastAssessment}`
    //       );
    //       }
    //     //this.props.history.push(`/assessment/${SecAbbr}/${nextSectionID}/${nextSection.Name}/${isQualifierLastAssessment}`);
    //   }
    // }
    let assesment_type = localStorage.getItem("assesment_type");
    if (assesment_type === "SERVICE_ONLY" && SecAbbr === "S") {
      //window.location.reload();
      axios
        .get(
          `${API_BASE_URL}/assessment-categories/status?ppid=${localStorage.getItem("ppid")}&year=2022` 
        )
        .then((res) => {
          var users = res.data[1].sections;

          var test = users.filter((x) => x.hasCompleted === true);
          //alert("assa");
          var nameArray = test.map(function (el) {
            return el.Typeform_ID;
          });
          console.log(nameArray);

          this.setState({
            completedSections: nameArray,
            showSpinner:false
          });
          if (assessmentObj && assessmentObj.length > 0) {
            let isSectionCompleted = true;
            for (const section of assessmentObj) {
              if (
                section &&
                nameArray.indexOf(section.Typeform_ID) === -1 &&
                section.Typeform_ID !== lastSectionId
              ) {
                console.log(section.Typeform_ID);
                nextSectionID = section.Typeform_ID;
                nextSection = section;
                isSectionCompleted = false;
                break;
              }
            }

            this.setState({
              nextSectionID: nextSectionID,
              nextSection: nextSection
            });

            if (
              nextSectionID === "" &&
              nextSectionID.length === 0 &&
              !this.state.isQualifierSection
            ) {
              this.setState({
                openCelebrationPopup: !this.state.openCelebrationPopup,
              });
            }
            //Get list of all sections
            let sectionsList = [];
            this.props.assessments.map((assessment, index) => {
              if (assessment && assessment.sections) {
                assessment.sections.map((section, index) => {
                  sectionsList = [...sectionsList, section];
                });
              }
            });

            let completedSec = this.props.completedSections;
            completedSec.push(this.props.match.params.formId);
            //let uniqueCompletdSectionIds = [...new Set(this.props.completedSections)];

            /**
             * taking the unique list of completed sections, if all sections are completed
             * then we will load the assessment completed page
             */
            const uniqueCompletdSectionIds = this.useFilter(completedSec);
            let isAllSectionsCompleted =
              uniqueCompletdSectionIds.length === sectionsList.length
                ? true
                : false;

            try {
              if (isSectionCompleted && !isAllSectionsCompleted) {
                CurrentType = ifCompleted;
                this.setState({
                  CurrentType: CurrentType,
                  showSpinner:false
                });
                this.setAssessment(ifCompleted, DigitalSection, ServiceSection);
              }
            } catch (e) {}

            if (redirect) {
              if (SecAbbr === "D" && nextSectionID === "") {
                // myVar is undefined or null }
               
                window.location = `/reassessment-introduction/${SecAbbr}/${this.state.sections[1].Typeform_ID}/${isQualifierLastAssessment}`;

              } else if (SecAbbr === "S" && nextSectionID === "") {
                // myVar is undefined or null }
                this.props.history.push(
                  `/reassessment-introduction/${SecAbbr}/${this.state.sections[2].Typeform_ID}/${isQualifierLastAssessment}`
                );
              } else {
                this.props.history.push(
                  `/reassessment-introduction/${SecAbbr}/${nextSectionID}/${isQualifierLastAssessment}`
                );
              }
              //this.props.history.push(`/assessment/${SecAbbr}/${nextSectionID}/${nextSection.Name}/${isQualifierLastAssessment}`);
            }
          }
          console.log("res", res.data);
        })
        .catch((error) => {});
    } else if (assesment_type === "BOTH" && SecAbbr === "D" && this.state.stopCall) {
      this.setState({
        stopCall:false
      })
      //alert("assesment_type",assesment_type);
      //this.props.history.push(`/dashboard2022/${localStorage.getItem('ppid')}`);

      // window.location.reload();
      axios
        .get(
          `${API_BASE_URL}/assessment-categories/status?ppid=${localStorage.getItem("ppid")}&year=2022`
            
        )
        .then((res) => {
          var users = res.data[1].sections;

          var test = users.filter((x) => x.hasCompleted === true);

          var nameArray = test.map(function (el) {
            return el.Typeform_ID;
          });
          console.log(nameArray);

          this.setState({
            completedSections: nameArray,
            showSpinner:false
          });
          if (assessmentObj && assessmentObj.length > 0) {
            let isSectionCompleted = true;
            for (const section of assessmentObj) {
              if (
                section &&
                nameArray.indexOf(section.Typeform_ID) === -1 &&
                section.Typeform_ID !== lastSectionId
              ) {
                console.log(section.Typeform_ID);
                nextSectionID = section.Typeform_ID;
                nextSection = section;
                isSectionCompleted = false;
                break;
              }
            }

            console.log("this.props.completedSections1", nameArray);
            console.log(
              "this.props.completedSections1",
              this.props.completedSections
            );
            console.log("nextSection", nextSection);
            this.setState({
              nextSectionID: nextSectionID,
              nextSection: nextSection,
            });

            if (
              nextSectionID === "" &&
              nextSectionID.length === 0 &&
              !this.state.isQualifierSection
            ) {
              this.setState({
                openCelebrationPopup: !this.state.openCelebrationPopup,
              });
            }
            //Get list of all sections
            let sectionsList = [];
            this.props.assessments.map((assessment, index) => {
              if (assessment && assessment.sections) {
                assessment.sections.map((section, index) => {
                  sectionsList = [...sectionsList, section];
                });
              }
            });

            let completedSec = this.props.completedSections;
            completedSec.push(this.props.match.params.formId);
            //let uniqueCompletdSectionIds = [...new Set(this.props.completedSections)];

            /**
             * taking the unique list of completed sections, if all sections are completed
             * then we will load the assessment completed page
             */
            const uniqueCompletdSectionIds = this.useFilter(completedSec);
            let isAllSectionsCompleted =
              uniqueCompletdSectionIds.length === sectionsList.length
                ? true
                : false;

            try {
              if (isSectionCompleted && !isAllSectionsCompleted) {
                CurrentType = ifCompleted;
                this.setState({
                  CurrentType: CurrentType,
                });
                this.setAssessment(ifCompleted, DigitalSection, ServiceSection);
              }
            } catch (e) {}

            if (redirect) {
              if (SecAbbr === "D" && nextSectionID === "") {
                // myVar is undefined or null }
                this.props.history.push(
                  `/reassessment-introduction/${SecAbbr}/${this.state.sections[1].Typeform_ID}/${isQualifierLastAssessment}`
                );
              } else if (SecAbbr === "S" && nextSectionID === "") {
                // myVar is undefined or null }
                this.props.history.push(
                  `/reassessment-introduction/${SecAbbr}/${this.state.sections[2].Typeform_ID}/${isQualifierLastAssessment}`
                );
              } else {
                this.props.history.push(
                  `/reassessment-introduction/${SecAbbr}/${nextSectionID}/${isQualifierLastAssessment}`
                );
              }
              //this.props.history.push(`/assessment/${SecAbbr}/${nextSectionID}/${nextSection.Name}/${isQualifierLastAssessment}`);
            }
          }
          console.log("res", res.data);
        })
        .catch((error) => {});
    } else {
      if (assessmentObj && assessmentObj.length > 0) {
        let isSectionCompleted = true;
        for (const section of assessmentObj) {
          if (
            section &&
            this.props.completedSections.indexOf(section.Typeform_ID) === -1 &&
            section.Typeform_ID !== lastSectionId
          ) {
            nextSectionID = section.Typeform_ID;
            nextSection = section;
            isSectionCompleted = false;
            break;
          }
        }

        this.setState({
          nextSectionID: nextSectionID,
          nextSection: nextSection,
        });

        if (
          nextSectionID === "" &&
          nextSectionID.length === 0 &&
          !this.state.isQualifierSection
        ) {
          this.setState({
            openCelebrationPopup: !this.state.openCelebrationPopup,
          });
        }
        //Get list of all sections
        let sectionsList = [];
        this.props.assessments.map((assessment, index) => {
          if (assessment && assessment.sections) {
            assessment.sections.map((section, index) => {
              sectionsList = [...sectionsList, section];
            });
          }
        });

        let completedSec = this.props.completedSections;
        completedSec.push(this.props.match.params.formId);
        //let uniqueCompletdSectionIds = [...new Set(this.props.completedSections)];

        /**
         * taking the unique list of completed sections, if all sections are completed
         * then we will load the assessment completed page
         */
        const uniqueCompletdSectionIds = this.useFilter(completedSec);
        let isAllSectionsCompleted =
          uniqueCompletdSectionIds.length === sectionsList.length
            ? true
            : false;

        try {
          if (isSectionCompleted && !isAllSectionsCompleted) {
            CurrentType = ifCompleted;
            this.setState({
              CurrentType: CurrentType,
            });
            this.setAssessment(ifCompleted, DigitalSection, ServiceSection);
          }
        } catch (e) {}

        if (redirect) {
          if (SecAbbr === "D" && nextSectionID === "") {
            // myVar is undefined or null }
            this.props.history.push(
              `/reassessment-introduction/${SecAbbr}/${this.state.sections[1].Typeform_ID}/${isQualifierLastAssessment}`
            );
          } else if (SecAbbr === "S" && nextSectionID === "") {
            // myVar is undefined or null }
            this.props.history.push(
              `/reassessment-introduction/${SecAbbr}/${this.state.sections[2].Typeform_ID}/${isQualifierLastAssessment}`
            );
          } else {
            this.props.history.push(
              `/reassessment-introduction/${SecAbbr}/${nextSectionID}/${isQualifierLastAssessment}`
            );
          }
          //this.props.history.push(`/assessment/${SecAbbr}/${nextSectionID}/${nextSection.Name}/${isQualifierLastAssessment}`);
        }
      }
    }
  };

  getCurrentSectionName = () => {
    let data;
    if (this.state.sections && this.state.sections.length > 0) {
      data = this.state.sections.filter(
        (section) => section.Typeform_ID === this.props.match.params.formId
      );
    }

    return (
      <span>
        {" "}
        Good going {getUserName()}! You have completed {data.Name} section{" "}
      </span>
    );
  };

  loadNextAssessment = (type) => {
    if (type) {
      let redirect = true;
      this.setAssessment(
        type,
        this.state.DigitalSection,
        this.state.ServiceSection,
        redirect
      );
    } else {
      this.props.history.push(
        `/assessment-introduction/${this.state.nextSectionID}/${this.state.nextSection.Name}`
      );
      //this.props.history.push(`/assessment/${this.state.nextSectionID}/${this.state.nextSection.Name}`);
    }
  };

  submitAssessment = () => {
    this.setState({
      showLoader: true,
    });
    //this.props.submitAssessment();
    let ppid = localStorage.getItem("ppid");
    axios
      .get(`${API_BASE_URL}/assessment-statuses/score?year=2022&ppid=` + ppid)
      .then((res) => {
        if (res) {
          localStorage.setItem("hasSubmited", true);
          this.setState({
            showLoader: false,
          });
          this.props.history.push(`/reassessment-report/` + ppid);
        }
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        });
      });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  wipeAssessmentData = () => {
    this.props.wipeAssessmentData(localStorage.getItem("ppid"));
    this.setState({
      modal: !this.state.modal,
    });
  };

  componentWillUnmount() {
    this.setState({
      showLoader: false,
    });
  }

  timer = () => {
    this.setState({
      showSubmit: true,
      showSpinner: !this.state.showSpinner,
    });
  };

  showLoader = () => {
    if (!this.state.showSubmit) {
      localStorage.setItem("showSubmit", true);
      this.setState({
        showSpinner: !this.state.showSpinner,
      });

      var intervalId = setTimeout(this.timer, 400);
    }
  };

  render() {
    const { assessments } = this.props;
    const type =
      localStorage.getItem("assesment_type") === "DIGITAL_ONLY"
        ? "Digital"
        : localStorage.getItem("assesment_type") === "SERVICE_ONLY"
        ? "Service"
        : "Both";
    const {
      nextSection,
      isQualifierSection,
      CurrentType,
      showLoader,
      showSpinner,
      userInfo,
    } = this.state;
    let data;
    let currentSectionName = "";
    let ppid = localStorage.getItem("ppid");
    let hasQualifierCompleted = false;
    let hasAssessmentSubmitted = false;
    let digitalData = {
      started: false,
      totalSections: 0,
      completedSections: 0,
    };
    let serviceData = {
      started: false,
      totalSections: 0,
      completedSections: 0,
    };

    if (
      assessments &&
      assessments.length > 0 &&
      assessments[0].sections &&
      assessments[0].sections.length > 0
    ) {
      hasQualifierCompleted = assessments[0].sections[0].hasCompleted;
      hasAssessmentSubmitted = assessments[0].sections[0].hasFinallySubmitted;
      let digitalTotalSections = 0;
      let digitalCompletedSections = 0;
      let serviceTotalSections = 0;
      let serviceCompletedSections = 0;

      /**
       * Settin the digital and Service assessment data
       * This is to show there in assessment completion page
       */
      assessments.map((assessment, index) => {
        if (
          assessment &&
          assessment.sections &&
          assessment.sections.length > 0
        ) {
          assessment.sections.map((sec, i) => {
            if (assessment.Key.indexOf("Digital") > -1) {
              digitalData.id = assessment.id;
              digitalTotalSections++;
              if (sec.hasCompleted) {
                digitalCompletedSections++;
              }
            }

            if (assessment.Key.indexOf("Service") > -1) {
              serviceData.id = assessment.id;
              serviceTotalSections++;
              if (sec.hasCompleted) {
                serviceCompletedSections++;
              }
            }
          });

          digitalData.started = digitalCompletedSections > 0 ? true : false;
          digitalData.totalSections = digitalTotalSections;
          digitalData.completedSections = digitalCompletedSections;

          serviceData.started = serviceCompletedSections > 0 ? true : false;
          serviceData.totalSections = serviceTotalSections;
          serviceData.completedSections = serviceCompletedSections;

          try {
            if (digitalData.digitalCompletedSections === 0) {
              digitalData.completedPer = 0;
            } else {
              digitalData.completedPer = Math.round(
                (100 / digitalData.totalSections) *
                  digitalData.completedSections
              );

              if (digitalData.completedPer > 100) {
                digitalData.completedPer = 100;
              }
            }

            if (serviceData.serviceCompletedSections === 0) {
              serviceData.completedPer = 0;
            } else {
              serviceData.completedPer = Math.ceil(
                (100 / serviceData.totalSections) *
                  serviceData.completedSections
              );
              if (serviceData.completedPer > 100) {
                serviceData.completedPer = 100;
              }
            }
          } catch (e) {
            console.info(e);
          }
        }
      });
    }

    if (this.state.sections && this.state.sections.length > 0) {
      this.state.sections.map((section, index) => {
        if (section.Typeform_ID === this.props.match.params.formId) {
          currentSectionName = section.Name;
        }
      });
    }

    const { t } = this.props;
    const selectedlan = localStorage.getItem("lan") || "en";

    return (
      <div className="d-flex justify-content-center hp-layout-wrap w-100">
        <div className="d-flex hp-layout hp-home">
          <Header />

          <p className=" ml-3 bread-crumb hp-lite">
            <Link to={`/dashboard/${localStorage.getItem("ppid")}`}>
              {t("COMMON.COMMON_HOME")}
            </Link>{" "}
            &gt;{" "}
            <Link onClick={() => this.props.history.goBack()}>
              {t("COMMON.COMMON_ASSESSMENT")}
            </Link>{" "}
            &gt; <span>{t("ASSESSMENT.ASSESSMENT_PARTNER_PROFILE")}</span>
          </p>

          {this.state.showSpinner && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "850px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {((this.state.nextSectionID && this.state.nextSectionID.length > 0) ||
            isQualifierSection === true) && (
            <div className="hp-content-wrap">
              <div className="d-flex justify-content-center align-items-center hp-seection-completed">
                {false && (
                  <div>
                    <img
                      src={SectionEndImage}
                      className="img-fluid hp-section-end-img"
                    />
                  </div>
                )}

                {false && (
                  <div className="ux-msg-completion-screen-images">
                    <div className="ux-msg-completion-screen-icon-lock">
                      <img src={icon1} alt="" />
                    </div>
                    <div className="ux-msg-completion-screen-icon">
                      <img src={icon2} alt="" />
                    </div>
                    <div className="ux-msg-completion-screen-icon">
                      <img src={icon3} alt="" />
                    </div>
                    <div className="ux-msg-completion-screen-icon">
                      <img src={icon4} alt="" />
                    </div>
                  </div>
                )}
                <CompletionAnimation />
                <CelebrationAnimation />

                <div className="d-flex justify-content-center align-items-center hp-lite hp-seection-completed-msg mb-2">
                  {isQualifierSection === true && (
                    <div className="hp-seection-completed-user-msg">
                      {t("ASSESSMENT.ASSESSMENT_GOOD_GOING", {
                        name: getUserName(),
                      })}
                    </div>
                  )}

                  <div>
                    {t("ASSESSMENT.ASSESSMENT_YOU_HAVE_COMPLETED", {
                      sectionName: currentSectionName,
                    })}
                  </div>
                </div>

                {isQualifierSection === true ? (
                  <div className="ux-qualifier-updates">
                    {/*<span>You have begun your journey to unlock your <b className="ux-bold-msg">personalised Reports and Insights</b></span>*/}
                    <span>{t("UX.MSG_QUALIFIER1")}</span>
                  </div>
                ) : (
                  <div className="ux-qualifier-updates">
                    {/*<span>You are on your way to the exclusive <b className="ux-bold-msg">Digital Toolkit</b> repository</span>*/}
                    <span>{t("UX.MSG_QUALIFIER2")}</span>
                  </div>
                )}

                {!isQualifierSection && (
                  <div className="hp-lets-begin-assessment">
                    {t("UX.MSG_QUALIFIER3", { sectionName: nextSection.Name })}
                  </div>
                )}

                {isQualifierSection === true ? (
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    {localStorage.getItem("assesment_type") === "BOTH" && (
                      <div>
                        <div
                          className="hp-cta-v1"
                          onClick={this.loadNextAssessment.bind(
                            this,
                            "Digital"
                          )}
                        >
                          {t("ASSESSMENT.ASSESSMENT_START_DIGITAL")}
                        </div>
                        <div
                          className="hp-cta-v1 mt-2"
                          onClick={this.loadNextAssessment.bind(
                            this,
                            "Service"
                          )}
                        >
                          {t("ASSESSMENT.ASSESSMENT_START_SERVICES")}
                        </div>
                      </div>
                    )}
                    {localStorage.getItem("assesment_type") ===
                      "DIGITAL_ONLY" && (
                      <div>
                        <div
                          className="hp-cta-v1"
                          onClick={this.loadNextAssessment.bind(
                            this,
                            "Digital"
                          )}
                        >
                          {t("ASSESSMENT.ASSESSMENT_START_DIGITAL")}
                        </div>
                      </div>
                    )}

                    {localStorage.getItem("assesment_type") ===
                      "SERVICE_ONLY" && (
                      <div>
                        <div
                          className="hp-cta-v1 mt-2"
                          onClick={this.loadNextAssessment.bind(
                            this,
                            "Service"
                          )}
                        >
                          {t("ASSESSMENT.ASSESSMENT_START_SERVICES")}
                        </div>
                      </div>
                    )}
                  </div>
                ) : nextSection && nextSection.Name ? (
                  <div className="d-flex justify-content-center align-items-center mt-4 flex-column">
                    <div
                      onClick={this.loadNextAssessment.bind(this, CurrentType)}
                      className="hp-cta-v1 start-section-btn-title"
                    >
                      {t("ASSESSMENT.ASSESSMENT_BEGIN", {
                        sectionName: nextSection.Name,
                      })}
                    </div>
                  </div>
                ) : (
                  "Sorry Something went wrong"
                )}
              </div>
            </div>
          )}
          {this.state.nextSectionID === "" &&
            this.state.nextSectionID.length === 0 &&
            !isQualifierSection &&
            this.state.dataLoaded && (
              <div className="hp-content-wrap d-flex hp-assessment-submit">
                <div className="hp-guide-assessment-block">
                  <div className="hp-assessment">
                    <div className="hp-regular hp-32 hp-primary-color">
                      {t("ASSESSMENT.ASSESSMENT_WELCOME", {
                        name: getUserName(),
                      })}
                    </div>
                    <div className="hp-lite hp-18 hp-assessment-completed-text">
                      Nullam quis accumsan ante, vitae varius neque. Cras sit
                      amet ligula ipsum. Quisque quis tincidunt turpis, eu
                      scelerisque tellus. Nullam quis accumsan ante, vitae
                      varius neque.
                    </div>
                    <a
                      className="mt-2 hp-assessment-link hp-regular hp-18"
                      href="#"
                    >
                      Guide to re-assessment
                      <span className="ml-2">
                        <MDBIcon icon="arrow-right" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="hp-final-submit-wrap mt-2">
                  <div className="hp-lite hp-32">
                    Great! You have completed {type} re-assessment.
                  </div>
                  <div className="hp-lite hp-18 hp-assessment-completed-text">
                    Now you can generate {type} re-assessment report without
                    completing service re-assessment. Compare your pervious
                    digital assessment and re-assessment report after the ‘Final
                    Submission’.
                  </div>
                </div>
                <div className="my-2 mx-4 d-flex align-items-start flex-column">
                  <div
                    className="hp-asmnt-submit-btn hp-cta-v1 hp-regular hp-18 hp-cta-v1h"
                    onClick={this.submitAssessment}
                  >
                    {t("DASHBOARD.DASHBOARD_FINISH_CTA")}
                  </div>
                  <div className="d-flex col-12 justify-content-start daashboard-download-responses-wrap">
                    <a
                      target="_blank"
                      className="daashboard-download-responses-btn"
                      href={`${API_BASE_URL}/assessment-statuses/downloadResponse?ppid=${ppid}`}
                      rel="noreferrer"
                    >
                      {t("DASHBOARD.DASHBOARD_DOWNLOAD_RESPONSE")}
                      <span className="ml-2">
                        <img src={download_img} />
                      </span>
                    </a>
                  </div>
                </div>
                {localStorage.getItem("assesment_type") === "BOTH" && (
                  <div className="d-flex col-12 hp-completed-card-wrap">
                    <div className="hp-assessment-completed-graph-block d-flex col-sm-12 hp-completed-card flex-column">
                      <div className="d-flex hp-assessment-completed-top w-100 mt-3">
                        <div className="d-flex flex-column hp-50 hp-completed-card-title">
                          <div className="hp-bold hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_DIGITAL_TITLE")}
                          </div>
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_TEXT")}
                          </div>
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_COMPLETED_TEXT")}
                          </div>
                        </div>
                        <div className="d-flex flex-column hp-50">
                          <div className="hp-14 hp-bold hp-assessment-view-report">
                            {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT")}
                            <span className="ml-2">
                              <MDBIcon icon="arrow-right" />
                            </span>
                          </div>
                          <div className="hp-10 hp-regular flex-row d-flex justify-content-end mt-1">
                            <div className="d-flex hp-assessment-final-submit align-items-center mr-1">
                              <MDBIcon icon="lock" size="2x" />
                            </div>
                            <div className="d-flex flex-column hp-assessment-final-submit">
                              <div>
                                {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                              </div>
                              <div>
                                {t(
                                  "DASHBOARD.DASHBOARD_CARD_VIEW_REPORT_SMALL"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex hp-assessment-completed-chart justify-content-center my-4">
                        <RoundChart value={100} isStart={false} />
                      </div>
                      <div className="d-flex hp-assessment-completed-retake flex-column">
                        <div className="d-flex justify-content-center hp-primary-color hp-regular hp-18 hp-completed-retake">
                          <Link to={`/assessment-retake/${digitalData.id}`}>
                            {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                          </Link>
                        </div>
                        <div className="d-flex justify-content-center align-items-center hp-lite hp-12 my-3 mx-4">
                          {t("DASHBOARD.DASHBOARD_CARD_DIGITAL_DESC")}
                        </div>
                      </div>
                    </div>
                    <div className="hp-assessment-completed-graph-block d-flex col-sm-12 hp-completed-card flex-column">
                      <div className="d-flex hp-assessment-completed-top w-100 mt-3">
                        <div className="d-flex flex-column hp-50 hp-completed-card-title">
                          <div className="hp-bold hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_SERVICES_TITLE")}
                          </div>
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_TEXT")}
                          </div>
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_COMPLETED_TEXT")}
                          </div>
                        </div>
                        <div className="d-flex flex-column hp-50">
                          <div className="hp-14 hp-bold hp-assessment-view-report">
                            {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT")}
                            <span className="ml-2">
                              <MDBIcon icon="arrow-right" />
                            </span>
                          </div>
                          <div className="hp-10 hp-regular flex-row d-flex justify-content-end mt-1">
                            <div className="d-flex hp-assessment-final-submit align-items-center mr-1">
                              <MDBIcon icon="lock" size="2x" />
                            </div>
                            <div className="d-flex flex-column hp-assessment-final-submit">
                              <div>
                                {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                              </div>
                              <div>
                                {t(
                                  "DASHBOARD.DASHBOARD_CARD_VIEW_REPORT_SMALL"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex hp-assessment-completed-chart justify-content-center my-4">
                        <RoundChart value={100} isStart={false} />
                      </div>
                      <div className="d-flex hp-assessment-completed-retake flex-column">
                        <div className="d-flex justify-content-center hp-primary-color hp-regular hp-18 hp-completed-retake">
                          <Link to={`/assessment-retake/${serviceData.id}`}>
                            {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                          </Link>
                        </div>
                        <div className="d-flex justify-content-center align-items-center hp-lite hp-12 my-3 mx-4">
                          {t("DASHBOARD.DASHBOARD_CARD_SERVICES_DESC")}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {localStorage.getItem("assesment_type") === "DIGITAL_ONLY" && (
                  <div className="d-flex col-12 hp-completed-card-wrap">
                    <div className="hp-assessment-completed-graph-block d-flex col-sm-12 hp-completed-card flex-column">
                      <div className="d-flex hp-assessment-completed-top w-100 mt-3">
                        <div className="d-flex flex-column hp-50 hp-completed-card-title">
                          <div className="hp-bold hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_DIGITAL_TITLE")}
                          </div>
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_TEXT")}
                          </div>
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_COMPLETED_TEXT")}
                          </div>
                        </div>
                        <div className="d-flex flex-column hp-50">
                          <div className="hp-14 hp-bold hp-assessment-view-report">
                            {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT")}
                            <span className="ml-2">
                              <MDBIcon icon="arrow-right" />
                            </span>
                          </div>
                          <div className="hp-10 hp-regular flex-row d-flex justify-content-end mt-1">
                            <div className="d-flex hp-assessment-final-submit align-items-center mr-1">
                              <MDBIcon icon="lock" size="2x" />
                            </div>
                            <div className="d-flex flex-column hp-assessment-final-submit">
                              <div>
                                {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                              </div>
                              <div>
                                {t(
                                  "DASHBOARD.DASHBOARD_CARD_VIEW_REPORT_SMALL"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex hp-assessment-completed-chart justify-content-center my-4">
                        <RoundChart value={100} isStart={false} />
                      </div>
                      <div className="d-flex hp-assessment-completed-retake flex-column">
                        <div className="d-flex justify-content-center hp-primary-color hp-regular hp-18 hp-completed-retake">
                          <Link to={`/assessment-retake/${digitalData.id}`}>
                            {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                          </Link>
                        </div>
                        <div className="d-flex justify-content-center align-items-center hp-lite hp-12 my-3 mx-4">
                          {t("DASHBOARD.DASHBOARD_CARD_DIGITAL_DESC")}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {localStorage.getItem("assesment_type") === "SERVICE_ONLY" && (
                  <div className="d-flex col-12 hp-completed-card-wrap">
                    <div className="hp-assessment-completed-graph-block d-flex col-sm-12 hp-completed-card flex-column">
                      <div className="d-flex hp-assessment-completed-top w-100 mt-3">
                        <div className="d-flex flex-column hp-50 hp-completed-card-title">
                          <div className="hp-bold hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_SERVICES_TITLE")}
                          </div>
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_ASSESSMENT_TEXT")}
                          </div>
                          <div className="hp-regular hp-18">
                            {t("DASHBOARD.DASHBOARD_CARD_COMPLETED_TEXT")}
                          </div>
                        </div>
                        <div className="d-flex flex-column hp-50">
                          <div className="hp-14 hp-bold hp-assessment-view-report">
                            {t("DASHBOARD.DASHBOARD_CARD_VIEW_REPORT")}
                            <span className="ml-2">
                              <MDBIcon icon="arrow-right" />
                            </span>
                          </div>
                          <div className="hp-10 hp-regular flex-row d-flex justify-content-end mt-1">
                            <div className="d-flex hp-assessment-final-submit align-items-center mr-1">
                              <MDBIcon icon="lock" size="2x" />
                            </div>
                            <div className="d-flex flex-column hp-assessment-final-submit">
                              <div>
                                {t("DASHBOARD.DASHBOARD_CARD_FINAL_SUBMIT")}
                              </div>
                              <div>
                                {t(
                                  "DASHBOARD.DASHBOARD_CARD_VIEW_REPORT_SMALL"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex hp-assessment-completed-chart justify-content-center my-4">
                        <RoundChart value={100} isStart={false} />
                      </div>
                      <div className="d-flex hp-assessment-completed-retake flex-column">
                        <div className="d-flex justify-content-center hp-primary-color hp-regular hp-18 hp-completed-retake">
                          <Link to={`/assessment-retake/${serviceData.id}`}>
                            {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                          </Link>
                        </div>
                        <div className="d-flex justify-content-center align-items-center hp-lite hp-12 my-3 mx-4">
                          {t("DASHBOARD.DASHBOARD_CARD_SERVICES_DESC")}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="hp-submit-v2 d-flex flex-column">
                  {/* <div className="d-flex h-18 hp-submit-v2-text text-red">
                    {t('DASHBOARD.DASHBOARD_FINISH_TITLE')}
                  </div> */}
                </div>
                <div className="hp-guide-assessment-block">
                  <div className="hp-assessment">
                    <div className="hp-regular hp-32 hp-primary-color">
                      Partner Profile
                    </div>
                    <div className="hp-lite hp-18 hp-assessment-completed-text">
                      Nullam quis accumsan ante, vitae varius neque. Cras sit
                      amet ligula ipsum. Quisque quis tincidunt turpis, eu
                      scelerisque tellus. Nullam quis accumsan ante, vitae
                      varius neque. Cras sit amet ligula ipsum. Quisque quis
                      tincidunt turpis, eu scelerisque tellus. Nullam quis
                      accumsan ante, vitae varius neque.
                    </div>
                    <div className="mt-2 d-flex flex-coloumn warmup-retake">
                      <div className="warmup-retake-icon">
                        <MDBIcon icon="check-circle" size="2x" />
                      </div>
                      <div className="warmup-retake-text">
                        Completed Partner profile
                      </div>
                    </div>
                    {/* <div className="mt-2">Completed</div> */}
                    {!hasAssessmentSubmitted && (
                      <a className="mt-2 assessment-retake-link" href="#">
                        {t("DASHBOARD.DASHBOARD_CARD_CTA_RETAKE_TEXT")}
                      </a>
                    )}
                  </div>
                </div>
                <GetConnectedEDC />
                <>
                  <div className="w-100 d-flex justify-content-between mt-3">
                    <div
                      className={this.state.isTab ? "tab tab-active" : "tab"}
                      onClick={() =>
                        this.setState({
                          isTab: true,
                        })
                      }
                    >
                      <p>Digital Toolkit Resources</p>
                    </div>

                    <div
                      className={this.state.isTab ? "tab" : "tab tab-active"}
                      onClick={() =>
                        this.setState({
                          isTab: false,
                        })
                      }
                    >
                      <p>Service Toolkit Resources</p>
                    </div>
                  </div>
                  {this.state.isTab ? (
                    <DigitalToolKitComponent />
                  ) : (
                    <ServiceToolKitComponent />
                  )}
                </>
              </div>
            )}
          <Footer />
        </div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}></MDBModalHeader>
          <MDBModalBody>
            <div className="hp-reatake-alert-wrap">
              <div className="hp-retake-icon d-flex justify-content-center align-items-center">
                <img src={alarm} className="img-fluid" />
              </div>
              <div className="hp-retake-warn-text-1">
                <div className="retake-title">
                  {t("ASSESSMENT.ASSESSMENT_RETAKE_MODAL_TITLE")}
                </div>
                <div className="retake-body">
                  {t("ASSESSMENT.ASSESSMENT_RETAKE_MSG1")}
                </div>
              </div>
              <div className="hp-retake-warn-text-2">
                <div className="retake-confirm-text">
                  {t("ASSESSMENT.ASSESSMENT_RETAKE_MODAL_CONFIRMATION")}
                </div>
              </div>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <div
              className="hp-data-wipe-confirm"
              onClick={this.wipeAssessmentData}
            >
              {t("DASHBOARD.DASHBOARD_MODAL_RETAKE_CTA1")}
            </div>
            <div>
              <div
                className="hp-cta-v1 hp-data-wipe-cancel-cta"
                onClick={this.toggle}
              >
                {t("DASHBOARD.DASHBOARD_MODAL_RETAKE_CTA2")}
              </div>
            </div>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={this.state.openCelebrationPopup}
          className="custom-modal-animation"
          size="lg"
        >
          <MDBModalBody>
            <div className="completion-modal-msg">
              <CompletionAnimation />
              <div className="completion-modal-msg-text-wrap">
                {userInfo && userInfo.PartnerName && (
                  <div className="completion-modal-msg-text-1">
                    {t("UX.SUBMIT_MSG_1", { name: userInfo.PartnerName })}{" "}
                  </div>
                )}
                <div className="completion-modal-msg-text-2">
                  {t("UX.SUBMIT_MSG_2")}
                </div>
                <div className="completion-modal-msg-text-3">
                  {t("UX.SUBMIT_MSG_3")}
                </div>
              </div>
              <div className="completion-modal-msg-btn-wrap">
                <div
                  className="chp-asmnt-submit-btn hp-cta-v1 hp-regular hp-18 hp-cta-v1h"
                  onClick={() => {
                    this.setState({ openCelebrationPopup: false });
                  }}
                >
                  Okay
                </div>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>

        {showLoader && <ProgressLoader />}
        <CelebrationAnimation />
      </div>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ReAssessmentCompletionPage)
);
