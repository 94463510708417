import { combineReducers } from 'redux';

/**
 * Import custom reducers below
 * **/
import { homeReducer } from '../pages/Home/homeReducer';
import {reportReducer} from './reportReducer';
import {toolkitReducer} from './toolkitReducer';
import {pspeedReducer} from './pspeedReducer';
import { authReducer } from "./authReducer";
import { bpReducer } from "./bpReducer";

export default combineReducers({
    homeReducer,
    reportReducer,
    toolkitReducer,
    pspeedReducer,
    authReducer,
    bpReducer
});