import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Header from '../../../components/BusinessPlanner/WizardHeader/Header';
import TimeLine from '../../../components/BusinessPlanner/TimeLine';
import TimerComponent from '../../../components/BusinessPlanner/Timer';
import axios from "axios";
import {API_BASE_URL} from '../../../config/api-config';

import { sendPageViewGTM } from '../../../utils/util';
import './budgetplanner.scss';
import timer from '../../../assets/icons/timer.svg';
import rightArrow from '../../../assets/icons/right-arrow.svg';

import ProgressLoader from '../../../components/ProgressLoader';
import engage from '../../../assets/images/businessplanner/planner/engage.svg';
import transact from '../../../assets/images/businessplanner/planner/transaction.svg';
import retain from '../../../assets/images/businessplanner/planner/retain.svg';
import reach from '../../../assets/images/businessplanner/planner/reach.svg';

import add from '../../../assets/tour/add.png';
import slider from '../../../assets/tour/slider.png';
import details from '../../../assets/tour/details.png';
import budget from '../../../assets/tour/budget.png';

import bpChannelData from '../../../config/bp_channel_details.json';

//redux
import {fetchBPMaterData, setBPActiveSection, setPlanSelectedState, setPlanBudget, setPlanAllocation, setBulkPlanData} from '../../../actions/bpAction';
import {getBPMasterData, getActiveSection} from '../../../reducers/bpReducer';
import { MDBIcon, MDBTooltip } from 'mdbreact';

const mapStateToProps = (state) => {
  return {
   bpMasterData: getBPMasterData(state),
   activeSection: getActiveSection(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      fetchBPMaterData: (ppid) => dispatch(fetchBPMaterData(ppid)),
      setBPActiveSection: (section) => dispatch(setBPActiveSection(section)),
      setPlanSelectedState: (key, val) => dispatch(setPlanSelectedState(key, val)),
      setPlanBudget: (key, val) => dispatch(setPlanBudget(key, val)),
      setPlanAllocation: (key, val) => dispatch(setPlanAllocation(key, val)),
      setBulkPlanData: (data) => dispatch(setBulkPlanData(data))
  }
}

class BudgetPlanner extends React.Component {

  state = {
    budgetAllocationError: false,
    openCard: true,
    sectionList: ['Engage', 'Transact', 'Retain', 'Reach'],
    nextSection: 'Transact',
    OneOff: ['Allocate budget', '1st quarter', '2nd quarter', '3rd quarter', '4th quarter'],
    Regular: ['Allocate budget', 'Monthly', 'Quarterly', 'Annually'],
    WEBSITE: 0,
    WEB_MAINTENANCE_HOSTING: 0,
    IOS_AND_ANDROID_APP: 0,
    CONTENT_PERSONALIZATION_CDP:0,
    DATA_PRIVACY_COOKIE_MANAGEMENT_CMP: 0,
    CRM: 0,
    CHATBOT: 0,
    ECOMMERCE_ENABLEMENT: 0,
    LOYALTY_SOLUTION: 0,
    LISTENING_AND_RESPONSE_MANAGEMENT: 0,
    MARKETING_AUTOMATION: 0,
    ANALYTICS_AND_DATA_VISUALISATION: 0,
    WEBSITE_ALLOCATION: '',
    WEB_MAINTENANCE_HOSTING_ALLOCATION: '',
    IOS_AND_ANDROID_APP_ALLOCATION: '',
    CONTENT_PERSONALIZATION_CDP_ALLOCATION: '',
    DATA_PRIVACY_COOKIE_MANAGEMENT_CMP_ALLOCATION: '',
    CRM_ALLOCATION: '',
    CHATBOT_ALLOCATION: '',
    ECOMMERCE_ENABLEMENT_ALLOCATION: '',
    LOYALTY_SOLUTION_ALLOCATION: '',
    LISTENING_AND_RESPONSE_MANAGEMENT_ALLOCATION: '',
    MARKETING_AUTOMATION_ALLOCATION: '',
    ANALYTICS_AND_DATA_VISUALISATION_ALLOCATION: '',
    SELECTED_PLANS: [],
    WEBSITE_DETAILS: false,
    WEB_MAINTENANCE_HOSTING_DETAILS: false,
    IOS_AND_ANDROID_APP_DETAILS: false,
    CONTENT_PERSONALIZATION_CDP_DETAILS:false,
    DATA_PRIVACY_COOKIE_MANAGEMENT_CMP_DETAILS: false,
    CRM_DETAILS: false,
    CHATBOT_DETAILS: false,
    ECOMMERCE_ENABLEMENT_DETAILS: false,
    LOYALTY_SOLUTION_DETAILS: false,
    LISTENING_AND_RESPONSE_MANAGEMENT_DETAILS: false,
    MARKETING_AUTOMATION_DETAILS: false,
    ANALYTICS_AND_DATA_VISUALISATION_DETAILS: false,
    WEBSITE_REC: 0,
    WEB_MAINTENANCE_HOSTING_REC: 0,
    IOS_AND_ANDROID_APP_REC: 0,
    CONTENT_PERSONALIZATION_CDP_REC:0,
    DATA_PRIVACY_COOKIE_MANAGEMENT_CMP_REC: 0,
    CRM_REC: 0,
    CHATBOT_REC: 0,
    ECOMMERCE_ENABLEMENT_REC: 0,
    LOYALTY_SOLUTION_REC: 0,
    LISTENING_AND_RESPONSE_MANAGEMENT_REC: 0,
    MARKETING_AUTOMATION_REC: 0,
    ANALYTICS_AND_DATA_VISUALISATION_REC: 0,
    showGuidedTour: false,
    tourName: true,
    tourSwitch: false,
    tourSwitchDisable: false,
    tourDetails: false,
    tourSlide: false,
    tourBudget: false,
    firstActiveSection: ''
  }

  toggleOptionCard = (e) => {
    if (e.target.nodeName == 'INPUT') {
      e.target.parentNode.parentNode.parentNode.parentNode.classList.toggle(
        'added'
      );
    }
  }

  toggleValueDescriptions = (key) => {
    this.setState({
      [key+'_DETAILS']: !this.state[key+'_DETAILS']
    });
  }

  pushAsideByValue = (value, max, data) => {
      var rec = this.state[data.Key+'_REC'];
      let margin = (rec - value) / (max - value) * 100;
      margin = margin - 3.8;
      return { marginLeft: margin + '%' };
  }

  /**
   * To set the next section ID
   * **/
  setNextSectionId = () => {
    let currentIndex = 0;
    let nextSection = '';
    if(this.props.activeSection) {
      for(let i=0;i<this.state.sectionList.length;i++) {
        if(this.state.sectionList[i] === this.props.activeSection) {
          currentIndex = i;
        }
      }
      if(currentIndex < this.state.sectionList.length) {
        nextSection = this.state.sectionList[currentIndex+1];
      }
    }

    if(this.state.nextSection) {
      let gotoSectionIdex = this.state.sectionList.indexOf(this.state.nextSection);
      if((gotoSectionIdex+1) <= this.state.sectionList.length) {
        this.setState({
          nextSection: this.state.sectionList[gotoSectionIdex+1]
        })
      }
    }
    
    return nextSection;
  }

  componentDidMount = () => {

    
    let {SELECTED_PLANS} = this.state;
    let showGuidedTour = localStorage.getItem('SHOW_GUIDED_TOUR');
    if(showGuidedTour === 'true') {
      this.setState({
        showGuidedTour: true
      });
    }

    if(this.props.match.params.section) {
      this.props.setBPActiveSection(this.props.match.params.section);
    }

    let firstTech = this.state.firstActiveSection || '';
    /* Setting the efault data */
    if(this.props.bpMasterData) {
      this.props.bpMasterData.map((data, i)=> {

        /* Pushing the selected plans to an array */
        if(data.HasPartnerSelected) {
          if(firstTech.length === 0) {
            firstTech = data.Key;
          }
          SELECTED_PLANS.push(data.Key);
          this.setState({SELECTED_PLANS: SELECTED_PLANS});
        }

        /* Setting the recommended budget for each plan */
        this.setState({
          [data.Key]: data.Recommended
        });
        this.setState({
          [data.Key+'_REC']: data.Recommended
        });
      })

      if(firstTech) {
        this.setState({
          firstActiveSection: firstTech
        });
      }
    }

    //GTM PageLoad

    try {
      let pagePath = window.location ? window.location.pathname : "";
      if (window && window.dataLayer) {
         sendPageViewGTM("Business Planner: Budget Planner", pagePath);
      }
    } catch (e) {}
  }

  /**
   * Load the next section ID, which we stored in the state already
   * **/
  loadNextSection = () => {

    let {bpMasterData, activeSection} = this.props;
    let itemList = [];
    var emptyAllocation = [];
    if(bpMasterData) {
      bpMasterData.map((data, i)=> {
        if((data.Section === activeSection) && data.HasPartnerSelected) {
          let allocationData = '';
          if(this.state[data.Key+'_ALLOCATION'] === '') {
              /*if(data.BudgetType === 'OneOff') {
                allocationData = this.state.OneOff[0];
              } else if(data.BudgetType === 'Regular') {
                allocationData = this.state.Regular[0];
              }*/
              emptyAllocation.push(data.Key);
          } else {
            allocationData = this.state[data.Key+'_ALLOCATION'];
          }

          if(allocationData !== '') {
            itemList.push({
              Key: data.Key,
              Recommended: this.state[data.Key] ? parseInt(this.state[data.Key]): '',
              Allocation: allocationData
            })
          }
          
        }
      })

      if(emptyAllocation.length === 0)  {
        this.props.setBulkPlanData(itemList);
      }
    }
    
    if(emptyAllocation.length === 0)  {
      let nextSection;
      document.querySelector(`.error-banner`).classList.add('not-visible');
      if(this.props.match.params.section && this.props.match.params.section.length > 0) {
        this.props.history.push('/bp/complete-planner');
      } else {
        nextSection = this.setNextSectionId();
        this.props.setBPActiveSection(nextSection);
      }
      if(nextSection === 'Reach') {
        this.props.history.push('/bp/budget-planner-reach');
      }
    } else{
      emptyAllocation.map((channel,i)=> {
        document.querySelector(`#${channel} .value-error-label`).classList.remove('not-visible');
        document.querySelector(`#${channel} .option-value-select`).classList.add('error-border');
      })
      document.querySelector(`.error-banner`).classList.remove('not-visible');
      
    }
    
  }

  /**
   * Update the Budget for each plan upon selection
   * **/
  updateBudgetWithKey = (masterData, e) => {
    this.setState({
      [masterData.Key]: e.target.value
    })
    this.props.setPlanBudget(masterData.Key, e.target.value)
  }

  /**
   * Update the Allocation type(one off, subscription) for each plan upon selection
   * **/
  updateBudgetAllocation = (masterData, e) => {
    if(e.target.value !== 'Allocate budget') {
      this.setState({
        [masterData.Key+'_ALLOCATION']: e.target.value
      })
      
      document.querySelector(`#${masterData.Key}_MSG`).classList.remove('not-visible');
      document.querySelector(`#${masterData.Key} .value-error-label`).classList.add('not-visible');
      document.querySelector(`#${masterData.Key} .option-value-select`).classList.remove('error-border');
      this.props.setPlanAllocation(masterData.Key, e.target.value);
    } else{
      this.setState({
        [masterData.Key+'_ALLOCATION']: ''
      })
      document.querySelector(`#${masterData.Key}_MSG`).classList.add('not-visible');
    }
  }

  handleCardUpdate = (masterData, e) => {
    this.props.setPlanSelectedState(masterData.Key, e.target.checked)
  }

  ctaTour = (tour) => {
    if(tour === 'name') {
      this.setState({
        tourName: false,
        tourDetails: false,
        tourSlide: false,
        tourSwitch: true
      })
    } else if(tour === 'switch') {
      this.setState({
        tourName: false,
        tourSwitch: false,
        tourSlide: false,
        tourDetails: true
      })
    } else if(tour === 'details') {
      this.setState({
        tourName: false,
        tourSwitch: false,
        tourSlide: true,
        tourDetails: false
      })
    } else if(tour === 'slide') {
      this.setState({
        tourName: false,
        tourSwitch: false,
        tourSlide: false,
        tourDetails: false,
        tourBudget: true
      })
    } else if(tour === 'budget') {
      this.setState({
        tourName: false,
        tourSwitch: false,
        tourSlide: false,
        tourDetails: false,
        tourBudget: false
      });
      let ppid =  localStorage.getItem('ppid');
      axios.post(`${API_BASE_URL}/business-planner/guidedtour-status`, {
        "ppid": ppid
      })
      .then(res => {
          if(res) {
              this.setState({
                showGuidedTour: false
              });
              localStorage.setItem('SHOW_GUIDED_TOUR', false)
          }
      })      
    }
  }

  render() {
    const {bpMasterData, activeSection} = this.props;
    const {nextSection, SELECTED_PLANS, sectionList, showGuidedTour} = this.state;
    var budgetType = '';

    return(
      <div className='wizard-page'>
        <div className="hp-nav w-100">
          <Header />
        </div>
        {this.state.showGuidedTour &&
          <div className="guided-tour-wrap"></div>
        }
        <TimeLine sectionList={sectionList} currentSection={activeSection}/>
        <div className='d-flex w-100 justify-content-center flex-column'>
          {/* container */}
          <div className='error-banner not-visible'>Red fields are mandatory</div>
          <div className='container budget-planner'>
            <div className='wizard-container'>
              <div className='step-header'>
                <h2>
                  { (()=>{
                      if(activeSection === 'Engage') { return (<img src={engage} className='header-icon' />)}
                      else if(activeSection === 'Transact') { return (<img src={transact} className='header-icon' />)}
                      else if(activeSection === 'Retain') { return (<img src={retain} className='header-icon' />)}
                      else if(activeSection === 'Reach') { return (<img src={reach} className='header-icon' />)}
                  })()
                  }
                  <span className='header-text'>
                    Let's start with
                    <strong> {activeSection}</strong>
                  </span>
                </h2>
                <TimerComponent />
              </div>

              {bpMasterData && activeSection && bpMasterData.filter(bpMaster=> bpMaster.Section === activeSection).map((masterData, index) => (
                <div className={masterData.HasPartnerSelected ? `option-card added` : 'option-card'} key={masterData.Key}>
                  <div className='option-card-top'>
                    <div className='option-card-header'>
                      <div className='option-card-title'>
                        {masterData.Name}
                        {(showGuidedTour && (this.state.firstActiveSection === masterData.Key) && this.state.tourName) &&
                          <div className="tour-tech-name">
                              <div className="tour-tech-content">
                                <div className="tour-tech-highlight">{masterData.Name}</div>
                                <div className="tour-tech-text">Your pre-selected technology is shown here</div>
                                <div className="tour-tech-cta" onClick={this.ctaTour.bind(this, 'name')}>Okay</div>
                              </div>
                          </div>
                        }
                      </div>
                      <div className='option-card-label'>Basic Budget {masterData.CurrencySymbol}{Math.round(masterData.Basic)}</div>
                    </div>

                    <div
                      className='option-card-toggle'
                      onClick={this.toggleOptionCard.bind(this)}
                    >
                      <label className='custom-control-prefix'>Remove</label>
                      <div className='custom-control custom-switch'>
                        <input
                          type='checkbox'
                          className='custom-control-input'
                          id={`customSwitchesChecked-${index}`}
                          onChange={this.handleCardUpdate.bind(this, masterData)}
                          checked={masterData.HasPartnerSelected ? true : false}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={`customSwitchesChecked-${index}`}
                        >
                          Add
                        </label>
                        {(showGuidedTour && (this.state.firstActiveSection === masterData.Key) && this.state.tourSwitch) &&
                          <div className="tour-tech-tour">
                              <div className="tour-tech-add">
                                <div className="tour-tech-highlight">
                                 <img src={add} className="img-add"/>
                                </div>
                                <div className="tour-tech-text">You can add and remove a technology from your plan here</div>
                                <div className="tour-tech-cta" onClick={this.ctaTour.bind(this, 'switch')}>Okay</div>
                              </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  {masterData.HasPartnerSelected && 
                    <div className='option-card-content'>
                      <h5 className='option-sub-title'>
                        Budget
                        <span className='md-only-text'>
                          {' '}
                          - Basic | Recommended | Advanced
                        </span>
                        <span
                          className='option-details'
                          onClick={this.toggleValueDescriptions.bind(this, masterData.Key)}
                        >
                          Details +
                          {(showGuidedTour && (this.state.firstActiveSection === masterData.Key) && this.state.tourDetails) &&
                            <div className="tour-tech-tour">
                                <div className="tour-tech-details">
                                  <div className="tour-tech-highlight">
                                    <img src={details} className="img-details"/>
                                  </div>
                                  <div className="tour-tech-text">Find out about Budget and which features Are included under Basic, Recommended and Advanced</div>
                                  <div className="tour-tech-cta" onClick={this.ctaTour.bind(this, 'details')}>Okay</div>
                                </div>
                            </div>
                          }
                        </span>
                        {this.state[masterData.Key+'_DETAILS'] &&
                          <div className='option-levels-description'>
                            <p className='option-levels-info'>
                              {bpChannelData[masterData.Key].DESCRIPTION}
                            </p>
                            <div className='row'>
                              <div className='col-md'>
                                <h4 className='level-title'>BASIC LEVEL</h4>
                                <ul>
                                  {bpChannelData[masterData.Key] && bpChannelData[masterData.Key].BASIC && bpChannelData[masterData.Key].BASIC.map((item, i)=>(
                                    <li>{item}</li>
                                  ))}
                                </ul>
                              </div>
                              <div className='col-md'>
                                <h4 className='level-title'>RECOMMENDED</h4>
                                <ul>
                                  {bpChannelData[masterData.Key] && bpChannelData[masterData.Key].RECOMMENDED && bpChannelData[masterData.Key].RECOMMENDED.map((item, i)=>(
                                    <li>{item}</li>
                                  ))}
                                </ul>
                              </div>
                              <div className='col-md'>
                                <h4 className='level-title'>ADVANCED</h4>
                                <ul>
                                  {bpChannelData[masterData.Key] && bpChannelData[masterData.Key].ADVANCED && bpChannelData[masterData.Key].ADVANCED.map((item, i)=>(
                                    <li>{item}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        }
                      </h5>

                      <div className='option-text'>
                        Your annual budget for {masterData.Name} is,
                      </div>
                      <div className='option-value'>{masterData.CurrencySymbol}{Math.round(masterData.Recommended)}{masterData.CurrencySuffix}</div>

                      <div className='option-slider-container'>
                        <label htmlFor={`customRange${index}`} className='option-slider-label'>
                          <span className='min-value-label'>Basic</span>
                          <span
                            className='best-value-label'
                            style={this.pushAsideByValue(masterData.Basic, masterData.Advanced, masterData)}
                          >
                            | Recommended
                            {(showGuidedTour && (this.state.firstActiveSection === masterData.Key) && this.state.tourSlide) &&
                              <div className="tour-tech-slider">
                                  <div className="tour-tech-slider">
                                    <div className="tour-tech-highlight">
                                        <img src={slider} className="img-slider"/>
                                    </div>
                                    <div className="tour-tech-text">Use the slider to set the budget amount</div>
                                    <div className="tour-tech-cta" onClick={this.ctaTour.bind(this, 'slide')}>Okay</div>
                                  </div>
                              </div>
                            }
                          </span>
                          <span className='max-value-label'>Advanced</span>
                        </label>
                        <input
                          type='range'
                          className='custom-range'
                          id={`customRange${index}`}
                          max={masterData.Advanced}
                          min={masterData.Basic}
                          value={this.state[masterData.Key]}
                          step={masterData.Basic}
                          onChange={this.updateBudgetWithKey.bind(this, masterData)}
                        />
                        <label className='option-slider-label'>
                          <span className='min-value-label'>{masterData.CurrencySymbol}{Math.round(masterData.Basic)}{masterData.CurrencySuffix}/annum</span>
                          <span className='max-value-label'>{masterData.CurrencySymbol}{Math.round(masterData.Advanced)}{masterData.CurrencySuffix}/annum</span>
                        </label>
                      </div>
                      <div className='option-value-selecter' id={masterData.Key}>
                        <div className='not-visible value-error-label'>Allocate payment</div>
                        <select className='option-value-select' onChange={this.updateBudgetAllocation.bind(this, masterData)}>
                          {this.state[masterData.BudgetType] && this.state[masterData.BudgetType].map((type, i) => (
                            <option className='option-value-option' value={type} key={type}>
                              {(type === 'Allocate budget') ? 'Allocate budget': 'Allocate in '+ type}
                            </option>
                          ))}
                        </select>
                        {(showGuidedTour && (this.state.firstActiveSection === masterData.Key) && this.state.tourBudget) &&
                          <div className="tour-tech-tour">
                              <div className="tour-tech-budget">
                                <div className="tour-tech-highlight">
                                    <img src={budget} className="img-budget"/>
                                </div>
                                <div className="tour-tech-text budget-text">Allocate budget by Either one time payment or By subscription model</div>
                                <div className="tour-tech-cta" onClick={this.ctaTour.bind(this, 'budget')}>Okay</div>
                              </div>
                          </div>
                        }
                        <MDBIcon icon="info-circle" className="info-icon tooltip-icon" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                          <span class="tooltip-text"></span>
                        </MDBIcon>
                       
                      </div>

                      <div className='option-payment-label not-visible' id={`${masterData.Key}_MSG`}>
                      {masterData.CurrencySymbol}{Math.round(this.state[masterData.Key])} have been allocated in the {this.state[masterData.Key+'_ALLOCATION'] !== '' ? this.state[masterData.Key+'_ALLOCATION'] : '1st quarter'} in your planner.
                      </div>
                    </div>
                  }
                  
                </div>
              ))}

              <div className='next-step-container'>
                <div className='row' onClick={this.loadNextSection}>
                  <h3 className='md-col-10 next-step-label'>
                    Now, let’s set budget for <span className="next-section-label">{nextSection}</span>
                  </h3>
                  <MDBIcon className='next-step-icon' icon="arrow-right" />
                </div>
              </div>
            </div>
          </div>
          {/* /container */}
        </div>
      </div>
    )
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BudgetPlanner));