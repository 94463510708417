import React from "react";

import './startplanner.scss'

/**
 * Components import
 * **/

import Header from '../../../components/BusinessPlanner/WizardHeader/Header';
import Footer from '../../../components/Footer/Footer';

import timer from "../../../assets/icons/timer.svg";
import rightArrow from "../../../assets/icons/right-arrow.svg";
import document from "../../../assets/icons/Group739.svg";
import { MDBIcon } from "mdbreact";

import axios from "axios";
import {API_BASE_URL} from '../../../config/api-config';

class PlannerCustomise extends React.Component {

    constructor(props) {
        super(props);

    }

    state = {
        techCount: 0
    }

    ctaClick = () => {
        this.props.history.push(`/bp/budget-planner`);
    }

    componentDidMount = () => {
        let techCount = this.props.match.params && this.props.match.params.techCount
                ? this.props.match.params.techCount
                : 0;
        this.setState({
            techCount: techCount
        });

        let ppid = localStorage.getItem('ppid');
        axios.get(`${API_BASE_URL}/business-planner/guidedtour-status?ppid=`+ ppid)
        .then(res => {
            if(res && res.data) {
                localStorage.setItem('SHOW_GUIDED_TOUR', !res.data.status);
            }
        })

    }

    render() {

        return (
            <div className="planner-customise">
                <Header />
                <div className="container">
                    <div className="timer-container">
                        <img src={timer} className="stopwatch" />
                        <span className="time-left">15 Minutes Left</span>
                    </div>

                    <div className="content">
                        <div className="info-card">
                            <img className="icon" alt=""
                                src={document} />
                            <p className="info">
                                Now let’s plan budget for {this.state.techCount} technologies that you have added in your Digital Business plan.
                            </p>
                            <a className="redirection" onClick={this.ctaClick}>Plan and customise
                                <MDBIcon icon="arrow-right" className="right-arrow" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PlannerCustomise;