import React, { useState } from 'react';

/**
 * Component import
 */

/**
 * Style imports
 */
import './slider.scss'

const Slider = (props) => {

    const label = props.label;
    const valuePrefix = props.valuePrefix;
    const valueSuffix = props.valueSuffix;
    const min = props.min;
    const max = props.max;
    const initialValue = props.initialValue;
    const step = props.step ? props.step : 1;
    const displayValue = props.displayValue;
    const roundValue = props.roundValue;
    const [value, setValue] = useState(initialValue ? initialValue : min);
    const changeSliderValue = e => {
        setValue(e.target.value);
        props.handleValueChange(e.target.value);
    }

    return (
        <div className="hp-slider-container">
            {displayValue &&
                <label htmlFor="customRange1" className="slider-label">
                    <span className="value-prefix">{valuePrefix}</span>
                    <span className="value">{(value).toLocaleString("en-US")}{valueSuffix}</span>
                    <span className="value-postfix">{label}</span>
                </label>
            }
            {roundValue &&
                <input type="range" min={Math.round(min)} max={Math.round(max)} className="custom-range" id="customRange1" step={step} onChange={changeSliderValue} value={value} />
            }
            {roundValue &&
                <div className='slider-range option-slider-label'>
                    <span className="min-value-label">{valuePrefix ? valuePrefix + Math.round(min) + valueSuffix: Math.round(min) + valueSuffix}</span>
                    <span className="max-value-label">{valuePrefix ? valuePrefix + Math.round(max) + valueSuffix: Math.round(max) + valueSuffix}</span>
                </div>
            }
            {!roundValue &&
                <input type="range" min={(min)} max={(max)} className="custom-range" id="customRange1" step={step} onChange={changeSliderValue} value={value} />
            }
            {!roundValue &&
                <div className='slider-range option-slider-label'>
                    <span className="min-value-label">{valuePrefix ? valuePrefix + (min).toLocaleString("en-US") + valueSuffix: (min).toLocaleString("en-US") + valueSuffix}</span>
                    <span className="max-value-label">{valuePrefix ? valuePrefix + (max).toLocaleString("en-US") + valueSuffix: (max).toLocaleString("en-US") + valueSuffix}</span>
                </div>
            }
        </div>
    )
}

export default Slider;