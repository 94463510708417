import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import React from 'react'
import './edcmodal.scss'

export default function EDCModal({show,handleClose}) {
    return (
        <MDBModal isOpen={show} toggle={handleClose} centered 
        keyboard={false} className="edmodal-container">
        <MDBModalHeader toggle={handleClose} className="border-0">&nbsp;</MDBModalHeader>
        <MDBModalBody className="body">
          <p className="heading hp-regular">Thank you for your interest in our EDC program.</p>
          <p className="hp-lite">We are here to help you get connected with the industry experts. We have notified the EDCs about your selection. 
            <br/>
            <br/>
            The respective EDC shall get in touch with you.</p>
        </MDBModalBody>
      </MDBModal>
    )
}
