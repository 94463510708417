import React from 'react'
import './homebanner.scss';
import { useTranslation } from 'react-i18next';

import analytics from '../../assets/icons/UX/004-analytics.svg';
import product from '../../assets/icons/UX/001-new-product.svg';
import advice from '../../assets/icons/UX/003-advice.svg';

export default function HomeBanner(props) {
    const { t, i18n } = useTranslation();
    return(
        <div className="hp-home-page-banner">
            <div className="hp-home-banner-title">{t('UX.HOME_BANNER_TITLE')}</div>
            <div className="hp-home-banner-icons">
                <div className="hp-home-banner-icon-wrap">
                    <div className="hp-home-banner-icon">
                        <img src={analytics} />
                    </div>
                    <div className="hp-home-banner-icon-text">{t('UX.HOME_BANNER_FEATURE_1')}</div>
                </div>
                <div className="hp-home-banner-icon-wrap">
                    <div className="hp-home-banner-icon">
                        <img src={product} />
                    </div>
                    <div className="hp-home-banner-icon-text">{t('UX.HOME_BANNER_FEATURE_2')}</div>
                </div>
                <div className="hp-home-banner-icon-wrap">
                    <div className="hp-home-banner-icon">
                        <img src={advice} />
                    </div>
                    <div className="hp-home-banner-icon-text">{t('UX.HOME_BANNER_FEATURE_3')}</div>
                </div>
            </div>
            <div className="banner-modal-btn-wrap">
                <div className="hp-white-btn" onClick={props.bannerHandler}>
                    Okay
                </div>
            </div>
            
        </div>
    )
}