import React from "react";
import { TYPEFORM_URL } from "../../config/api-config";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { sendPageViewGTM } from "../../utils/util";

import { connect } from "react-redux";
import {
  LoadAssessments,
  SaveCompletedSections,
  emptyCompletedSections,
  updateAssessmentStatus,
  SaveCompletedAssessmentByType,
  SaveTotalAssessmentByType,
} from "../Home/actions";
import {
  getAssessments,
  getCompletedSections,
  getCompletedDigitalSection,
  getTotalDigitalSections,
  getCompletedServiceSections,
  getTotalServiceSections,
} from "../Home/homeReducer";

const mapStateToProps = (state) => {
  return {
    assessments: getAssessments(state),
    completedSections: getCompletedSections(state),
    CompletedDigitalSections: getCompletedDigitalSection(state),
    totalDigitalSections: getTotalDigitalSections(state),
    CompletedServiceSections: getCompletedServiceSections(state),
    totalServiceSections: getTotalServiceSections(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    LoadAssessments: (ppid) => dispatch(LoadAssessments(ppid)),
    saveComepletedSecetions: (data) => dispatch(SaveCompletedSections(data)),
    emptyCompletedSections: () => dispatch(emptyCompletedSections()),
    updateAssessmentStatus: (ppid) => dispatch(updateAssessmentStatus(ppid)),
    SaveCompletedAssessmentByType: (data, type) =>
      dispatch(SaveCompletedAssessmentByType(data, type)),
    SaveTotalAssessmentByType: (data, type) =>
      dispatch(SaveTotalAssessmentByType(data, type)),
  };
};

class AssessmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.LoadAssessments(localStorage.getItem("ppid"));
  }

  state = {
    AssessmentName: "Assessment",
    sectionsList: [],
  };

  useFilter = (arr) => {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  };

  componentDidMount() {
    //fetch form URL from page URL
    let url =
      this.props.match.params && this.props.match.params.url
        ? this.props.match.params.url
        : null;
    let section =
      this.props.match.params && this.props.match.params.section
        ? this.props.match.params.section
        : null;
    let fromQualifier =
      this.props.match.params && this.props.match.params.fromQ
        ? this.props.match.params.fromQ
        : null;
    let isRetake =
      this.props.match.params && this.props.match.params.retake
        ? this.props.match.params.retake
        : false;
    let qParams;

    if (this.props.assessments && this.props.assessments.length > 0) {
      let sectionsList = [];
      this.props.assessments.map((assessment, index) => {
        if (assessment && assessment.sections) {
          assessment.sections.map((section, index) => {
            sectionsList = [...sectionsList, section];
          });
        }
      });

      this.setState({
        sectionsList: sectionsList,
      });

      let completedSectionsList = [];
      if (sectionsList.length > 0) {
        sectionsList.map((section, index) => {
          if (section && section.hasCompleted) {
            completedSectionsList.push(section.Typeform_ID);
          }
        });
      }

      this.props.saveComepletedSecetions(completedSectionsList);
    }

    const {
      completedSections,
      CompletedDigitalSections,
      CompletedServiceSections,
      totalDigitalSections,
      totalServiceSections,
    } = this.props;
    let totalDigital = 0,
      totalService = 0;
    let completedDigital = [],
      completedService = [];
    let that = this;

    let AssessmentName = "Assessment";
    let assssmentFlag;
    if (section === "D") {
      AssessmentName = "Digital Assessment";
      assssmentFlag = "digital";
    } else if (section === "S") {
      AssessmentName = "Service Assessment";
      assssmentFlag = "service";
    }

    this.setState({
      AssessmentName: AssessmentName,
    });

    //Build typeform URL
    let ppid = localStorage.getItem("ppid");
    let partnerId = ppid;

    if (fromQualifier) {
      qParams = localStorage.getItem("qParam");
      if (qParams !== null && qParams !== undefined) {
        ppid = ppid + "&" + qParams;
      }
    }

    if (ppid) {
      let formURL = TYPEFORM_URL + url + '?typeform-welcome=0' + "#ppid=" + ppid;
      // Load typeform widget by passing form URL
      var el = document.getElementById("hp-assessment-form");
      let that = this;
      window.typeformEmbed.makeWidget(el, formURL, {
        hideFooter: false,
        hideHeaders: true,
        hideScrollbars: true,
        onSubmit: function (data) {
          /**
           * This onSubmit will be invoked after completing a section in assessment
           * isRatke, to check whether the assessent is a retake or normal flow
           */
          if (!isRetake) {
            let isLastAssessmentSectionCompleted = false;
            let completedAssessment = "";
            let { assessments } = that.props;

            /**
             * Logic to check, at the end of eash section, whether that is the last section in that assessment(Digital or Service)
             * IF it is that last, will redirect to assessent end page
             * Else, to section End page
             */
            if (assessments && assessments.length > 0) {
              assessments.map((assessment, index) => {
                if (assessment.Key.indexOf("Digital") > -1) {
                  totalDigital = assessment.sections.length;
                }

                if (assessment.Key.indexOf("Service") > -1) {
                  totalService = assessment.sections.length;
                }
                assessment.sections.map((sec, i) => {
                  if (assessment.Key.indexOf("Digital") > -1) {
                    if (sec.hasCompleted) {
                      completedDigital.push(sec.Typeform_ID);
                    }
                  }

                  if (assessment.Key.indexOf("Service") > -1) {
                    if (sec.hasCompleted) {
                      completedService.push(sec.Typeform_ID);
                    }
                  }
                });
              });
            }

            let totalDigitalLength = totalDigital;
            let totalServiceLength = totalService;
            let digtalCompleted = completedDigital;
            let serviceCompleted = completedService;

            // Updating application store with list of all completed section ID'd
            that.props.saveComepletedSecetions(url);

            // Logices to save completed section ID's assessment wise.
            if (section === "D") {
              let uniqueDigitalAssessments = that.useFilter(
                CompletedDigitalSections
              );
              that.props.SaveCompletedAssessmentByType(url, "digital");
              //that.props.SaveTotalAssessmentByType(url, 'digital')

              if (
                uniqueDigitalAssessments &&
                uniqueDigitalAssessments.length + 1 >= totalDigitalLength
              ) {
                isLastAssessmentSectionCompleted = true;
                completedAssessment = "digital";
              }
            } else if (section === "S") {
              let uniqueServiceAssessments = that.useFilter(
                CompletedServiceSections
              );
              that.props.SaveCompletedAssessmentByType(url, "service");
              //this.props.SaveTotalAssessmentByType(url, 'service')

              if (
                uniqueServiceAssessments &&
                uniqueServiceAssessments.length + 1 >= totalServiceLength
              ) {
                isLastAssessmentSectionCompleted = true;
                completedAssessment = "service";
              }
            }

            /**
             * If all the sections in Digital and Service completed redirect to section end page,
             * so it will show up the option to submit the assessmnt
             *
             * Else,
             * We will check whether the assessment competed was the last section in the current assessment.
             * Based on that we will redirect to either section end page or assessmnt end page
             *
             */
            if (
              digtalCompleted.length + 1 >= totalDigitalLength &&
              serviceCompleted.length + 1 >= totalServiceLength
            ) {
              that.props.history.push(`/section-end/${section}/${url}`);
            } else {
              if (
                isLastAssessmentSectionCompleted &&
                completedAssessment &&
                assssmentFlag.indexOf(completedAssessment) > -1
              ) {
                that.props.history.push(
                  `/assessment-end/${completedAssessment}/${url}`
                );
              } else {
                that.props.history.push(`/section-end/${section}/${url}`);
              }
            }
          } else {
            // if the assessment is taken from retake flow, redirect to dashboard once the assessment is done.
            that.props.history.push(
              `/dashboard/${partnerId}/${url}/${isRetake}`
            );
          }
        },
        opacity: 0,
      });

      this.props.updateAssessmentStatus(partnerId);
    }

    //GTM PageLoad
    try {
      let pagePath = window.location ? window.location.pathname: '';
      sendPageViewGTM('Assessment Page', pagePath);
    } catch(e){}
  }

  render() {
    const devStyle = {
      height: "90vh",
      width: "100%",
      overflow: "hidden",
    };

    const { AssessmentName } = this.state;
    const { t } = this.props;
    const selectedlan = localStorage.getItem('lan') || 'en';

    return (
      <div className="d-flex justify-content-center hp-layout-wrap w-100">
        <div className="d-flex hp-layout hp-home">
          <Header />
          <p className=" ml-3 bread-crumb hp-lite">
            <Link to={`/dashboard/${localStorage.getItem("ppid")}`}>{t('COMMON.COMMON_HOME')}</Link>{" "}
            &gt;{" "}
            <Link to={`/dashboard/${localStorage.getItem("ppid")}`}>
              {AssessmentName}
            </Link>{" "}
            &gt;{" "}
            <span>
              {this.props.match.params?.name === "Qualifier"
                ? "Partner profile"
                : this.props.match.params?.name}
            </span>
          </p>
          <div className="" id="hp-assessment-form" style={devStyle}></div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssessmentPage));
