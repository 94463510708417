
import React from 'react';
import GaugeChart from 'react-gauge-chart';

import { BrowserRouter, Switch, Redirect, Route, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { MDBIcon } from 'mdbreact';

import { connect } from 'react-redux';

import report from "../../assets/images/report/002-analytics.svg";
import report_img from "../../assets/images/report/002-analytics.svg";

import gobal_active from "../../assets/images/report/global.svg";
import employee from "../../assets/images/report/employee.svg";
import solidarity from "../../assets/images/report/solidarity.svg";
import transaction from "../../assets/images/report/transaction.svg";

import gobal_disabled from "../../assets/images/report/global_disabled.svg";
import employee_disabled from "../../assets/images/report/employee_disabled.svg";
import solidarity_disabled from "../../assets/images/report/solidarity_disabled.svg";
import transaction_disabled from "../../assets/images/report/transaction_disabled.svg";

import portfolio_active from "../../assets/images/report/portfolio_active.svg";
import lorry_active from "../../assets/images/report/lorry_active.svg";
import revenue_active from "../../assets/images/report/revenue_active.svg";

import portfolio_disabled from "../../assets/images/report/portfolio_disabled.svg";
import lorry_disabled from "../../assets/images/report/lorry_disabled.svg";
import revenue_disabled from "../../assets/images/report/revenue_disabled.svg";

import download_img from "../../assets/icons/download.svg";
import hp_logo from '../../assets/icons/hp-logo.svg';


import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PDFRadar from '../../components/RadarChart/RadarChart'
import {getUserName} from '../../utils/util';

import axios from "axios";
import {API_BASE_URL} from '../../config/api-config';


import './reportpdf.scss';


import {loadAssessmentReport, loadRecommendationsBySection, loadRecommendationsBySubSection, loadFullreport} from '../../actions/reportAction';
import {getDetailedReport, getSectionRecommendation, getSubSectionRecommendation, getLoadingStatus, getFullReport} from '../../reducers/reportReducer';

import Recommendation from "../../components/Recommendation/Recommendation";
import Tookit from "../../components/Toolkit/Toolkit";
import RadarComponent from '../../components/RadarComponent';
import PageSpeedResult from '../../components/PageSpeedResult/PageSpeedResult';

import {loadPageSpeedReport} from '../../actions/pspeedAction';
import { getFullSiteReports } from '../../reducers/pspeedReducer';


const mapStateToProps = (state) => {
    return {
        reportData: getDetailedReport(state),
        sectionRecommendation: getSectionRecommendation(state),
        subSectionRecommendation: getSubSectionRecommendation(state),
        loading: getLoadingStatus(state),
        fullReport: getFullReport(state),
        siteReportList: getFullSiteReports(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadAssessmentReport: () => dispatch(loadAssessmentReport()),
        loadSectionRecommendation: (sectionID) => dispatch(loadRecommendationsBySection(sectionID)),
        loadSubSectionRecommendation: (subsectionID) => dispatch(loadRecommendationsBySubSection(subsectionID)),
        loadFullreport: (ppid) => dispatch(loadFullreport(ppid)),
        loadPageSpeedReport: (ppid) => dispatch(loadPageSpeedReport(ppid))
    }
}


class ReportPDF extends React.Component {

    componentDidMount = () => {
        if(this.props.match.params.ppid) {
            this.props.loadFullreport(this.props.match.params.ppid);
            this.props.loadPageSpeedReport(this.props.match.params.ppid);
            this.getUserNamefromService();
        }
    }

    state = {
        userName: 'Ethinos',
        imgObj: {
            'Reach':gobal_active,
            'Engage':employee,
            'Transact':transaction,
            'Retain':solidarity,
            'Offering Portfolio': portfolio_active,
            'Revenue Growth': revenue_active,
            'Services & Solutions Delivery': lorry_active
        }
    }

    getUserNamefromService = () => {
        let ppid = this.props.match.params.ppid;
        try {
            if(ppid) {
                axios.get(`${API_BASE_URL}/partners/getdetails?ppid=`+ppid)
                .then(res => {
                    let data = res.data.data;
                    if(data && data.length > 0 && data[0].PartnerName && data[0].PartnerName !== null) {
                        this.setState({
                            userName: data[0].PartnerName
                        });
                    }
                }).catch((err)=> {
                    console.info('Error ---', err);
                })
            }
        }catch(e) {}
        
    }

    render() {

        const {fullReport, siteReportList} = this.props;
        const {imgObj, userName} = this.state;
        const { t } = this.props;
        let score = 0;
        let peerScore = 0;
        let score_exec = 0;
        let peerScore_exec = 0;
        let assessmentSectionScore = 0;
        let partnerPosition = '';
        let partnerDigitalPosition = '';
        let digitalChartClass = false;
        const chartDesktopStyle = {
            width: '200px'
        };

        const guageExecSummary = {
            width: '130px'
        };

        function getParameterByName(name, url = window.location.href) {
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        let lanParam = getParameterByName('lan') || 'en';
        let dataParam = getParameterByName('date') || new Date().toDateString();

        // let lanParam = window.location.href.indexOf('?lan=') > -1 ? window.location.href.split('?lan=')[1] : 'en';
        // let dataParam = window.location.href.indexOf('&date=') > -1 ? window.location.href.split('&date=')[1] : new Date().toDateString();
        
        return(
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                <div className="d-flex hp-layout hp-home">
                    <div className="hp-pdf-report-page hp-content-wrap">
                        {fullReport && fullReport.length > 0 && fullReport.map((report, index) => (
                        <div className={`${report.Key.indexOf('Service') > -1 ? 'executive-sumamry service-summary': 'executive-sumamry'}`} key={index}>
                                {report && report.Key && (report.Key.indexOf('Digital') > -1) &&
                                    <div className="exec-summary-title">
                                        <div className="title-left">
                                            <div className="title-logo">
                                                <img src={hp_logo} className="img-fluid" />
                                            </div>
                                            <div className="title-text">
                                                Assessment
                                    </div>
                                        </div>

                                        <div className="title-right">
                                            <div className="title-img">
                                                <img src={report_img} className="img-fluid report-image"/>
                                            </div>
                                            <div className="title-content-wrap">
                                                <div className="title-1">{t('PDF.PDF_EXECUTIVE_SUMMARY')}</div>
                                                <div className="title-2">{t('REPORT.REPORT_ASSESSMENT_COMPLETED')}</div>
                                                <div className="title-3">{t('REPORT.REPORT_LAST_DATE')}: {dataParam.replace(/%20/g, " ")}</div>
                                            </div>
                                        </div>

                                    </div>
                                }

                                <div className="exec-assessment-summary">
                                    <div className="assessment-info">
                                        <div className="assessment-name">
                                            <div className="line-1">{report.name}</div>
                                            <div className="line-2">{t('PDF.PDF_RESULTS')}</div>
                                        </div>
                                        {(()=>{

                                            if(report && report.scorePercentage) {
                                                assessmentSectionScore = Math.round(report.scorePercentage);
                                            }

                                            if(assessmentSectionScore) {
                                                if(assessmentSectionScore <= 25) {
                                                    partnerPosition = 'Lagging';
                                                } else if(assessmentSectionScore > 25 && assessmentSectionScore <= 50) {
                                                    partnerPosition = 'Intermediate';
                                                } else if(assessmentSectionScore > 50 && assessmentSectionScore <= 75) {
                                                    partnerPosition = 'Advanced';
                                                } else if(assessmentSectionScore > 75 && assessmentSectionScore <=100) {
                                                    partnerPosition = 'Leader';
                                                }

                                                if(report.Key && report.Key.indexOf('Digital') > -1) {
                                                    partnerDigitalPosition = partnerPosition;
                                                }
                                            }
                                        })()}

                                         {/* -------------- Score Bar Starts-------------- */}
                                    
                                <div className="score-bar">
                                    <div className="report-score-dflex">
                                        <p className="title-head-text-left">{t('PDF.PDF_SCORE', {assessmentName: report.name})}<br /> {t('PDF.PDF_SCORE_TEXT')}</p>
                                        <p className="title-head-text-right"> {t('REPORT.REPORT_SCORE_TITLE', {score: Math.ceil(fullReport?.[report.name =='Digital Assessment'?0:1]?.scorePercentage || 0)})}</p>
                                    </div>
                                    <div className="score-bar">
                                        <div className="score-bar-fill" style={{width: `${(fullReport?.[report.name =='Digital Assessment'?0:1]?.scorePercentage || 0)}%`}}></div>
                                    </div>
                                </div>
                                {/* -------------- Score Bar Ends -------------- */}
                                        <div className="assessment-welcome-msg">{t('REPORT.REPORT_USER_POSITION', {name: userName, position: partnerPosition})}</div>
                                        <div className="assessment-welcome-desc">
                                        {t('PDF.PDF_SEE_HOW_PERFORMED')}
                                    </div>
                                                                       </div>
                                    <div className="assessment-chart">
                                        <PDFRadar chartdata={report} />
                                    </div>
                                </div>
                                {report && report.sections && report.sections.length > 0 && report.sections.map((section, i) => (
                                    <div className="exec-summary-wrap" key={i}>
                                        <div className="exec-section-sumamry">
                                            <div className="section-info">
                                                <div className="info-left">
                                                    <div className="section-img">
                                                        <img src={imgObj[section.Name]} className="img-fluid"/>
                                                    </div>
                                                </div>
                                                <div className="info-right">
                                                    <div className="section-name">{section.Name}</div>
                                                    <div className="section-desc">{t('REPORT.REPORT_SCORED_COMPARE_PEER', {sectionName: section.Name})}</div>
                                                </div>
                                            </div>
                                            <div className="section-chart">
                                                <div className="section-chart-item-left">
                                                    <GaugeChart
                                                        id="gauge-chart1"
                                                        nrOfLevels={8}
                                                        cornerRadius={0}
                                                        hideText={true}
                                                        colors={["#D3F2FF", "#007DB2"]}
                                                        arcWidth={0.2}
                                                        percent={section.scorePercentage? section.scorePercentage: 0}
                                                        style={guageExecSummary}
                                                    />
                                                    <div className="score-text">{t('REPORT.REPORT_YOUR_SCORE')}</div>
                                                    <div className="score-val">{(section.scorePercentage * 100).toFixed(2)}%</div>
                                                </div>
                                                <div className="section-chart-item-right">
                                                    <GaugeChart
                                                        id="gauge-chart1"
                                                        nrOfLevels={8}
                                                        cornerRadius={0}
                                                        hideText={true}
                                                        colors={["#D3F2FF", "#007DB2"]}
                                                        arcWidth={0.2}
                                                        percent={section.ScorePercentagePeerScore? section.ScorePercentagePeerScore: 0}
                                                        style={guageExecSummary}
                                                    />
                                                    <div className="score-text">{t('REPORT.REPORT_PEER_SCORE')}</div>
                                                    <div className="score-val">{(section.ScorePercentagePeerScore * 100).toFixed(2)}%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="exec-subsection-summary">
                                            <div className="exec-subsection-row">
                                                {section && section.sub_sections && section.sub_sections.length > 0 && section.sub_sections.map((sub, j) => {
                                                    if(sub && sub.Name && sub.Name !== 'Qualifier questions')
                                                        return <div className="exec-subsection-item" key={j}>
                                                            <div className="subsection-name">
                                                                {sub.Name}
                                                            </div>
                                                            <div className="subsection-score">
                                                                {(()=>{
                                                                    score_exec = sub.ScorePercentage ? sub.ScorePercentage * 100 : 0;
                                                                    score_exec = Math.round(score_exec);

                                                                    peerScore_exec = sub.ScorePercentagePeerScore ? sub.ScorePercentagePeerScore * 100 : 0;
                                                                    peerScore_exec = Math.round(peerScore_exec);
                                                                })()}
                                                                <div className="score-text">{t('REPORT.REPORT_SCORE_TITLE', {score: score_exec})}</div>
                                                                <div className="score-bg">
                                                                    <div className="score-fg" style={{width:`${score_exec}%`}}></div>
                                                                </div>
                                                            </div>
                                                            <div className="subsection-desc">
                                                                {(() => {
                                                                    if(score_exec <=25) {
                                                                        return(
                                                                            <p>
                                                                                {t('PDF.PDF_POSITION_LAGGING_MSG', {sectionName: sub.Name,score: score_exec,peerScore: peerScore_exec})}
                                                                            </p>
                                                                        )
                                                                    } else if(score_exec > 25 && score_exec <= 50) {
                                                                        return (
                                                                            <p>
                                                                                {t('PDF.PDF_POSITION_INTERMEDIATE_MSG', {sectionName: sub.Name,score: score_exec,peerScore: peerScore_exec})}
                                                                            </p>
                                                                        )

                                                                    } else if(score_exec > 50 && score_exec <= 75) {
                                                                        return (
                                                                            <p>
                                                                                {t('PDF.PDF_POSITION_GOOD_MSG', {sectionName: sub.Name,score: score_exec,peerScore: peerScore_exec})}
                                                                            </p>
                                                                        )
                                                                    } else if(score_exec > 75 && score_exec <=100) {
                                                                        return (
                                                                            <p>
                                                                                {t('PDF.PDF_POSITION_EXCELLENT_MSG', {sectionName: sub.Name,score: score_exec,peerScore: peerScore_exec})}
                                                                            </p>
                                                                        )
                                                                    }

                                                                })()}
                                                            </div>
                                                        </div>
                                                
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        ))}
                        <div className="pdf-report-header">
                            <div className="header-img">
                                <img src={hp_logo} className="img-fluid" />
                            </div>
                            <div className="header-text">{t('PDF.PDF_FULL_REPORT')}</div>
                        </div>

                        <div className="d-flex hp-report-top">
                            <div className="d-flex hp-report-top-left-panel">
                                <div className="d-flex pdf-top-panel-icon">
                                    <img src={report} className="img-fluid report-image"/>
                                </div>
                                <div className="d-flex flex-column ml-2">
                                    <div className="hp-report-page-link1">{t('DASHBOARD.DASHBOARD_CARD_VIEW_REPORT')}</div>
                                    <div className="hp-report-page-link2">{t('REPORT.REPORT_ASSESSMENT_COMPLETED')}</div>
                                    <div className="hp-report-page-link3">{t('REPORT.REPORT_LAST_DATE')}: {dataParam.replace(/%20/g, " ")}</div>
                                </div>
                            </div>
                            <div className="d-flex flex-column hp-report-top-right-panel">
                                <div className="d-flex hp-report-heading">
                                    {t('REPORT.REPORT_USER_POSITION', {name: userName, position: partnerDigitalPosition})}
                                </div>
                                <div className="d-flex hp-report-content">
                                    {t('REPORT.REPORT_DESC')}
                                </div>
                                {/* -------------- Score Bar Starts-------------- */}
                                <div className="score-bar">
                                    <div className="report-score-dflex">
                                        <p className="title-head-text-left">Your digital <br />assessment score</p>
                                        <p className="title-head-text-right">{t('REPORT.REPORT_SCORE_TITLE', {score: Math.ceil(fullReport?.find(item => item.Key=='Digital Assessment')?.scorePercentage)||0})}</p>
                                    </div>
                                    <div className="score-bar">
                                        <div className="score-bar-fill" style={{ width: `${fullReport?.find(item => item.Key=='Digital Assessment')?.scorePercentage}%` }}></div>
                                    </div>
                                </div>
                                {/* -------------- Score Bar Ends -------------- */}
                            </div>
                        </div>

                        {fullReport && fullReport.length > 0 && fullReport.map((report, index) => (
                            <div className={`${(report.Key.indexOf('Service') > -1) ? 'service-report-body': ''} pdf-report-body`} key={index}>
                                {/* HEADER */}
                                <div className="pdf-report-section-header">
                                    <div className="pdf-header-level1">{report.name}</div>
                                    <div className="pdf-header-level2">Results</div>
                                    <div className="pdf-header-level3">{t('PDF.PDF_SCROLL_DOWN')}</div>
                                </div>

                                {(()=>{

                                    if(report && report.scorePercentage) {
                                        assessmentSectionScore = Math.round(report.scorePercentage);
                                    }

                                    if(assessmentSectionScore) {
                                        if(assessmentSectionScore <= 25) {
                                            partnerPosition = 'Lagging';
                                        } else if(assessmentSectionScore > 25 && assessmentSectionScore <= 50) {
                                            partnerPosition = 'Intermediate';
                                        } else if(assessmentSectionScore > 50 && assessmentSectionScore <= 75) {
                                            partnerPosition = 'Advanced';
                                        } else if(assessmentSectionScore > 75 && assessmentSectionScore <=100) {
                                            partnerPosition = 'Leader';
                                        }
                                        if(report.Key && report.Key.indexOf('Digital') > -1) {
                                            partnerDigitalPosition = partnerPosition;
                                        }

                                    }
                                })()}

                                {/* RADAR CHART */}
                                <div className="hp-report-survey-chart d-flex flex-column mt-4">
                                    <div className="hp-report-survey-chart-title d-flex w-100 justify-content-center">
                                        Your overall position in Digital is: <span className="hp-report-partner-position">{partnerPosition}</span>
                                    </div>
                                    {(()=>{

                                        if(report.Key.indexOf('Digital') > -1) {
                                            digitalChartClass = true;
                                        }
                                    })()}
                                    <div className={`w-100 d-flex align-items-center justify-content-center report-radar-wrap ${digitalChartClass ? 'digital-spider-chart' : ''} mt-4`}>
                                        <RadarComponent data1={report} />
                                    </div>
                                    {lanParam && lanParam === 'en' &&
                                        <div className="d-flex justify-content-center p-2 peer-rating-disclaimer">
                                            <div className="label-wrap">
                                                <span className="disclaimer-label">{t('REPORT.REPORT_DISCLAIMER_LABEL')}</span> {t('REPORT.REPORT_DISCLAIMER_TEXT')}
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* SEE MORE TEXT */}
                                <div className="pdf-report-see-details-text">
                                {t('PDF.PDF_SEE_HOW_PERFORMED')}​
                                </div>

                                {/* SUB-SECTION REPORT */}

                                {report && report.sections && report.sections.length > 0 && report.sections.map((section, i) => (
                                    <div className="pdf-section-container" key={i}>
                                        {/* SECTION HEADER */}
                                        <div className="pdf-section-header" >
                                            <div className="pdf-section-header-icon">
                                                <img src={imgObj[section.Name]} className="img-fluid"/>
                                            </div>
                                            <div className="pdf-section-header-text">{section.Name}</div>
                                        </div>

                                        {/* SECTION TITLE */}
                                        <div className="pdf-section-desc">{t('REPORT.REPORT_SCORED_COMPARE_PEER', {sectionName: section.Name})}</div>
                                        {/* SECTION GUAGE CHART */}
                                        <div className="pdf-report-guage-wrap" >
                                            <div className="hp-report-guage-chart-wrap">
                                                <div className="guage-chart d-none d-md-block flex-column">
                                                    <GaugeChart
                                                        id="gauge-chart1"
                                                        nrOfLevels={8}
                                                        cornerRadius={0}
                                                        hideText={true}
                                                        colors={["#D3F2FF", "#007DB2"]}
                                                        arcWidth={0.2}
                                                        percent={section.scorePercentage ? section.scorePercentage: 0}
                                                        style={chartDesktopStyle}
                                                    />
                                                    <div className="hp-report-guage-title">
                                                    {t('REPORT.REPORT_YOUR_SCORE')}
                                                    </div>
                                                    <div className="hp-report-guage-title-score">
                                                        {(section.scorePercentage * 100).toFixed(2)}%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hp-report-guage-chart-wrap" >
                                                <div className="guage-chart d-none d-md-block flex-column">
                                                    <GaugeChart
                                                        id="gauge-chart1"
                                                        nrOfLevels={8}
                                                        cornerRadius={0}
                                                        hideText={true}
                                                        colors={["#D3F2FF", "#007DB2"]}
                                                        arcWidth={0.2}
                                                        percent={section.ScorePercentagePeerScore? section.ScorePercentagePeerScore: 0}
                                                        style={chartDesktopStyle}
                                                    />
                                                    <div className="hp-report-guage-title">
                                                    {t('REPORT.REPORT_PEER_SCORE')}
                                                    </div>
                                                    <div className="hp-report-guage-title-score">
                                                        {(section.ScorePercentagePeerScore * 100).toFixed(2)}%
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {lanParam && lanParam === 'en' &&
                                            <div className="d-flex justify-content-center p-2 peer-rating-disclaimer">
                                                <div className="pt-2 label-wrap">
                                                <span className="disclaimer-label">{t('REPORT.REPORT_DISCLAIMER_LABEL')}</span> {t('REPORT.REPORT_DISCLAIMER_TEXT')}
                                                </div>
                                            </div>
                                        }
                                        {/* SECTION RECOMMENDATION */}
                                        <div className="pdf-report-section-recommenation">
                                            <div className="pdf-recommendation-title d-flex justify-content-center">
                                            {t('PDF.PDF_FEEDBACK_TITLE')}
                                            </div>
                                            {section && section.sub_sections && section.sub_sections.length > 0 && section.sub_sections.map((sub, j) => (
                                                <div className="pdf-recommenation-wrap" key={j}>
                                                    <div className="pdf-rec-item">
                                                        <div className="rdf-rec-item-summary">
                                                            <div className="hp-report-recommendation-item">
                                                                <div className="hp-recommend-item-title">
                                                                    {sub.Name}
                                                                </div>
                                                                <div className="hp-recommend-item-score">
                                                                    <div className="hp-assessment-scores-section-bar-wrap">
                                                                        <div className="hp-report-score">
                                                                            <span className="hp-report-score-left"></span>
                                                                            {(()=>{
                                                                                score = sub.ScorePercentage ? sub.ScorePercentage * 100 : 0;
                                                                                score = Math.round(score);

                                                                                peerScore = sub.ScorePercentagePeerScore ? sub.ScorePercentagePeerScore * 100 : 0;
                                                                                peerScore = Math.round(peerScore);
                                                                            })()}
                                                                            <span className="hp-report-score-right">Scored {score} out of 100</span>
                                                                        </div>
                                                                        <div className="hp-assessment-scores-section-bar">
                                                                            <div className="hp-assessment-scores-section-bar-fill" style={{width:`${score}%`}}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="hp-recommend-item-content">
                                                                    {(() => {

                                                                    if(section.Key.indexOf('Transact') > -1 && score === 0) {
                                                                        return (
                                                                            <p>
                                                                                Not Applicable
                                                                            </p>
                                                                        )
                                                                    } else {
                                                                        if(score <=25) {
                                                                            return(
                                                                                <p>
                                                                                    {t('PDF.PDF_POSITION_LAGGING_MSG', {sectionName: sub.Name,score: score,peerScore: peerScore})}
                                                                                </p>
                                                                            )
                                                                        } else if(score > 25 && score <= 50) {
                                                                            return (
                                                                                <p>
                                                                                    {t('PDF.PDF_POSITION_INTERMEDIATE_MSG', {sectionName: sub.Name,score: score,peerScore: peerScore})}
                                                                                </p>
                                                                            )

                                                                        } else if(score > 50 && score <= 75) {
                                                                            return (
                                                                                <p>
                                                                                    {t('PDF.PDF_POSITION_GOOD_MSG', {sectionName: sub.Name,score: score,peerScore: peerScore})}
                                                                                </p>
                                                                            )
                                                                        } else if(score > 75 && score <=100) {
                                                                            return (
                                                                                <p>
                                                                                    {t('PDF.PDF_POSITION_EXCELLENT_MSG', {sectionName: sub.Name,score: score,peerScore: peerScore})}
                                                                                </p>
                                                                            )
                                                                        }
                                                                    }

                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {siteReportList && siteReportList.length > 0 && sub.Key.trim().indexOf('Website Experience') > -1 &&
                                                            <div className="pdf-rec-speed-report">
                                                                <div className="pdf-rec-speed-report-title">{t('PDF.PDF_GOOGLE_TITLE')}</div>
                                                                <div className="pdf-rec-speed-report-desc">
                                                                    <p className="para1">
                                                                    {t('PDF.PDF_GOOGLE_MSG1')}
                                                                    </p>
                                                                    <p className="">
                                                                    {t('PDF.PDF_GOOGLE_MSG2')}
                                                                    </p>
                                                                    <p>
                                                                    {t('PDF.PDF_GOOGLE_MSG3')}
                                                                    </p>
                                                                    <p>
                                                                    {t('PDF.PDF_GOOGLE_MSG4')}
                                                                    </p>
                                                                    <p>
                                                                    {t('PDF.PDF_GOOGLE_MSG5')}
                                                                    </p>
                                                                    <p className="">
                                                                    {t('PDF.PDF_GOOGLE_MSG6')}
                                                                    </p>
                                                                    <p className="">
                                                                    {t('PDF.PDF_GOOGLE_MSG7')}
                                                                    </p>
                                                                </div>
                                                                {siteReportList && siteReportList.length > 0 && siteReportList.map((report, index) => (
                                                                    <div className="pdf-rec-speed-report-container" key={index}>
                                                                        {report && report.Results && report.Results.length > 0 && report.Results.map((selectedDeviceData, k)=>(
                                                                            <div className="pdf-rec-speed-report-wrap" key={k}>
                                                                                {selectedDeviceData && selectedDeviceData.Type === 'mobile' &&
                                                                                    <div className="pdf-rec-speed-reportwrap-head">
                                                                                        <MDBIcon icon="mobile-alt" />
                                                                                        {t('PDF.PDF_MOBILE')}
                                                                                    </div>
                                                                                }

                                                                                {selectedDeviceData && selectedDeviceData.Type === 'desktop' &&
                                                                                    <div className="pdf-rec-speed-reportwrap-head">
                                                                                        <MDBIcon icon="desktop" />
                                                                                        {t('PDF.PDF_DESKTOP')}
                                                                                    </div>
                                                                                }
                                                                                <PageSpeedResult report={report} selectedDeviceData={selectedDeviceData} fromPDF={true} />
                                                                            </div>
                                                                        ))}

                                                                    </div>
                                                                ))}

                                                            </div>
                                                        }

                                                        {sub && sub.RecommendationText && sub.RecommendationText.length > 0 &&
                                                            <div className="pdf-rec-item-details">
                                                                <div className="pdf-rec-item-details-data">
                                                                    <div className="hp-rec-content-title d-flex">
                                                                        {sub.Name} {t('PDF.PDF_RECOMMENDATIONS')}
                                                                    </div>
                                                                    <div className="hp-rec-content-para1 d-flex" dangerouslySetInnerHTML={{ __html: sub.RecommendationText}}>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <div className="pdf-end">
                           {t('PDF.PDF_REPORT_ENDS')}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportPDF));