

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'

import { Provider } from 'react-redux'

import { BrowserRouter, Switch, Redirect, Route, Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
//IE Poyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import configureStore from './store';


/**
 * Custom font imports
 * **/

import './assets/fonts/WOFF/HPSimplified_W_Rg.woff';
import './assets/fonts/WOFF/HPSimplified_W_Lt.woff';
import './assets/fonts/WOFF/HPSimplified_W_Bd.woff';

import './assets/fonts/WOFF2/HPSimplified_W_Rg.woff2';
import './assets/fonts/WOFF2/HPSimplified_W_Lt.woff2';
import './assets/fonts/WOFF2/HPSimplified_W_Bd.woff2';

/**
 * MDB React imports
 * **/

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

// import i18n (needs to be bundled ;)) 
import './i18next';

/**
 * Custom styles
 * **/
import './style.scss';

/**
 * Custom imports
 * **/
import './i18next';
import Home from './pages/Home/Home';
import HomeReAssessment from './pages/HomeReAssessment/Home'
import AssessmentPage from './pages/Assessment/AssessmentPage';
import AssessmentPage2022 from './pages/Assessment2022/AssessmentPage';
import AssessmentCompletionPage from './pages/AssessmentCompletion/AssessmentCompletionPage';
import AssessmentCompletionPage2022 from './pages/AssessmentCompletion2022/AssessmentCompletionPage';
import AssessmentReportPage from './pages/AssessmentReport/AssessmentReportPage';
import ReportPage from "./pages/ReportPage/ReportPage";
import DigitalToolKit from './pages/DigtalToolKit/DigtalToolKit';
import Recommendations from "./pages/Recommendations/Recommendations";
import AssessmentIntroductionPage from "./pages/AssessmentIntroduction/AssessmentIntroductionPage";
import AssessmentIntroductionPage2022 from "./pages/AssessmentIntroduction2022/AssessmentIntroductionPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import Dashboard2022 from "./pages/Dashboard2022/Dashboard"
import FAQs from './pages/FAQs';
import AssessmentRetake from './pages/AssessmentRetake/AssessmentRetake';
import AssessmentRetake2022 from './pages/AssessmentRetake2022/AssessmentRetake';
import ReportPDF from './pages/ReportPDF/ReportPDF';
import ReportPrint from './pages/ReportPrint/ReportPrint';

import reportWebVitals from './reportWebVitals';
import EDC from './pages/EDC'
import EDCFilterResult from './pages/EDCFilterResult';
import ProfileSetting from './pages/ProfileSetting';
import AssessmentStartPage from './pages/AssessmentStart';
import AssessmentStartPage2022 from './pages/AssessmentStart2022';
import AssessmentEndPage from './pages/AssessmentEnd';
import AssessmentEndPage2022 from './pages/AssessmentEnd2022';
import ReAssessmentEndPage from './pages/ReAssessmentEnd';
import ReAssessmentReportPage from './pages/ReAssessmentReport/ReAssessmentReportPage';
import ReportPageNew from "./pages/ReportPageNew/ReportPageNew";

import PageSpeed from './pages/PageSpeed/PageSpeed';
import LandingPage from './pages/LandingPage/LandingPage';
import Copyright from './pages/Copyright';
import Disclaimer from './pages/Disclaimer';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import BusinessPlanner from './pages/BusinessPlanner';

import 'react-notifications/lib/notifications.css';

import axios from "axios";

// ToDo: Remove  - Temp Planner UI Page
import BusinessPlannerWizard from "./pages/BusinessPlanner/BudgetPlanner";
import PlannerCustomise from './pages/BusinessPlanner/StartPlanner'
import BusinessPlannerTechnologySelectorPage from './pages/BusinessPlanner/TechnologySelectorPage'
import BusinessPlannerLandingPage from './pages/BusinessPlanner/BusinessPlannerLandingPage';
import BusinessPlannerProgressReach from './pages/BusinessPlanner/BusinessPlannerReach';
import ReachCalculator from './pages/BusinessPlanner/ReachCalculator';
import BusinessPlannerSummary from './pages/BusinessPlanner/BussinessPlannerSummary';
import CompletePlanner from './pages/BusinessPlanner/CompletePlanner';
import CompletePlannerConfirm from './pages/BusinessPlanner/CompletePlannerStart';
import PDFreport from './pages/BusinessPlanner/PDFReport';
import ReAssessmentPage from './pages/ReAssessment/ReAssessmentPage';
import ReAssessmentCompletionPage from './pages/ReAssessmentCompletion/ReAssessmentCompletionPage';
import ReAssessmentIntroductionPage from './pages/ReAssessmentIntroduction/ReAssessmentIntroductionPage';
import ReportPage2022 from './pages/ReportPage2022/ReportPage2022';
import ReportPDF2022 from './pages/ReportPDF2022/ReportPDF2022';


function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// Add a request interceptor to inject auth tokens to outgoing API call
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('authorizationtoken');
  if (token) {
    config.headers.Authorization = token;
    let lan = localStorage.getItem('lan');
    if (window.location.href.indexOf('/pdf-report/') > -1) {
      // let isURLHasLan = window.location.href.indexOf('?lan=') > -1 ? window.location.href.split('?lan=')[1] : 'en';
      // lan = isURLHasLan;
      lan = getParameterByName('lan') || 'en';
    }
    if (lan) {
      config.headers.Language = lan;
    }
  } else {
    let lan = localStorage.getItem('lan');
    if (window.location.href.indexOf('/pdf-report/') > -1) {
      // let isURLHasLan = window.location.href.indexOf('?lan=') > -1 ? window.location.href.split('?lan=')[1] : 'en';
      // lan = isURLHasLan;
      lan = getParameterByName('lan') || 'en';
    }
    if (lan) {
      config.headers.Language = lan;
    }
  }
  return config;
});

// validate user authentication
export const authValidate = () => {
  let authKey = localStorage.getItem('authorizationtoken');
  let ppid = localStorage.getItem('ppid');
  let authenticated = false;
  let isPDFReport = window.location.href.indexOf('/pdf-report/') > -1 ? true : false;

  if ((authKey && ppid) || isPDFReport) {
    authenticated = true;
  }
  return authenticated;
}

const tagManagerArgs = {
  gtmId: 'GTM-W7SDM5G'
};
TagManager.initialize(tagManagerArgs);

const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={null}>
      <BrowserRouter history={history}>
        <Switch>
          <Route path="/assessment/:section/:url/:name/:fromQ/:retake?" component={AssessmentPage} />
          <Route path="/assessment2022/:section/:url/:name/:fromQ/:retake?" component={AssessmentPage2022} />
          <Route path="/reassessment/:section/:url/:name/:fromQ/:retake?" component={ReAssessmentPage} />
          <Route path="/reassessment-introduction/:assessment/:secId/:fromQ" component={ReAssessmentIntroductionPage} />
          <Route path="/assessment-introduction/:assessment/:secId/:fromQ" component={AssessmentIntroductionPage} />
          <Route path="/assessment-introduction2022/:assessment/:secId/:fromQ" component={AssessmentIntroductionPage2022} />
          <Route path="/assessment-start2022/:assessment/:formURL" component={AssessmentStartPage2022} />
          <Route path="/assessment-start/:assessment/:formURL" component={AssessmentStartPage} />
          <Route path="/assessment-end/:assessment/:formID" component={AssessmentEndPage} />
          <Route path="/assessment-end2022/:assessment/:formID" component={AssessmentEndPage2022} />
          <Route path="/reassessment-end/:assessment/:formID" component={ReAssessmentEndPage} />
          <Route path="/section-end/:section/:formId" component={AssessmentCompletionPage} />
          <Route path="/section-end2022/:section/:formId" component={AssessmentCompletionPage2022} />
          <Route path="/reassessment/section-end/:section/:formId" component={ReAssessmentCompletionPage} />
          <Route path="/reassessment-report/:ppid" component={ReAssessmentReportPage} />
          <Route path="/report-2022/:ppid?/:assessmentId?/:secId?" component={ReportPageNew} />
          <Route path="/assessment-report/:ppid" component={AssessmentReportPage} />
          <Route path="/report/:ppid?/:assessmentId?/:secId?" component={ReportPage} />
          <Route path="/report2022/:ppid?/:assessmentId?/:secId?" component={ReportPage2022} />
          <Route path="/dashboard/:ppid/:url?/:fromRetake?" component={Dashboard} />
          <Route path="/dashboard2022/:ppid/:url?/:fromRetake?" component={Dashboard2022} />
          <Route path="/recommendations/:sectionID/:subSectionID/:showSiteReport/:assessment" component={Recommendations} />
          <Route path="/toolkit" component={DigitalToolKit} />
          <Route path="/faqs" component={FAQs} />
          <Route path="/assessment-retake/:assessmentID" component={AssessmentRetake} />
          <Route path="/assessment-retake2022/:assessmentID" component={AssessmentRetake2022} />
          <Route path="/edc/countryid/:countryId" component={EDCFilterResult} />
          <Route path="/edc" component={EDC} />
          <Route path="/profile-settings" component={ProfileSetting} />
          <Route path="/pagespeed-report" component={PageSpeed} />
          <Route path="/pdf-report/:ppid?" component={ReportPDF} />
          <Route path="/pdf-report2022/:ppid?" component={ReportPDF2022} />
          <Route path="/pdf-ReportPrint/:ppid?" component={ReportPrint} />
          <Route path="/copyright" component={Copyright} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={Terms} />

          {/* ToDo: Remove - Temp Planner UI Page */}
          <Route path="/bp/complete-planner/start" component={CompletePlannerConfirm} />
          <Route path="/bp/complete-planner" component={CompletePlanner} />
          <Route path="/bp/reach-planner/:edit?" component={ReachCalculator} />
          <Route path="/bp/start-planner/:techCount?" component={PlannerCustomise} />
          <Route path="/bp/budget-planner-reach/:edit?" component={BusinessPlannerProgressReach} />
          <Route path="/bp/budget-planner/:section?" component={BusinessPlannerWizard} />
          <Route path="/bp/tech-selection" component={BusinessPlannerTechnologySelectorPage} />
          <Route path="/bp/home" component={BusinessPlannerLandingPage} />
          <Route path="/bp/summary" component={BusinessPlannerSummary} />
          <Route path="/bp/pdf/:ppid?" component={PDFreport} />

          <Route path="/reassessment/:ppid/:redirect?" component={HomeReAssessment} />
          <Route path="/:ppid/:redirect?" component={Home} />
          <Route exact path="/" component={LandingPage} />

        </Switch>
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
