import React from 'react'
import Chart from 'react-google-charts'
const LineData = [
    ['Match', '2021 SCORES', '2022 SCORES'],
    ['',0,0],
    ['Reach',  30, 63],
    ['Engage',  8, 60],
    ['Transact', 64, 75],
    ['Retain',  50, 85]
  ]
const LineChartOptions = {
  colors: ['#D1D1D1', '#BCE7FA'],
  pointSize:7,
  series:{
      0:{pointShape:'circle'}
  },

  hAxis: {
    color: "#0096D6", fontName: "", fontSize: 10,
    gridlines: {
        color: "#0096D6"
    },
    baselineColor: '#0096D6'
  },
  vAxis: {
    color: "#0096D6", fontName: "", fontSize: 10,
    gridlines: {
        color: 'transparent'
    },
    baselineColor: '#0096D6'
  },
  
}
function MultiLineChart () {
 
    return (
      <div className="container mt-5">
        <h2>Digital Assessment survey comparison 2022-2021</h2>
        <Chart
          width={'700px'}
          height={'410px'}
          chartType="AreaChart"
          loader={<div>Loading Chart</div>}
          data={LineData}
          options={LineChartOptions}
          
        />
      </div>
    );
  
}
export default MultiLineChart