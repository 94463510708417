import { act } from "react-dom/test-utils";
import { FETCH_PSPEED_REPORT, FETCH_PSPEED_REPORT_SUCCESS, FETCH_PSPEED_FULL_REPORT_SUCCESS, FETCH_PSPEED_FULL_REPORT } from "../actions/pspeedAction";

const initialState = {
    pspeedReport: [],
    loading: false,
    siteReportList: []
}

export const pspeedReducer = (state = initialState, action) => {
    /*if (!state[action.listId]) {
        return state;
    }*/
    switch(action.type) {
        case FETCH_PSPEED_REPORT:
            return {
                ...state,
                loading: true
            }
        case FETCH_PSPEED_REPORT_SUCCESS:
            let report = action.payload;
            return {
                ...state,
                pspeedReport: report,
                loading: false
            } 
        case FETCH_PSPEED_FULL_REPORT:
            return {
                ...state,
                loading: true
            }
        case FETCH_PSPEED_FULL_REPORT_SUCCESS:
            let reportList = action.payload;
            return {
                ...state,
                siteReportList: reportList,
                loading: false
            }  
        default:
            return {
                ...state
            }
    }
}

export const getPerformanceReport = state => state['pspeedReducer'].pspeedReport;
export const getLoadindStatus = state => state['pspeedReducer'].loading;
export const getFullSiteReports = state => state['pspeedReducer'].siteReportList;

