import React from "react";
import { Radar } from "react-chartjs-2";
import "./radarchart.scss";

function PDFRadar({ chartdata }) {
  const dataRadar = {
    labels: chartdata?.sections?.map((item) => (item?.Name && item?.Name === 'Services & Solutions Delivery') ? 'Service Delivery' : item?.Name),
    datasets: [
      {
        label: "Your Score",
        backgroundColor: "rgb(86,193,255,.2)",
        borderColor: "#56C1FF",
        pointRadius: 5,
        border: 1,
        pointBackgroundColor: "#56C1FF",
        data: chartdata?.sections?.map((item) => Math.round(item?.scorePercentage * 100)),
      },
      {
        label: "Peer Score",
        backgroundColor: "rgb(205,205,205,.5)",
        borderColor: "rgb(205,205,205)",
        pointRadius: 5,
        pointBackgroundColor: "rgb(205,205,205)",
        data: chartdata?.sections?.map((item) => Math.round(item?.ScorePercentagePeerScore * 100)),
      },
    ],
  };
  return (
    <>
      <Radar
        data={dataRadar}
        width={338}
        height={280}
        options={{
          responsive: true,
          scale: {
            gridLines: {
              circular: true,
              color: ["#deeaef", "#a8e6ff", "#4ecaff", "#39b2e5"],
              drawTicks: true,
            },
            pointLabels: {
              fontSize: 12,
              fontColor: "#0096D6",
              fontStyle: "bold",
            },
            angleLines: {
              color: "#39B2E5",
            },
            ticks: {
              beginAtZero: true,
              suggestedMin: 25,
              suggestedMax: 100,
              stepSize: 25,
              fontColor: "#39B2E5",
              fontSize: 10,
              fontStyle: "bold",
            },
          },
          legend: {
            position: "bottom",
            align: "center",
            labels: {
              fontSize: 12,
              boxWidth: 8,
              usePointStyle: true,
            },
          },
        }}
      />
    </>
  );
}

export default PDFRadar;

