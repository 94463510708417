import React from 'react';
import './timeline.scss';

import { API_BASE_URL } from "../../../config/api-config";
import axios from "axios";
import active_icon from '../../../assets/icons/UX/Progress/Group 594.svg';
import disabled_icon from '../../../assets/icons/UX/Progress/Ellipse 80.svg';


function TimeLine(props) {
    const sectionIndex = props.sectionList.indexOf(props.currentSection);
    return(
        <div className="hp-timeline-wrap">
            <div className="hp-bp-timeline-bar">
                <div className="hp-bp-timeline-bar-wrap">
                    <div className="hp-bp-timeline-bar-gray">
                        <div className={`hp-bp-timeline-bar-blue hp-bp-timeline-filled-`+sectionIndex}>
                        </div>
                        <div className="hp-bp-timeline-milestones">
                            <div className='hp-bp-timeline-points hp-bp-timeline-0'>
                                <img className={sectionIndex >= 0 ? 'active' : 'disabled'} src={sectionIndex >= 0 ? active_icon : disabled_icon} alt="Qualifier completed" />
                            </div>
                            <div className='hp-bp-timeline-points hp-bp-timeline-1'>
                                <img className={sectionIndex >= 1 ? 'active' : 'disabled'} src={sectionIndex >= 1 ? active_icon : disabled_icon} alt="Qualifier completed" />
                            </div>
                            <div className='hp-bp-timeline-points hp-bp-timeline-2'>
                                <img className={sectionIndex >= 2 ? 'active' : 'disabled'} src={sectionIndex >= 2 ? active_icon : disabled_icon} alt="Qualifier completed" />
                            </div>
                            <div className='hp-bp-timeline-points hp-bp-timeline-3'>
                                <img className={sectionIndex >= 3 ? 'active' : 'disabled'} src={sectionIndex >= 3 ? active_icon : disabled_icon} alt="Qualifier completed" />
                            </div> 
                        </div>
                        <div className="timeline-sections">
                            <div className="timeline-section-name section1">
                                Engage
                            </div>
                            <div className="timeline-section-name section2">
                                Transact
                            </div>
                            <div className="timeline-section-name section3">
                                Retain
                            </div>
                            <div className="timeline-section-name section4">
                                Reach
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default TimeLine;