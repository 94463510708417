import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import React, { useState } from "react";

export default function IEModal() {
  let isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const [show, setShow] = useState(isIE);
  const handleClose = () => setShow(false);
  return (
    <MDBModal
      isOpen={show}
      toggle={handleClose}
      centered
      keyboard={false}
      className="edmodal-container"
    >
      <MDBModalHeader toggle={handleClose} className="border-0">
        &nbsp;
      </MDBModalHeader>
      <MDBModalBody className="body">
        <p className="heading hp-regular">Disclaimer</p>
        <p className="hp-lite">
          In case you experience any difficulties while accessing the assessment
          via Internet Explorer, we would suggest you to try using any other
          browser such as Edge, Chrome, Safari, Firefox etc. in order to have a
          seamless experience.
        </p>
      </MDBModalBody>
    </MDBModal>
  );
}
