import React from 'react';


import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { withTranslation } from 'react-i18next';
import { sendPageViewGTM } from "../../utils/util";

import icon1 from '../../assets/icons/UX/msg/Icon ionic-md-lock.svg';
import icon2 from '../../assets/icons/UX/msg/004-analytics.svg';
import icon3 from '../../assets/icons/UX/msg/001-new-product.svg';
import icon4 from '../../assets/icons/UX/msg/003-advice.svg';

import CompletionAnimation from "../../components/CompletionAnimation";
import CelebrationAnimation from "../../components/CelebrationAnimation";

import { getUserName } from '../../utils/util';

import './assessmentend.scss';

import { connect } from 'react-redux';
import { LoadAssessments, SaveCompletedSections, emptyCompletedSections, updateAssessmentStatus, SaveCompletedAssessmentByType, SaveTotalAssessmentByType } from '../Home/actions';
import { getAssessments,getCompletedSections,getCompletedDigitalSection,getTotalDigitalSections,getCompletedServiceSections,getTotalServiceSections } from '../Home/homeReducer';


const mapStateToProps = (state) => {
    return {
     assessments: getAssessments(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        LoadAssessments: (ppid) => dispatch(LoadAssessments(ppid, ownProps))
    }
}

class AssessmentEndPage extends React.Component {

    state = {
        sectionName: ''
    };

    constructor(props) {
        super(props);
        let ppid = localStorage.getItem('ppid');
        this.props.LoadAssessments(ppid);
    }

    componentDidMount () {
        //GTM PageLoad
        try {
            let pagePath = window.location ? window.location.pathname: '';
            sendPageViewGTM('Assessment Section End Page', pagePath);
        } catch(e){}
    }

    SaveAssessment = () => {
        let section = '';
        let url = this.props.match.params.formID;
        let assessment = this.props.match.params.assessment ? this.props.match.params.assessment : '';
        console.log("assessment",assessment+url);
        this.props.history.push(`/dashboard/${localStorage.getItem('ppid')}`);
    }

    getSectionNamebyID = (sectionId) => {
        
    }

    render() {

        let assessmentName = '';
        let asmntName = '';
        if(this.props.match.params.assessment) {
            assessmentName = this.props.match.params.assessment;
            if(assessmentName === 'digital') {
                asmntName = 'Digital';
            } else if(assessmentName === 'service') {
                asmntName = 'Service';
            }
        }

        let sectionsList = [];
        let sectionName = '';
        const {assessments} = this.props;

        if(assessments && assessments.length > 0) {
            this.props.assessments.map((assessment, index) => {
                if(assessment && assessment.sections) {
                    for(let section in assessment.sections) {
                        if(section && assessment.sections[section].Typeform_ID === this.props.match.params.formID) {
                            sectionName =  assessment.sections[section].Name;
                            break;
                        }
                    }
                }
            });
        }

        const { t } = this.props;
        const selectedlan = localStorage.getItem('lan') || 'en';

        return(
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                <div className="d-flex hp-layout hp-home">
                    <Header />
                        <div className="hp-content-wrap assessment-end-page flex-column">
                            {false &&
                                <div className="ux-msg-completion-screen-images">
                                    <div className="ux-msg-completion-screen-icon-lock">
                                        <img src={icon1} alt=""/>
                                    </div>
                                    <div className="ux-msg-completion-screen-icon">
                                        <img src={icon2} alt=""/>
                                    </div>
                                    <div className="ux-msg-completion-screen-icon">
                                        <img src={icon3} alt=""/>
                                    </div>
                                    <div className="ux-msg-completion-screen-icon">
                                        <img src={icon4} alt=""/>
                                    </div>
                                </div>
                            }
                            

                            <CompletionAnimation />
                            <CelebrationAnimation />
                            <div className="assessment-end-container">
                                {sectionName && 
                                    <div className="welcome-text">{t('ASSESSMENT.ASSESSMENT_END_MSG_LINE_1', {sectionName: sectionName})}</div>
                                }
                                
                                <div className="welcome-msg">{t('ASSESSMENT.ASSESSMENT_END_MSG_LINE_2', {assessment: asmntName})}</div>

                                <div className="ux-qualifier-updates">
                                    {t('UX.MSG_SECTION_END_1')}
                                </div>

                                <div className="welcome-msg">{t('UX.MSG_SECTION_END_2')}</div>
                                
                                <div className="assessment-end-btn-wrap">
                                    <div className="hp-btn-v1" onClick={this.SaveAssessment}>{t('ASSESSMENT.ASSESSMENT_END_CTA')}</div>
                                </div>
                            </div>
                        </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssessmentEndPage));