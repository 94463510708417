import axios from "axios";
import { MDBIcon } from "mdbreact";
import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import { API_BASE_URL } from "../../config/api-config";
import "./slider.scss";

import slider1 from "../../assets/images/slider/slider1.png";
import slider3 from "../../assets/images/slider/flying-laptop-mobile-tablet-3d-rendering-showing-builder-website-responsive-web-design@2x.png";
import slider4 from "../../assets/images/slider/Business planner.png";
import slider4Menu from "../../assets/images/slider/increase.svg";
import slider2 from "../../assets/images/sample-img.png";
import feature from "../../assets/images/featured.png";
import facebook from "../../assets/images/slider/001-facebook.svg";
import linkedIn from "../../assets/images/slider/006-linkedin.svg";
import youtube from "../../assets/images/slider/002-youtube.svg";
import line from "../../assets/images/slider/007-line.svg";
import insta from "../../assets/images/slider/003-instagram.svg";
import whatsApp from "../../assets/images/slider/005-whatsapp.svg";
import comingSoon from "../../assets/images/slider-coming-soon.svg";

export default class DigiToolKitSlider extends Component {
  state = {
    result: [],
  };

  // getBanners = () => {
  //   const url = `${API_BASE_URL}/toolkit-banners`;
  //   axios.get(url).then((response) => {
  //     this.setState({
  //       result: response?.data,
  //     });
  //   });
  // };

  // componentDidMount() {
  //   this.getBanners();
  // }
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1.2,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    // const SliderItem = ({ data }) => {
    //   return (
    //     <div
    //       style={{
    //         height: "319px",
    //         width: "620px",
    //         borderRadius: "30px",
    //         margin: "0 10px",
    //         cursor: "pointer",
    //       }}
    //       onClick={() => window.open(data?.Url, "_blank")}
    //     >
    //       <img
    //         alt="slider"
    //         src={data?.Banner?.url}
    //         style={{
    //           height: "100%",
    //           width: "100%",
    //           objectFit: "cover",
    //           borderRadius: "25px",
    //         }}
    //       />
    //     </div>
    //   );
    // };

    const addDataLayerFunc = async (bannerTitle, buttonTitle) => {
      let userId = await localStorage.getItem("ppid");
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: "BannerClick",
          Title: bannerTitle,
          ButtonTitle: buttonTitle,
          userId: userId,
        });
      }
    };

    function SampleNextArrow(props) {
      const { onClick } = props;
      return (
        <div
          style={{ position: "absolute", right: 22, top: "45%" }}
          onClick={onClick}
        >
          <MDBIcon icon="arrow-right" style={{ color: "#fff", fontSize: 25 }} />
        </div>
      );
    }

    function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
        <div
          style={{ position: "absolute", left: 22, top: "45%", zIndex: 999 }}
          onClick={onClick}
        >
          <MDBIcon icon="arrow-left" style={{ color: "#fff", fontSize: 25 }} />
        </div>
      );
    }

    // const { result } = this.state;

    const Slider1 = () => (
      <div
        className="slider-wrapper"
        onClick={() => {
          addDataLayerFunc(
            "Create the perfect social media presence for your company",
            "Explore"
          );
          window.open(
            "https://social-tool.hppartnerassessment.com/perfectsocialmediahandle.php",
            "_blank"
          );
        }}
      >
        <div className="row">
          <div className="col-7">
            <img src={feature} alt="new" className="feature-img" />
            <p className="slider-text">
              Create the perfect social media presence for your company
            </p>
            <div className="row mx-3">
              <div className="d-flex flex-column justify-content-center align-items-center mr-2 mr-md-2">
                <img src={facebook} alt="social" className="social-icon" />
                <p className="social-text">Facebook</p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mr-2 mr-md-2">
                <img src={linkedIn} alt="social" className="social-icon" />
                <p className="social-text">LinkedIn</p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mr-2 mr-md-2">
                <img src={youtube} alt="social" className="social-icon" />
                <p className="social-text">YouTube</p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mr-2 mr-md-2">
                <img src={line} alt="social" className="social-icon" />
                <p className="social-text">Line</p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mr-2 mr-md-2">
                <img src={insta} alt="social" className="social-icon" />
                <p className="social-text">Instagram</p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mr-2 mr-md-2">
                <img src={whatsApp} alt="social" className="social-icon" />
                <p className="social-text">WhatsApp</p>
              </div>
            </div>

            <button className="btn mt-4 ml-3 explore-btn ">
              <p className="m-0">Explore</p>
            </button>
          </div>
          <div className="col-5 col-md-3">
            <img src={slider1} alt="slider" className="slider1-img" />
          </div>
        </div>
      </div>
    );
    const Slider2 = () => (
      <div
        className="slider-wrapper"
        onClick={() => {
          addDataLayerFunc(
            "Setup you personalised Google Datastudio Digital Dashboard",
            "Explore"
          );
          window.open(
            "https://datastudio.google.com/u/0/reporting/97fd663f-f7da-4898-936e-045d9e8b0785",
            "_blank"
          );
        }}
      >
        <div className="d-flex align-items-center">
          <div className="col-7">
            <img src={feature} alt="new" className="feature-img" />
            <p className="slider-text">
              Setup you personalised Google Datastudio Digital Dashboard
            </p>

            <div className="col-5">
              <img src={slider2} alt="slider" className="slider2-md-img" />
            </div>
            <button className="btn mt-4 ml-3 explore-btn ">
              <p className="m-0">Explore</p>
            </button>
          </div>
          <div className="col-5">
            <img src={slider2} alt="slider" className="slider2-img" />
          </div>
        </div>
      </div>
    );

    const Slider3 = () => (
      <div
        className="slider-wrapper"
        onClick={() => {
          addDataLayerFunc("Website Starter Kit", "Explore");
          window.open(
            "https://hp-website-tool.s3.ap-south-1.amazonaws.com/Website_Starter_Kit_350f2ad468.zip",
            "_blank"
          );
        }}
      >
        <div className="d-flex align-items-lg-center align-items-md-start">
          <div className="col-6">
            <img src={feature} alt="new" className="feature-img-1" />
            {/* <p className="coming-soon">COMING SOON!</p> */}
            <p className="slider-text3">Website Starter Kit</p>
            <p className="sub-text">
              Set up a world class website
              <br /> in a two days
            </p>

            <button className="btn mt-4 ml-3 explore-btn ">
              <p className="m-0">Explore</p>
            </button>
          </div>
          <div className="col-6">
            {/* <img src={comingSoon} alt="slider" className="coming-soon-img" /> */}
            <img src={slider3} alt="slider" className="slider3-img" />

            {/* <img src={comingSoon} alt="slider" className="coming-soon-md-img" /> */}
            <img src={slider3} alt="slider" className="slider3-md-img" />
          </div>
        </div>
      </div>
    );

    const Slider4 = () => (
      <div
        className="slider-wrapper"
        onClick={() => {
          addDataLayerFunc("Business Planner", "Start Planning");
          window.open("https://hppartnerassessment.com/bp/home", "_blank");
        }}
      >
        <div className="d-flex align-items-center">
          <div className="col-lg-6 col-md-11">
            {/* <img
              src={comingSoon}
              alt="slider"
              className="coming-soon4-md-img"
            /> */}
            <img src={slider4} alt="slider" className="slider4-md-img" />
            <p className="slider-text4">Business Planner</p>
            <img src={slider4Menu} alt="social" className="social-icon2" />
            <p className="sub-text2">
              Need help planning budgets for <br />
              digitising your business?
              <br /> in a two days
            </p>

            <button className="btn mt-4 mt-md-0 ml-3 explore-btn ">
              <p className="m-0">Start Planning</p>
            </button>
          </div>
          <div className="col-lg-6 col-md-1">
            {/* <img src={comingSoon} alt="slider" className="coming-soon-img" /> */}
            <img src={slider4} alt="slider" className="slider4-img" />
          </div>
        </div>
      </div>
    );

    return (
      <div className="my-3">
        <Slider {...settings}>
          <Slider1 />
          <Slider2 />
          <Slider3 />
          <Slider4 />
        </Slider>
      </div>
    );
  }
}
