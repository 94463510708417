import { act } from "react-dom/test-utils";
import { FETCH_REPORT, FETCH_RECOMMENDATIONS_SECTION, 
    FETCH_RECOMMENDATIONS_SUB_SECTION, FETCH_RECOMMENDATIONS_SECTION_SUCCESS,
    FETCH_RECOMMENDATIONS_SUB_SECTION_SUCCESS,
    FETCH_FULL_REPORT, FETCH_FULL_REPORT_SUCCESS, FETCH_SITE_LIST_SUCCESS,
    FETCH_RECOMMENDATIONS_ERROR, FETCH_REPORT_ERROR} from "../actions/reportAction";;

const initialState = {
    reportDetails: [],
    sectionRecommendation: [],
    subSectionRecommendation: {},
    loading: false,
    fullReport: [],
    sites: [],
    Error: false
}

export const reportReducer = (state = initialState, action) => {
    /*if (!state[action.listId]) {
        return state;
    }*/
    switch(action.type) {
        case FETCH_REPORT:
            let reportDetails = [...state.reportDetails];
            reportDetails = action.payload;
            return {
                ...state,
                reportDetails,
                Error: false
            };
        case FETCH_RECOMMENDATIONS_SECTION:
            return {
                ...state,
                loading: true,
                Error: false
            }
        case FETCH_RECOMMENDATIONS_SECTION_SUCCESS:
            let sectionRecommendation = action.payload.data;
            let reportDetailsData = action.payload.reportData ? action.payload.reportData : [...state.reportDetails];
            return {
                ...state,
                sectionRecommendation,
                reportDetails: reportDetailsData,
                loading: false,
                Error: false
            }
        case FETCH_RECOMMENDATIONS_SUB_SECTION:
            return {
                ...state,
                loading: true,
                Error: false
            }
        case FETCH_RECOMMENDATIONS_SUB_SECTION_SUCCESS:
            let subSectionRecommendation = action.payload;
            return {
                ...state,
                subSectionRecommendation: subSectionRecommendation,
                loading: false,
                Error: false
            }
        case FETCH_RECOMMENDATIONS_ERROR:
            return {
                ...state,
                Error: true
            }
        case FETCH_FULL_REPORT_SUCCESS:
            let fullReport = action.payload;
            return {
                ...state,
                fullReport,
                Error: false
            }
        case FETCH_SITE_LIST_SUCCESS:
            let siteList = action.payload;
            return {
                ...state,
                sites: siteList,
                loading: false,
                Error: false
            }
        case FETCH_FULL_REPORT:
            return {
                ...state,
                Error: false
            }
        case FETCH_REPORT_ERROR:
            return {
                ...state,
                Error: true
            }
        default:
            return {
                ...state,
                Error: false
            }
    }
}

export const getDetailedReport = state => state['reportReducer'].reportDetails;
export const getSectionRecommendation = state => state['reportReducer'].sectionRecommendation;
export const getSubSectionRecommendation = state => state['reportReducer'].subSectionRecommendation;
export const getLoadingStatus = state => state['reportReducer'].loading;
export const getFullReport = state => state['reportReducer'].fullReport;
export const getSites = state => state['reportReducer'].sites;
export const getReportErrorState = state => state['reportReducer'].Error;
export const getErrorState = state => state['reportReducer'].Error;

