import axios from "axios";
import {API_BASE_URL} from '../config/api-config';

export const FETCH_TOOLKIT = 'FETCH_TOOLKIT';
export const FETCH_TOOLKIT_SUCCESS = 'FETCH_TOOLKIT_SUCCESS';
export const FETCH_TOOLKIT_ERROR = 'FETCH_TOOLKIT_ERROR';
export const FETCH_TOOLKIT_SECTION_SUCCESS = 'FETCH_TOOLKIT_SECTION_SUCCESS';
export const FETCH_TOOLKIT_SERVICE_SECTION_SUCCESS = 'FETCH_TOOLKIT_SERVICE_SECTION_SUCCESS';

export const fetchToolkit  = (data) => {
    return{
        type: FETCH_TOOLKIT_SUCCESS,
        payload: data
    }
}

export const fetchSectionSuccess = (data) => {
    return {
        type: FETCH_TOOLKIT_SECTION_SUCCESS,
        payload: data
    }
}

export const fetchServiceSectionSuccess = (data) =>{
    return {
        type: FETCH_TOOLKIT_SERVICE_SECTION_SUCCESS,
        payload: data
    }
}

export const loadToolkit = (id, section) => {
    let sectionD = section ? section : null;
    let apiUrl = '';
    if(id) {
        apiUrl = '/toolkits?sub_section._id='+id;
    } else {
        apiUrl = '/toolkits';
    }
    
    return dispatch => {
        axios.get(`${API_BASE_URL}${apiUrl}`)
        .then(res => {
            let rs = {
                data: res.data,
                section: sectionD
            }
            return rs;
        })
        .then(data => dispatch(fetchToolkit(data)))
    };
}

export const loadSectionDetails = () => {
    return dispatch => {
        axios.get(`${API_BASE_URL}/toolkits/sections/`)
        .then(res => {
            if(res.data) {
                let data = res.data;
                if(data.sections && data.sections.length > 0 && data.sections[0].SubSections && data.sections[0].SubSections.length > 0) {
                    dispatch(loadToolkit(data.sections[0].SubSections[0]._id, data));
                }
            }
        })
    };
}

export const loadServiceSectionDetails = () =>{
    return dispatch => {
        axios.get(`${API_BASE_URL}/toolkits/sections/service`)
        .then(res => {
            if(res.data) {
                let data = res.data;
                console.log(`toolkit`, res?.data)
                if(data.sections && data.sections.length > 0 && data.sections[0].SubSections && data.sections[0].SubSections.length > 0) {
                    dispatch(loadToolkit(data.sections[0].SubSections[0]._id, data));
                }
            }
        })
    };
}