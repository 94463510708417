import React from 'react';
import { connect } from 'react-redux';
import { MDBAlert, MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import { partnerRegistration, setLanguageForUser } from '../../actions/authAction';
import { getAuthData, getErrorState } from '../../reducers/authReducer';
import {getHomeActionError} from '../Home/homeReducer';
import {getReportErrorState} from '../../reducers/reportReducer';

import hp_logo from '../../assets/icons/hp-logo_old.svg';
import './landingpage.scss';
import IEModal from '../../components/IEDisclaimerModal';
import lanConfig from "../../config/languages.json";
import { withTranslation } from 'react-i18next';
import { sendPageViewGTM } from "../../utils/util";



const mapStateToProps = (state) => {
    return {
     auth: getAuthData(state),
     Error: getErrorState(state),
     HomeError: getHomeActionError(state),
     ReportError: getReportErrorState(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        partnerRegistration: (token) => dispatch(partnerRegistration(token, ownProps)),
        setLanguageForUser: (ppid, lan) => dispatch(setLanguageForUser(ppid, lan, ownProps))
    }
}

class LandingPage extends React.Component {

    state = {
        lan: '',
        loading: false,
        hasError: false
    }

    componentDidMount = () =>{

        /**
         * Logic to get the token id from URL, the pass it on to API for authentication
         * On successful authentication, will be redirected to the application
         * else, to the landing page
         */
        let {search} = this.props.location;
        let splitAr = search.trim().split('=');
        let qp = splitAr.length > 0 ? splitAr[1]: null;

        if(qp && qp !== null) {
            this.props.partnerRegistration(qp);
        }

        //GTM PageLoad
        try {
            let pagePath = window.location ? window.location.pathname: '';
            sendPageViewGTM('Landing Page', pagePath);
        } catch(e){}
    }

    updateLanguage = e => {

        if(e.target.value) {
            this.props.i18n.changeLanguage(e.target.value);
            this.setState({
                lan: e.target.value
            });
        }
    }

    selectLanguage = () => {
        let ppid = localStorage.getItem('ppid');
        this.setState({
            loading: true
        });
        if(ppid && this.state.lan) {
            localStorage.setItem('lan', this.state.lan||'en');
            this.props.setLanguageForUser(ppid, this.state.lan||'en');
        } else {
            this.setState({
                loading: false,
                hasError: true
            });
        }
    }

    render() {
        const {Error,auth} = this.props;
        const { t } = this.props;
        const {loading, hasError} = this.state;
        const selectedlan = localStorage.getItem('lan') || 'en';
        return (
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                {loading && 
                    <div className="d-flex justify-content-center align-items-center loading-wrap">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                <div className="d-flex hp-layout hp-home">
                    {(Error || hasError) &&
                        <MDBAlert color="danger" className="d-flex justify-content-center">
                            Invalid Token: User authentication failed.
                        </MDBAlert>
                    }
                    {(this.props.HomeError || this.props.ReportError) &&
                        <MDBAlert color="danger" className="d-flex justify-content-center">
                            Unauthorized access
                        </MDBAlert> 
                    }
                    
                    <div className="hp-content-wrap hp-landing-page">
                        <div className="hp-landing-page-content">
                            <img src={hp_logo} />
                        </div>
                        <div className="hp-landing-page-text">keep reinventing</div>
                        <div className="switch-lang-container">

                        
                            <select onChange={this.updateLanguage}>
                                <option>
                                    Select language
                                </option>
                                {lanConfig && lanConfig.length > 0 && lanConfig.map((lan, i)=>(
                                    <option value={lan.code} key={i}>{lan.label}</option>
                                ))}
                            </select>
                            <div className="disclaimer-text">
                                {t('COMMON.COMMON_LANGUAGE_DISCLAIMER')}
                            </div>
                            <div className="select-lan-btn" onClick={this.selectLanguage}>
                                <MDBIcon icon="arrow-alt-circle-right" size="2x"/>
                            </div>
                        </div>
                        {/* <div className="disclaimer-wrap">
                            <div className="disclaimer-title">Disclaimer:</div>
                            <div className="disclaimer-content">
                            As a reminder, deadline to have HP Capabilities Assessment associated benefits to HP Amplify program for FY22 was 31st of August 2021.
                            The HP Capabilities Assessment will continue to be available for HP Partners to benefit from the recommendations and associated Digital Toolkits
                            </div>
                        </div> */}
                    </div>
                </div>
                <IEModal />
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LandingPage));