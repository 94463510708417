/** Returns the logged partner name **/
export const getUserName = () => {
    let userInfo = localStorage.getItem('userInfo');
    let userName = '';
    if(!userInfo || userInfo === undefined || userInfo === null) {

    } else {
       userName = JSON.parse(userInfo).PartnerName;
    }
    return userName;
}

export const sendPageViewGTM = (title, pagePath) => {
    //GTM PageLoad
    try {
        if(window && window.dataLayer) {
            let userInfo = localStorage.getItem('userInfo');
            let country ='';
            let region= '';
            if(userInfo) {
                userInfo = JSON.parse(userInfo);
                country = userInfo.Country;
                region = userInfo.Region;
            }
            
            window.dataLayer.push({
                'event': 'CustomPageview',
                'page':{
                    'title':'HP Partner Assessment - ' + title, //title of page (Dynamic)
                    'path': pagePath, //page Path(Dynamic)
                'type': title //page type(Dynamic)
                },
                'countryname': country, //Country Name (Dynamic)
                'partnercountry': country,
                'partnerregion': region
            })
        }
    } catch(e){}
}