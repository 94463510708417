import React from "react";
import { connect } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";

import { API_BASE_URL } from "../../config/api-config";

import { loadAssessmentReport } from "../Home/actions";
import {
  getAssessmentReport,
  getLoadingScoreStatus,
} from "../Home/homeReducer";
import { withTranslation } from "react-i18next";
import { sendPageViewGTM } from "../../utils/util";

/**
 * Custom Imports
 * **/

import "./assessmentreport.scss";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import RoundChart from "../../components/RoundChart/RoundChart";
import { Link } from "react-router-dom";

import { getUserName } from "../../utils/util";

import Toolkit from "../../components/Toolkit/Toolkit";
import DigitalToolKitComponent from "../../components/DigitalToolkit";

const mapStateToProps = (state) => {
  return {
    report: getAssessmentReport(state),
    loading: getLoadingScoreStatus(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadAssessmentReport: (ppid) =>
      dispatch(loadAssessmentReport(ppid, ownProps)),
  };
};
class AssessmentReportPage extends React.Component {
  state = {
    DigitalPercentage: 0.0,
    ServicePercentage: 0.0,
    ppid: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.match.params && this.props.match.params.ppid) {
      this.setState({
        ppid: this.props.match.params.ppid,
      });
      this.props.loadAssessmentReport(this.props.match.params.ppid);
    }

    if (this.props.report && this.props.report.length > 0) {
      let DigitalPercentage = 0,
        ServicePercentage = 0;
      this.props.report.map((item, index) => {
        if (item.Key === "Digital Assessment") {
          DigitalPercentage = Math.round(item.scorePercentage);
        } else if (item.Key === "Service Assessment") {
          ServicePercentage = Math.round(item.scorePercentage);
        }
      });

      this.setState({
        DigitalPercentage: DigitalPercentage,
        ServicePercentage: ServicePercentage,
      });
    }

    //GTM PageLoad
    try {
      let pagePath = window.location ? window.location.pathname : "";
      sendPageViewGTM("Dashboard Page", pagePath);
    } catch (e) {}
  }

  loadDetailedReport = () => {
    let ppid = this.state.ppid;
    axios
      .get(`${API_BASE_URL}/partners/is-report-visited?ppid=` + ppid)
      .then((res) => {
        this.props.history.push(`/report/${ppid}`);
      })
      .catch((e) => {
        this.props.history.push(`/report/${ppid}`);
      });
  };

  hasMatchingLan = (lan) => {
    let lanList = [
      "en",
      "fr",
      "it",
      "de",
      "es",
      "ru",
      "pt",
      "id",
      "ja",
      "ko",
      "th",
      "tr",
      "vi",
      "hi",
    ];
    let hasLan = lanList.includes(lan) || false;
    return hasLan;
  };

  hasNotMatchinglan = (lan) => {
    let lanList = [
      "en",
      "fr",
      "it",
      "de",
      "es",
      "ru",
      "pt",
      "id",
      "ja",
      "ko",
      "th",
      "tr",
      "vi",
      "hi",
    ];
    let hasLan = lanList.includes(lan) || false;
    return !hasLan;
  };

  render() {
    const { report, loading } = this.props;
    const { t } = this.props;
    let selectedLanguage = localStorage.getItem("lan") || "en";
    return (
      <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
        {selectedLanguage && this.hasMatchingLan(selectedLanguage) && (
          <div className="d-flex hp-layout hp-home">
            <Header />
            <p className=" ml-3 bread-crumb hp-lite">
              <Link to={`/dashboard/${localStorage.getItem("ppid")}`}>
                Home
              </Link>{" "}
              &gt;{" "}
              <Link onClick={() => this.props.history.goBack()}>
                Assessment
              </Link>{" "}
              &gt; <span>Dashboard</span>
            </p>

            {localStorage.getItem("reAssesment") === "true" && (
              <div className="hp-tabs-wrapper">
                <div
                  onClick={() =>
                    this.props.history.push(
                      `/reassessment-report/${localStorage.getItem("ppid")}`
                    )
                  }
                  className="tabsInactive"
                >
                  2022 Results
                </div>
                <div
                  onClick={() =>
                    this.props.history.push(
                      `/assessment-report/${localStorage.getItem("ppid")}`
                    )
                  }
                  className="tabs"
                >
                  2021 Results
                </div>
              </div>
            )}

            {/* Desktop view */}
            <div className="hp-report hp-content-wrap d-none d-md-block">
              {loading && (
                <div className="d-flex justify-content-center align-items-center loading-wrap-full">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              <div className="hp-report-status">
                <div className="d-flex flex-column hp-result-landing-chart-wrap">
                  <RoundChart value={100} isStart={false} />
                  <div className="d-flex hp-regular hp-12 hp-chart-text mx-1">
                    {t("REPORT.REPORT_THANK_MSG")}
                  </div>
                </div>

                <div className="hp-report-status-score">
                  <div className="hp-primary-color hp-regular hp-22">
                    Hello {getUserName()},
                  </div>
                  <div className="hp-assessment-completed">
                    {t("REPORT.REPORT_YOUR_ASSESSMNET_COMPLETE")}
                  </div>
                  <div className="hp-regular hp-14 hp-assessment-last-updated">
                    Last date: {new Date().toDateString()}
                  </div>
                  <div className="hp-assessment-scores">
                    <div className="hp-assessment-scores-section">
                      <div className="hp-assessment-scores-section-text hp-bold hp-16">
                        {t("REPORT.REPORT_YOUR_DIGITAL_SCORE")}
                      </div>
                      <div className="hp-assessment-scores-section-bar-wrap">
                        <div className="hp-report-score">
                          <span className="hp-report-score-left">&nbsp;</span>
                          <span className="hp-report-score-right">
                            Scored{" "}
                            {report.length > 0 && report[0].scorePercentage
                              ? Math.round(report[0].scorePercentage)
                              : 0}{" "}
                            out of 100
                          </span>
                        </div>
                        <div className="hp-assessment-scores-section-bar">
                          <div
                            className="hp-assessment-scores-section-bar-fill"
                            style={{
                              width:
                                (report.length > 0 && report[0].scorePercentage
                                  ? Math.round(report[0].scorePercentage)
                                  : 0) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="hp-assessment-scores-section mt-4">
                      <div className="hp-assessment-scores-section-text hp-bold hp-16">
                        {t("REPORT.REPORT_YOUR_SERVICES_SCORE")}
                      </div>
                      <div className="hp-assessment-scores-section-bar-wrap">
                        <div className="hp-report-score">
                          <span className="hp-report-score-left">&nbsp;</span>
                          <span className="hp-report-score-right">
                            Scored{" "}
                            {report.length > 0 && report[1].scorePercentage
                              ? Math.round(report[1].scorePercentage)
                              : 0}{" "}
                            out of 100
                          </span>
                        </div>
                        <div className="hp-assessment-scores-section-bar">
                          <div
                            className="hp-assessment-scores-section-bar-fill"
                            style={{
                              width:
                                (report.length > 0 && report[1].scorePercentage
                                  ? Math.round(report[1].scorePercentage)
                                  : 0) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hp-assessment-description d-flex mt-4">
                    {t("REPORT.REPORT_VIEW_YOUR_ENTIRE_REPORT")}
                  </div>
                  <div className="hp-assessment-show-details d-flex mt-3 mb-2">
                    <div
                      className="hp-cta-v1"
                      onClick={this.loadDetailedReport}
                    >
                      {t("REPORT.REPORT_VIEW_FULL_REPORT")}
                    </div>
                  </div>
                </div>
              </div>
              <DigitalToolKitComponent />
            </div>

            {/* Mobile view */}

            <div className="hp-report hp-content-wrap d-sm-blocck d-md-none">
              <div className="hp-primary-color hp-regular hp-22">
                Hello {getUserName()},
              </div>
              <div className="hp-report-status">
                <div className="d-flex justify-content-center align-items-center hp-report-landing hp-report-landing-mob">
                  <RoundChart
                    value={100}
                    isStart={false}
                    width={85}
                    stroke={8}
                    viewReportChart={true}
                  />
                  <div className="hp-report-chart-right-mob">
                    <div className="hp-assessment-completed hp-22 hp-lite">
                      Assessment Completed
                    </div>
                    <div className="hp-regular hp-14 hp-assessment-last-updated hp-14 hp-lite">
                      Last date: 20 December 2020
                    </div>
                  </div>
                </div>

                <div className="hp-report-status-score">
                  <div className="hp-assessment-scores">
                    <div className="hp-assessment-scores-section">
                      <div className="hp-assessment-scores-section-text hp-bold hp-16">
                        {t("REPORT.REPORT_YOUR_DIGITAL_SCORE")}
                      </div>
                      <div className="hp-assessment-scores-section-bar-wrap">
                        <div className="hp-report-score">
                          <span className="hp-report-score-left"></span>
                          <span className="hp-report-score-right">
                            Scored{" "}
                            {report.length > 0 && report[0].scorePercentage
                              ? Math.round(report[0].scorePercentage)
                              : 0}{" "}
                            out of 100
                          </span>
                        </div>
                        <div className="hp-assessment-scores-section-bar">
                          <div
                            className="hp-assessment-scores-section-bar-fill"
                            style={{
                              width:
                                (report.length > 0 && report[0].scorePercentage
                                  ? Math.round(report[0].scorePercentage)
                                  : 0) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="hp-assessment-scores-section mt-4">
                      <div className="hp-assessment-scores-section-text hp-bold hp-16">
                        {t("REPORT.REPORT_YOUR_SERVICES_SCORE")}
                      </div>
                      <div className="hp-assessment-scores-section-bar-wrap">
                        <div className="hp-report-score">
                          <span className="hp-report-score-left"></span>
                          <span className="hp-report-score-right">
                            Scored{" "}
                            {report.length > 0 && report[1].scorePercentage
                              ? Math.round(report[1].scorePercentage)
                              : 0}{" "}
                            out of 100
                          </span>
                        </div>
                        <div className="hp-assessment-scores-section-bar">
                          <div
                            className="hp-assessment-scores-section-bar-fill"
                            style={{
                              width:
                                (report.length > 0 && report[1].scorePercentage
                                  ? Math.round(report[1].scorePercentage)
                                  : 0) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hp-assessment-description d-flex mt-4">
                    {t("REPORT.REPORT_VIEW_YOUR_ENTIRE_REPORT")}
                  </div>
                  <div className="hp-assessment-show-details d-flex mt-3 mb-2 justify-content-center">
                    <div
                      className="hp-cta-v1"
                      onClick={this.loadDetailedReport}
                    >
                      {t("REPORT.REPORT_VIEW_FULL_REPORT")}
                    </div>
                  </div>
                </div>
              </div>
              <DigitalToolKitComponent />
            </div>
            <Footer />
          </div>
        )}
        {selectedLanguage && this.hasNotMatchinglan(selectedLanguage) && (
          <div className="d-flex hp-layout hp-home">
            <Header />
            <p className=" ml-3 bread-crumb hp-lite">
              <Link to={`/${localStorage.getItem("ppid")}/true`}>Home</Link>{" "}
              &gt; <span>Dashboard</span>
            </p>
            <div className="hp-content-wrap d-flex hp-assessment-submit hp-dashboard hp-report hp-other-lan-msg-wrap">
              <div className="other-lan-msg">
                {t("COMMON.COMMON_ASSESSMENT_COMPLETED_OTHERLAN")}
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentReportPage)
);
