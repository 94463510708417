import React, { useEffect, useState } from 'react';
import './completionanimation.scss';

import image1 from '../../assets/images/XMLID_1840_.svg';
import image2 from '../../assets/icons/UX/msg/image2.svg';
import image3 from '../../assets/icons/UX/msg/icon5.svg';

import icon1 from '../../assets/icons/UX/msg/Icon ionic-md-lock.svg';
import icon2 from '../../assets/icons/UX/msg/004-analytics.svg';
import icon3 from '../../assets/icons/UX/msg/001-new-product.svg';
import icon4 from '../../assets/icons/UX/msg/003-advice.svg';


export default function CompletionAnimation() {
    const [showImg1, setShowImg1] =  useState(true);
    const [showImg2, setShowImg2] =  useState(false);
    const [showImg3, setShowImg3] =  useState(false);
    const [showImg4, setShowImg4] =  useState(false);

    useEffect(() => {
        setTimeout(()=>{
            setShowImg1(false);
            setShowImg2(true);
        },1000)

        setTimeout(()=>{
            setShowImg1(false);
            setShowImg2(false);
            setShowImg3(true);
        },2000)

        setTimeout(()=>{
            setShowImg1(false);
            setShowImg2(false);
            setShowImg3(false);
            setShowImg4(true);
        },3950)
    }, [])
    return (
        <div className="hp-completion-animation">
            <div className="img-containers">
                {showImg1 &&
                    <img src={image1} className="animatin-img-1" />
                }
                {showImg2 &&
                    <img src={image2} className="animatin-img-2" />
                }
                {showImg3 &&
                    <img src={image3} className="animatin-img-3" />
                }
            </div>
            {showImg4 &&
                <div className="ux-msg-completion-screen-images">
                    <div className="ux-msg-completion-screen-icon-lock">
                        <img src={icon1} alt=""/>
                    </div>
                    <div className="ux-msg-completion-screen-icon">
                        <img src={icon2} alt=""/>
                    </div>
                    <div className="ux-msg-completion-screen-icon">
                        <img src={icon3} alt=""/>
                    </div>
                    <div className="ux-msg-completion-screen-icon">
                        <img src={icon4} alt=""/>
                    </div>
                </div>
            }
        </div>
    )
}