import React, { useEffect, useState } from "react";
import { MDBProgress } from "mdbreact";

import "./loader.scss";

export default function ProgressLoader({}) {
  const [value, setValue] = useState(1);
  useEffect(() => {
    const timer = setInterval(() => {
     if(value <98) setValue(value + 2);
    }, 2000);
    return () => {
      clearInterval(timer);
    };
  });
  return (
    <div className="flex-columns-center overlay">
      <div className="c">
        <p className="hp-lite">Saving...</p>
        <MDBProgress
          className="my-2"
          material
          value={value}
          color="info"
          animated
        />
        <span className="hp-lite">{value} % out of 100</span>
      </div>
    </div>
  );
}
