import React from 'react';

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BrowserRouter, Switch, Redirect, Route, Link } from 'react-router-dom';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { sendPageViewGTM } from "../../utils/util";

import { getUserName } from '../../utils/util';
import { withTranslation } from 'react-i18next';

import './assessmentstart.scss';
import { connect } from 'react-redux';
import { LoadAssessments, SaveCompletedSections, emptyCompletedSections, updateAssessmentStatus, SaveCompletedAssessmentByType, SaveTotalAssessmentByType } from '../HomeReAssessment/actions';
import { getAssessments,getCompletedSections,getCompletedDigitalSection,getTotalDigitalSections,getCompletedServiceSections,getTotalServiceSections } from '../Home/homeReducer';


const mapStateToProps = (state) => {
    return {
     assessments: getAssessments(state),
     completedSections: getCompletedSections(state),
     CompletedDigitalSections: getCompletedDigitalSection(state),
     totalDigitalSections: getTotalDigitalSections(state),
     CompletedServiceSections: getCompletedServiceSections(state),
     totalServiceSections: getTotalServiceSections(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        LoadAssessments: (ppid) => dispatch(LoadAssessments(ppid, ownProps)),
        saveComepletedSecetions: (data) => dispatch(SaveCompletedSections(data)),
        emptyCompletedSections: () => dispatch(emptyCompletedSections()),
        updateAssessmentStatus: (ppid) => dispatch(updateAssessmentStatus(ppid)),
        SaveCompletedAssessmentByType: (data, type) => dispatch(SaveCompletedAssessmentByType(data, type)),
        SaveTotalAssessmentByType: (data, type) => dispatch(SaveTotalAssessmentByType(data, type))
    }
}
class AssessmentStartPage extends React.Component {

    state = {
        nextAssessmentName: '',
        nextSectionID: '',
        nextSection: {}
    }

    constructor(props) {
        super(props);
        let ppid = localStorage.getItem('ppid');
        this.props.LoadAssessments(ppid);
    }

    componentDidMount () {
        let section = '';
        let formID = this.props.match.params.formURL;
        let assessment = this.props.match.params.assessment ? this.props.match.params.assessment : '';
        let completedDigitalAssessments =[], completedServiceAssessments = [], totalDigitalAssessments = 0, totalSericeAssessments = 0;
        let currentAssessmentData;

        let nextAssessmentName = '';
        if(assessment === 'digital') {
            nextAssessmentName = 'Service';
            currentAssessmentData = completedServiceAssessments;
        } else if(assessment === 'service') {
            nextAssessmentName = 'Digital';
            currentAssessmentData = completedDigitalAssessments;
        }

        this.setState({
            nextAssessmentName: nextAssessmentName
        });

        const {assessments} =  this.props;
        let nextSectionID;
        let nextSection;
        
        let sectionsList = [];
        if(assessments && assessments.length > 0) {
            assessments.map((assessment, index) => {
                if(assessment && assessment.sections) {
                    assessment.sections.map((section, index) => {
                        sectionsList = [...sectionsList, section];
                        if(assessment.Key.indexOf('Digital') > -1) {
                            if(section.hasCompleted) {
                                completedDigitalAssessments.push(section.Typeform_ID);
                            }
                            
                        } else if(assessment.Key.indexOf('Service') > -1) {
                            if(section.hasCompleted) {
                                completedServiceAssessments.push(section.Typeform_ID);
                            }
                        }
                    })
                    
                }
            });

            let completedSections = [];
            if(sectionsList.length > 0) {
                sectionsList.map((section, index) => {
                    if(section && section.hasCompleted) {
                        completedSections.push(section.Typeform_ID);
                    }
                });
            }

            

            assessments.map((assessment, index) => {
                if(assessment && assessment.sections) {
                    for(const section of assessment.sections) {
                        if(assessment.Key.indexOf(nextAssessmentName) > -1 && currentAssessmentData.indexOf(section.Typeform_ID) === -1) {
                            nextSectionID = section.Typeform_ID;
                            nextSection = section;
                            break;
                        }
                    }
                }
            });

            this.setState({
                nextSectionID: nextSectionID,
                nextSection: nextSection
            })
            
            
        }

        //GTM PageLoad
        try {
            let pagePath = window.location ? window.location.pathname: '';
            sendPageViewGTM('Assessment Section Start Page', pagePath);
        } catch(e){}
    }

    loadNextAssessment = () => {

        let SecAbbr = '';

        if(this.state.nextAssessmentName === 'Digital') {
            SecAbbr = 'D';
        } else if(this.state.nextAssessmentName === 'Service') {
            SecAbbr = 'S';
        }

        this.props.history.push(`/assessment-introduction2022/${SecAbbr}/${this.state.nextSectionID}/false`);
    }

    render() {
        const {nextAssessmentName} = this.state;
        let assessName = (nextAssessmentName === 'Service') ? 'Services' : nextAssessmentName;
        const { t } = this.props;
        
        return(
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                <div className="d-flex hp-layout hp-home">
                    <Header />
                        <div className="hp-content-wrap assessment-start-page flex-column">
                            <div className="assessment-start-container">
                                <div className="welcome-text">{t('ASSESSMENT.ASSESSMENT_START_LINE_1', {name: getUserName()})}</div>
                                <div className="welcome-msg">{t('ASSESSMENT.ASSESSMENT_START_LINE_2', {assessment: assessName})}</div>
                                <div className="assessment-start-btn-wrap">
                                    <div className="hp-btn-v1" onClick={this.loadNextAssessment}>{t('ASSESSMENT.ASSESSMENT_START_LINE_3', {assessment: assessName})}</div>
                                </div>
                                <div className="assessment-start-link-wrap">
                                    <Link to={`/dashboard2022/${localStorage.getItem('ppid')}`}>{t('ASSESSMENT.ASSESSMENT_START_LINE_4')}</Link>
                                </div>
                            </div>
                        </div>
                    <Footer />
                </div>
            </div>
        )
    }
    
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssessmentStartPage));