import axios from "axios";
import {API_BASE_URL} from '../config/api-config';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export const FETCH_BP_MASTER_DATA = 'FETCH_BP_MASTER_DATA';
export const FETCH_BP_MASTER_DATA_SUCCESS = 'FETCH_BP_MASTER_DATA_SUCCESS';
export const FETCH_BP_MASTER_DATA_ERROR = 'FETCH_BP_MASTER_DATA_ERROR';

export const BP_PLANNER_ACTIVE_SECTION = 'BP_PLANNER_ACTIVE_SECTION';
export const BP_PLANNER_SET_PLAN_STATE = 'BP_PLANNER_SET_PLAN_STATE';
export const BP_PLANNER_SET_PLAN_BUDGET = 'BP_PLANNER_SET_PLAN_BUDGET';
export const BP_PLANNER_SET_PLAN_ALLOCATION = 'BP_PLANNER_SET_PLAN_ALLOCATION';
export const BP_PLANNER_SET_PLAN_BULK = 'BP_PLANNER_SET_PLAN_BULK';

export const BP_REACH_GET_MASTER = 'BP_REACH_GET_MASTER';
export const BP_REACH_GET_MASTER_SUCCESS = 'BP_REACH_GET_MASTER_SUCCESS';
export const BP_REACH_GET_MASTER_ERROR = 'BP_REACH_GET_MASTER_ERROR';

export const BP_REACH_SET_BUDGET = 'BP_REACH_SET_BUDGET';
export const BP_REACH_SET_VISITOR = 'BP_REACH_SET_VISITOR';
export const BP_REACH_SET_TRANSACTIONS = 'BP_REACH_SET_TRANSACTIONS';
export const BP_REACH_SET_ASV = 'BP_REACH_SET_ASV';
export const BP_REACH_SET_CR = 'BP_REACH_SET_CR';
export const BP_REACH_SET_CPC = 'BP_REACH_SET_CPC';
export const BP_SET_REACH_PLANNER_STATE = 'BP_SET_REACH_PLANNER_STATE';

export const BP_POST_REACH_SUCCESS = 'BP_POST_REACH_SUCCESS';
export const BP_POST_REACH_ERROR = 'BP_POST_REACH_ERROR';
export const BP_UPDATE_REACH_REC = 'BP_UPDATE_REACH_REC';

export const BP_COMPLETE_PLANNER_SUCCESS = 'BP_COMPLETE_PLANNER_SUCCESS';
export const BP_COMPLETE_PLANNER_ERROR = 'BP_COMPLETE_PLANNER_ERROR';
export const BP_COMPLETE_PLANNER_START = 'BP_COMPLETE_PLANNER_START';

export const BP_SUMMARY_SUCCESS = 'BP_SUMMARY_SUCCESS';
export const BP_SUMMARY_ERROR = 'BP_SUMMARY_ERROR';
export const BP_SUMMARY_START = 'BP_SUMMARY_START';

export const BP_SET_REACH_CHANNEL_STATE = 'BP_SET_REACH_CHANNEL_STATE';

export const FETCH_PDF_SUCCESS = 'FETCH_PDF_SUCCESS';
export const FETCH_PDF_ERROR = 'FETCH_PDF_ERROR';


export const fetchBPMaterData = (ppid) => {
    return dispatch => {
        axios.get(`${API_BASE_URL}/business-planner-technologies?ppid=`+ppid)
            .then(res => {
                dispatch(fetchBPMaterDataSuccess(res.data));
                try {
                    if(res.data && res.data.length > 0) {
                        localStorage.setItem('BP_CURRENCY', res.data[0].Currency);
                    }
                } catch(e) {}
            }).catch((err)=> {
                dispatch(fetchBPMaterDataError())
            })
    }
}

export const fetchBPMaterDataSuccess = (data) => {
    return {
        type: FETCH_BP_MASTER_DATA_SUCCESS,
        payload: data
    }
}

export const fetchBPMaterDataError = () => {
    return {
        type: FETCH_BP_MASTER_DATA_ERROR
    }
}

export const setBPActiveSection = (data) => {
    return {
        type: BP_PLANNER_ACTIVE_SECTION,
        payload: data
    }
}

export const setPlanSelectedState = (Key, Val) => {
    let data = {
        Key,
        Val
    };
    return {
        type: BP_PLANNER_SET_PLAN_STATE,
        payload: data
    }
}

export const setPlanBudget = (Key, Val) => {
    let data = {
        Key,
        Val
    };
    return {
        type: BP_PLANNER_SET_PLAN_BUDGET,
        payload: data
    }
}

export const setPlanAllocation = (Key, Val) => {
    let data = {
        Key,
        Val
    };
    return {
        type: BP_PLANNER_SET_PLAN_ALLOCATION,
        payload: data
    }
}

export const setBulkPlanData = (data) => {
    return {
        type: BP_PLANNER_SET_PLAN_BULK,
        payload: data
    }
}

export const setReachBudget = (data) => {
    return {
        type: BP_REACH_SET_BUDGET,
        payload: data
    }
}

export const setReachVisitor = (data) => {
    return {
        type: BP_REACH_SET_VISITOR,
        payload: data
    }
}

export const setReachTransaction = (data) => {
    return {
        type: BP_REACH_SET_TRANSACTIONS,
        payload: data
    }
}

export const setReachASV = (data) => {
    return {
        type: BP_REACH_SET_ASV,
        payload: data
    }
}

const fetchReachMasterError = () => {
    return {
        type: BP_REACH_GET_MASTER_ERROR
    }
}
const fetchReachMasterSuccess = (data) => {
    return {
        type: BP_REACH_GET_MASTER_SUCCESS,
        payload: data
    }
}

export const getReachMasterData = (ppid) => {
    return dispatch => {
        axios.get(`${API_BASE_URL}/business-planner/reach?ppid=`+ppid)
            .then(res => {
                dispatch(fetchReachMasterSuccess(res.data));
                
            }).catch((err)=> {
                dispatch(fetchReachMasterError())
            })
    }
}

export const submitReachData = (data, ppid) => {
    return dispatch => {
        axios.post(`${API_BASE_URL}/business-planner/reach?ppid=`+ppid, data)
            .then(res => {
                dispatch(reactSubmitSuccess(res.data));
                
            }).catch((err)=> {
                dispatch(reachSubmitError())
            })
    }
}

export const reactSubmitSuccess = (data) => {
    return {
        type: BP_POST_REACH_SUCCESS,
        payload: data
    }
}

export const reachSubmitError = () => {
    return {
        type: BP_POST_REACH_ERROR
    }
}
export const updateReachRecomendation = (key, data) => {
    return {
        type: BP_UPDATE_REACH_REC,
        payload: data,
        key: key
    }
}

export const submitCompletePlanner = (data, ppid) => {
    return dispatch => {
        dispatch(completePlannerStart());
        axios.post(`${API_BASE_URL}/business-planner/complete-planner?ppid=`+ppid, data)
            .then(res => {
                dispatch(completePlannerSuccess(res.data));
                
            }).catch((err)=> {
                dispatch(completePlannerError())
            })
    }
}

export const completePlannerSuccess = (data) => {
    return {
        type: BP_COMPLETE_PLANNER_SUCCESS,
        payload: data
    }
}

export const completePlannerStart = () => {
    return {
        type: BP_COMPLETE_PLANNER_START
    }
}

export const completePlannerError = () => {
    return {
        type: BP_COMPLETE_PLANNER_ERROR
    }
}

export const calculateSummary = (ppid) => {
    return dispatch => {
        dispatch(summaryStart());
        axios.post(`${API_BASE_URL}/business-planner/summary?ppid=`+ppid)
            .then(res => {
                dispatch(summarySuccess(res.data));
                
            }).catch((err)=> {
                dispatch(summaryError())
            })
    }
}

export const summaryStart =()=> {
    return {
        type: BP_SUMMARY_START
    }
}

export const summarySuccess = (data) => {
    return {
        type: BP_SUMMARY_SUCCESS,
        payload: data
    }
}

export const summaryError =()=> {
    return {
        type: BP_SUMMARY_ERROR
    }
}

export const setReachChannelState = (key)=> {
    return {
        type: BP_SET_REACH_CHANNEL_STATE,
        payload: key
    }
}

export const setReachPlannerState = (key)=> {
    return {
        type: BP_SET_REACH_PLANNER_STATE,
        payload: key
    }
}

export const fetchPDFData = (ppid) => {
    return dispatch => {
        axios.get(`${API_BASE_URL}/business-planner/pdf-report-data?ppid=`+ppid)
            .then(res => {
                dispatch(fetchPDFSuccess(res.data));
                
            }).catch((err)=> {
                dispatch(fetchPDFError())
            })
    }
}

export const fetchPDFSuccess = (data) => {
    return {
        type: FETCH_PDF_SUCCESS,
        payload: data
    }
}

export const fetchPDFError = () => {
    return {
        type: FETCH_PDF_ERROR
    }
}