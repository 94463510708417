import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBInput,
} from "mdbreact";
import { partnerRegistrationRedirect } from '../../actions/authAction';
import { connect } from 'react-redux';
import { sendPageViewGTM } from "../../utils/util";

import { useTranslation } from 'react-i18next';

import Header from "../../components/Header/Header";

import edcBg from "../../assets/images/edc-bg.png";
import archie from "../../assets/icons/arctic.svg";
import rightArrow from "../../assets/icons/right-arrow.svg";

import "./edc.scss";
import Footer from "../../components/Footer/Footer";
import { API_BASE_URL } from "../../config/api-config";
import axios from "axios";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      partnerRegistrationRedirect: (token,pathName) => dispatch(partnerRegistrationRedirect(token, pathName, ownProps))
  }
}


function EDC({ history,dispatch }) {
  const [countries, setCountries] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isExpanded, setExpanded] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if(!localStorage.getItem("authorizationtoken")){
    const params = new URLSearchParams(window.location.search);
    let qp = params.get('tokenId')

    if(qp && qp !== null) {
      dispatch(partnerRegistrationRedirect(qp,"edc"));

    }
  }
    if (countries?.length === 0) {
      axios.get(`${API_BASE_URL}/countries`).then((res) => {
        if (res) {
          setCountries(res?.data);
        }
      });
    }

    //GTM PageLoad
    try {
      let pagePath = window.location ? window.location.pathname: '';
      sendPageViewGTM('EDC Home Page', pagePath);
    } catch(e){}
  }, []);

  let countrySelected = countries?.find((item) => item?._id === selected)
    ?.Country;

  const handleClick = () => {

    try{
      let ppid= localStorage.getItem('ppid');
      if(ppid) {
        axios
        .get(`${API_BASE_URL}/partners/is-edc-visited?ppid=${ppid}`)
        .then((res) => {
          if(res){
            console.log('EDC visited')
          }
        });
      }
    }catch(e){

    }

    try {
      if(window && window.dataLayer) {
          window.dataLayer.push({
            'event': 'Find EDC',
            'SelectedCountry': countrySelected
          })
      }
    } catch(e){}

    window.scrollTo(0, 0);
    history.push(`/edc/countryid/${selected}`);
  };
  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
      <div className="d-flex hp-layout hp-home">
        <Header />
        <div className="hp-content-wrap edc-container">
          <p className="bread-crumb hp-lite">
          <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt;{" "}
            <span>EDC</span>
          </p>
          <img src={edcBg} alt="edc-image" className="edc-bg-wrap" />

          <div className="edc-filter-wrapper">
            <div className="edc-heading-wrap">
              <p className="hp-bold title edc-title">{t('EDC.EDC_TITLE')}</p>
              <p className="hp-lite heading edc-heading">
              {t('EDC.EDC_DESC_1')}
                <br /> {t('EDC.EDC_DESC_2')}!
              </p>
            </div>
            <div className="edc-filter-contain">
              <p className="hp-lite desc">
              {t('EDC.EDC_MSG')}
              </p>
              <div
                className="toggle-btn"
                onClick={() => setExpanded(!isExpanded)}
              >
                <div className="d-flex align-items-center">
                  <img src={archie} alt="archie" className="mr-3" />
                  <p className="m-0 hp-lite">
                    {selected ? countrySelected : "Select country"}
                  </p>
                </div>

                <img
                  src={rightArrow}
                  alt="right-arrow"
                  className={isExpanded ? "rotate" : ""}
                />
              </div>
              {isExpanded &&
                countries?.map((item, i) => (
                  <div
                    className={
                      i === countries.length - 1
                        ? "input-cb input-last"
                        : "input-cb"
                    }
                    key={item?._id}
                    onClick={() => {
                      setSelected(item?._id);
                      setExpanded(!isExpanded);
                    }}
                  >
                    <input
                      type="radio"
                      name={item?.Name}
                      value={item?._id}
                      className="k-radio"
                      defaultChecked={item?._id === selected}
                    />
                    <label htmlFor="radio" className="hp-lite">
                      {item?.Country}
                    </label>
                  </div>
                ))}
              <button
                className="btn-submit hp-regular"
                onClick={handleClick}
                disabled={!selected}
              >
                {t('EDC.EDC_FIND')}
              </button>

              <p className="hp-lite disclaimer">
                <span className="hp-regular">{t('EDC.EDC_DISCLAIMER')}-</span> {t('EDC.EDC_DISCLAIMER_1')}
                <br />
                <br />
                {t('EDC.EDC_DISCLAIMER_2')}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

//export default EDC;
export default connect(mapDispatchToProps)(EDC);
