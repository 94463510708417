import React from "react";

import "./header.scss";
import logo from "../../../assets/icons/HP_Blue_RGB_150_MN.png";

import { withTranslation } from "react-i18next";

class Header extends React.Component {
  state = {
    isMenuOpen: false,
    ppid: "",
  };

  constructor (props) {
    super(props);
  }

  componentDidMount() {
    let ppid = localStorage.getItem("ppid");
    this.setState({
      ppid: ppid,
    });
  }

  openMenu = () => {
    let menuAction = !this.state.isMenuOpen;
    this.setState({
      isMenuOpen: menuAction,
    });
  };

  gotoBPHome = () => {
    //this.props.history.push('/bp/home');
  }

  updateLanguage = (e) => {
    localStorage.setItem("lan", e.target.value);
    this.props.i18n.changeLanguage(e.target.value);
  };

  render() {
    let { isHome } = this.props;
    const { isMenuOpen, ppid } = this.state;
    const { t } = this.props;
    const selectedlan = localStorage.getItem("lan") || "en";

    const hasAssessmentSubmitted = localStorage.getItem("hasSubmited")
      ? localStorage.getItem("hasSubmited")
      : false;

    return (
      <div className="hp-nav w-100">
        <div className="hp-bp-navbar d-flex justify-content-center">
          <div className="hp-logo-left" onClick={this.gotoBPHome.bind(this)}>
            <span>
              <img src={logo} className="hp-logo" />
            </span>
            <span className="hp-logo-text">Business Planner</span>
          </div>
          <div className="hp-logo-right"></div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Header);
