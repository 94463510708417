
import React from 'react';
import { MDBBtn, MDBInput } from 'mdbreact';
import { Fragment } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {sendPageViewGTM} from '../../../utils/util';


import Slider from '../../../components/Slider/Slider';
import Header from '../../../components/BusinessPlanner/WizardHeader/Header';

 /**
  * Style imports
  */
 import './reachcalculator.scss';

 import ReachPlanner from '../../../components/BusinessPlanner/ReachPlanner';

//redux
import {setReachBudget, setReachVisitor, setReachTransaction, setReachASV, getReachMasterData, submitReachData, updateReachRecomendation, setReachChannelState, setReachPlannerState} from '../../../actions/bpAction';
import {getBPReachData, getReachRecommendations, getReachTotal, getAppState} from '../../../reducers/bpReducer';

const mapStateToProps = (state) => {
    return {
        bpReach: getBPReachData(state),
        bpReachRec: getReachRecommendations(state),
        bpReachTotal: getReachTotal(state),
        appState: getAppState(state)
    }
  }
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setReachBudget: (data) => dispatch(setReachBudget(data)),
        setReachVisitor: (data) => dispatch(setReachVisitor(data)),
        setReachTransaction: (data) => dispatch(setReachTransaction(data)),
        setReachASV: (data) => dispatch(setReachASV(data)),
        getReachMasterData: (ppid) => dispatch(getReachMasterData(ppid)),
        submitReachData: (data, ppid) => dispatch(submitReachData(data, ppid)),
        updateReachRecomendation: (key, data) => dispatch(updateReachRecomendation(key, data)),
        setReachChannelState: (key) => dispatch(setReachChannelState(key)),
        setReachPlannerState: (key) => dispatch(setReachPlannerState(key))
    }
  }
 
 class ReachCalculator extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        showPlanner: false,
        showRecalculatebtn: false
    }

    

    toggleComponent = () => {

    }

    componentDidMount = () => {
        if(this.props && this.props.bpReachRec) {
            this.props.bpReachRec.map((item, i)=> {
                this.setState({
                    [item.Key]: item.HasSelected,
                    [item.Key+'_Budget']: item.Budget
                })
            })
        }

        //GTM PageLoad

        try {
            let pagePath = window.location ? window.location.pathname : "";
            if (window && window.dataLayer) {
            sendPageViewGTM("Business Planner: Reach Calculator", pagePath);
            }
        } catch (e) {}
    }

    onHandleClick = (data) => {
        let ppid = localStorage.getItem('ppid');
        if(data && ppid) {
            let reachData = {
                'Budget': data.budget,
                'ASV': data.asv,
                'CPC': data.cpc,
                'CR': data.cr
            };

            if(reachData) {
                let reachMaster = this.props.bpReachRec;
                if(reachMaster && reachMaster !== null) {
                    reachMaster.map((rec,i)=> {
                        this.setState({
                            [rec.Key+'_Budget']: undefined
                        });
                    })
                }
            }
            
            if(ppid) {
                this.props.submitReachData(reachData, ppid);
            }
            
            this.props.setReachPlannerState(true);
            this.setState({
                showPlanner: !this.state.showPlanner
            })
        }
    }

    ReachRecalculate = () => {
        let data = {};
        let {bpReach} = this.props;
        let ppid = localStorage.getItem('ppid');
        if(bpReach) {
            data['Budget'] = bpReach.budget;
            data['ASV'] = bpReach.asv;
            data['CPC'] = bpReach.cpc;
            data['CR'] = bpReach.cr;
        }
        let reachSelectedChannels = [];
        if(this.props.bpReachRec) {
            this.props.bpReachRec.map((reachData, i)=> {
                if(reachData.HasSelected) {
                    reachSelectedChannels.push({
                        'Key': reachData.Key,
                        'Budget': reachData.Budget
                    })
                }
            })
        }
        if(reachSelectedChannels) {
            data['SelectedChannels'] = reachSelectedChannels;
        }

        if(ppid) {
            this.props.submitReachData(data, ppid);
        }
    }

    onRecChange = (e) => {
        /*if(this.props && this.props.bpReachRec) {
            this.props.bpReachRec.map((item, i)=> {
                if(item.key === rec.key) {
                    item.HasSelected = !item.HasSelected;
                }
            })
        }*/
        /*this.setState({
            [rec.Key]: !this.state[rec.Key]
        });
        this.props.updateReachRecomendation('HasSelected', rec);*/
    }

    handleCheckbox = (rec) => {
        if(rec && rec.Key) {
            this.props.setReachChannelState(rec.Key);
            this.setState({
                showRecalculatebtn: true
            });
        }
    }

    updateBudget = (rec, e) => {
        this.setState({
            [rec.Key+'_Budget']: e.target.value
        })
        rec.cBudget = e.target.value;
        this.props.updateReachRecomendation('Budget', rec);
        this.setState({
            showRecalculatebtn: true
        });
    }

    handleProceed = () => {
        this.props.history.push('/bp/complete-planner/start');
    }
     render() {
        
        const {showPlanner, showRecalculatebtn} = this.state;
        const {bpReach, bpReachRec, bpReachTotal, appState} = this.props;
        let CR = (bpReach && bpReach.cr) ? bpReach: 0;
        if(bpReach && bpReach.transactions && bpReach.visitor) {
            CR = ((bpReach.transactions/bpReach.visitor)*100).toFixed(2);
        }

        return (
            <div className="bp-reach-planner">
                <div className="hp-nav w-100">
                    <Header />
                </div>
                {bpReach &&
                    <div className={appState.showPlanner? 'container bp-reach-budget-recommendation planner-box-shadow': 'container bp-reach-budget-recommendation'}>
                    
                        {!appState.showPlanner &&
                            <div className="bp-reach-budget-recommendation-collapsed">
                                <p className="bp-reach-budget-recommendation-collapsed-title">
                                    Digital Media Planner
                                </p>
                                <p className="bp-reach-budget-recommendation-collapsed-description">
                                    Your parameters are set. Confirm the information set in the calculator below.
                                    In the next step you will get media plan that will get you maximum results from your budget
                                </p>
                                <ReachPlanner CurrencySymbol={bpReach.CurrencySymbol} CurrencySuffix={bpReach.CurrencySuffix} handleClick={this.onHandleClick} budget_min={bpReach.budget_min} budget_max={bpReach.budget_max} budget={bpReach.budget ?bpReach.budget :5000} cr_min={0} cr_max={parseInt(CR)+10} cr={CR} asv_min={bpReach.asv_min} asv_max={bpReach.asv_max} asv={bpReach.asv} cpc_min={bpReach.cpc_min} cpc_max={bpReach.cpc_max} cpc={bpReach.cpc} transactions={bpReach.transactions} visitor={bpReach.visitor} showShadow={appState.showPlanner} recalculate={false}/>
                            </div>
                        }
                        {appState.showPlanner &&
                            <div className="planner-expanded">
                                <div className="planner-expanded-title">
                                    <span className="bold-title">Reach</span> Business plan as per the set monthly budget
                                </div>
                                <ReachPlanner CurrencySymbol={bpReach.CurrencySymbol} CurrencySuffix={bpReach.CurrencySuffix} handleClick={this.onHandleClick} budget_min={bpReach.budget_min} budget_max={bpReach.budget_max} budget={bpReach.budget ?bpReach.budget :5000} cr_min={0} cr_max={parseInt(CR)+10} cr={CR} asv_min={bpReach.asv_min} asv_max={bpReach.asv_max} asv={bpReach.asv} cpc_min={bpReach.cpc_min} cpc_max={bpReach.cpc_max} cpc={bpReach.cpc} transactions={bpReach.transactions} visitor={bpReach.visitor} showShadow={appState.showPlanner} recalculate={true}/>
                            </div>
                        }
                        
            
                        {appState.showPlanner && bpReachRec && bpReachTotal &&
                            <div className="bp-reach-budget-recommendation-expanded">
                                <p className="bp-reach-budget-recommendation-expanded-title">
                                    Your channel mix recommendations
                                </p>
                                <p className="bp-reach-budget-recommendation-expanded-description">
                                    Below is your channel mix recommendation of based on the budget.
                                    Projected numbers are based upon industry standards.
                                    You can add/ remove/ edit the channels or recalculate using the above calculator.
                                </p>
                                <div className="bp-reach-budget-recommendation-expanded-table">
                                    <table>
                                        <thead>
                                            <tr className="tb-header-row">
                                                <td>Select Channels</td>
                                                <td>Budget</td>
                                                <td>Visits</td>
                                                <td>CR%</td>
                                                <td>CPC</td>
                                                <td>Sales</td>
                                                <td>Revenue</td>
                                                <td>ROI</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bpReachRec.map((rec, i)=>(
                                                <tr className={rec.HasSelected ? 'tb-data-row': 'tb-data-row tb-row-disabled'} key={rec.Key}>
                                                    <td>
                                                        <div className="budget-selection-wrap">
                                                            
                                                            <input type="checkbox" className="budget-selecion-input" 
                                                            id={`${rec.Key}`}
                                                            checked={rec.HasSelected ? true : false}
                                                            onChange={this.handleCheckbox.bind(this, rec)}
                                                            value={rec.HasSelected}
                                                            />
                                                            <label className="budget-selecion-label">{rec.Name}</label>
                                                        </div>
                                                    </td>
                                                    <td><MDBInput id={`${rec.Key}`+'_Budget'} value={`${this.state[rec.Key+'_Budget'] === undefined ? Math.round(rec.Budget)+''+bpReach.CurrencySuffix: Math.round(this.state[rec.Key+'_Budget'])+''+bpReach.CurrencySuffix}`} onChange={this.updateBudget.bind(this, rec)}/></td>
                                                    <td><MDBInput id={`${rec.Key}`+'_Visits'} value={rec.Visits} disabled /></td>
                                                    <td><MDBInput id={`${rec.Key}`+'_CR'} value={`${rec.CR}`+'%'} /></td>
                                                    <td><MDBInput id={`${rec.Key}`+'_CPC'} value={rec.CPC} /></td>
                                                    <td><MDBInput id={`${rec.Key}`+'_Sales'} value={rec.Sales} disabled /></td>
                                                    <td><MDBInput id={`${rec.Key}`+'_Revenue'} value={`${bpReach.CurrencySymbol}${(rec.Revenue).toLocaleString("en-US")}${bpReach.CurrencySuffix}`} disabled /></td>
                                                    <td><MDBInput id={`${rec.Key}`+'_ROI'} value={`${Math.round(rec.ROI)}`+'%'} disabled /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr className="tb-total-header-row">
                                                <td></td>
                                                <td>Budget</td>
                                                <td>Visits</td>
                                                <td>CR%</td>
                                                <td>CPC</td>
                                                <td>Sales</td>
                                                <td>Revenue</td>
                                                <td>ROI</td>
                                            </tr>
                                            <tr className="tb-total-data-row">
                                                <td>Total</td>
                                                <td>{bpReach.CurrencySymbol}{Math.round(bpReachTotal.Budget)}{bpReach.CurrencySuffix}</td>
                                                <td>{bpReachTotal.Visits}</td>
                                                <td>{(bpReachTotal.CR).toFixed(2)}</td>
                                                <td>{(bpReachTotal.CPC).toFixed(2)}</td>
                                                <td>{bpReachTotal.Sales}</td>
                                                <td>{bpReach.CurrencySymbol}{(bpReachTotal.Revenue).toLocaleString("en-US")}{bpReach.CurrencySuffix}</td>
                                                <td>{(bpReachTotal.ROI).toFixed(2)}%</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                {showRecalculatebtn &&
                                    <div className="hp-reach-budget-recalculate">
                                            <div className="recalculate-btn" onClick={this.ReachRecalculate}>Recalculate</div>
                                    </div>
                                }
                                
                                <div className="bp-reach-budget-recommendation-expanded-disclaimer">
                                    <p className="bp-reach-budget-recommendation-expanded-disclaimer-title">
                                        Disclaimer
                                    </p>
                                    <p className="bp-reach-budget-recommendation-expanded-disclaimer-description">
                                        Disclaimer The numbers displayed is the forecast are based on your inputs,
                                        and some predictive estimates and is for demonstrative purposes, the real
                                        numbers may vary depending on Conversion Rate%, media type and other factors.
                                        This plan is to give you an indicative understanding of what you may achieve.
                                    </p>
                                </div>
                                <div className="bp-reach-budget-recommendation-expanded-budget-summary">
                                    <div className="bp-reach-budget-recommendation-expanded-budget-summary-side">
                                        <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-title">TOTAL ASSIGNED BUDGET FOR REACH CHANNELS</p>
                                        <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-amount">{bpReach.CurrencySymbol}{Math.round(bpReachTotal.Budget).toLocaleString("en-US")}{bpReach.CurrencySuffix}/ month</p>
                                        <div className="bp-reach-budget-recommendation-expanded-budget-summary-side-items">
                                            <div className="bp-reach-budget-recommendation-expanded-budget-summary-side-item">
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item1">CPC</p>
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item2">{bpReach.CurrencySymbol}{(bpReachTotal.CPC).toFixed(2)}{bpReach.CurrencySuffix}</p>
                                            </div>
                                            <div className="bp-reach-budget-recommendation-expanded-budget-summary-side-item">
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item1">CR%</p>
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item2">{(bpReachTotal.CR).toFixed(2)}%</p>
                                            </div>
                                            <div className="bp-reach-budget-recommendation-expanded-budget-summary-side-item">
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item1">ASV</p>
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item2">{bpReach.CurrencySymbol}{bpReachTotal.ASV}{bpReach.CurrencySuffix}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bp-reach-budget-recommendation-expanded-budget-summary-side">
                                        <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-title">TOTAL REVENUE GENERATED BY REACH CHANNELS</p>
                                        <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-amount">{bpReach.CurrencySymbol}{(bpReachTotal.Revenue).toLocaleString("en-US")}{bpReach.CurrencySuffix}/ month</p>
                                        <div className="bp-reach-budget-recommendation-expanded-budget-summary-side-items">
                                            <div className="bp-reach-budget-recommendation-expanded-budget-summary-side-item">
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item1">Clicks</p>
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item2">{bpReachTotal.Visits}</p>
                                            </div>
                                            <div className="bp-reach-budget-recommendation-expanded-budget-summary-side-item">
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item1">Conv</p>
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item2">{bpReachTotal.Sales}</p>
                                            </div>
                                            <div className="bp-reach-budget-recommendation-expanded-budget-summary-side-item">
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item1">ROAS</p>
                                                <p className="bp-reach-budget-recommendation-expanded-budget-summary-side-item2">{bpReachTotal.ROI.toFixed(2)}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bp-reach-budget-recommendation-expanded-budget-confirm">
                                    <p className="bp-reach-budget-recommendation-expanded-budget-confirm-question">Happy with your plan?</p>
                                    <div className="bp-reach-budget-recommendation-expanded-budget-confirm-btn-area">
                                        <MDBBtn className="bp-reach-budget-recommendation-expanded-budget-confirm-btn" color="primary" onClick={this.handleProceed}>Yes, proceed</MDBBtn>
                                        <Link className="bp-reach-budget-recommendation-expanded-budget-confirm-recalculate" to="/#">Recalculate</Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                
            </div>
            
        );
     }
 }
 
 export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReachCalculator));
 