import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

//const Languages = ["en", "de", "jp", "kr", "es", "it", "fr"];
const Languages = ["en", "fr","it","de","es","el","ru","pt", "id", "ja", "ko", "th", "vi", "tr", "hi"];

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

let getLan = () => {
  let lan;
  if(window.location.href.indexOf('/pdf-report/') > -1) {
    // let isURLHasLan = window.location.href.indexOf('?lan=') > -1 ? window.location.href.split('?lan=')[1] : 'en';
    // lan = isURLHasLan;
    lan = getParameterByName('lan') || 'en'
  } else {
    lan = localStorage.getItem('lan');
  }
  return lan;
}

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
 
    lng: getLan() || 'en',
    fallbackLng: 'en',
    whitelist: Languages,
    backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json?nocache=2',
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
 
export default i18n;

