import { FETCH_TOOLKIT_SUCCESS, FETCH_TOOLKIT_SECTION_SUCCESS, FETCH_TOOLKIT_SERVICE_SECTION_SUCCESS } from "../actions/toolkitAction";

const initialState = {
    toolkit: [],
    section: {},
    serviceSection:{}
}

export const toolkitReducer = (state = initialState, action) => {
    /*if (!state[action.listId]) {
        return state;
    }*/
    switch(action.type) {
        case FETCH_TOOLKIT_SUCCESS:
            let toolkit = action.payload.data;

            let sectionData;
            if(action.payload.section !== null) {
                sectionData = action.payload.section;
            } else {
                sectionData = state.section;
            }

            return {
                ...state,
                toolkit,
                section: sectionData
            };
        case FETCH_TOOLKIT_SECTION_SUCCESS:
            let section = action.payload;
            return {
                ...state,
                section
            } ;
        case FETCH_TOOLKIT_SERVICE_SECTION_SUCCESS:
                let serviceSection = action.payload;
                console.log(`serviceSection`, serviceSection)
                return {
                    ...state,
                    serviceSection
                }  
        default:
            return {
                ...state
            }
    }
}

export const getDetailedReport = state => state['toolkitReducer'].toolkit;
export const getToolkitSection = state => state['toolkitReducer'].section;
export const getServiceToolkitSection = state => state['toolkitReducer'].serviceSection;

