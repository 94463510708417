import { MDBIcon } from "mdbreact";
import React from "react";
import { useHistory } from "react-router";
import "./getConnected.scss";
import test_img from '../../assets/images/report/transaction_disabled.svg';

import increase from '../../assets/icons/increase.svg';
import crm from '../../assets/icons/crm.svg';
import excellence from '../../assets/icons/excellence.svg';


export default function GetConnectedEDC() {
    const history = useHistory()
    const handleClick =()=>{
        window.scroll({top: 0, behavior: "smooth"})
        history.push('/edc');
    }
  return (
    <div className="edc-get-connect">
      <div className="edc-get-connect-title">Choose to Connect with an HP Recommended ‘External Digital Consultant’ - EDC</div>
      <div className="edc-get-connect-sub-title">Here is why?</div>
      <div className="edc-get-connect-why">
        <div className="edc-get-connect-why-item">
            <img src={increase} />
            <div className="edc-get-connect-why-item-title">Enhance your Digital Capabilities</div>
        </div>
        <div className="edc-get-connect-why-item">
            <img src={crm} />
            <div className="edc-get-connect-why-item-title">Improve Customer Engagement</div>
        </div>
        <div className="edc-get-connect-why-item">
            <img src={excellence} />
            <div className="edc-get-connect-why-item-title">Achieve Digital Excellence</div>
        </div>
      </div>
      <div className="edc-get-connect-sub-title extra-margin">Get connected now!</div>
      <div className="edc-get-connect-desc">Select from the HP approved list of ‘External Digital Consultants’ in your country</div>
      <div>
        <button className="connect-me-btn" onClick={handleClick}>Contact Me</button>
      </div>
    </div>
  );
}
