import { act } from "react-dom/test-utils";
import { FETCH_LOGIN_DATA, FETCH_LOGIN_DATA_SUCCESS, FETCH_LOGIN_DATA_ERROR} from "../actions/authAction";

const initialState = {
    loading: false,
    auth: null,
    Error: null
}

export const authReducer = (state = initialState, action) => {
    /*if (!state[action.listId]) {
        return state;
    }*/
    switch(action.type) {
        case FETCH_LOGIN_DATA:
            return {
                ...state
            }
        case FETCH_LOGIN_DATA_SUCCESS:
            let authData = action.payload;
            return {
                ...state,
                auth: authData,
                Error: false
            }
        case FETCH_LOGIN_DATA_ERROR:
            return {
                ...state,
                Error: true
            }
        default:
            return state;
    }
}

export const getAuthData = state => state['authReducer'].auth;
export const getErrorState = state => state['authReducer'].Error;


