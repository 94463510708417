import React from 'react';
import './pdfreport.scss';

import hp_logo from '../../../assets/icons/BP/Component 70.svg';
import { MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import bpChannelData from '../../../config/bp_channel_details.json';

//redux
import {fetchPDFData} from '../../../actions/bpAction';
import {getPDFData} from '../../../reducers/bpReducer';

const mapStateToProps = (state) => {
    return {
        bpPDF: getPDFData(state)
    }
  }
  
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchPDFData: (ppid) => dispatch(fetchPDFData(ppid))
    }
}

class PDFreport extends React.Component {

    componentDidMount =() => {
        if(this.props.match.params.ppid) {
            this.props.fetchPDFData(this.props.match.params.ppid);
        }
    }
    render() {

        const {bpPDF} = this.props;
        return (
            <div className="bp-pdf">
               <div className="executive-summary">
                    <div className="section-1">
                        <div className="section-title">
                            <img src={hp_logo} className="hp-logo"/>
                            Digital Business Planner
                        </div>
                        <div className="section-info">
                            {bpPDF && bpPDF.partnerName &&
                                <div className="partner-name">{bpPDF.partnerName}</div>
                            }
                            <div className="created-date"> Date created: {new Date().toDateString()} </div>
                        </div>
                    </div>
                    
                    {bpPDF && bpPDF.executiveSummary &&
                        <div className="section-2">
                            <div className="bp-reach-budget-recommendation-expanded-disclaimer report-disclaimer">
                                <p className="bp-reach-budget-recommendation-expanded-disclaimer-title">
                                    Disclaimer
                                </p>
                                <p className="bp-reach-budget-recommendation-expanded-disclaimer-description">
                                The ROI numbers displayed in the calculation are indicative and are provided for your information purposes only, as a free-of-charge service. Your actual realized ROI may differ significantly from the ROI numbers shown in the calculation, as the numbers used in preparation of the calculation are based on various factors (e.g. Conversion Rate percentages, the Average Sales Value, the Cost per Click, the price and quality of the media/technologies purchased) and are based on inputs that you provided in the tool. You should make your own independent judgment when using the tool and employ them as indicative information to evaluate cost and performance, as actual numbers will vary. 
                                </p>
                            </div>
                            <div></div>
                            <div className="summary-title">EXECUTIVE SUMMARY</div>
                            <div className="summary-data">
                                <span className="summary-label">Budget for the Year: </span>
                                <span className="summary-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.firstYearBudget).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</span>
                            </div>
                            <div className="summary-data">
                                <span className="summary-label">Expected return on investment year one: </span>
                                <span className="summary-value">{bpPDF.executiveSummary.estimatedROI.toFixed(2)}%</span>
                            </div>
                            <div className="summary-data">
                                <span className="summary-label">Expected period to break even on cost: </span>
                                <span className="summary-value">{bpPDF.executiveSummary.predictedBreakEvenPoint}</span>
                            </div>
                        </div> 
                    }
                    
               </div>

                {/* Reach plan */}

                {bpPDF && bpPDF.ReachPlanner &&
                    <div className="reach-plan">
                        <p className="bp-reach-budget-recommendation-expanded-title">
                            Your channel mix recommendations
                        </p>
                        <p className="bp-reach-budget-recommendation-expanded-description">
                            Below is your channel mix recommendation of based on the budget.
                            Projected numbers are based upon industry standards.
                            You can add/ remove/ edit the channels or recalculate using the above calculator.
                        </p>
                        <div className="bp-reach-budget-recommendation-expanded-table">
                            <table>
                                <thead>
                                    <tr className="tb-header-row">
                                        <td>Select Channels</td>
                                        <td>Budget</td>
                                        <td>Visits</td>
                                        <td>CR%</td>
                                        <td>CPC</td>
                                        <td>Sales</td>
                                        <td>Revenue</td>
                                        <td>ROI</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bpPDF.ReachPlanner && bpPDF.ReachPlanner.reachTypes && bpPDF.ReachPlanner.reachTypes.map((reach, i)=> (
                                        <tr className={reach.HasSelected ? 'tb-data-row': 'tb-data-row tb-row-disabled'} key={reach.Key}>
                                            <td>
                                                <div className="budget-selection-wrap">
                                                    <input type="checkbox" className="budget-selecion-input" 
                                                    id={`${reach.Key}`}
                                                    checked={reach.HasSelected ? true : false}
                                                    value={reach.HasSelected}
                                                    />
                                                    <label className="budget-selecion-label">{reach.Name}</label>
                                                </div>
                                            </td>
                                            <td>{bpPDF.CurrencySymbol}{Math.round(reach.Budget).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</td>
                                            <td>{reach.Visits}</td>
                                            <td>{reach.CR.toFixed(2)}</td>
                                            <td>{reach.CPC.toFixed(2)}</td>
                                            <td>{reach.Sales}</td>
                                            <td>{bpPDF.CurrencySymbol}{Math.round(reach.Revenue).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</td>
                                            <td>{reach.ROI.toFixed(2)}%</td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr className="tb-total-header-row">
                                        <td></td>
                                        <td>Budget</td>
                                        <td>Visits</td>
                                        <td>CR%</td>
                                        <td>CPC</td>
                                        <td>Sales</td>
                                        <td>Revenue</td>
                                        <td>ROI</td>
                                    </tr>
                                    {bpPDF.ReachPlanner && bpPDF.ReachPlanner.reachTypes && bpPDF.ReachPlanner.total &&
                                        <tr className="tb-total-data-row">
                                            <td>Total</td>
                                            <td>{bpPDF.CurrencySymbol}{Math.round(bpPDF.ReachPlanner.total.Budget).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</td>
                                            <td>{bpPDF.ReachPlanner.total.Visits}</td>
                                            <td>{bpPDF.ReachPlanner.total.CR.toFixed(2)}</td>
                                            <td>{bpPDF.ReachPlanner.total.CPC.toFixed(2)}</td>
                                            <td>{bpPDF.ReachPlanner.total.Sales}</td>
                                            <td>{bpPDF.CurrencySymbol}{Math.round(bpPDF.ReachPlanner.total.Revenue).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</td>
                                            <td>{bpPDF.ReachPlanner.total.ROI.toFixed(2)}%</td>
                                        </tr>
                                    }
                                </tfoot>
                            </table>
                        </div>
                        <div className="bp-reach-budget-recommendation-expanded-disclaimer">
                            <p className="bp-reach-budget-recommendation-expanded-disclaimer-title">
                                Disclaimer
                            </p>
                            <p className="bp-reach-budget-recommendation-expanded-disclaimer-description">
                                The numbers displayed is the forecast are based on your inputs,
                                and some predictive estimates and is for demonstrative purposes, the real
                                numbers may vary depending on Conversion Rate%, media type and other factors.
                                This plan is to give you an indicative understanding of what you may achieve.
                            </p>
                        </div>
                </div>
                }

                {bpPDF && bpPDF.CompletePlanner &&
                <div className="channel-selections">
                        {bpPDF.CompletePlanner.technologies && bpPDF.CompletePlanner.technologies.map((channel, i)=> {
                            if(channel && channel.HasPartnerSelected && channel.Key !== 'DEMAND_GENERATION_BUDGET')
                            return <div className="channel-item" key={channel.Key}>
                                <div className="channel-title">{channel.Name}</div>
                                <div className="channel-sub-title">Selected Budget - {channel.BudgetGroup}</div>
                                <div className="channel-description">{bpChannelData[channel.Key] && bpChannelData[channel.Key]["DESCRIPTION"] ? bpChannelData[channel.Key]["DESCRIPTION"]: '' }</div>
                                <div className="channel-budget">{channel.BudgetGroup} LEVEL</div>
                                <div className="channel-budget-features">
                                 {bpChannelData[channel.Key] && bpChannelData[channel.Key][channel.BudgetGroup.toUpperCase()] && bpChannelData[channel.Key][channel.BudgetGroup.toUpperCase()].map((item, i)=>(
                                    <div>{item}</div>
                                  ))}
                                </div>
                            </div>
                        })}
                    </div>
                }

                {/* Complete Planner */}

                {bpPDF && bpPDF.CompletePlanner &&
                    <div className="complete-planner">
                        <div className="complete-planner-data">
                            <div className="complete-planner-title">
                                The complete planner for <span className="bold-title">Reach, Engage, Transact and Retain</span>
                            </div>
                            <div className="complete-planner-table-wrap">
                                <div className="cp-table">
                                    <div className="cp-t-header">
                                        <div className="one-row-header c1">Technologies</div>
                                        <div className="one-row-header c2">One Time pay</div>
                                        <div className="one-row-header c3">Subscription</div>
                                        <div className="two-row-header cc">
                                            <div className="row-1">1st Quarter</div>
                                            <div className="row-2">
                                                <div className="row-rt">Month 1</div>
                                                <div className="row-rt">Month 2</div>
                                                <div>Month 3</div>
                                            </div>
                                        </div>
                                        <div className="two-row-header cc">
                                            <div className="row-1">2nd Quarter</div>
                                            <div className="row-2">
                                                <div className="row-rt">Month 4</div>
                                                <div className="row-rt">Month 5</div>
                                                <div>Month 6</div>
                                            </div>
                                        </div>
                                        <div className="two-row-header cc">
                                            <div className="row-1">3rd Quarter</div>
                                            <div className="row-2">
                                                <div className="row-rt">Month 7</div>
                                                <div className="row-rt">Month 8</div>
                                                <div>Month 9</div>
                                            </div>
                                        </div>
                                        <div className="two-row-header cc">
                                            <div className="row-1">4th Quarter</div>
                                            <div className="row-2">
                                                <div className="row-rt">Month 10</div>
                                                <div className="row-rt">Month 11</div>
                                                <div>Month 12</div>
                                            </div>
                                        </div>
                                        <div className="two-row-header c-total">
                                            <div className="row-1">Total</div>
                                            <div className="row-2">
                                                Annual
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cp-t-body">
                                        {/* Reach */}
                                        <div className="cp-t-section-header">
                                            <div className="t-section-title">Reach</div>
                                        </div>
                                        {bpPDF.CompletePlanner && bpPDF.CompletePlanner.technologies && bpPDF.CompletePlanner.technologies.map((data, i)=> {
                                            if(data && data.Section && data.Section === 'Reach') 
                                                return <div className="cp-t-row row-selected">
                                                    <div className="t-r-technology">
                                                        <div className="selected-icon">
                                                            <MDBIcon icon="check-circle" />
                                                        </div>
                                                        {data.Name}
                                                    </div>
                                                    <div className="t-r-pay">NA</div>
                                                    <div className="t-r-subscription">Monthly</div>
                                                    {data.MonthwiseBudget && data.MonthwiseBudget.map((budget, key)=>(
                                                        <div className="t-r-cell" key={budget.MonthName}>
                                                            {bpPDF.CurrencySymbol}{Math.round(budget.Budget).toLocaleString("en-US")}{bpPDF.CurrencySuffix}
                                                        </div>
                                                    ))}
                                                    <div className="t-r-total">{bpPDF.CurrencySymbol}{Math.round(data.MonthwiseBudgetTotal).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                                </div>
                                            })
                                        }

                                        {/* Engage */}
                                        <div className="cp-t-section-header">
                                            <div className="t-section-title">Engage</div>
                                        </div>
                                        {bpPDF.CompletePlanner && bpPDF.CompletePlanner.technologies && bpPDF.CompletePlanner.technologies.map((data, i)=> {
                                            if(data && data.Section && data.Section === 'Engage') 
                                            return <div className={data.HasPartnerSelected ? 'cp-t-row row-selected': 'cp-t-row'}>
                                                <div className="t-r-technology">
                                                    {data.HasPartnerSelected &&
                                                        <div className="selected-icon">
                                                            <MDBIcon icon="check-circle" />
                                                        </div>
                                                    }
                                                    {data.Name}
                                                </div>
                                                <div className="t-r-pay">
                                                    {data.BudgetType === 'OneOff' ? data.BudgetTypeSelected : 'NA'}
                                                </div>
                                                <div className="t-r-subscription">
                                                    {data.BudgetType === 'Regular' ? data.BudgetTypeSelected : 'NA'}
                                                </div>
                                                {data.MonthwiseBudget && data.MonthwiseBudget.map((budget, key)=>(
                                                    <div className="t-r-cell" key={budget.MonthName}>
                                                        {bpPDF.CurrencySymbol}{Math.round(budget.Budget).toLocaleString("en-US")}{bpPDF.CurrencySuffix}
                                                    </div>
                                                ))}
                                                <div className="t-r-total">{bpPDF.CurrencySymbol}{data.MonthwiseBudgetTotal.toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                        })}

                                        {/* Transact */}
                                        <div className="cp-t-section-header">
                                            <div className="t-section-title">Transact</div>
                                        </div>
                                        {bpPDF.CompletePlanner && bpPDF.CompletePlanner.technologies && bpPDF.CompletePlanner.technologies.map((data, i)=> {
                                            if(data && data.Section && data.Section === 'Transact') 
                                            return <div className={data.HasPartnerSelected ? 'cp-t-row row-selected': 'cp-t-row'}>
                                                <div className="t-r-technology">
                                                    {data.HasPartnerSelected &&
                                                        <div className="selected-icon">
                                                            <MDBIcon icon="check-circle" />
                                                        </div>
                                                    }
                                                    {data.Name}
                                                </div>
                                                <div className="t-r-pay">
                                                    {data.BudgetType === 'OneOff' ? data.BudgetTypeSelected : 'NA'}
                                                </div>
                                                <div className="t-r-subscription">
                                                    {data.BudgetType === 'Regular' ? data.BudgetTypeSelected : 'NA'}
                                                </div>
                                                {data.MonthwiseBudget && data.MonthwiseBudget.map((budget, key)=>(
                                                    <div className="t-r-cell" key={budget.MonthName}>
                                                        {bpPDF.CurrencySymbol}{Math.round(budget.Budget).toLocaleString("en-US")}{bpPDF.CurrencySuffix}
                                                    </div>
                                                ))}
                                                <div className="t-r-total">{bpPDF.CurrencySymbol}{data.MonthwiseBudgetTotal.toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                        })}

                                        {/* Retain */}
                                        <div className="cp-t-section-header">
                                            <div className="t-section-title">Retain</div>
                                        </div>
                                        
                                        {bpPDF.CompletePlanner && bpPDF.CompletePlanner.technologies && bpPDF.CompletePlanner.technologies.map((data, i)=> {
                                            if(data && data.Section && data.Section === 'Retain') 
                                            return <div className={data.HasPartnerSelected ? 'cp-t-row row-selected': 'cp-t-row'}>
                                                <div className="t-r-technology">
                                                    {data.HasPartnerSelected &&
                                                        <div className="selected-icon">
                                                            <MDBIcon icon="check-circle" />
                                                        </div>
                                                    }
                                                    {data.Name}
                                                </div>
                                                <div className="t-r-pay">
                                                    {data.BudgetType === 'OneOff' ? data.BudgetTypeSelected : 'NA'}
                                                </div>
                                                <div className="t-r-subscription">
                                                    {data.BudgetType === 'Regular' ? data.BudgetTypeSelected : 'NA'}
                                                </div>
                                                {data.MonthwiseBudget && data.MonthwiseBudget.map((budget, key)=>(
                                                    <div className="t-r-cell" key={budget.MonthName}>
                                                        {bpPDF.CurrencySymbol}{Math.round(budget.Budget).toLocaleString("en-US")}{bpPDF.CurrencySuffix}
                                                    </div>
                                                ))}
                                                <div className="t-r-total">{bpPDF.CurrencySymbol}{data.MonthwiseBudgetTotal.toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                        })}

                                    </div>
                                    <div className="cp-t-footer">
                                    <div className="footer-row-1">
                                        <div className='footer-total-label'>
                                                Total
                                            </div>
                                            {bpPDF.CompletePlanner && bpPDF.CompletePlanner.totalMonthly && bpPDF.CompletePlanner.totalMonthly.map((data, i) => (
                                                <div className="footer-total-val" key={data.MonthName}>{bpPDF.CurrencySymbol}{Math.round(data.BudgetMonthly).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            ))}
                                            {bpPDF.CompletePlanner && bpPDF.CompletePlanner.totalAnnualy && 
                                                <div className="footer-grand-total-val">{bpPDF.CurrencySymbol}{Math.round(bpPDF.CompletePlanner.totalAnnualy).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            }
                                        </div>
                                        <div className="footer-row-2">
                                            <div className="footer-empty-cell"></div>
                                            <div className="footer-quarter-label">1st Quarter</div>
                                            <div className="footer-quarter-label">2nd Quarter</div>
                                            <div className="footer-quarter-label">3rd Quarter</div>
                                            <div className="footer-quarter-label">4th Quarter</div>
                                            <div className="footer-annual-label">Annual</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* Detailed Summary */}
                {bpPDF && bpPDF.executiveSummary &&
                    <div className="detailed-summary">
                        <div className="summary-left">
                            <div className="left-container">
                                <div className="left-img"></div>
                                <div className="left-summary-table">
                                    <div className="left-table-wrap">
                                        <div className="bp-summary">
                                            <div className="summary-title">SUMMARY</div>
                                            <div className="summary-row">
                                                <div className="summary-label">First Year Budget</div>
                                                <div className="summary-val">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.firstYearBudget).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                            <div className="summary-row">
                                                <div className="summary-label">Estimated ROI</div>
                                                <div className="summary-val">{bpPDF.executiveSummary.estimatedROI.toFixed(2)}%</div>
                                            </div>
                                            <div className="summary-row">
                                                <div className="summary-label">Estimated first year revenue</div>
                                                <div className="summary-val">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.estimatedFirstYearRevenue).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-table-wrap">
                                        <div className="bp-investment">
                                            <div className="investment-title">QUARTERLY INVESTMENT</div>
                                            <div className="investment-data-row">
                                                <div className="investment-quarter">1st</div>
                                                <div className="investment-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.investmentQ1).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                            <div className="investment-data-row">
                                                <div className="investment-quarter">2nd</div>
                                                <div className="investment-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.investmentQ2).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                            <div className="investment-data-row">
                                                <div className="investment-quarter">3rd</div>
                                                <div className="investment-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.investmentQ3).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                            <div className="investment-data-row">
                                                <div className="investment-quarter">4th</div>
                                                <div className="investment-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.investmentQ4).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="summary-right">
                            <div className="roi-title">
                                <span></span>
                                <span>ROI AND BREAKEVEN POINT</span>
                            </div>
                            <div className="main-channels-title"> Main Channels</div>
                            <div className="main-channel-row">
                                <div className="main-channel-label">Reach</div>
                                <div className="main-channel-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.reach).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                            </div>
                            <div className="main-channel-row">
                                <div className="main-channel-label">Engage</div>
                                <div className="main-channel-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.engage).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                            </div>
                            <div className="main-channel-row">
                                <div className="main-channel-label">Transact</div>
                                <div className="main-channel-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.transact).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                            </div>
                            <div className="main-channel-row">
                                <div className="main-channel-label">Retain</div>
                                <div className="main-channel-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.retain).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                            </div>


                            <div className="roi-tital-cost-row">
                                <div className="roi-total-label">Total cost year 1 *</div>
                                <div className="roi-total-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.totalCostYear1).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                            </div>

                            <div className="roi-split-row">
                                <div className="roi-split-label">Predicted average monthly revenue</div>
                                <div className="roi-split-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.predictedAverageMonthlyRevenue).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                            </div>
                            <div className="roi-split-row">
                                <div className="roi-split-label">Predicted annual revenue year 1*</div>
                                <div className="roi-split-value">{bpPDF.CurrencySymbol}{Math.round(bpPDF.executiveSummary.predictedAnnualRevenueYear1).toLocaleString("en-US")}{bpPDF.CurrencySuffix}</div>
                            </div>
                            <div className="roi-split-row">
                                <div className="roi-split-label">Net ROI year 1</div>
                                <div className="roi-split-value">{bpPDF.executiveSummary.netROIYear1.toFixed(2)}%</div>
                            </div>
                            <div className="roi-split-row">
                                <div className="roi-split-label">Predicted breakeven point*</div>
                                <div className="roi-split-value">{bpPDF.executiveSummary.predictedBreakEvenPoint}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PDFreport));