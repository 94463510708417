import axios from "axios";
import { API_BASE_URL } from "../../config/api-config";

/**
 *
 * **/

export const UPDATE_ASSESSMENT_DATA = "UPDATE_ASSESSMENT_DATA";
export const FETCH_ASSESSMENT = "FETCH_ASSESSMENT";
export const SAVE_COMPLETED_SECTIONS = "SAVE_COMPLETED_SECTIONS";
export const SUBMIT_ASSESSMENT = "SUBMIT_ASSESSMENT";
export const FETCH_ASSESSMENT_REPORT = "FETCH_ASSESSMENT_REPORT";
export const FETCH_ASSESSMENT_START = "FETCH_ASSESSMENT_START";
export const EMPTY_COMPLETED_SECTIONS = "EMPTY_COMPLETED_SECTIONS";
export const CLEAR_ASSESSMENT_DATA = "CLEAR_ASSESSMENT_DATA";
export const SAVE_COMPLETED_SECTIONS_BY_TYPE =
  "SAVE_COMPLETED_SECTIONS_BY_TYPE";
export const SAVE_TOTAL_SECTIONS_BY_TYPE = "SAVE_TOTAL_SECTIONS_BY_TYPE";
export const FETCH_ASSESSMENT_ERROR = "FETCH_ASSESSMENT_ERROR";
export const FETCH_ASSESSMENT_REPORT_START = "FETCH_ASSESSMENT_REPORT_START";
export const FETCH_ASSESSMENT_REPORT_ERROR = "FETCH_ASSESSMENT_REPORT_ERROR";

export const fetchAssessmentsStart = () => {
  return {
    type: FETCH_ASSESSMENT_START,
  };
};

export const fetchAssessments = (data) => {
  return {
    type: FETCH_ASSESSMENT,
    payload: data,
  };
};

export const fetchAssessmentsError = (data) => {
  return {
    type: FETCH_ASSESSMENT_ERROR,
    payload: data,
  };
};

export const saveAssessment = () => {
  return {
    type: SUBMIT_ASSESSMENT,
  };
};

export const SaveCompletedSections = (data) => {
  return {
    type: SAVE_COMPLETED_SECTIONS,
    payload: data,
  };
};

export const SaveCompletedAssessmentByType = (data, key) => {
  return {
    type: SAVE_COMPLETED_SECTIONS_BY_TYPE,
    payload: data,
    key: key,
  };
};

export const SaveTotalAssessmentByType = (data, key) => {
  return {
    type: SAVE_TOTAL_SECTIONS_BY_TYPE,
    payload: data,
    key: key,
  };
};

export const emptyCompletedSections = () => {
  return {
    type: FETCH_ASSESSMENT_START,
  };
};

export const fetchAssessmentReport = (data) => {
  return {
    type: FETCH_ASSESSMENT_REPORT,
    payload: data,
  };
};

export const clearAssessmentData = () => {
  return {
    type: CLEAR_ASSESSMENT_DATA,
  };
};

export const updateAssessment = () => {
  return {
    type: UPDATE_ASSESSMENT_DATA,
  };
};

export const fetchAssessmentReportStart = () => {
  return {
    type: FETCH_ASSESSMENT_REPORT_START,
  };
};

export const fetchAssessmentReportError = () => {
  return {
    type: FETCH_ASSESSMENT_REPORT_ERROR,
  };
};

export const loadAssessmentReport = (data) => {
  let ppid = localStorage.getItem("ppid");

  return (dispatch) => {
    dispatch(fetchAssessmentReportStart());
    axios
      .get(`${API_BASE_URL}/assessment-statuses/getscore?year=2022&ppid=` + ppid)
      .then((res) => {
        return res.data;
      })
      .then((data) => dispatch(fetchAssessmentReport(data)))
      .catch((error) => dispatch(fetchAssessmentReportError()));
  };
};

export const submitAssessment = () => {
  let ppid = localStorage.getItem("ppid");
  return (dispatch) => {
    axios
      .get(`${API_BASE_URL}?ppid=` + ppid)
      .then((res) => {
        return res.data;
      })
      .then((data) => dispatch(saveAssessment(data)));
  };
};

export const LoadAssessments = (ppidData, ownProps, fromHomePage) => {

  let ppid =  localStorage.getItem("ppid")||ppidData;
  return (dispatch) => {
    axios
      .get(`${API_BASE_URL}/assessment-categories/status?year=2022&ppid=` + ppid)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let d = res.data[0];
          let hasStarted = false;
          let hasSubmited = false;
          let reOption = localStorage.getItem("reAssessmentOption");
        
          if (d.sections && d.sections.length > 0) {
            hasStarted = d.sections[0].hasStarted;
            hasSubmited = d.sections[0].hasFinallySubmitted;
            localStorage.setItem("hasStarted", hasStarted);
            localStorage.setItem("hasSubmited", hasSubmited);
            if (ownProps && fromHomePage) {
              let isRedirect = ownProps.match.params.redirect;
              if (
                hasStarted &&
                (isRedirect === undefined || !isRedirect || isRedirect === null)
              ) {
                  axios
                      .get(`${API_BASE_URL}/partners/progress-status?year=2022&ppid=${localStorage.getItem('ppid')}`)
                      .then((res) => {
                        if(reOption === 'NONE'){
                          ownProps.history.push(`/dashboard/${localStorage.getItem('ppid')}`);
                         // ownProps.history.push("/dashboard2022");
                        }
                          let q = res.data[1].status;
                          let d = res.data[0].status;
                          if(d === true && q != false){
                            localStorage.setItem("started2022",false);
                            localStorage.setItem("hasSubmited2022",true)
                            ownProps.history.push(`/reassessment-report/${localStorage.getItem('ppid')}`);
                          }else if(q === true ){
                            localStorage.setItem("started2022",true)
                            ownProps.history.push(`/dashboard2022/${localStorage.getItem('ppid')}`);
                          }
                          //d.data.sort((a, b) => (a.order > b.order) ? 1 : -1);
                          //this.setState({result: d})
                      });
         
                if(reOption != 'null'){
                  ownProps.history.push(`/dashboard2022/${localStorage.getItem('ppid')}`);
                 // ownProps.history.push("/dashboard2022");
                }
                if (localStorage.getItem("reAssesment")) {
                } else {
                  ownProps.history.push(`/dashboard2022/${localStorage.getItem('ppid')}`);
                }
              }
            }
          }
        }
        return res.data;
      })
      .then((data) => dispatch(fetchAssessments(data)))
      .catch((error) => {
        let err = error.response;
        if (err && err.data && err.data.statusCode === 401) {
          dispatch(fetchAssessmentsError("Unauthorized Access"));
        } else {
          dispatch(fetchAssessmentsError());
        }
        ownProps.history.push("/");
      });
  };
};

export const wipeAssessmentData = (ppidData, ownProps) => {
  let ppid = ppidData ? ppidData : localStorage.getItem("ppid");
  return (dispatch) => {
    axios
      .get(`${API_BASE_URL}/partners/clear?year=2022&ppid=` + ppid)
      .then((res) => {
        return res;
      })
      .then((data) => dispatch(LoadAssessments(ppid, ownProps)));
  };
};

export const updateAssessmentStatus = (ppidData) => {
  let ppid = ppidData ? ppidData : localStorage.getItem("ppid");
  return (dispatch) => {
    axios
      .post(`${API_BASE_URL}/partners/qualifier-status?ppid=` + ppid)
      .then((res) => {
        return res;
      })
      .then((data) => dispatch(updateAssessment(data)));
  };
};
