import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

import './celebrationanimation.scss';

export default function CelebrationAnimation() {
    const [animationOpasity, setOpacity] = useState(0)

    const toggleConfetti = (no) => {
        setOpacity(no);
    };
    

    useEffect(() => {
        setTimeout(() => {
            toggleConfetti(500);
        }, 100);

        setTimeout(() => {
            toggleConfetti(0);
        }, 3000);
    }, []);
    
    return(
        <Confetti
        className="celebration-animation"
        width={window.innerWidth || 300}
        height={window.innerHeight || 200}
        numberOfPieces={animationOpasity}
        gravity={0.2}
        />
    )
}