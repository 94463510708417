import React from "react";
import { Bar } from "react-chartjs-2";
import styles from "./index.module.scss";

function VerticalChart({ t, section, chartData, chartData21, chartData22, type }) {
  console.log("asdasds1", chartData21?.sub_sections?.map(item => item?.Key));
  console.log("asdasds2z", chartData22?.sub_sections?.map(item => item?.Key));
  const labels1 = chartData21?.sub_sections?.map(item => item?.Key);
  //const label22= chartData22?.sub_sections?.map(item=> item?.Key);
  const data2021 =
    type === "Digital"
      ? chartData?.[0]?.scores?.[0]?.sections
      : chartData?.[0]?.scores?.[1]?.sections;
  const data2022 =
    type === "Digital"
      ? chartData?.[1]?.scores?.[0]?.sections
      : chartData?.[1]?.scores?.[1]?.sections;

  const section2021 = data2021
    ?.find(item => item?.Name === section?.Name || 'Reach')
  const section2022 = data2022
    ?.find(item => item?.Name === section?.Name || 'Reach')

  console.log('section2021', labels1)
  console.log('section2022', chartData)

  const options = {
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
  };

  const labels = labels1;
  const colorCall = (data) => {
    console.log("sss", data);
    let color = "";
    if (data) {
      if (data <= 25) {
        color = "#deeaef";
      } else if (data > 25 &&
        data <= 50) {
        color = "#a8e6ff";
      } else if (data > 50 &&
        data <= 75) {
        color = "#4ecaff";
      } else if (data > 75 &&
        data <= 100) {
        color = "#39b2e5";
      }
    }
    return color;
  }
  const colorCall22 = (data) => {
    console.log("sss", data);
    let color = "";
    if (data) {
      if (data <= 25) {
        color = "#deeaef";
      } else if (data > 25 &&
        data <= 50) {
        color = "#a8e6ff";
      } else if (data > 50 &&
        data <= 75) {
        color = "#4ecaff";
      } else if (data > 75 &&
        data <= 100) {
        color = "#39b2e5";
      }
    }
    return color;
  }
  const color21 = chartData21?.sub_sections?.map(item => colorCall(item?.ScorePercentagePeerScore * 100));
  const color22 = chartData22?.sub_sections?.map(item => colorCall22(item?.ScorePercentagePeerScore * 100));

  const data = {
    labels,
    datasets: [
      {
        label: "2021",
        data: chartData21?.sub_sections?.map(item => item?.ScorePercentagePeerScore * 100),
        backgroundColor: color21,
      },
      {
        label: "2022",
        data: chartData22?.sub_sections?.map(item => item?.ScorePercentagePeerScore * 100),
        backgroundColor: color22,
      },
    ],
  };

  return (
    <div className={styles.wrapper}>
      <Bar options={options} data={data} />
      <div className="tabs-container mt-3 mb-1">
        <div className="tabs">
          <span className="tab1 hp-lite">{t("REPORT.REPORT_LAGGING")}</span>
          <span className="hp-lite">0%-25%</span>
        </div>
        <div className="tabs">
          <span className="tab2 hp-lite">
            {t("REPORT.REPORT_INTERMEDIATE")}
          </span>
          <span className="hp-lite">26%-50%</span>
        </div>
        <div className="tabs">
          <span className="tab3 hp-lite">{t("REPORT.REPORT_ADVANCED")}</span>
          <span className="hp-lite">51%-75%</span>
        </div>
        <div className="tabs">
          <span className="tab4 hp-lite">{t("REPORT.REPORT_LEADER")}</span>
          <span className="hp-lite">76%-100%</span>
        </div>
      </div>
    </div>
  );
}

export default VerticalChart;
