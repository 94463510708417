import React from "react";
import { Radar } from "react-chartjs-2";
import "./radar.scss";

function RadarComponent({ data1 }) {
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const dataRadar = {
    labels: data1?.sections?.map((item) =>
      item?.Name == "Services & Solutions Delivery"
        ? "Service Delivery"
        : item?.Name
    ),
    datasets: [
      {
        label: "Your Score",
        backgroundColor: "rgb(86,193,255,.2)",
        borderColor: "#56C1FF",
        pointRadius: isMobile ? 2.5 : 5,
        border: 1,
        borderWidth: isMobile ? 1 : 3,
        pointBackgroundColor: "#56C1FF",
        data: data1?.sections?.map((item) =>
          Math.round(item?.scorePercentage * 100)
        ),
      },
      {
        label: "Peer Score",
        backgroundColor: "rgb(205,205,205,.5)",
        borderColor: "rgb(205,205,205)",
        pointRadius: 5,
        pointRadius: isMobile ? 2.5 : 5,
        borderWidth: isMobile ? 1 : 3,
        pointBackgroundColor: "rgb(205,205,205)",
        data: data1?.sections?.map((item) =>
          Math.round(item?.ScorePercentagePeerScore * 100)
        ),
      },
    ],
  };
  return (
    <>
      <Radar
        className="radar-2"
        data={dataRadar}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          scale: {
            gridLines: {
              circular: true,
              color: ["#deeaef", "#a8e6ff", "#4ecaff", "#39b2e5"],
              drawTicks: true,
            },
            pointLabels: {
              fontSize: isMobile ? 10 : 16,
              fontColor: "#0096D6",
              fontStyle: "bold",
            },
            angleLines: {
              color: "#39B2E5",
            },
            ticks: {
              beginAtZero: true,
              suggestedMin: 25,
              suggestedMax: 100,
              stepSize: 25,
              fontColor: "#39B2E5",
              fontSize: isMobile ? 10 : 15,
              fontStyle: "bold",
            },
          },
          legend: {
            position: "bottom",
            align: "center",
            labels: {
              fontSize: isMobile ? 10 : 14,
              boxWidth: isMobile ? 4 : 8,
              usePointStyle: true,
            },
          },
        }}
      />
    </>
  );
}

export default RadarComponent;
