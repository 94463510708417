import React from 'react';
import { connect } from 'react-redux';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import gobal_active from "../../assets/images/report/global.svg";
import employee from "../../assets/images/report/employee.svg";
import solidarity from "../../assets/images/report/solidarity.svg";
import transaction from "../../assets/images/report/transaction.svg";

import lorry from "../../assets/images/report/lorry.svg";
import revenue from "../../assets/images/report/revenue_active.svg";
import portfolio from "../../assets/images/report/portfolio_active.svg";
import { sendPageViewGTM } from "../../utils/util";


import { LoadAssessments, SaveCompletedSections, submitAssessment } from '../HomeReAssessment/actions';
import { getAssessments, getCompletedSections } from '../Home/homeReducer';
import { withTranslation } from 'react-i18next';

import "./AssessmentIntroduction.scss";


const mapStateToProps = (state) => {
    return {
     assessments: getAssessments(state),
     completedSections: getCompletedSections(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        LoadAssessments: (ppid) => dispatch(LoadAssessments(ppid, ownProps)),
        SaveCompletedSections: (data) => dispatch(SaveCompletedSections(data)),
        submitAssessment: () => dispatch(submitAssessment())
    }
}

class AssessmentIntroductionPage extends React.Component {

    state = {
        sectionId: '',
        AssessmentName: '',
        imgObj: {
            "Reach": gobal_active,
            "Engage": employee,
            "Transact": transaction,
            "Retain": solidarity,
            "Offering Portfolio": portfolio,
            "Revenue Growth": revenue,
            "Services & Solutions Delivery": lorry
        }
    };

    componentDidMount() {
        
        let sectionID = '';
        if(this.props.match.params && this.props.match.params.secId) {
            sectionID = this.props.match.params.secId;
            this.setState({
                sectionId: sectionID
            }); 
        }
        let ppid = localStorage.getItem('ppid');

        if(sectionID) {
            this.props.LoadAssessments(ppid);
        }

        if(this.props.match.params.assessment) {
            let asmnt = this.props.match.params.assessment;
            let assessmentName = 'Assessment';
            if(asmnt === 'D') {
                assessmentName = 'Digital Assessment';
            } else if(asmnt === 'S') {
                assessmentName = 'Service Assessment';
            }
           
            this.setState ({
                AssessmentName: assessmentName
            });
        }

        //GTM PageLoad
        try {
            let pagePath = window.location ? window.location.pathname: '';
            sendPageViewGTM('Assessment Sub-Section Start Page', pagePath);
        } catch(e){}
    }

    loadAssessment = (section, currentAssessment) => {
        if(section) {
            let sec = section;
            const isQualifierLastAssessment = this.props.match.params.fromQ ? this.props.match.params.fromQ : false;
            let moduleAbbr = '';
            if(currentAssessment.Key.indexOf('Digital') > -1) {
                moduleAbbr = 'D';
            } else if(currentAssessment.Key.indexOf('Service') > -1) {
                moduleAbbr = 'S';
            }
            
            this.props.history.push(`/assessment2022/${moduleAbbr}/${sec.Typeform_ID}/${sec.Key}/${isQualifierLastAssessment}`);
        }
    }

    render() {

        const { assessments, t, i18n } = this.props;
        const { imgObj,AssessmentName } = this.state;
        let sectionsList = [];
        let currentAssessment;
        let sectionData;
        assessments.map((assessment, index) => {
            if(assessment && assessment.sections) {
                assessment.sections.map((section, index) => {
                    sectionsList = [...sectionsList, section];
                    if(section.Typeform_ID === this.state.sectionId) {
                        sectionData = section;
                        currentAssessment = assessment;
                    }
                    
                })
                
            }
        });
         
        return(
            <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100">
                <div className="d-flex hp-layout hp-home">
                    <Header />
                    <div className="hp-content-wrap assessment-intro-wrap flex-column">
                        {sectionData &&
                            <div className="assessment-intro-unit">
                                <div className="assessment-intro-img">
                                    <img src={imgObj[sectionData.Name]}  className="img-fluid"/>
                                </div>
                                <div className="assessment-intro-title">
                                    {AssessmentName} - {sectionData.Name}
                                </div>
                                <div className="assessment-intro-desc">
                                    {sectionData.Description}
                                </div>
                                <div className="assessment-intro-btn hp-btn-v1" onClick={this.loadAssessment.bind(this, sectionData, currentAssessment)}>
                                    {t('ASSESSMENT.ASSESSMENT_INTRODUCTION_OK')}
                                </div>
                            </div>
                        }
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssessmentIntroductionPage));