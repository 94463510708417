import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { MDBCollapse, MDBIcon } from "mdbreact";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import download_img from "../../assets/icons/download.svg";
import { useTranslation } from 'react-i18next';

import { API_BASE_URL } from "../../config/api-config";
import axios from "axios";

import retakePDF from '../../assets/HP-Guide–Assessment Retake.pdf';

import "./faqs.scss";

const FAQs = () => {
  const hasAssessmentSubmitted = localStorage.getItem('hasSubmited') ? localStorage.getItem('hasSubmited') : false;
  const [collapseID, setCollapseID] = useState(null);
  const [isModalOpen, setModalState] = useState(false);
  const [retakeReason, setRetakeReason] = useState("");
  const [helpIssue, setHelpReason] = useState("");
  const [modalMsg, setModalMsg] = useState("");

  const toggleCollapse = (id) => () =>
    setCollapseID(collapseID !== id ? id : "");

  const history = useHistory();
  const goBack = () => history.goBack();
  const { t, i18n } = useTranslation();
  let sendRequest = (param) => {
    var txt;
      //setModalState(true);
      var issue = "";
      var event = '';
      var msg = '';
      if(param === 'help') {
        issue = helpIssue;
        event = 'Send Help Request';
        msg="Thank you for reaching out to us. Your query has been passed to the concerned team. They will reach out to you shortly.";
      } else if(param === 'retake') {
        issue = retakeReason;
        event = 'Retake Assessment';
        msg="Your request has been taken into account; you will get an email from our HELP team after your request gets approved. After which you can revisit the platform within 24 hours to Retake your assessment.";
      }

      if(param && issue) {

        // GTM code
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': event
        });

        let ppid = localStorage.getItem('ppid');
        axios.post(`${API_BASE_URL}/partners/retake-request`, {
          "ppid": ppid,
          "type": param,
          "text": issue
        })
          .then(res => {
              if(res) {
                setModalMsg(msg);
                setModalState(true);
                if(param === 'help') {
                  setHelpReason("");
                } else if(param === 'retake') {
                  setRetakeReason("");
                }
              }
          })
      }
  }

  let toggle = () => {
    setModalState(!isModalOpen);
  }

  let handleRetakeChange = (e) => {
    setRetakeReason(e.target.value);
  }

  let handleHelpChange = (e) => {
    setHelpReason(e.target.value);
  }

  let downloadHelpPDF = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'Download Help PDF',
    });
  }

  return (
    <div className="no-gutter d-flex justify-content-center hp-layout-wrap w-100 faq-help-wrap">
      <div className="d-flex hp-layout hp-home">
        <Header />
        <p className="bread-crumb hp-lite faq-bread-crumb">
          <Link to={`/dashboard/${localStorage.getItem('ppid')}`}>Home</Link> &gt; <span>FAQs</span>
          </p>
        <div className="hp-content-wrap faq-container">
          
          {hasAssessmentSubmitted === 'true' &&
            <div className="help-retake-wrap d-flex flex-column">
              <div className="help-retake-title d-flex">{t('FAQ.RETAKE_TITLE')}</div>
              <div className="help-retake-desc d-flex">{t('FAQ.RETAKE_DESCRIPTION')}</div>
              <div className="help-retake-options d-flex flex-column w-100">
                <div className="help-retake-label">{t('FAQ.RETAKE_LABEL')}</div>
                <div className="mt-2">
                  <textarea className="help-textarea" placeholder={t('FAQ.RETAKE_PLACEHOLDER')} cols="50" onChange={handleRetakeChange} value={retakeReason}/>
                </div>
              </div>
              <div className="help-retake-cta">
                <button className="send-request hp-btn-v1" onClick={ () => sendRequest('retake')}>{t('FAQ.RETAKE_CTA')}</button>
                <a className="download-pdf" onClick={downloadHelpPDF} href={retakePDF} target="_blank">
                  <span><img src={download_img} className="mr-2"/>{t('FAQ.RETAKE_DOWNLOAD_PDF')}</span>
                </a>
              </div>
            </div>
          }

          <div className="help-retake-wrap d-flex flex-column mt-2">
            <div className="help-retake-title d-flex">{t('FAQ.HELP_TITLE')}</div>
            <div className="help-retake-desc d-flex">{t('FAQ.HELP_DESCRIPTION')}</div>
            <div className="help-retake-options d-flex flex-column w-100">
              <div className="mt-2">
                <textarea className="help-textarea" placeholder={t('FAQ.HELP_PLACEHOLDER')} cols="50" onChange={handleHelpChange} value={helpIssue} />
              </div>
            </div>
            <div className="help-retake-cta">
              <button className="send-request hp-btn-v1" onClick={ () => sendRequest('help')}>{t('FAQ.RETAKE_CTA')}</button>
            </div>
          </div>

          <div className="accordion-collapse mt-3">
            <p className="hp-lite toggle-body">
              For any question related to HP Digital & Services Assessment and HP Amplify Program please contact your respective PBM (Partner Business Manager). You can also reach out to us at help@hppartnerassessment.com for further assistance.
            </p>
          </div>

          <p className="text-right hp-regular back-btn" onClick={goBack}>
            Back
          </p>
        </div>
        <Footer />
      </div>
      <MDBModal isOpen={isModalOpen} toggle={toggle} size="sm">
        <MDBModalBody>
            <div className="hp-retake-confirm-msg mt-3">
            {modalMsg}
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <button onClick={toggle} className="hp-help-cta">Okay</button>
            </div>
        </MDBModalBody>
    </MDBModal>
    </div>
  );
};

export default FAQs;
