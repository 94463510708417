import React from "react";
import { MDBIcon } from "mdbreact";
import "./toolkit.scss";

import { connect } from "react-redux";
import {
 MDBContainer,
 MDBBtn,
 MDBModal,
 MDBModalBody,
 MDBModalHeader,
 MDBModalFooter,
} from "mdbreact";

import {
 getDetailedReport,
 getToolkitSection,
} from "../../reducers/toolkitReducer";
import { loadToolkit, loadSectionDetails } from "../../actions/toolkitAction";

import play_icon from "../../assets/images/report/Component48–2.svg";
import videoBg from "../../assets/images/video_bg.png";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
 return {
  toolkit: getDetailedReport(state),
  section: getToolkitSection(state),
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  loadToolkit: () => dispatch(loadToolkit()),
  loadSectionDetails: () => dispatch(loadSectionDetails()),
 };
};

class Tookit extends React.Component {
 state = {
  isModalOpen: false,
  selectedUrl: "",
  selectedType: "",
  selectedContent: null,
 };

 componentDidMount = () => {
  this.props.loadToolkit();
 };

 toggleModal = () => {
  this.setState({
   isModalOpen: !this.state.isModalOpen,
  });
 };

 loadDocument = (url, type, data) => {
  this.setState({
   selectedUrl: url,
   selectedType: type,
   selectedContent: data && data.Code ? data.Code : null,
   videoLink: data && data.VideoLink ? data.VideoLink : null,
   videoName: data && data.VideoLabel ? data.VideoLabel : null,
  });

  this.setState({
   isModalOpen: !this.state.isModalOpen,
  });
 };

 render() {
  const { toolkit } = this.props;
  const { videoName, videoLink } = this.state;

  let tVideo = [];
  let tAudio = [];
  let tPpt = [];
  let tDoc = [];
  let tLink = [];
  let tPdf = [];
  let tZip = [];

  let tVideoMob = [];
  let tAudioMob = [];
  let tPptMob = [];
  let tDocMob = [];
  let tLinkMob = [];
  let tPdfMob = [];
  let tZipMob = [];

  let testArr = [];

  let tVideoCount = 0,
   tAudioCount = 0,
   tPptCount = 0,
   tDocCount = 0,
   tLinkCount = 0,
   tPdfCount = 0,
   tZipCount = 0;
  if (toolkit && toolkit.length > 0) {
   toolkit.map((t, i) => {
    if (t.Type === "Presentation") {
     if (tPpt.length <= 3) {
      tPpt.push(t);
     }

     if (tPptMob.length <= 1) {
      tPptMob.push(t);
     }

     tPptCount++;
    } else if (t.Type === "Word") {
     if (tDoc.length <= 3) {
      tDoc.push(t);
     }

     if (tDocMob.length <= 1) {
      tDocMob.push(t);
     }

     tDocCount++;
    } else if (t.Type === "Video") {
     if (tVideo.length <= 2) {
      tVideo.push(t);
     }

     if (tVideoMob.length <= 1) {
      tVideoMob.push(t);
     }
     tVideoCount++;
    } else if (t.Type === "Audio") {
     if (tAudio.length <= 3) {
      tAudio.push(t);
     }

     if (tAudioMob.length <= 1) {
      tAudioMob.push(t);
     }
     tAudioCount++;
    } else if (t.Type === "Url") {
     if (tLink.length <= 3) {
      tLink.push(t);
     }

     if (tLinkMob.length <= 1) {
      tLinkMob.push(t);
     }
     tLinkCount++;
    } else if (t.Type === "Pdf") {
     if (tPdf.length <= 3) {
      tPdf.push(t);
     }

     if (tPdfMob.length <= 1) {
      tPdfMob.push(t);
     }

     tPdfCount++;
    } else if (t.Type === "Zip") {
     if (tZip.length <= 3) {
      tZip.push(t);
     }

     if (tZipMob.length <= 1) {
      tZipMob.push(t);
     }

     tZipCount++;
    }

    if (t.Code) {
     testArr.push(t);
    }
   });
  }

  return (
   <div className="hp-toolkit-section d-flex flex-column">
    <div className="hp-toolkit-section-title d-flex">Digital Toolkit</div>
    <div className="hp-toolkit-section-desc d-flex">
     Below you will find the recommended tools for you to develop your digital
     capabilities.
    </div>

    {/* VIDEOS */}

    {tVideo && tVideo.length > 0 && (
     <div className="hp-toolkit-sub-section d-flex flex-column">
      {tVideoCount > 3 && (
       <div className="hp-toolkit-more-link hp-toolkit-more-link-dektop">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}

      {tVideoCount > 2 && (
       <div className="hp-toolkit-more-link d-sm-block d-md-none">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}
      <div className="d-flex hp-toolkit-video-wrap col-12">
       {tVideo &&
        tVideo.length > 0 &&
        tVideo.map((tv, i) => (
         <div
          className="hp-toolkit-video-tile col-md-4 col-sm-6 d-none d-md-block"
          key={i}
          onClick={this.loadDocument.bind(this, tv.Url, tv.Type, tv)}
         >
          <div
           className="hp-toolkit-video-img"
           style={{
            background: tVideo?.[i]?.VideoThumbnailUrl
             ? `url(${tVideo?.[i]?.VideoThumbnailUrl}) center no-repeat`
             : `url(${videoBg})`,
           }}
          >
           <img src={play_icon} />
          </div>
          <div className="hp-toolkit-video-title">{tv.Title}</div>
          <div className="hp-toolkit-video-desc">{tv.Description}</div>
         </div>
        ))}

       {/* Mobile */}
       {tVideoMob &&
        tVideoMob.length > 0 &&
        tVideoMob.map((tv, i) => (
         <div
          className="hp-toolkit-video-tile col-md-4 col-sm-6 d-sm-block d-md-none"
          style={{ width: "50%" }}
          key={i}
          onClick={this.loadDocument.bind(this, tv.Url, tv.Type, tv)}
         >
          <div
           className="hp-toolkit-video-img"
           style={{
            background: tVideo?.[i]?.VideoThumbnailUrl
             ? `url(${tVideo?.[i]?.VideoThumbnailUrl}) center no-repeat`
             : `url(${videoBg})`,
           }}
          >
           <img src={play_icon} />
          </div>
          <div className="hp-toolkit-video-title">{tv.Title}</div>
          <div className="hp-toolkit-video-desc">{tv.Description}</div>
         </div>
        ))}
      </div>
     </div>
    )}

    {/* PRESENTATION */}
    {tPpt && tPpt.length > 0 && (
     <div className="hp-toolkit-sub-section d-flex flex-column">
      {tPptCount > 4 && (
       <div className="hp-toolkit-more-link hp-toolkit-more-link-dektop">
        {" "}
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}

      {tPptCount > 2 && (
       <div className="hp-toolkit-more-link d-sm-block d-md-none">
        {" "}
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}
      <div className="d-flex hp-toolkit-content-wrap col-12">
       {tPpt &&
        tPpt.length > 0 &&
        tPpt.map((tpt, i) => (
         <div
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-none d-md-block"
          key={i}
          onClick={this.loadDocument.bind(this, tpt.Url, tpt.Type, tpt)}
         >
          <div className="hp-toolkit-content-img img-ppt"></div>
          <div className="hp-toolkit-content-title">{tpt.Title}</div>
          <div className="hp-toolkit-content-desc">{tpt.Description}</div>
          <div
           className="hp-toolkit-content-link"
           onClick={this.loadDocument.bind(this, tpt.Url, tpt.Type, tpt)}
          >
           Open document
          </div>
         </div>
        ))}

       {/* Mobile */}
       {tPptMob &&
        tPptMob.length > 0 &&
        tPptMob.map((tpt, i) => (
         <div
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-sm-block d-md-none"
          key={i}
          style={{ width: "50%" }}
          onClick={this.loadDocument.bind(this, tpt.Url, tpt.Type, tpt)}
         >
          <div className="hp-toolkit-content-img img-ppt"></div>
          <div className="hp-toolkit-content-title">{tpt.Title}</div>
          <div className="hp-toolkit-content-desc">{tpt.Description}</div>
          <div
           className="hp-toolkit-content-link"
           onClick={this.loadDocument.bind(this, tpt.Url, tpt.Type, tpt)}
          >
           Open document
          </div>
         </div>
        ))}
      </div>
     </div>
    )}

    {/* AUDIO */}
    {tAudio && tAudio.length > 0 && (
     <div className="hp-toolkit-sub-section d-flex flex-column">
      {tAudioCount > 4 && (
       <div className="hp-toolkit-more-link hp-toolkit-more-link-dektop">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}

      {tAudioCount > 2 && (
       <div className="hp-toolkit-more-link d-sm-block d-md-none">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}
      <div className="d-flex hp-toolkit-content-wrap col-12">
       {tAudio &&
        tAudio.length > 0 &&
        tAudio.map((ta, i) => (
         <div
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-none d-md-block"
          key={i}
          onClick={this.loadDocument.bind(this, ta.Url, ta.Type)}
         >
          <div className="hp-toolkit-content-img img-audio"></div>
          <div className="hp-toolkit-content-title">{ta.Title}</div>
          <div className="hp-toolkit-content-desc">{ta.Description}</div>
          <div
           className="hp-toolkit-content-link"
           onClick={this.loadDocument.bind(this, ta.Url, ta.Type)}
          >
           Open document
          </div>
         </div>
        ))}

       {tAudioMob &&
        tAudioMob.length > 0 &&
        tAudioMob.map((ta, i) => (
         <div
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-sm-block d-md-none"
          key={i}
          style={{ width: "50%" }}
          onClick={this.loadDocument.bind(this, ta.Url, ta.Type)}
         >
          <div className="hp-toolkit-content-img img-audio"></div>
          <div className="hp-toolkit-content-title">{ta.Title}</div>
          <div className="hp-toolkit-content-desc">{ta.Description}</div>
          <div
           className="hp-toolkit-content-link"
           onClick={this.loadDocument.bind(this, ta.Url, ta.Type)}
          >
           Open document
          </div>
         </div>
        ))}
      </div>
     </div>
    )}

    {/* ARTICLE */}
    {tDoc && tDoc.length > 0 && (
     <div className="hp-toolkit-sub-section d-flex flex-column">
      {tDocCount > 4 && (
       <div className="hp-toolkit-more-link hp-toolkit-more-link-dektop">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}

      {tDocCount > 2 && (
       <div className="hp-toolkit-more-link d-sm-block d-md-none">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}
      <div className="d-flex hp-toolkit-content-wrap col-12">
       {tDoc &&
        tDoc.length > 0 &&
        tDoc.map((td, i) => (
         <div
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-none d-md-block"
          key={i}
          onClick={this.loadDocument.bind(this, td.Url, td.Type, td)}
         >
          <div className="hp-toolkit-content-img img-file"></div>
          <div className="hp-toolkit-content-title">{td.Title}</div>
          <div className="hp-toolkit-content-desc">{td.Description}</div>
          <div
           className="hp-toolkit-content-link"
           onClick={this.loadDocument.bind(this, td.Url, td.Type, td)}
          >
           Open document
          </div>
         </div>
        ))}

       {tDocMob &&
        tDocMob.length > 0 &&
        tDocMob.map((td, i) => (
         <div
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-sm-block d-md-none"
          key={i}
          style={{ width: "50%" }}
          onClick={this.loadDocument.bind(this, td.Url, td.Type, td)}
         >
          <div className="hp-toolkit-content-img img-file"></div>
          <div className="hp-toolkit-content-title">{td.Title}</div>
          <div className="hp-toolkit-content-desc">{td.Description}</div>
          <div
           className="hp-toolkit-content-link"
           onClick={this.loadDocument.bind(this, td.Url, td.Type, td)}
          >
           Open document
          </div>
         </div>
        ))}
      </div>
     </div>
    )}

    {/* PDF */}
    {tPdf && tPdf.length > 0 && (
     <div className="hp-toolkit-sub-section d-flex flex-column">
      {tPdfCount > 4 && (
       <div className="hp-toolkit-more-link hp-toolkit-more-link-dektop">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}

      {tPdfCount > 2 && (
       <div className="hp-toolkit-more-link d-sm-block d-md-none">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}
      <div className="d-flex hp-toolkit-content-wrap col-12">
       {tPdf &&
        tPdf.length > 0 &&
        tPdf.map((tp, i) => (
         <div
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-none d-md-block"
          key={i}
          onClick={this.loadDocument.bind(this, tp.Url, tp.Type, tp)}
         >
          <div className="hp-toolkit-content-img-pdf img-pdf"></div>
          <div className="hp-toolkit-content-title">{tp.Title}</div>
          <div className="hp-toolkit-content-desc">{tp.Description}</div>
          <div
           className="hp-toolkit-content-link"
           onClick={this.loadDocument.bind(this, tp.Url, tp.Type, tp)}
          >
           Open document
          </div>
         </div>
        ))}

       {tPdfMob &&
        tPdfMob.length > 0 &&
        tPdfMob.map((tp, i) => (
         <div
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-sm-block d-md-none"
          key={i}
          style={{ width: "50%" }}
          onClick={this.loadDocument.bind(this, tp.Url, tp.Type, tp)}
         >
          <div className="hp-toolkit-content-img-pdf img-pdf"></div>
          <div className="hp-toolkit-content-title">{tp.Title}</div>
          <div className="hp-toolkit-content-desc">{tp.Description}</div>
          <div
           className="hp-toolkit-content-link"
           onClick={this.loadDocument.bind(this, tp.Url, tp.Type, tp)}
          >
           Open document
          </div>
         </div>
        ))}
      </div>
     </div>
    )}

    {/* Website Url's */}

    {tLink && tLink.length > 0 && (
     <div className="hp-toolkit-sub-section d-flex flex-column">
      {tLinkCount > 4 && (
       <div className="hp-toolkit-more-link hp-toolkit-more-link-dektop">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}

      {tLinkCount > 2 && (
       <div className="hp-toolkit-more-link d-sm-block d-md-none">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}
      <div className="d-flex hp-toolkit-content-wrap col-12">
       {tLink &&
        tLink.length > 0 &&
        tLink.map((tl, i) => (
         <a
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-none d-md-block"
          key={i}
          href={tl.Url}
          target="_blank"
         >
          <div className="hp-toolkit-content-img img-link"></div>
          <div className="hp-toolkit-content-title">{tl.Title}</div>
          <div className="hp-toolkit-content-desc">{tl.Description}</div>
          <div className="hp-toolkit-content-link">Open link</div>
         </a>
        ))}

       {tLinkMob &&
        tLinkMob.length > 0 &&
        tLinkMob.map((tl, i) => (
         <a
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-sm-block d-md-none"
          key={i}
          style={{ width: "50%" }}
          href={tl.Url}
          target="_blank"
         >
          <div className="hp-toolkit-content-img img-link"></div>
          <div className="hp-toolkit-content-title">{tl.Title}</div>
          <div className="hp-toolkit-content-desc">{tl.Description}</div>
          <div className="hp-toolkit-content-link">Open link</div>
         </a>
        ))}
      </div>
     </div>
    )}

    {tZip && tZip.length > 0 && (
     <div className="hp-toolkit-sub-section d-flex flex-column">
      {tZipCount > 4 && (
       <div className="hp-toolkit-more-link hp-toolkit-more-link-dektop">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}

      {tZipCount > 2 && (
       <div className="hp-toolkit-more-link d-sm-block d-md-none">
        <Link to={`/toolkit`}>Digital Toolkit</Link>
        <span className="hp-toolkit-more-link-icon">
         <MDBIcon icon="arrow-right" />
        </span>
       </div>
      )}
      <div className="d-flex hp-toolkit-content-wrap col-12">
       {tZip &&
        tZip.length > 0 &&
        tZip.map((tz, i) => (
         <a
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-none d-md-block"
          key={i}
          href={tz.Url}
          target="_blank"
         >
          <div className="hp-toolkit-content-img-file img-file"></div>
          <div className="hp-toolkit-content-title">{tz.Title}</div>
          <div className="hp-toolkit-content-desc">{tz.Description}</div>
          <div className="hp-toolkit-content-link">Download File</div>
         </a>
        ))}

       {tZipMob &&
        tZipMob.length > 0 &&
        tZipMob.map((tz, i) => (
         <a
          className="hp-toolkit-content-tile col-md-3 col-sm-6 d-sm-block d-md-none"
          key={i}
          style={{ width: "50%" }}
          href={tz.Url}
          target="_blank"
         >
          <div className="hp-toolkit-content-img-file img-file"></div>
          <div className="hp-toolkit-content-title">{tz.Title}</div>
          <div className="hp-toolkit-content-desc">{tz.Description}</div>
          <div className="hp-toolkit-content-link">Download file</div>
         </a>
        ))}
      </div>
     </div>
    )}

    {/* Modal Window */}
    <MDBModal
     isOpen={this.state.isModalOpen}
     toggle={this.toggleModal}
     style={{ display: "flex", justifyContent: "center" }}
    >
     {this.state.selectedType &&
      (this.state.selectedType === "Presentation" ||
       this.state.selectedType === "Word") &&
      this.state.selectedContent !== null && (
       <MDBModalBody
        style={{ backgroundColor: "#fff", width: "768px", height: "800px" }}
        className="d-none d-md-block doc-modal-body"
        dangerouslySetInnerHTML={{ __html: this.state.selectedContent }}
       ></MDBModalBody>
      )}
     {this.state.selectedType &&
      (this.state.selectedType === "Presentation" ||
       this.state.selectedType === "Word") &&
      this.state.selectedContent !== null && (
       <MDBModalBody
        style={{ backgroundColor: "#fff", width: "350px", height: "500px" }}
        className="d-none doc-modal-body"
        dangerouslySetInnerHTML={{ __html: this.state.selectedContent }}
       ></MDBModalBody>
      )}

     {this.state.selectedType &&
      (this.state.selectedType !== "Presentation" ||
       this.state.selectedType !== "Word") &&
      this.state.selectedContent === null && (
       <MDBModalBody
        style={{ backgroundColor: "#fff", width: "768px", height: "800px" }}
        className="d-none d-md-block"
       >
        <iframe src={this.state.selectedUrl} width="740px" height="740px" />
        {this.state.selectedType === "Video" && (
         <a
          href={videoLink}
          target="_blank"
          className="d-flex align-items-center justify-content-center toolkit-video-link"
         >
          {videoName}
         </a>
        )}
       </MDBModalBody>
      )}
     {this.state.selectedType &&
      (this.state.selectedType !== "Presentation" ||
       this.state.selectedType !== "Word") &&
      this.state.selectedContent === null && (
       <MDBModalBody
        style={{ backgroundColor: "#fff", width: "350px", height: "500px" }}
        className="d-sm-block d-md-none"
       >
        <iframe src={this.state.selectedUrl} width="320px" height="440px" />
        {this.state.selectedType === "Video" && (
         <a
          href={videoLink}
          target="_blank"
          className="d-flex align-items-center justify-content-center toolkit-video-link"
         >
          {videoName}
         </a>
        )}
       </MDBModalBody>
      )}
    </MDBModal>
   </div>
  );
 }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tookit);
