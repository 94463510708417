import { MDBIcon } from "mdbreact";
import { useState, useEffect } from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Link } from "react-router-dom";
import "./footer.scss";
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import FeatureFloatingButton from '../../components/FeatureFloatingButton';

const Footer = () => {
  const [showScroll, setShowScroll] = useState(false);
  const { t, i18n } = useTranslation();
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    if (process.browser) {
      window.addEventListener("scroll", checkScrollTop);
    }
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="hp-footer d-flex">
      <div className="hp-foter-left">
        <Link to="/copyright"><div className="hp-footer-link">{t('COMMON.COMMON_FOOTER_COPYRIGHT')}</div></Link>
        <Link to="/disclaimer"><div className="hp-footer-link">{t('COMMON.COMMON_FOOTER_DISCLAIMER')}</div></Link>
      </div>
      <div className="hp-foter-right">
      <Link to="/privacy"><div className="hp-footer-link">{t('COMMON.COMMON_FOOTER_PRIVACY')}</div></Link>
      <Link to="/terms"><div className="hp-footer-link">{t('COMMON.COMMON_FOOTER_T&C')}</div></Link>

      {/*showScroll && (
        <MDBIcon
          icon="arrow-alt-circle-up"
          size="2x"
          className="scroll-to-top"
          onClick={scrollToTop}
        />
      )*/}
      </div>
      <NotificationContainer/>
      {/** Feature Floating Button*/}
      {localStorage.getItem('reAssesment') === 'false' &&localStorage?.getItem('hasSubmited') === 'true' &&
        <FeatureFloatingButton />
      }
      
    </div>
  );
};

export default Footer;
